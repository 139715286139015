import * as Yup from 'yup';
import { Translate } from '@hooks/useI18n';
import { IFilter, IRootFilter } from '@hooks/useQueryState/utils/types';
import { FilterTypes } from '@utils/constants';
import { ValidationErrorKeys } from '@utils/enums';

const addValidationToSchemaObject = (
  schema: Yup.AnyObjectSchema,
  filter: IFilter | IRootFilter,
  translate: Translate,
): Yup.AnyObjectSchema => {
  if (filter.required && filter.id) {
    return {
      ...schema,
      [filter.id]: Yup.string().required(
        translate(ValidationErrorKeys.Required),
      ),
    } as Yup.AnyObjectSchema;
  }

  return schema;
};

export const getFiltersValidationSchema = (
  filters: Array<IFilter>,
  translate: Translate,
) => {
  const schema = filters.reduce((values, filter) => {
    if (filter.type === FilterTypes.DATE_RANGE && filter.from && filter.to) {
      const updatedValues = addValidationToSchemaObject(
        values,
        filter.from,
        translate,
      );
      return addValidationToSchemaObject(updatedValues, filter.to, translate);
    }

    return addValidationToSchemaObject(values, filter, translate);
  }, {} as Yup.AnyObjectSchema);

  return Yup.object(schema as Yup.AnyObject);
};
