import { Dispatch, SetStateAction } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

interface IOptions {
  componentRestrictions: {
    country: string;
  };
  language: 'fr' | 'de' | 'en' | 'it';
  types: string[];
}

interface ILoadAutocompleteProps {
  inputId: string;
  options: IOptions;
  setAddress: (a: google.maps.places.PlaceResult) => void;
  setAutoComplete: Dispatch<
    SetStateAction<google.maps.places.Autocomplete | null>
  >;
  setQuery: Dispatch<SetStateAction<string>>;
}

const loader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
  version: 'weekly',
  libraries: ['places'],
});

const getPlacesAPI = () => {
  const places = window.google?.maps?.places;

  return places ? Promise.resolve(places) : loader.importLibrary('places');
};

export const loadAutocomplete = async ({
  inputId,
  options,
  setAddress,
  setAutoComplete,
  setQuery,
}: ILoadAutocompleteProps) => {
  const input = document.getElementById(inputId) as HTMLInputElement;
  const google = await getPlacesAPI();
  const autoComplete = new google.Autocomplete(input, options);
  autoComplete.setFields(['address_components', 'formatted_address']);
  autoComplete.addListener('place_changed', () => {
    const address = autoComplete.getPlace();
    if (address) {
      setAddress({
        ...address,
        address_components: [
          ...(address.address_components as google.maps.GeocoderAddressComponent[]),
          {
            long_name: '',
            short_name: '',
            types: ['second_line'],
          },
        ],
      });
      setQuery(address.formatted_address ?? '');
    }
    input.focus();
    input.blur();
  });
  setAutoComplete(autoComplete);
};
