/* eslint-disable max-lines */
import { KTUtil } from './util';

// Component Definition
const KTOffcanvas = function ktOffcanvas(elementId, options) {
  // Main object
  let the = this;

  // Get element object
  const element = KTUtil.getById(elementId);
  const body = KTUtil.getBody();

  if (!element) {
    return;
  }

  // Default options
  let defaultOptions = {
    attrCustom: '',
  };

  /// /////////////////////////
  // ** Private Methods  ** //
  /// /////////////////////////

  const Plugin = {
    construct(opts) {
      if (KTUtil.data(element).has('offcanvas')) {
        the = KTUtil.data(element).get('offcanvas');
      } else {
        // Reset offcanvas
        Plugin.init(opts);

        // Build offcanvas
        Plugin.build();

        KTUtil.data(element).set('offcanvas', the);
      }

      return the;
    },

    init(opts) {
      the.events = [];

      // merge default and user defined options
      the.options = KTUtil.deepExtend({}, defaultOptions, opts);

      the.classBase = the.options.baseClass;
      the.attrCustom = the.options.attrCustom;
      the.classShown = `${the.classBase}-on`;
      the.classOverlay = `${the.classBase}-overlay`;

      the.state = KTUtil.hasClass(element, the.classShown) ? 'shown' : 'hidden';
    },

    build() {
      function setTargetAndToggle(e) {
        e.preventDefault();
        the.target = this;
        Plugin.toggle();
      }

      // offcanvas toggle
      if (the.options.toggleBy) {
        if (typeof the.options.toggleBy === 'string') {
          KTUtil.addEvent(
            KTUtil.getById(the.options.toggleBy),
            'click',
            setTargetAndToggle,
          );
        } else if (the.options.toggleBy && the.options.toggleBy[0]) {
          if (the.options.toggleBy[0].target) {
            the.options.toggleBy.forEach(toggle => {
              KTUtil.addEvent(
                KTUtil.getById(toggle.target),
                'click',
                setTargetAndToggle,
              );
            });
          } else {
            the.options.toggleBy.forEach(toggle => {
              KTUtil.addEvent(
                KTUtil.getById(toggle),
                'click',
                setTargetAndToggle,
              );
            });
          }
        } else if (the.options.toggleBy && the.options.toggleBy.target) {
          KTUtil.addEvent(
            KTUtil.getById(the.options.toggleBy.target),
            'click',
            setTargetAndToggle,
          );
        }
      }

      // offcanvas close
      const closeBy = KTUtil.getById(the.options.closeBy);
      if (closeBy) {
        KTUtil.addEvent(closeBy, 'click', e => {
          e.preventDefault();
          the.target = this;
          Plugin.hide();
        });
      }
    },

    isShown() {
      return the.state === 'shown';
    },

    toggle() {
      Plugin.eventTrigger('toggle');

      if (the.state === 'shown') {
        Plugin.hide();
      } else {
        Plugin.show();
      }
    },

    show() {
      if (the.state === 'shown') {
        return;
      }

      Plugin.eventTrigger('beforeShow');

      Plugin.toggleClass('show');

      // Offcanvas panel
      KTUtil.attr(body, `data-offcanvas-${the.classBase}`, 'on');
      KTUtil.addClass(element, the.classShown);

      if (the.attrCustom.length > 0) {
        KTUtil.attr(body, `data-offcanvas-${the.classCustom}`, 'on');
      }

      the.state = 'shown';

      if (the.options.overlay) {
        the.overlay = KTUtil.insertAfter(
          document.createElement('DIV'),
          element,
        );
        KTUtil.addClass(the.overlay, the.classOverlay);

        KTUtil.addEvent(the.overlay, 'click', e => {
          e.preventDefault();
          Plugin.hide(the.target);
        });
      }

      Plugin.eventTrigger('afterShow');
    },

    hide() {
      if (the.state === 'hidden') {
        return;
      }

      Plugin.eventTrigger('beforeHide');

      Plugin.toggleClass('hide');

      KTUtil.removeAttr(body, `data-offcanvas-${the.classBase}`);
      KTUtil.removeClass(element, the.classShown);

      if (the.attrCustom.length > 0) {
        KTUtil.removeAttr(body, `data-offcanvas-${the.attrCustom}`);
      }

      the.state = 'hidden';

      if (the.options.overlay && the.overlay) {
        KTUtil.remove(the.overlay);
      }

      Plugin.eventTrigger('afterHide');
    },

    toggleClass(mode) {
      const id = KTUtil.attr(the.target, 'id');
      let toggleBy;

      if (
        the.options.toggleBy &&
        the.options.toggleBy[0] &&
        the.options.toggleBy[0].target
      ) {
        the.options.toggleBy.forEach(toggle => {
          if (toggle.target === id) {
            toggleBy = toggle;
          }
        });
      } else if (the.options.toggleBy && the.options.toggleBy.target) {
        toggleBy = the.options.toggleBy;
      }

      if (toggleBy) {
        const el = KTUtil.getById(toggleBy.target);

        if (mode === 'show') {
          KTUtil.addClass(el, toggleBy.state);
        }

        if (mode === 'hide') {
          KTUtil.removeClass(el, toggleBy.state);
        }
      }
    },

    eventTrigger(name, args) {
      // eslint-disable-next-line consistent-return
      the.events.forEach((event, i) => {
        if (event.name === name) {
          if (event.one) {
            if (!event.fired) {
              the.events[i].fired = true;
              return event.handler.call(this, the, args);
            }
          } else {
            return event.handler.call(this, the, args);
          }
        }
      });
    },

    addEvent(name, handler, one) {
      the.events.push({
        name,
        handler,
        one,
        fired: false,
      });
    },
  };

  /// ///////////////////////
  // ** Public Methods ** //
  /// ///////////////////////

  /**
   * Set default options
   * @param options
   */
  the.setDefaults = function setDefaults(opts) {
    defaultOptions = opts;
  };

  /**
   * Check if canvas is shown
   * @returns {boolean}
   */
  the.isShown = () => Plugin.isShown();

  /**
   * Set to hide the canvas
   */
  the.hide = () => Plugin.hide();

  /**
   * Set to show the canvas
   */
  the.show = () => Plugin.show();

  /**
   * Attach event
   * @param name
   * @param handler
   */
  the.on = (name, handler) => Plugin.addEvent(name, handler);

  /**
   * Attach event that will be fired once
   * @param name
   * @param handler
   */
  the.one = (name, handler) => Plugin.addEvent(name, handler, true);

  /// ////////////////////////////
  // ** Plugin Construction ** //
  /// ////////////////////////////
  // Run plugin
  Plugin.construct.apply(the, [options]);
};

export default KTOffcanvas;
