import { NotificationBanner } from '@components/NotificationBanner';
import { Translate } from '@hooks/useI18n';
import { matchingContractUuidsDisplayLimit } from '@utils/constants';
import { AlertSeverity } from '@utils/enums';

interface IGetNotificationProps {
  count?: number;
  translate: Translate;
}

export const MatchingContractsNotification = ({
  count = 0,
  translate,
}: IGetNotificationProps) => {
  const notificationOptions = [
    {
      id: 'matching_contract_uuids',
      message: translate(
        'OPS_PORTAL.TITLE.RISK_TEST_MATCHING_CONTRACTS_LIMIT_MESSAGE',
        { matchingContractUuidsDisplayLimit },
      ),
      severity: AlertSeverity.Warning,
      title: translate('OPS_PORTAL.TITLE.RISK_TEST_MATCHING_CONTRACTS_LIMIT'),
    },
  ];
  return count > matchingContractUuidsDisplayLimit ? (
    <NotificationBanner notifications={notificationOptions} />
  ) : null;
};
