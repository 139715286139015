import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useHasPermission } from '@hooks/useHasPermission';
import { IOpsPortalPauseCommunications } from '@schemas/opsPortal/types/pauseCommunications';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseGetPausedCommunicationsQuery {
  externalContractUuid: string | undefined;
  isInArrears: boolean;
}

export const useGetPausedCommunicationsQuery = ({
  externalContractUuid,
  isInArrears,
}: IUseGetPausedCommunicationsQuery) => {
  const canFetchPausedCommunicationStatus = useHasPermission([
    'ops_portal.can_pause_collections_communications',
  ]);

  const {
    data: pauseCommunicationsData,
    error: pauseCommunicationsError,
    isInitialLoading: isPauseCommunicationsLoading,
  } = useQuery<IOpsPortalPauseCommunications, AxiosError>(
    [QueryKeys.PAUSE_COMMUNICATIONS, externalContractUuid],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.PauseCollectionsCommunications}/${externalContractUuid}`,
      ),
    {
      enabled:
        Boolean(externalContractUuid) &&
        canFetchPausedCommunicationStatus &&
        isInArrears,
    },
  );

  return {
    pauseCommunicationsData,
    pauseCommunicationsError,
    isPauseCommunicationsLoading,
  };
};
