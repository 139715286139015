import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextFieldRHF as TextField } from '@components/TextField';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IIban, IOpsPortalIban } from '@schemas/opsPortal/types/iban';
import { ValidationErrorKeys } from '@utils/enums';
import { validateIBAN } from '@utils/validateIBAN';

interface IChangeIbanFormProps extends Partial<IOpsPortalIban> {
  formID: string;
  handleSubmit: (data: IOpsPortalIban) => void;
}

export const ChangeIbanForm = ({
  formID,
  handleSubmit,
  iban,
}: IChangeIbanFormProps) => {
  const { translate } = useI18n();

  const defaultValues = { iban: iban ?? '' };

  const validationSchema = (newValue?: IIban) =>
    Yup.object({
      iban: Yup.string()
        .required(translate(ValidationErrorKeys.Required))
        .test(
          'not-duplicate',
          translate(ValidationErrorKeys.ValueHasNotChanged),
          value => newValue !== value,
        )
        .test('valid-iban', translate(ValidationErrorKeys.InvalidIban), value =>
          validateIBAN(value),
        ),
    });

  const methods = useForm<IOpsPortalIban>({
    defaultValues,
    resolver: yupResolver(validationSchema(iban)),
  });

  return (
    <FormProvider {...methods}>
      <form id={formID} onSubmit={methods.handleSubmit(handleSubmit)}>
        <TextField label={translate('TITLE.BANK_ACCOUNT_NUMBER')} name="iban" />
      </form>
    </FormProvider>
  );
};
