import { useMemo, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Card } from '@components/Card';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ClientsideTable } from '@components/Table/ClientsideTable';
import { ITableRefObject } from '@components/Table/types';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useEarlySettlementQuoteCreateModal } from '@hooks/useModal/hooks/useEarlySettlementQuoteCreateModal';
import { useEarlySettlementQuoteDisplayModal } from '@hooks/useModal/hooks/useEarlySettlementQuoteDisplayModal';
import { IAmountObject } from '@schemas/opsPortal/types/contractDetails';
import { IOpsPortalEarlySettlements } from '@schemas/opsPortal/types/earlySettlements';
import { QueryKeys, TableNames } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { getEarlySettlementsTableColumns } from './getEarlySettlementsTableColumns';
import { mapEarlySettlementsTableData } from './mapEarlySettlementsTableData';
import { IEarlySettlementsTableColumn } from './types';

interface IEarlySettlementsProps {
  contractUuid?: string;
  externalContractUUID?: string;
  remainingBalance?: IAmountObject;
}

export const EarlySettlements = ({
  contractUuid,
  externalContractUUID,
  remainingBalance,
}: IEarlySettlementsProps) => {
  const canFetchEarlySettlements = useHasPermission([
    'ops_portal.fetch_early_settlement',
  ]);
  const [earlySettlementQuoteUuid, setEarlySettlementQuoteUuid] =
    useState<string>();
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const refToDataTable =
    useRef<ITableRefObject<IEarlySettlementsTableColumn>>(null);

  const { data, error, isLoading } = useQuery<
    IOpsPortalEarlySettlements,
    AxiosError
  >(
    [QueryKeys.EARLY_SETTLEMENTS],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchEarlySettlementList}/${contractUuid}`,
      ),
    {
      enabled: Boolean(contractUuid) && canFetchEarlySettlements,
    },
  );

  const earlySettlementModal = useEarlySettlementQuoteDisplayModal({
    earlySettlementQuoteUuid,
    externalContractUUID,
  });

  const earlySettlementCreateModal = useEarlySettlementQuoteCreateModal({
    remainingBalance,
    contractUuid,
    dependencies: [remainingBalance],
  });

  const columns = useMemo(
    () =>
      getEarlySettlementsTableColumns({
        translate,
        setEarlySettlementQuoteUuid,
        ctaProps: earlySettlementModal?.ctaProps,
      }),
    [earlySettlementModal?.ctaProps, translate],
  );

  const ordersTableData = mapEarlySettlementsTableData({
    data: data?.early_settlement_quotes,
    getLocaleDate,
    translate,
    getLocaleCurrencyValue,
  });

  return canFetchEarlySettlements ? (
    <>
      <Card
        buttons={
          earlySettlementCreateModal
            ? [earlySettlementCreateModal.ctaProps]
            : []
        }
        title={translate('OPS_PORTAL.TITLE.EARLY_SETTLEMENT_QUOTES')}
      >
        <QueryStateRouter error={error} isLoading={isLoading}>
          <ClientsideTable
            columns={columns}
            data={ordersTableData}
            ref={refToDataTable}
            tableName={TableNames.EARLY_SETTLEMENTS}
          />
        </QueryStateRouter>
      </Card>
      {earlySettlementModal?.Modal}
      {earlySettlementCreateModal?.Modal}
    </>
  ) : null;
};
