import { useCallback, useMemo, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { TextField } from '@components/TextField';
import { useI18n } from '@hooks/useI18n/useI18n';
import { AccountSettingsFormNames } from '@utils/constants';
import { FormObserver } from './FormObserver';
import { getCustomAddressComponents } from './getCustomAddressComponents';
import { getFormattedAddress } from './getFormattedAddress';
import { getManualAddressFormData } from './getManualAddressFormData';
import { getValidationSchema } from './getValidationSchema';
import { IAddressValues, IManualAddressFormProps } from './types';

export const ManualAddressForm = ({
  addressComponents,
  formattedAddress,
  formID,
  handleChange,
  handleSubmit,
  initialFormattedAddress,
}: IManualAddressFormProps) => {
  const { translate } = useI18n();

  const initialFormValues = useMemo(
    () => getManualAddressFormData(addressComponents),
    [addressComponents],
  );

  const onFormSubmit = useCallback(
    (formValues: IAddressValues) => {
      const isAddressUpdated = Boolean(formattedAddress);

      if (isAddressUpdated && initialFormattedAddress !== formattedAddress) {
        handleSubmit({
          formatted_address: getFormattedAddress(formValues),
          address_components: getCustomAddressComponents({
            addressComponents,
            formValues,
          }),
        });
      }
    },
    [
      addressComponents,
      formattedAddress,
      handleSubmit,
      initialFormattedAddress,
    ],
  );

  const onChange = useRef((formValues: IAddressValues) => {
    handleChange({
      formatted_address: getFormattedAddress(formValues),
      address_components: getCustomAddressComponents({
        addressComponents,
        formValues,
      }),
    });
  }).current;

  const form = AccountSettingsFormNames.MANUAL_ADDRESS;

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={onFormSubmit}
      validationSchema={getValidationSchema(form)({ translate })}
    >
      <Form id={formID}>
        <TextField
          id="address_street_number"
          label={translate('LABEL.ADDRESS_STREET_NUMBER')}
          name="address_street_number"
        />
        <TextField
          id="address_first_line"
          label={translate('LABEL.ADDRESS_FIRST_LINE')}
          name="address_first_line"
        />
        <TextField
          id="address_second_line"
          label={translate('LABEL.ADDRESS_SECOND_LINE')}
          name="address_second_line"
        />
        <Row>
          <Col sm="6" xs="12">
            <TextField
              containerClassName="mb-2"
              id="address_post_code"
              label={translate('LABEL.ADDRESS_POST_CODE')}
              name="address_post_code"
            />
          </Col>
          <Col sm="6" xs="12">
            <TextField
              containerClassName="mb-2"
              id="address_town"
              label={translate('LABEL.ADDRESS_TOWN')}
              name="address_town"
            />
          </Col>
        </Row>
        <FormObserver onChange={onChange} />
      </Form>
    </Formik>
  );
};
