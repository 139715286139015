import * as Yup from 'yup';
import { Translate } from '@hooks/useI18n';
import { ValidationErrorKeys } from '@utils/enums';

interface IAddressProps {
  initialAddress?: string;
  newAddress?: string;
  translate: Translate;
}

interface ISchema {
  address: (props: IAddressProps) => Yup.AnyObjectSchema;
  manualAddress: () => Yup.AnyObjectSchema;
}

export const getValidationSchema = (form: keyof ISchema) => {
  const schemata = {
    address: ({ initialAddress, newAddress, translate }: IAddressProps) =>
      Yup.object({
        address: Yup.string()
          .test('required', translate(ValidationErrorKeys.Required), () =>
            Boolean(newAddress),
          )
          .test(
            'not-duplicate',
            translate(ValidationErrorKeys.AddressHasNotChanged),
            () => initialAddress !== newAddress,
          ),
      }),

    manualAddress: ({ translate }: IAddressProps) =>
      Yup.object({
        address_street_number: Yup.string().required(
          translate(ValidationErrorKeys.AddressStreetNumberRequired),
        ),
        address_first_line: Yup.string().required(
          translate(ValidationErrorKeys.AddressFirstLineRequired),
        ),
        address_post_code: Yup.string().required(
          translate(ValidationErrorKeys.AddressPostCodeRequired),
        ),
        address_town: Yup.string().required(
          translate(ValidationErrorKeys.AddressTownRequired),
        ),
      }),
  };

  return schemata[form];
};
