import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DropdownRHF as Dropdown } from '@components/Dropdown';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ValidationErrorKeys } from '@utils/enums';
import { IArrearsManagedByRequest, IUpdateArrearsManagedByForm } from './types';

export const UpdateArrearsManagedByForm = ({
  arrearsManagedBy,
  collectionAgencies,
  formID,
  handleSubmit,
}: IUpdateArrearsManagedByForm) => {
  const { translate } = useI18n();

  const defaultValues = {
    arrears_managed_by: arrearsManagedBy,
  };

  const validationSchema = (newValue?: string) =>
    Yup.object({
      arrears_managed_by: Yup.string()
        .required(translate(ValidationErrorKeys.Required))
        .test(
          'not-duplicate',
          translate(ValidationErrorKeys.ValueHasNotChanged),
          value => newValue !== value,
        ),
    });

  const methods = useForm<IArrearsManagedByRequest>({
    defaultValues,
    resolver: yupResolver(validationSchema(arrearsManagedBy)),
  });

  const collectionAgenciesOptions = collectionAgencies
    ? Object.keys(collectionAgencies).map(key => ({
        id: key,
        name: collectionAgencies[key as keyof typeof collectionAgencies],
      }))
    : [];

  return (
    <FormProvider {...methods}>
      <form id={formID} onSubmit={methods.handleSubmit(handleSubmit)}>
        <Dropdown
          label={translate('OPS_PORTAL.TITLE.ARREARS_MANAGED_BY')}
          name="arrears_managed_by"
          options={collectionAgenciesOptions}
        />
      </form>
    </FormProvider>
  );
};
