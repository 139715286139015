import { useEffect } from 'react';
import { useI18n } from '@hooks/useI18n/useI18n';
import { redirectToExternalUrl } from '@utils/redirectToExternalUrl';
import { IMultiLanguageGuideProps } from './types';

export const MultiLanguageGuide = ({
  guides,
  translationRef,
}: IMultiLanguageGuideProps) => {
  const { selectedLanguage, translate } = useI18n();

  useEffect(() => {
    redirectToExternalUrl(
      guides.find(guide => guide.lang === selectedLanguage)?.url,
    );
  }, [guides, selectedLanguage]);

  return <p>{translate(translationRef)}</p>;
};
