const dateFormat: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
};

export const getDateFormat = (
  includeTime: boolean,
): Intl.DateTimeFormatOptions =>
  includeTime ? { ...dateFormat, ...timeFormat } : dateFormat;
