import {
  Row,
  HeaderGroup,
  Cell,
  flexRender as tanstackFlexRender,
} from '@tanstack/react-table';
import { NoTableData } from '@components/NoTableData';

interface ITableBody<TData> {
  flexRender: typeof tanstackFlexRender;
  headerGroups: HeaderGroup<TData>[];
  rowClasses?: string[];
  rows: Row<TData>[];
}

export const TableBody = <TData,>({
  flexRender,
  headerGroups,
  rowClasses = [],
  rows,
}: ITableBody<TData>) => (
  <tbody>
    {rows.length ? (
      rows.map(row => (
        <tr
          className={rowClasses.length ? rowClasses[row.index] : ''}
          key={row.id}
        >
          {row.getVisibleCells().map((cell: Cell<TData, unknown>) => (
            <td key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))
    ) : (
      <NoTableData headerGroups={headerGroups} />
    )}
  </tbody>
);
