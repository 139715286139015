import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ITemplateListItemTriggerId } from '@schemas/opsPortal/types/templateList';
import { ValidationErrorKeys } from '@utils/enums';
import {
  getTemplateFields,
  getTemplateFormConfig,
} from './getCustomEmailFields';
import { IHandleSendEmailProps } from './types';

interface IUseCustomEmailFormProps {
  currency: string;
  defaultValues: IHandleSendEmailProps;
  isInArrears: boolean;
  triggerId: ITemplateListItemTriggerId;
}

export const useCustomEmailForm = ({
  currency,
  defaultValues,
  isInArrears,
  triggerId,
}: IUseCustomEmailFormProps) => {
  const { translate } = useI18n();

  const TemplateFields = useMemo(
    () =>
      getTemplateFields({
        currency,
        isInArrears,
        translate,
        triggerId,
      }),
    [currency, isInArrears, translate, triggerId],
  );

  const { templateFieldDefaultValues, validation } = getTemplateFormConfig(
    isInArrears,
    translate,
    triggerId,
  );

  const methods = useForm<IHandleSendEmailProps>({
    defaultValues: {
      ...defaultValues,
      ...templateFieldDefaultValues,
    },
    resolver: yupResolver(
      Yup.object({
        subject: Yup.string().required(translate(ValidationErrorKeys.Required)),
        ...validation,
      }),
    ),
  });

  return {
    methods,
    TemplateFields,
  };
};
