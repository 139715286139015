import { getDateFormat } from './getDateFormat';

interface IGetLocaleDate {
  date: string;
  formatOverride?: Intl.DateTimeFormatOptions;
  includeTime?: boolean;
  languageCode: string;
}

export const getLocaleDate = ({
  date,
  formatOverride,
  includeTime = false,
  languageCode,
}: IGetLocaleDate) => {
  const dateFormat = formatOverride ?? getDateFormat(includeTime);

  return Intl.DateTimeFormat(languageCode, dateFormat).format(new Date(date));
};
