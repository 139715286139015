import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IOpsPortalAssignmentHistory } from '@schemas/opsPortal/types/assignmentHistory';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseGetAssignmentHistoryQuery {
  externalContractUUID?: string;
}

export const useGetAssignmentHistoryQuery = ({
  externalContractUUID,
}: IUseGetAssignmentHistoryQuery) => {
  const {
    data: assignmentHistoryData,
    error: assignmentHistoryError,
    isLoading: assignmentHistoryLoading,
  } = useQuery<IOpsPortalAssignmentHistory, AxiosError>(
    [QueryKeys.ASSIGNMENT_HISTORY, externalContractUUID],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.AssignmentHistory}/${externalContractUUID}`,
        {},
      ),
    {
      enabled: Boolean(externalContractUUID),
    },
  );
  return {
    assignmentHistoryData,
    assignmentHistoryError,
    assignmentHistoryLoading,
  };
};
