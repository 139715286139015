import messagesDe from './de.json';
import messagesEn from './en.json';
import messagesFr from './fr.json';
import messagesIt from './it.json';

export const translations = {
  de: messagesDe,
  en: messagesEn,
  fr: messagesFr,
  it: messagesIt,
};
