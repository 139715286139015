import { Modal, ModalFormButtons } from '@hooks/useModal/components';
import { IModalProps, IUseModalResponse } from './types';

interface ISelectedModalProps {
  modal: IUseModalResponse;
  modalProps: IModalProps;
}

export const ModalSelect = ({ modal, modalProps }: ISelectedModalProps) => {
  const { closeModal, formStatusMessage, isModalShowing, modalName } = modal;

  const {
    allowoverflow = false,
    cancelText,
    clickHandler,
    customButtons,
    extratall = false,
    formID,
    fullscreen = false,
    hasHeader,
    hideModal,
    isDangerousSubmit = false,
    isFormProcessing = false,
    isLoading = false,
    loadingOverlay = false,
    loadingOverlayText,
    modalContent,
    showFormButtons,
    submitDisabled = false,
    submitText,
    title,
    width,
  } = modalProps;

  return (
    <Modal
      allowoverflow={allowoverflow}
      extratall={extratall}
      footer={
        <ModalFormButtons
          cancelText={cancelText}
          clickHandler={clickHandler}
          customButtons={customButtons}
          formID={formID}
          hideModal={hideModal ?? closeModal}
          isDangerousSubmit={isDangerousSubmit}
          isFormProcessing={isFormProcessing}
          showFormButtons={showFormButtons ?? !formStatusMessage}
          submitDisabled={submitDisabled}
          submitText={submitText}
        />
      }
      fullscreen={fullscreen}
      hasHeader={hasHeader}
      hideModal={closeModal}
      isLoading={isLoading}
      isModalShowing={isModalShowing}
      key={modalName}
      loadingOverlay={loadingOverlay}
      loadingOverlayText={loadingOverlayText}
      title={title}
      width={width}
    >
      {modalContent}
    </Modal>
  );
};
