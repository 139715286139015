import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IOpsPortalContractRiskAndWaterfall } from '@schemas/opsPortal/types/contractRiskAndWaterfall';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseGetRiskAndWaterfall {
  externalContractUuid?: string;
}

export const useGetRiskAndWaterfall = ({
  externalContractUuid,
}: IUseGetRiskAndWaterfall) => {
  const {
    data: riskAndWaterfallData,
    error: riskAndWaterfallError,
    isLoading: isRiskAndWaterfallLoading,
  } = useQuery<IOpsPortalContractRiskAndWaterfall, AxiosError>(
    [QueryKeys.RISK_AND_WATERFALL, externalContractUuid],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.RiskAndWaterfall}/${externalContractUuid}`,
      ),
  );

  return {
    isRiskAndWaterfallLoading,
    riskAndWaterfallData,
    riskAndWaterfallError,
  };
};
