import { AxiosError } from 'axios';
import { Translate } from '@hooks/useI18n';

interface IGetErrorMessageKeyProps {
  error: AxiosError;
  genericErrorMessageKey?: string;
  translate: Translate;
}

export const getErrorMessage = ({
  error,
  genericErrorMessageKey = 'ERROR.UNEXPECTED_ERROR',
  translate,
}: IGetErrorMessageKeyProps) => {
  const {
    request: { responseURL, status },
  } = error;

  const errorMessages: Record<number, string> = {
    403: 'ERROR.UNAUTHORISED_REQUEST',
    404: 'ERROR.RESOURCE_NOT_FOUND',
  };

  const errorMessage = translate(
    errorMessages[status] || genericErrorMessageKey,
  );

  return `${errorMessage}: ${responseURL}`;
};
