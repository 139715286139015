import { useCallback, useState } from 'react';
import { Formik, Form } from 'formik';
import { useI18n } from '@hooks/useI18n/useI18n';
import { AccountSettingsFormNames } from '@utils/constants';
import { AddressFinder } from './AddressFinder';
import { CountrySelector } from './CountrySelector';
import { getValidationSchema } from './getValidationSchema';
import { ISearchAddressFormProps } from './types';

export const SearchAddressForm = ({
  address,
  formID,
  handleChange,
  handleSubmit,
  initialAddress,
}: ISearchAddressFormProps) => {
  const { translate } = useI18n();

  const initialFormikValues = {
    ...initialAddress,
  };
  const [selectedCountry, setSelectedCountry] = useState(
    initialAddress.country_alpha_2 ?? '',
  );

  const onAddressChanged = useCallback(
    a => {
      handleChange(a);
    },
    [handleChange],
  );

  const onSubmit = useCallback(() => handleSubmit(), [handleSubmit]);

  const form = AccountSettingsFormNames.ADDRESS;

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema(form)({
        initialAddress: initialFormikValues.formatted_address ?? '',
        newAddress: address.formatted_address,
        translate,
      })}
    >
      <Form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
      >
        <CountrySelector
          countryAlpha2={selectedCountry}
          updateCountry={setSelectedCountry}
        />
        <AddressFinder
          address={address.formatted_address}
          country={selectedCountry}
          setAddress={onAddressChanged}
        />
      </Form>
    </Formik>
  );
};
