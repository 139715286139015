import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { IConsumerPortalAddressData } from '@schemas/consumerPortal/types/addressData';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { getErrorMessage } from '@utils/getErrorMessage';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { EditAddressForm } from './EditAddressForm';

interface IUseEditAddressModalProps extends ISharedModalProps {
  data?: IConsumerPortalAddressData;
}

export const useEditAddressModal = (
  props: IUseEditAddressModalProps,
): Modal => {
  const { data } = props;
  const { translate } = useI18n();

  const modal = useModal({
    sharedProps: props,
    modalName: 'editAddressModal',
    permissions: [
      'merchant_portal.can_view_account_info',
      'consumer_portal.change_consumeruser',
      'ops_portal.view_merchant',
    ],
    buttonTranslationKey: 'PAGE.ACCOUNT_SETTINGS.EDIT_ADDRESS',
  });

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, AxiosError>(
    requestData =>
      postDataToEndpoint({
        endpoint: Endpoints.FetchUserAddress,
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CONSUMER_ADDRESS]);
        modal.resolveForm(
          translate('PAGE.ACCOUNT_SETTINGS.ADDRESS_SUCCESSFULLY_EDITED'),
        );
      },
      onError: error => {
        modal.resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey:
              'PAGE.ACCOUNT_SETTINGS.UNABLE_TO_EDIT_ADDRESS',
            translate,
          }),
        );
      },
    },
  );

  const handleSubmit = useCallback(
    values => {
      mutate(values);
    },
    [mutate],
  );

  const formID = 'edit-address';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={modal.formStatusMessage}
      isLoading={isLoading}
    >
      <EditAddressForm
        data={data}
        formID={formID}
        handleSubmit={handleSubmit}
      />
    </ModalStatusRouter>
  );

  const modalProps = {
    formID,
    isFormProcessing: isLoading,
    isLoading,
    modalContent,
    title: translate('PAGE.ACCOUNT_SETTINGS.EDIT_ADDRESS'),
  };

  return getModalDetails({ modal, modalProps });
};
