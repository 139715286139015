import { Translate } from '@hooks/useI18n';
import { FilterTypes } from '@utils/constants';
import { IFilter, IMerchantOption } from './types';

interface IGetMerchantsDropdown {
  merchantsOptions?: IMerchantOption[];
  queryParams: Record<string, string>;
  translate: Translate;
}

export const getMerchantsDropdown = ({
  merchantsOptions,
  queryParams,
  translate,
}: IGetMerchantsDropdown): IFilter => ({
  accessorKey: 'merchantName',
  id: 'merchant',
  label: translate('OPS_PORTAL.TITLE.MERCHANTS'),
  options: merchantsOptions,
  type: FilterTypes.DROPDOWN,
  value: queryParams?.merchant,
});
