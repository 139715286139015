import { ModalProgressBar } from '@metronic/_partials/controls';
import { ModalSize } from './enums';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from './Modal.styles';
import { ModalLoadingOverlay } from './ModalLoadingOverlay';

interface IModal {
  allowoverflow?: boolean;
  children: React.ReactNode;
  extratall?: boolean;
  footer?: React.ReactNode;
  fullscreen?: boolean;
  hasHeader?: boolean;
  hideModal?: () => void;
  isLoading?: boolean;
  isModalShowing?: boolean;
  loadingOverlay?: boolean;
  loadingOverlayText?: string;
  title?: string;
  width?: ModalSize;
}

export const Modal = ({
  allowoverflow = false,
  children,
  extratall = false,
  footer,
  fullscreen = false,
  hasHeader = true,
  hideModal,
  isLoading = false,
  isModalShowing = false,
  loadingOverlay = false,
  loadingOverlayText,
  title,
  width,
}: IModal) => (
  <ModalContainer
    extratall={Boolean(extratall).toString()}
    fullscreen={Boolean(fullscreen).toString()}
    onHide={hideModal}
    show={isModalShowing}
    width={width}
  >
    {isLoading && <ModalProgressBar />}
    {hasHeader ? (
      <ModalHeader closeButton={true}>
        <ModalTitle as="h2">{title}</ModalTitle>
      </ModalHeader>
    ) : null}
    <ModalBody $allowoverflow={allowoverflow}>
      {children}
      {loadingOverlay ? (
        <ModalLoadingOverlay
          isLoading={isLoading}
          loadingText={loadingOverlayText}
        />
      ) : null}
    </ModalBody>
    <ModalFooter>{footer}</ModalFooter>
  </ModalContainer>
);
