import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { QueryKeys } from '@utils/constants';
import { downloadPdfFromBlob } from '@utils/downloadPdfFromBlob';
import { Endpoints } from '@utils/enums';
import { getErrorMessage } from '@utils/getErrorMessage';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { ArrearsAmountForm } from './ArrearsAmountForm';

interface IDownloadQrPdfModalProps extends ISharedModalProps {
  uuid?: string;
}

export const useDownloadQrPdfModal = (
  props: IDownloadQrPdfModalProps,
): Modal => {
  const { uuid } = props;
  const { translate } = useI18n();

  const modal = useModal({
    sharedProps: props,
    modalName: 'downloadQrPdfModal',
    buttonTranslationKey: 'OPS_PORTAL.TITLE.DOWNLOAD_QR_PDF',
    permissions: ['ops_portal.view_contract'],
  });
  const { formStatusMessage, resolveForm } = modal;

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<ArrayBuffer, AxiosError, void>(
    requestData =>
      postDataToEndpoint({
        endpoint: `${Endpoints.DownloadQrPdf}/${uuid}`,
        requestData,
        config: { responseType: 'arraybuffer' },
      }),
    {
      onSuccess: response => {
        downloadPdfFromBlob({
          filename: `${uuid}_QR_Payment`,
          pdfData: response,
        });
        queryClient.invalidateQueries([QueryKeys.DOWNLOAD_QR_PDF, uuid]);
        resolveForm(translate('OPS_PORTAL.PAGE.CONTRACTS.PDF_DOWNLOADED'));
      },
      onError: error => {
        resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey: 'ERROR.FILE_DOWNLOAD',
            translate,
          }),
        );
      },
    },
  );

  const handleSubmit = useCallback(
    values => {
      mutate(values);
    },
    [mutate],
  );

  const formID = 'download-qr-pdf';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isLoading}
    >
      <ArrearsAmountForm formID={formID} handleSubmit={handleSubmit} />
    </ModalStatusRouter>
  );

  const modalProps = {
    formID,
    isLoading,
    modalContent,
    title: translate('OPS_PORTAL.TITLE.DOWNLOAD_QR_PDF'),
  };

  return getModalDetails({ modal, modalProps });
};
