import { useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { useQueries } from '@tanstack/react-query';
import { Card } from '@components/Card';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ServersideTable } from '@components/Table/ServersideTable';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useViewRawDataModal } from '@hooks/useModal/hooks/useViewRawDataModal';
import { useOriginator } from '@hooks/useOriginator';
import { useQueryState } from '@hooks/useQueryState';
import { IOpsPortalApiCalls } from '@schemas/opsPortal/types/apiCalls';
import { IOpsPortalMerchantsList } from '@schemas/opsPortal/types/merchantsList';
import { QueryKeys, TableNames } from '@utils/constants';
import { ButtonVariant, Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { getCursorPaginationCallbacks } from '@utils/getCursorPaginationCallbacks';
import { getMerchantsOptions } from '@utils/getMerchantsOptions';
import { getColumns } from './getColumns';
import { mapData } from './mapData';

export const APICalls = () => {
  const { getLocaleDate, translate } = useI18n();
  const { originator } = useOriginator();

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(TableNames.API_CALLS);

  const [
    {
      data: apiCallsData,
      error: apiCallsError,
      isLoading: isApiCallsDataLoading,
    },
    {
      data: merchantsData,
      error: merchantsError,
      isLoading: isMerchantsLoading,
    },
  ] = useQueries<
    [[IOpsPortalApiCalls, AxiosError], [IOpsPortalMerchantsList, AxiosError]]
  >({
    queries: [
      {
        queryKey: [QueryKeys.API_CALLS, stringifiedQueryParams, originator],
        queryFn: () =>
          fetchDataFromEndpoint(Endpoints.FetchApiRequests, {
            query: { ...queryParams, originator },
          }),
      },
      {
        queryKey: [QueryKeys.FULL_MERCHANT_LIST, originator],
        queryFn: () =>
          fetchDataFromEndpoint(Endpoints.FetchMerchantsAll, {
            query: { originator },
          }),
      },
    ],
  });

  const merchantsOptions = useMemo(
    () => getMerchantsOptions(merchantsData),
    [merchantsData],
  );

  const filters = getFilters({ merchantsOptions });

  const [uuid, setUuid] = useState('');

  const viewRawDataModal = useViewRawDataModal({
    uuid,
    variant: [ButtonVariant.Secondary, ButtonVariant.Sm],
  });

  const columns = useMemo(
    () =>
      getColumns({
        ctaProps: viewRawDataModal?.ctaProps,
        setUuid,
        translate,
      }),
    [translate, viewRawDataModal?.ctaProps],
  );
  const mappedData = mapData({ data: apiCallsData?.results, getLocaleDate });

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  const { gotoNextPage, gotoPrevPage } = getCursorPaginationCallbacks({
    nextCursor: apiCallsData?.next_cursor,
    prevCursor: apiCallsData?.previous_cursor,
    queryState,
    updateQueryParams,
  });

  return (
    <Card title={translate('OPS_PORTAL.TITLE.API_CALLS')}>
      <QueryStateRouter
        error={apiCallsError || merchantsError}
        isLoading={isApiCallsDataLoading || isMerchantsLoading}
      >
        <ServersideTable
          columns={columns}
          data={mappedData}
          filters={filters}
          isCursorPagination={true}
          manageControlledState={updateQueryParams}
          pagination={{
            gotoNextPage,
            gotoPrevPage,
            pageSize: queryParams.pageSize,
            totalItems: apiCallsData?.count,
          }}
          resetFilters={resetFilters}
          tableState={queryState}
        />
      </QueryStateRouter>
      {viewRawDataModal?.Modal}
    </Card>
  );
};
