import { createColumnHelper } from '@tanstack/react-table';
import { ReactTableColumns } from '@appTypes';
import { Translate } from '@hooks/useI18n';
import { Selector } from '@pages/ConsumerDetails/components/CommunicationsCard/Selector';
import { ICommunicationsTableData } from '../types';

export const getCommunicationsTableColumns = (
  translate: Translate,
): ReactTableColumns<ICommunicationsTableData> => {
  const columnHelper = createColumnHelper<ICommunicationsTableData>();

  return [
    columnHelper.display({
      cell: ({ row }) => (
        <Selector
          aria-label={`Select to view ${row.original.communicationType} sent on ${row.original.sentDate}`}
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
          value={row.original.eventUUID}
        />
      ),
      id: 'eventSelector',
    }),
    columnHelper.accessor('communicationTypeLabel', {
      header: translate('OPS_PORTAL.PAGE.COMMUNICATIONS.COMMUNICATION_TYPE'),
    }),
    columnHelper.accessor('sentDate', {
      header: translate('OPS_PORTAL.PAGE.COMMUNICATIONS.SENT_DATE_TIME'),
    }),
  ];
};
