import { DatePicker } from '@components/DatePicker';
import { Dropdown } from '@components/Dropdown';
import { TextField } from '@components/TextField';
import { Translate } from '@hooks/useI18n';
import { getSettlementTypes } from '../utils/getSettlementTypes';

interface IManualPaymentFormFieldsProps {
  currency: string;
  translate: Translate;
}

export const ManualPaymentFormFields = ({
  currency,
  translate,
}: IManualPaymentFormFieldsProps) => {
  return (
    <>
      <TextField
        id="payment_reference"
        label={translate('OPS_PORTAL.TITLE.PAYMENT_REFERENCE')}
        name="payment_reference"
      />
      <DatePicker
        id="date"
        label={translate('PAGE.SETTLEMENTS.SETTLEMENT_DATE')}
        maxDate={new Date()}
        showPopperArrow={false}
      />
      <Dropdown
        id="settlement_type"
        label={translate('OPS_PORTAL.TITLE.SETTLEMENT_TYPE')}
        name="settlement_type"
        options={getSettlementTypes(translate)}
      />
      <TextField
        id="amount"
        label={`${translate('OPS_PORTAL.LABEL.AMOUNT_TO_PAY')} (${currency})`}
        name="amount"
      />
    </>
  );
};
