import * as Yup from 'yup';
import { Translate } from '@hooks/useI18n';
import { ValidationErrorKeys } from '@utils/enums';

export const getValidationSchema = (translate: Translate) => () =>
  Yup.object({
    // Not using password validation on old passwords as they may not comply with password rules.
    old_password: Yup.string().required(
      translate(ValidationErrorKeys.Required),
    ),
    new_password: Yup.string()
      .required(translate(ValidationErrorKeys.Required))
      .min(8, translate(ValidationErrorKeys.MinCharsX, { min: 8 }))
      .max(250, translate(ValidationErrorKeys.MaxCharsX, { max: 250 }))
      .matches(/[a-z]+/, translate(ValidationErrorKeys.MinLowerCase))
      .matches(/[A-Z]+/, translate(ValidationErrorKeys.MinUpperCase))
      .matches(/\d+/, translate(ValidationErrorKeys.MinNumbers))
      .matches(/[^a-zA-Z0-9\s]/, translate(ValidationErrorKeys.MinSymbols)),
    new_password_confirm: Yup.string().test(
      'passwords-match',
      translate(ValidationErrorKeys.PasswordsMustMatch),
      function confirmPasswords(value) {
        return this.parent.new_password === value;
      },
    ),
  });
