// Based on https://akashhamirwasia.com/blog/building-expressive-confirm-dialog-api-in-react/
import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ConfirmDialog } from './ConfirmDialog';
import {
  IConfirmDialogContext,
  IConfirmDialogProviderProps,
  IConfirmOptions,
  IUseRefProp,
} from './types';

const ConfirmDialogContext = createContext<IConfirmDialogContext>(null);

export const ConfirmDialogProvider = ({
  children,
}: IConfirmDialogProviderProps) => {
  const { translate } = useI18n();
  const [options, setOptions] = useState({
    cancelButtonText: translate('BUTTON.CANCEL'),
    confirmation: '',
    isOpen: false,
    okButtonText: translate('BUTTON.CONFIRM'),
    title: translate('TITLE.ARE_YOU_SURE'),
  });
  const fn = useRef<IUseRefProp | null>(null);

  const confirm = useCallback(
    (data: IConfirmOptions): Promise<boolean> => {
      return new Promise(resolve => {
        setOptions({ ...options, ...data, isOpen: true });
        fn.current = (choice: boolean) => {
          resolve(choice);
          setOptions({ ...options, isOpen: false });
        };
      });
    },
    [options, setOptions],
  );

  const dismiss = useCallback(
    () => (fn as React.MutableRefObject<IUseRefProp>).current(false),
    [],
  );

  const proceed = useCallback(
    () => (fn as React.MutableRefObject<IUseRefProp>).current(true),
    [],
  );

  return (
    <ConfirmDialogContext.Provider value={confirm}>
      {children}
      <ConfirmDialog {...options} dismiss={dismiss} proceed={proceed} />
    </ConfirmDialogContext.Provider>
  );
};

export const useConfirm = () => useContext(ConfirmDialogContext);
