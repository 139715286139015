import { Header, Renderable } from '@tanstack/react-table';

interface INonSortingHeader<T> {
  flexRender?: <TProps extends object>(
    Comp: Renderable<TProps>,
    props: TProps,
  ) => React.ReactNode | JSX.Element;
  header: Header<T, unknown>;
}

export const NonSortingHeader = <T,>({
  flexRender,
  header,
}: INonSortingHeader<T>) => (
  <th key={header.id}>
    {flexRender?.(header.column.columnDef.header, header.getContext())}
  </th>
);
