import { Link } from '@components/Common.styles';
import { IGetApplicationReferenceLinkProps } from './types';

export const ApplicationReferenceLink = ({
  data,
  isConsumer,
}: IGetApplicationReferenceLinkProps) => {
  const { applicationReference, contractId } = data;
  return contractId ? (
    <Link to={isConsumer ? `/applications/${contractId}` : contractId}>
      {applicationReference}
    </Link>
  ) : null;
};
