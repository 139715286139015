import InlineSVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '@utils/toAbsoluteUrl';

interface IProgressSVG {
  stepState: string | null;
}

export const ProgressSVG = ({ stepState }: IProgressSVG) => (
  <span className="svg-icon menu-icon pr-3">
    <InlineSVG
      src={toAbsoluteUrl(`/media/svg/shapes/${stepState ?? 'in-progress'}.svg`)}
      width="30px"
    />
  </span>
);
