import { forwardRef, useCallback, useEffect, useState } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  TableOptions,
  TableState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { NoTranslateWrapper } from '@components/Common.styles';
import { Filters } from '@components/Filters';
import { Pagination } from '@components/Pagination';
import { useQueryState } from '@hooks/useQueryState';
import { TableBody, TableHeader } from './components';
import { Table, TableWrapper } from './Table.styles';
import { IClientsideTableProps, ITableRefObject } from './types';
import {
  fuzzyFilter,
  handleFiltering,
  sortCurrency,
  sortNumeric,
} from './utils';

const ClientsideTableComponent = <TData, TCell>(
  {
    className,
    columns,
    columnVisibility = {} as VisibilityState,
    data,
    enableRowSelection = false,
    enableSorting = true,
    handleRowSelect = () => {},
    isInTab = false,
    onHandleFilterChanged = () => {},
    rowClasses,
    showClearFiltersButton = true,
    tableName,
    tableSpecificProps,
  }: IClientsideTableProps<TData, TCell>,
  ref: React.ForwardedRef<ITableRefObject<TData>>,
) => {
  const {
    activateQueryStateHook,
    getFilters,
    queryState,
    resetFilters,
    updateQueryParams,
  } = useQueryState(tableName, tableSpecificProps);

  const [rowSelection, setRowSelection] = useState({});
  const filters = getFilters(tableSpecificProps);
  const preparedQueryState = {
    ...queryState,
    columnFilters: queryState.columnFilters ?? [],
    pagination: {
      pageIndex: Number(queryState.pagination.pageIndex),
      pageSize: Number(queryState.pagination.pageSize),
    },
    sorting: queryState.sorting.map(sortObj => ({
      ...sortObj,
      desc: sortObj.desc ?? false,
    })),
  } as Partial<TableState>;

  const tableOptions: TableOptions<TData> = {
    autoResetPageIndex: false,
    columns,
    data,
    enableMultiSort: true,
    enableSorting: enableSorting && data.length > 1,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: fuzzyFilter,
    onStateChange: updateQueryParams,
    sortingFns: { sortCurrency, sortNumeric },
    state: preparedQueryState,
  };

  if (enableRowSelection) {
    tableOptions.enableMultiRowSelection = false;
    tableOptions.onRowSelectionChange = setRowSelection;
    tableOptions.state = {
      rowSelection,
      columnVisibility,
    };
  }

  const tableInstance = useReactTable(tableOptions);
  const {
    getAllColumns,
    getCanNextPage,
    getCanPreviousPage,
    getCoreRowModel: getCoreRows,
    getFilteredRowModel: getFilteredRows,
    getHeaderGroups,
    getPageCount,
    getPageOptions,
    getPaginationRowModel: getPageModel,
    getRowModel,
    getState,
    nextPage,
    previousPage,
    setPageIndex,
    setPageSize,
    setState,
  } = tableInstance;

  useEffect(() => {
    // Since enableMultiRowSelection is false, we can use [0] instead of filtering by id.
    const selectedRow = tableInstance.getSelectedRowModel().rows[0];
    handleRowSelect(selectedRow);
  }, [handleRowSelect, rowSelection, tableInstance]);

  const handleFilterChanged = useCallback(
    value => {
      onHandleFilterChanged(value);
      setState(value);
    },
    [onHandleFilterChanged, setState],
  );

  const filterHandler = useCallback(
    values =>
      handleFiltering({
        filters,
        setState: handleFilterChanged,
        state: getState(),
        values,
      }),
    [filters, getState, handleFilterChanged],
  );

  const pageSizeHandler = useCallback(
    size => {
      setPageSize(size);
    },
    [setPageSize],
  );

  const headerGroups = getHeaderGroups();

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  if (ref) {
    // eslint-disable-next-line no-param-reassign
    (ref as React.MutableRefObject<ITableRefObject<TData>>).current = {
      rows: getCoreRows().rows.map(row => row.original),
      columns: getAllColumns().map(column => column.columnDef),
    };
  }

  return (
    <>
      <Filters
        filterHandler={filterHandler}
        filters={filters}
        isInTab={isInTab}
        resetFilters={resetFilters}
        showClearFiltersButton={showClearFiltersButton}
      />
      <NoTranslateWrapper>
        <TableWrapper>
          <Table extraClassName={className}>
            <caption>
              <span className="sr-only">
                Column headers with buttons are sortable.
              </span>
            </caption>
            <TableHeader
              flexRender={flexRender}
              headerGroups={headerGroups}
              tableState={queryState}
            />
            <TableBody
              flexRender={flexRender}
              headerGroups={headerGroups}
              rowClasses={rowClasses}
              rows={getRowModel().rows}
            />
          </Table>
        </TableWrapper>

        <Pagination
          canNextPage={getCanNextPage()}
          canPreviousPage={getCanPreviousPage()}
          gotoPage={setPageIndex}
          nextPage={nextPage}
          pageCount={getPageCount()}
          pageIndex={getState().pagination.pageIndex}
          pageOptions={getPageOptions()}
          pageSize={getState().pagination.pageSize}
          previousPage={previousPage}
          rowCount={getPageModel().rows.length}
          setPageSize={pageSizeHandler}
          totalItems={getFilteredRows().rows.length}
        />
      </NoTranslateWrapper>
    </>
  );
};
ClientsideTableComponent.displayName = 'ClientsideTable';

export const ClientsideTable = forwardRef(ClientsideTableComponent) as <
  TData,
  TCell,
>(
  // eslint-disable-next-line no-use-before-define
  props: IClientsideTableProps<TData, TCell> &
    // eslint-disable-next-line no-use-before-define
    React.RefAttributes<ITableRefObject<TData>>,
) => React.ReactElement;
