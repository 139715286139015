import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { ITableParams } from '@hooks/useQueryState/utils/types';
import { IOpsPortalApplications } from '@schemas/opsPortal/types/applications';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

export const useGetApplicationsQuery = (queryParams: ITableParams) => {
  const {
    data: applicationsData,
    error: applicationsError,
    isLoading: isApplicationsLoading,
  } = useQuery<IOpsPortalApplications, AxiosError>(
    [QueryKeys.APPLICATIONS_TABLE_DATA, JSON.stringify(queryParams)],
    () =>
      fetchDataFromEndpoint(Endpoints.FetchApplications, {
        query: queryParams,
      }),
  );

  return {
    applicationsData,
    applicationsError,
    isApplicationsLoading,
  };
};
