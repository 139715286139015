import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components/index';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps } from '@hooks/useModal/types';
import { usePortalError } from '@hooks/usePortalError/usePortalError';
import { QueryKeys } from '@utils/constants';
import { postDataToEndpoint } from '@utils/postDataToEndpoint/postDataToEndpoint';
import { ConfirmDispatchModalContent } from './ConfirmDispatchModalContent';

interface IUseConfirmDispatchModal extends ISharedModalProps {
  uuid?: string;
}

export const useConfirmDispatchModal = (props: IUseConfirmDispatchModal) => {
  const { uuid, variant } = props;
  const { translate } = useI18n();

  const modal = useModal({
    sharedProps: props,
    modalName: 'confirmDispatchModal',
    permissions: [
      'merchant_portal.can_dispatch_order',
      'ops_portal.can_dispatch_order',
    ],
    buttonTranslationKey: 'TITLE.CONFIRM_DISPATCH',
  });

  const queryClient = useQueryClient();
  const { handleAxiosError } = usePortalError();

  const { isLoading, mutate } = useMutation<void, AxiosError<IError>>(
    requestData =>
      postDataToEndpoint({
        endpoint: 'orders/confirm-dispatch',
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.ORDER_DETAILS, uuid]);
        modal.resolveForm(
          translate('PAGE.ORDERS.DISPATCH_SUCCESSFULLY_CONFIRMED'),
        );
      },
      onError: error => {
        handleAxiosError(error);
      },
    },
  );

  const handleSubmit = useCallback(values => mutate(values), [mutate]);

  const formID = 'confirmDispatchForm';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={modal.formStatusMessage}
      isLoading={isLoading}
    >
      <ConfirmDispatchModalContent
        formID={formID}
        handleSubmit={handleSubmit}
        uuid={uuid}
      />
    </ModalStatusRouter>
  );

  const modalProps = {
    formID,
    isDangerousSubmit: true,
    isFormProcessing: isLoading,
    isLoading,
    modalContent,
    submitText: translate('BUTTON.CONFIRM'),
    title: translate('TITLE.CONFIRM_DISPATCH'),
    variant,
  };

  return getModalDetails({ modal, modalProps });
};
