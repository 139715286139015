import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { usePortalError } from '@hooks/usePortalError';
import { IMerchantPortalMerchantStore } from '@schemas/merchantPortal/types/merchantStore';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { putDataToEndpoint } from '@utils/putDataToEndpoint';

export const useUpdateMerchantStoreQuery = (closeModal?: () => void) => {
  const queryClient = useQueryClient();
  const { translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast(translate);

  const { isLoading: updateMerchantStoreLoading, mutate: updateMerchantStore } =
    useMutation<void, AxiosError<IError>, IMerchantPortalMerchantStore>(
      requestData =>
        putDataToEndpoint({
          endpoint: Endpoints.MerchantStore,
          requestData,
        }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKeys.MERCHANT_STORE]);
          queryClient.invalidateQueries([QueryKeys.MERCHANT_STORES_TABLE_DATA]);
          message.success('STATUS.SUCCESSFULLY_UPDATED_MERCHANT_STORE');
          closeModal?.();
        },
        onError: error =>
          handleAxiosError(error, 'STATUS.FAILED_TO_UPDATE_MERCHANT_STORE'),
      },
    );

  return {
    updateMerchantStoreLoading,
    updateMerchantStore,
  };
};
