import { IOpsPortalAssignmentHistory } from '@schemas/opsPortal/types/assignmentHistory';

export const sortAssignmentHistoryData = (
  assignmentHistoryData: IOpsPortalAssignmentHistory,
) => ({
  ...assignmentHistoryData,
  assignment_history: assignmentHistoryData.assignment_history.sort(
    (assignment1, assignment2) =>
      new Date(assignment2?.assignment_start as string).getTime() -
      new Date(assignment1?.assignment_start as string).getTime(),
  ),
});
