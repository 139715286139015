import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { IOrders as IConsumerOrders } from '@schemas/consumerPortal/types/orders';
import { IOrders } from '@schemas/merchantPortal/types/orders';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { OriginationChannel } from '@utils/enums';
import { getFormattedAmount } from '@utils/getFormattedAmount';
import { getFullName } from '@utils/getFullName';
import { getStoreInformation } from '@utils/getStoreInformation';
import { getTranslatedData } from '@utils/getTranslatedData';
import { IOrderTableData } from './types';

interface IPrepareOrderTableData {
  data?: IOrders | IConsumerOrders;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  isConsumerPortal?: boolean;
  translate: Translate;
}

export const prepareOrderTableData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  isConsumerPortal = false,
  translate,
}: IPrepareOrderTableData) =>
  data?.map(dataRow => {
    const {
      amount,
      consumer_name: { first_name: firstName, last_name: lastName },
      created_date: createdDate,
      delay_finalisation: delayFinalisation,
      external_uuid: orderId,
      mediobanca_external_id: mediobancaExternalId,
      merchant_contract_status: merchantContractStatus = 'unknown',
      order_details: orderDetails,
      payment_plan_reference: paymentPlanReference,
      settlement_amount: settlementAmt,
      status = 'unknown',
    } = dataRow;

    const dateConfirmed =
      createdDate && getLocaleDate({ date: createdDate, includeTime: true });

    const merchantReference = orderDetails.order_reference;
    const customerName = getFullName({ firstName, lastName });
    const { currency } = amount;
    const orderValue = getFormattedAmount({
      amount: amount?.amount,
      currency,
      getLocaleCurrencyValue,
    });
    const settlementAmount = getFormattedAmount({
      amount: settlementAmt?.amount,
      currency,
      getLocaleCurrencyValue,
    });
    const translatedStatus = getTranslatedData({
      term: isConsumerPortal ? status : merchantContractStatus,
      translate,
    });

    const { origination_channel: originationChannel } = orderDetails;
    const translatedChannel =
      originationChannel &&
      translate(`LABEL.${originationChannel.toUpperCase()}`);

    const firstProduct = orderDetails.products?.[0];
    const productCode = firstProduct?.sku ? `${firstProduct.sku},` : null;
    const productId = firstProduct?.external_id
      ? `${firstProduct.external_id},`
      : null;
    const product = firstProduct?.name;

    const store = getStoreInformation(orderDetails);
    const storeValues = Object.values(store);

    return addPlaceholderForEmptyValues<IOrderTableData>({
      customerName,
      dateConfirmed,
      dateRaw: createdDate,
      delayFinalisation,
      mediobancaExternalId,
      merchantReference,
      orderId,
      orderValue,
      originationChannel:
        originationChannel === OriginationChannel.InStore && storeValues.length
          ? `${translatedChannel}: ${storeValues.join(' - ')}`
          : translatedChannel,
      paymentPlanReference,
      product,
      productCode,
      productId,
      rawStatus: isConsumerPortal ? status : merchantContractStatus,
      settlementAmount,
      status: translatedStatus,
    });
  }) ?? [];
