import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { Modal } from '@hooks/useModal/types';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { getErrorMessage } from '@utils/getErrorMessage';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { TriggerPaymentForm } from './TriggerPaymentForm';
import {
  IUseTriggerPaymentModalProps,
  IPayload,
  ISubmitHandlerProps,
  IMutationResponse,
} from './types';

export const useTriggerPaymentModal = (
  props: IUseTriggerPaymentModalProps,
): Modal => {
  const { remainderAmount, uuid } = props;
  const { translate } = useI18n();

  const modal = useModal({
    sharedProps: props,
    modalName: 'triggerPaymentModal',
    buttonTranslationKey: 'OPS_PORTAL.TITLE.TRIGGER_PAYMENT',
    permissions: ['ops_portal.trigger_active_payment'],
  });
  const { formStatusMessage, resolveForm } = modal;

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<
    IMutationResponse,
    AxiosError<IError>,
    IPayload
  >(
    data => {
      const endpoint = `${Endpoints.TriggerSinglePayment}/${uuid}`;
      return postDataToEndpoint({ endpoint, requestData: data });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CONTRACT_DETAILS_DATA, uuid]);
        queryClient.invalidateQueries([QueryKeys.MONETA_CONTRACT_DATA, uuid]);
        resolveForm(
          translate('OPS_PORTAL.STATUS.PAYMENT_SUCCESSFULLY_TRIGGERED'),
        );
      },
      onError: error => {
        resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey:
              'OPS_PORTAL.STATUS.UNABLE_TO_TRIGGER_PAYMENT',
            translate,
          }),
        );
      },
    },
  );

  const { amount: maxAmount, currency } = remainderAmount ?? {};

  const handleSubmit = useCallback(
    (data: ISubmitHandlerProps) => {
      if (currency) {
        mutate({ partial_payment: { amount: data.amount, currency } });
      }
    },
    [currency, mutate],
  );

  const formID = 'trigger-active-payment';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isLoading}
    >
      <TriggerPaymentForm
        currency={currency}
        formID={formID}
        handleSubmit={handleSubmit}
        maxAmount={maxAmount}
      />
    </ModalStatusRouter>
  );

  const modalProps = {
    formID,
    isFormProcessing: isLoading,
    isLoading,
    modalContent,
    title: translate('OPS_PORTAL.TITLE.TRIGGER_PAYMENT'),
  };

  return getModalDetails({ modal, modalProps });
};
