export const downloadFile = (url: string, forceDownload = true) => {
  const link = document.createElement('a');
  const href = forceDownload
    ? `${url}&response-content-disposition=attachment;`
    : url;

  link.target = forceDownload ? '_self' : '_blank';
  link.href = href;
  link.download = 'download';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
