export enum EarlySettlementActionType {
  Accept = 'accept',
  Cancel = 'cancel',
}

export enum EarlySettlementStatus {
  Closed = 'closed',
  Completed = 'completed',
  Expired = 'expired',
  Open = 'open',
}
