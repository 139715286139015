import { addHeadersToRequest } from './addHeadersToRequest';
import { handleAxiosError } from './handleAxiosError';
import { verifySession } from './verifySession';

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => verifySession({ config, store }),
    err => Promise.reject(err),
  );

  axios.interceptors.request.use(
    config => addHeadersToRequest({ config, store }),
    err => Promise.reject(err),
  );

  axios.interceptors.response.use(
    response => response,
    err => handleAxiosError(err),
  );
}
