import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { CheckboxFieldRHF } from '@components/CheckboxField';
import { DatePickerRHF } from '@components/DatePicker';
import { DropdownRHF } from '@components/Dropdown';
import { IDropdownOptions } from '@components/Dropdown/DropdownRHF';
import { TextFieldRHF } from '@components/TextField';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ValidationErrorKeys } from '@utils/enums';
import { PartialSettlementType } from './enums';
import { IEarlySettlementQuoteCreateFormProps } from './types';

export const EarlySettlementQuoteCreateForm = ({
  formID,
  handleSubmit,
  remainingBalance,
}: IEarlySettlementQuoteCreateFormProps) => {
  const { getLocaleCurrencyValue, translate } = useI18n();

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const defaultValues = {
    createFullEarlySettlement: true,
    earlySettlementDate: null,
  };

  const validationSchema = Yup.object({
    amount: Yup.number()
      .nullable()
      .when('createFullEarlySettlement', {
        is: false,
        then: schema =>
          schema
            .required(translate(ValidationErrorKeys.Required))
            .max(
              Number(remainingBalance?.amount),
              translate(ValidationErrorKeys.AmountCannotExceedRemainingBalance),
            )
            .typeError(translate(ValidationErrorKeys.AmountMustBeNumber))
            .moreThan(0, translate(ValidationErrorKeys.MinNum1)),
      }),
    earlySettlementDate: Yup.string().required(
      translate(ValidationErrorKeys.Required),
    ),
  });

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const options: IDropdownOptions[] = [
    {
      id: PartialSettlementType.ReducePayment,
      name: translate(
        `OPS_PORTAL.LABEL.${PartialSettlementType.ReducePayment}`,
      ),
    },
    {
      id: PartialSettlementType.ReduceTerm,
      name: translate(`OPS_PORTAL.LABEL.${PartialSettlementType.ReduceTerm}`),
    },
  ];

  const createFullEarlySettlement = methods.watch('createFullEarlySettlement');

  return (
    <FormProvider {...methods}>
      <form id={formID} onSubmit={methods.handleSubmit(handleSubmit)}>
        <Box mb={2}>
          <DatePickerRHF
            label={translate('OPS_PORTAL.TITLE.EARLY_SETTLEMENT_DATE')}
            name="earlySettlementDate"
            showPopperArrow={false}
          />
          <CheckboxFieldRHF
            label={translate(
              'OPS_PORTAL.BUTTON.CREATE_QUOTE_FOR_FULL_REMAINING_BALANCE',
              {
                balance: getLocaleCurrencyValue({
                  currency: remainingBalance.currency,
                  value: remainingBalance.amount,
                }),
              },
            )}
            name="createFullEarlySettlement"
          />
        </Box>
        {!createFullEarlySettlement ? (
          <>
            <TextFieldRHF
              id="amount"
              label={`${translate(
                'OPS_PORTAL.BUTTON.CREATE_QUOTE_FOR_AMOUNT',
              )} (${remainingBalance.currency})`}
              name="amount"
            />
            <DropdownRHF
              id="partialSettlementType"
              label={translate('OPS_PORTAL.TITLE.PARTIAL_SETTLEMENT_TYPE')}
              name="partialSettlementType"
              options={options}
            />
          </>
        ) : null}
      </form>
      <p className="text-danger text-center">
        <strong>{translate('GENERAL.ARE_YOU_SURE')}</strong>
      </p>
    </FormProvider>
  );
};
