import { ProfilePicker } from '@components/ProfilePicker';
import { useConfig } from '@hooks/useConfig';
import { useOriginator } from '@hooks/useOriginator';
import { Menu } from './Menu';
import { NavWrapper } from './Menu.styled';
import { Toggle } from './Toggle';

export const Aside = () => {
  const { showProfilePicker } = useConfig();
  const { userProfiles } = useOriginator();

  return (
    <div
      className="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
      id="kt_aside"
    >
      <Toggle />

      {showProfilePicker && userProfiles.length > 1 ? (
        <ProfilePicker userProfiles={userProfiles} />
      ) : null}

      <NavWrapper>
        <Menu />
      </NavWrapper>
    </div>
  );
};
