import { createColumnHelper } from '@tanstack/react-table';
import { Translate } from '@hooks/useI18n';
import { IAllocationTableColumns } from './types';

export const getAllocationsColumns = (translate: Translate) => {
  const columnHelper = createColumnHelper<IAllocationTableColumns>();

  return [
    columnHelper.accessor('allocationDate', {
      header: translate('LABEL.DATE'),
    }),
    columnHelper.accessor('allocationUuid', {
      header: translate('OPS_PORTAL.LABEL.ALLOCATION_UUID'),
    }),
    columnHelper.accessor('statementLineUuid', {
      header: translate('OPS_PORTAL.LABEL.STATEMENT_LINE_UUID'),
    }),
    columnHelper.accessor('statementLineReference', {
      header: translate('OPS_PORTAL.LABEL.REFERENCE'),
    }),
    columnHelper.accessor('allocationAmount', {
      header: translate('LABEL.AMOUNT'),
    }),
  ];
};
