import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { NoDataAvailable } from '@components/NoDataAvailable';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { useConfig } from '@hooks/useConfig';
import { OrderView } from '@pages/enums';
import { IMerchantPortalMediobancaSettlements } from '@schemas/merchantPortal/types/mediobancaSettlements';
import { IMerchantPortalOrderDetails } from '@schemas/merchantPortal/types/orderDetails';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { OrderDetailsDisplayMerchant } from './components';
import { viewConfig } from './config';

interface IOrderDetails {
  myOrder?: boolean;
}

export const OrderDetailsMerchant = ({ myOrder }: IOrderDetails) => {
  const { showInvoiceDetails } = useConfig();
  const { uuid } = useParams();

  const { endpoint, queryKey } =
    viewConfig[myOrder ? OrderView.MyOrder : OrderView.Order];

  const {
    data: orderDetailsData,
    error: orderDetailsError,
    isLoading: isOrderDetailsLoading,
  } = useQuery<IMerchantPortalOrderDetails, AxiosError>([queryKey, uuid], () =>
    fetchDataFromEndpoint(endpoint, { search: uuid }),
  );

  const {
    data: mediobancaSettlementsData,
    error: mediobancaSettlementsError,
    isInitialLoading: isMediobancaSettlementsLoading,
  } = useQuery<IMerchantPortalMediobancaSettlements, AxiosError>(
    [QueryKeys.MEDIOBANCA_SETTLEMENTS, uuid],
    () =>
      fetchDataFromEndpoint(Endpoints.FetchMediobancaSettlementsData, {
        search: uuid,
      }),
    { enabled: showInvoiceDetails },
  );

  return (
    <QueryStateRouter
      error={mediobancaSettlementsError || orderDetailsError}
      isLoading={isMediobancaSettlementsLoading || isOrderDetailsLoading}
    >
      {orderDetailsData?.length ? (
        <OrderDetailsDisplayMerchant
          mediobancaSettlementsData={mediobancaSettlementsData}
          orderDetailsData={orderDetailsData}
          queryKey={queryKey}
        />
      ) : (
        <NoDataAvailable />
      )}
    </QueryStateRouter>
  );
};
