import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IMerchantPortalMerchantStore } from '@schemas/merchantPortal/types/merchantStore';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

export const useGetMerchantStoreQuery = (storeUuid?: string) => {
  const {
    data: merchantStoreData,
    error: merchantStoreDataError,
    isLoading: isMerchantStoreDataLoading,
  } = useQuery<IMerchantPortalMerchantStore, AxiosError>(
    [QueryKeys.MERCHANT_STORE, storeUuid],
    () => fetchDataFromEndpoint(`${Endpoints.MerchantStore}/${storeUuid}`),
    {
      enabled: Boolean(storeUuid),
    },
  );

  return {
    merchantStoreData,
    merchantStoreDataError,
    isMerchantStoreDataLoading,
  };
};
