import { IDataRow } from '@components/DataDisplay/DataDisplay';
import { GetLocaleDate, Translate } from '@hooks/useI18n';
import { IOpsPortalConsumerDetails } from '@schemas/opsPortal/types/consumerDetails';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { getFullName } from '@utils/getFullName';

interface IGetDataListProps {
  data: IOpsPortalConsumerDetails;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const getDataList = ({
  data,
  getLocaleDate,
  translate,
}: IGetDataListProps): IDataRow[] => {
  const {
    consumer_details: {
      date_of_birth: dateOfBirth,
      dob,
      email,
      first_name: firstName,
      formatted_address: address,
      last_name: lastName,
      phone_number: phoneNumber,
    },
  } = data;

  const birthdate = dateOfBirth ?? dob;

  // It might seem odd to use both dob and dateOfBirth.
  // The endpoint sends both, and I'm coding defensively
  // in case one might be undefined.
  const birthDate = birthdate && getLocaleDate({ date: birthdate });

  return [
    {
      key: translate('OPS_PORTAL.LABEL.NAME'),
      value: getFullName({ firstName, lastName }),
    },
    {
      key: translate('OPS_PORTAL.PAGE.COMMUNICATIONS.EMAIL'),
      value: email,
    },
    {
      key: translate('LABEL.ADDRESS'),
      value: address,
    },
    {
      key: translate('LABEL.PHONE_NUMBER'),
      value: phoneNumber,
    },
    {
      key: translate('OPS_PORTAL.LABEL.DATE_OF_BIRTH'),
      value: birthDate,
    },
  ].map(addPlaceholderForEmptyValues<IDataRow>);
};
