import { Originators } from '@utils/constants';

const pathSections = {
  pagolight: Originators.PAGOLIGHT,
  pagolightpro: Originators.PAGOLIGHTPRO,
  'heylight/it': Originators.PAGOLIGHT,
};

export const getOriginatorByPath = () => {
  const pathSection = window.location.pathname
    .toLowerCase()
    .split('/auth/')[0]
    .substring(1);

  return (
    pathSections[pathSection as keyof typeof pathSections] ||
    Originators.HEIDIPAY
  );
};
