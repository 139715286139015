import { FormProvider, useForm, Controller } from 'react-hook-form';
import { FormHelperText, FormControl, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useGetRiskOverridesList } from '@pages/ApplicationDetails/hooks/queries/useGetRiskOverridesList';
import { useFormValidation } from '../hooks/useFormValidation';
import { IApplicantOverrideFormProps, IFormData } from '../types';
import { ExpiryDatePicker } from './ExpiryDateTimePicker';
import { RiskOverrideCheckboxList } from './OverrideListCheckbox';

export const ApplicantOverrideForm = ({
  formID,
  onSubmit,
}: IApplicantOverrideFormProps) => {
  const { translate } = useI18n();
  const { riskOverridesIsLoading, riskOverridesListData = [] } =
    useGetRiskOverridesList();

  const methods = useForm<IFormData>({
    defaultValues: {
      overrideLists: [],
      expiryTime: null,
    },
    mode: 'onSubmit',
  });

  const { handleDateError, validateDate } = useFormValidation(methods);
  const { errors } = methods.formState;

  return (
    <FormProvider {...methods}>
      <form id={formID} onSubmit={methods.handleSubmit(onSubmit)}>
        <p>{translate('OPS_PORTAL.RISK_TEST_OVERRIDE_INSTRUCTION')}</p>

        <Box marginY="16px">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl error={!!errors.expiryTime?.message} fullWidth={true}>
              <Controller
                control={methods.control}
                name="expiryTime"
                // eslint-disable-next-line react/jsx-no-bind
                render={({ field }) => (
                  <ExpiryDatePicker
                    errorMessage={errors.expiryTime?.message}
                    field={field}
                    onError={handleDateError}
                    translate={translate}
                  />
                )}
                rules={{ validate: validateDate }}
              />
            </FormControl>
          </LocalizationProvider>
        </Box>

        <FormControl error={!!errors.overrideLists?.message} fullWidth={true}>
          <Controller
            control={methods.control}
            name="overrideLists"
            // eslint-disable-next-line react/jsx-no-bind
            render={({ field }) => (
              <RiskOverrideCheckboxList
                field={field}
                isLoading={riskOverridesIsLoading}
                options={riskOverridesListData}
                translate={translate}
              />
            )}
            rules={{ required: translate('OPS_PORTAL.OVERRIDE_REQUIRED') }}
          />

          {errors.overrideLists?.message ? (
            <FormHelperText>{errors.overrideLists.message}</FormHelperText>
          ) : null}
        </FormControl>
      </form>
    </FormProvider>
  );
};
