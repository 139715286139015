import styled from 'styled-components';
import Grid from '@mui/material/Grid';

export const EarlySettlementDataGrid = styled(Grid)`
  > dl {
    padding-top: 1rem;
  }

  margin-bottom: 2rem;
`;

export const NewSettlementScheduleGrid = styled(Grid)`
  .table tr {
    td,
    th {
      padding: 0.25rem 0;
    }
  }
`;
