import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IMerchantPortalMerchantStores } from '@schemas/merchantPortal/types/merchantStores';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

export const useGetMerchantStoresQuery = (
  queryParams?: Record<string, string>,
  stringifiedQueryParams?: string,
) => {
  const {
    data: merchantStoresData,
    error: merchantStoresError,
    isLoading: isMerchantStoresLoading,
  } = useQuery<IMerchantPortalMerchantStores, AxiosError>(
    [QueryKeys.MERCHANT_STORES_TABLE_DATA, stringifiedQueryParams],
    () =>
      fetchDataFromEndpoint(Endpoints.FetchMerchantStores, {
        query: { ...queryParams },
      }),
  );

  return {
    merchantStoresData,
    merchantStoresError,
    isMerchantStoresLoading,
  };
};
