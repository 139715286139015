import { GetLocaleDate } from '@hooks/useI18n';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { getFullName } from '@utils/getFullName';
import { ICustomerDetailsData } from './types';

interface IMapCustomerDetailsDataProps {
  data: IContractDetails;
  getLocaleDate: GetLocaleDate;
}
export const mapCustomerDetailsData = ({
  data,
  getLocaleDate,
}: IMapCustomerDetailsDataProps): ICustomerDetailsData => {
  const {
    consumer_details: {
      date_of_birth: dateOfBirth,
      email,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
    },
    consumer_uuid: customerUUID,
    formatted_billing_address: billingAddress,
    formatted_shipping_address: shippingAddress,
    language,
  } = data;

  const customerName = getFullName({ firstName, lastName });

  const customerAddress = shippingAddress || billingAddress;

  return addPlaceholderForEmptyValues({
    customerUUID: { linkTo: `/consumers/${customerUUID}`, text: customerUUID },
    customerName,
    email,
    customerAddress,
    phoneNumber,
    language,
    dateOfBirth: dateOfBirth && getLocaleDate({ date: dateOfBirth }),
  });
};
