import { missingValuePlaceholder } from '../constants';

export const addPlaceholderForEmptyValues = <T>(
  data: Record<string, unknown>,
): T =>
  Object.keys(data).reduce((obj, key) => {
    return {
      ...obj,
      [key]:
        !data[key] && data[key] !== 0 ? missingValuePlaceholder : data[key],
    };
  }, {} as T);
