import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { ITerminableContract } from '@schemas/opsPortal/types/terminableContracts';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { getTranslatedData } from '@utils/getTranslatedData';
import { ITerminableContractTableColumns } from './types';

interface IMapTerminableContractTableData {
  data?: ITerminableContract[];
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const mapTerminableContractsTableData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  translate,
}: IMapTerminableContractTableData): ITerminableContractTableColumns[] =>
  data?.map((dataRow: ITerminableContract) => {
    const {
      amount,
      arrears_amount: arrearsAmount,
      arrears_managed_by: arrearsManagedBy,
      consumer_name: consumerName,
      contract_uuid: contractUuid,
      created_date: createdDate,
      credit_type: creditType,
      external_contract_uuid: externalContractUuid,
      merchant_name: merchantName,
      origination_channel: originationChannel,
      originator,
      payment_plan_reference: paymentPlanReference,
      status,
    } = dataRow;

    return addPlaceholderForEmptyValues({
      amount: getLocaleCurrencyValue({
        currency: amount.currency,
        value: amount.amount,
      }),
      arrearsAmount: getLocaleCurrencyValue({
        currency: arrearsAmount?.currency,
        value: arrearsAmount?.amount,
      }),
      arrearsManagedBy,
      consumerName: `${consumerName.first_name} ${consumerName.last_name}`,
      contractUuid,
      createdDate: getLocaleDate({ date: createdDate as string }),
      creditType,
      externalContractUuid,
      merchantName,
      origination_channel: originationChannel,
      originator,
      paymentPlanReference,
      selectAll: '',
      status: getTranslatedData({ term: status, translate }),
    });
  }) ?? [];
