interface ISetStoredLanguage {
  key?: string | null;
  language: string;
}

export const setStoredLanguage = ({ key, language }: ISetStoredLanguage) => {
  if (!key) {
    return null;
  }

  return localStorage.setItem(key, JSON.stringify({ language }));
};
