export const prepareReconciliationsValues = ({
  getLocaleCurrencyValue,
  getLocaleDate,
  reconciliations,
  translate,
}) => {
  const settlementTypeMap = {
    SETTLED: translate('TITLE.PAID'),
    REFUNDED: translate('TYPE.REFUND'),
  };

  return reconciliations.map(item => {
    const {
      consumer_name: consumerName,
      currency,
      installments,
      interest,
      invoice_number: invoiceNumber,
      mediobanca_contract_id: mediobancaContractId,
      payment_plan: paymentPlanId,
    } = item;

    const settlementType =
      settlementTypeMap[item.settlement_type.toUpperCase()] || '-';

    const settlementDate = item.settlement_date
      ? getLocaleDate({ date: item.settlement_date })
      : '—';

    const grossAmount = getLocaleCurrencyValue({
      currency,
      value: item.gross_amount,
    });

    const netAmount = getLocaleCurrencyValue({
      currency,
      value: item.net_amount,
    });

    const netFees = getLocaleCurrencyValue({
      currency,
      value: item.net_fee,
    });

    return {
      paymentPlanId,
      interest: getLocaleCurrencyValue({
        currency,
        value: interest,
      }),
      invoiceNumber,
      mediobancaContractId,
      consumerName,
      settlementDate,
      installments,
      grossAmount,
      netAmount,
      netFees,
      settlementType,
    };
  });
};
