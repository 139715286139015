import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

interface ICardContainerProps extends InputHTMLAttributes<HTMLInputElement> {
  isSubsection?: boolean;
  removePaddingBottom?: boolean;
}

interface ICardBodyProps extends InputHTMLAttributes<HTMLInputElement> {
  removePaddingBottom?: boolean;
}

export const CardContainer = styled.section.attrs({
  className: 'card card-custom card-xl-stretch mb-5 mb-xl-8',
})<ICardContainerProps>`
  background-color: ${props => props.color || '#ffffff'};

  .col-lg-6 & {
    flex: 1;
  }

  ${props =>
    props.isSubsection
      ? `
    &.card-custom {box-shadow: none;}
  `
      : ''}
`;

export const CardHeader = styled.header.attrs({
  className: 'card-header border-0 pt-5',
})`
  min-height: 0 !important;

  .tab-content & {
    min-height: 4.45rem !important;
  }
`;

export const CardBody = styled.div.attrs({
  className: 'card-body',
})<ICardBodyProps>`
  position: relative;
  ${props =>
    props.removePaddingBottom &&
    `
    &.card-body.card-body {
      padding-bottom: 0;
    }
  `}
`;

export const CardToolbar = styled.div.attrs({ className: 'card-toolbar' })`
  margin: 0 !important;
`;
