import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { getErrorMessage } from '@utils/getErrorMessage';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { AddNoteForm } from './AddNoteForm';

interface IAddNoteModalProps extends ISharedModalProps {
  uuid: string;
}

export const useAddNoteModal = (props: IAddNoteModalProps): Modal => {
  const { uuid } = props;
  const { translate } = useI18n();

  const modal = useModal({
    sharedProps: props,
    modalName: 'addNoteModal',
    permissions: ['ops_portal.view_contract'],
    buttonTranslationKey: 'OPS_PORTAL.TITLE.ADD_NOTE',
  });
  const { formStatusMessage, resolveForm } = modal;

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    requestData =>
      postDataToEndpoint({
        endpoint: `${Endpoints.AddContractNote}/${uuid}`,
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.NOTES_DATA, uuid]);
        resolveForm(
          translate('OPS_PORTAL.PAGE.CONTRACTS.NOTE_SUCCESSFULLY_ADDED'),
        );
      },
      onError: (error: AxiosError) => {
        resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey:
              'OPS_PORTAL.PAGE.CONTRACTS.UNABLE_TO_ADD_NOTE',
            translate,
          }),
        );
      },
    },
  );

  const handleSubmit = useCallback(
    values => {
      mutate(values);
    },
    [mutate],
  );

  const formID = 'add-note';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isLoading}
    >
      <AddNoteForm formID={formID} handleSubmit={handleSubmit} />
    </ModalStatusRouter>
  );

  const modalProps = {
    formID,
    isLoading,
    modalContent,
    title: translate('OPS_PORTAL.TITLE.ADD_NOTE'),
  };

  return getModalDetails({ modal, modalProps });
};
