import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { Modal } from '@hooks/useModal/types';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { getErrorMessage } from '@utils/getErrorMessage';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { SettlementAdjustmentForm } from './SettlementAdjustmentForm';
import { IAdjustmentRequest, ISettlementAdjustmentModalProps } from './types';

export const useSettlementAdjustmentModal = (
  props: ISettlementAdjustmentModalProps,
): Modal => {
  const { contractUuid, monetaData, uuid } = props;
  const { translate } = useI18n();

  const modal = useModal({
    sharedProps: props,
    modalName: 'contractAdjustmentsModal',
    buttonTranslationKey: 'OPS_PORTAL.BUTTON.ADJUSTMENTS',
    permissions: ['ops_portal.record_payment'],
  });
  const { formStatusMessage, resolveForm } = modal;

  const queryClient = useQueryClient();

  const currency = monetaData?.currency;
  const { isLoading: isAdjustmentLoading, mutate: mutateAdjustment } =
    useMutation<void, AxiosError, IAdjustmentRequest>(
      requestData => {
        if (requestData.delete_settlement) {
          return postDataToEndpoint({
            endpoint: Endpoints.DeleteSettlement,
            requestData: {
              settlement_uuid: requestData.settlement_uuid,
              contract_uuid: contractUuid,
            },
          });
        }
        return postDataToEndpoint({
          endpoint: Endpoints.AdjustSettlement,
          requestData: {
            contract_uuid: contractUuid,
            settlement_uuid: requestData.settlement_uuid,
            amount: {
              amount: requestData.amount,
              currency,
            },
          },
        });
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            QueryKeys.CONTRACT_DETAILS_DATA,
            uuid,
          ]);
          await queryClient.invalidateQueries([
            QueryKeys.MONETA_CONTRACT_DATA,
            uuid,
          ]);
          resolveForm(translate('OPS_PORTAL.LABEL.ADJUSTMENT_SUCCESSFUL'));
        },
        onError: (error: AxiosError) => {
          resolveForm(
            getErrorMessage({
              error,
              genericErrorMessageKey:
                'OPS_PORTAL.LABEL.UNABLE_TO_PROCESS_ADJUSTMENT',
              translate,
            }),
          );
        },
      },
    );
  const handleSubmit = useCallback(
    values => {
      mutateAdjustment(values);
    },
    [mutateAdjustment],
  );

  const formID = 'adjustSettlement';

  const modalContent = monetaData ? (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isAdjustmentLoading}
    >
      <SettlementAdjustmentForm
        contractSettlements={monetaData.settlements}
        contractUuid={contractUuid}
        formID={formID}
        handleSubmit={handleSubmit}
      />
    </ModalStatusRouter>
  ) : (
    <div>{translate('STATUS.NO_DATA_AVAILABLE')}</div>
  );

  const modalProps = {
    formID,
    isDangerousSubmit: true,
    isFormProcessing: isAdjustmentLoading,
    isLoading: isAdjustmentLoading,
    modalContent,
    submitText: translate('OPS_PORTAL.BUTTON.ADJUST_SETTLEMENT'),
    title: translate('OPS_PORTAL.TITLE.ADJUSTMENTS'),
  };

  return getModalDetails({ modal, modalProps });
};
