import { Copyright as StyledCopyright } from '@auth/pages/Auth.styles';

interface ICopyright {
  companyLegalName: string;
}

export const Copyright = ({ companyLegalName }: ICopyright) => (
  <StyledCopyright>
    &copy; 2020 - {new Date().getFullYear()} {companyLegalName}
  </StyledCopyright>
);
