import { useCallback, useState } from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePickerRHF } from '@components/DatePicker';
import { TextBoxRHF } from '@components/TextBox';
import { ToggleButton } from '@components/ToggleButton';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useUserDetails } from '@hooks/useUserDetails';
import { ValidationErrorKeys } from '@utils/enums';
import { ICreateOrModifyBlockPayload, IRemoveBlockPayload } from './types';

interface IApplicantBlockFormProps {
  applicantUuid?: string;
  consumerUuid?: string;
  contractUuid?: string;
  formID: string;
  handleSubmit: SubmitHandler<
    ICreateOrModifyBlockPayload | IRemoveBlockPayload
  >;
  isModifyApplicantBlock: boolean;
  isRemoveApplicantBlock: boolean;
}

export const ApplicantBlockForm = ({
  applicantUuid = '',
  consumerUuid = '',
  contractUuid = '',
  formID,
  handleSubmit,
  isModifyApplicantBlock,
  isRemoveApplicantBlock,
}: IApplicantBlockFormProps) => {
  const { translate } = useI18n();
  const { email } = useUserDetails();

  const [specifyBlockUntilDate, setSpecifyBlockUntilDate] = useState(false);
  const toggleSpecifyBlockUntilDate = useCallback(() => {
    setSpecifyBlockUntilDate(prev => !prev);
  }, []);

  const defaultValues = {
    applicant_uuid: applicantUuid,
    consumer_uuid: consumerUuid,
    contract_uuid: contractUuid,
    ...(isRemoveApplicantBlock
      ? { deactivation_reason: '' }
      : { block_reason: '', date_to_unblock: '' }),
    user: email,
  };

  const required = translate(ValidationErrorKeys.Required);

  const validationBlockUntilDate = specifyBlockUntilDate
    ? { date_to_unblock: Yup.string().required(required) }
    : { date_to_unblock: Yup.string() };

  const validationSchema = Yup.object({
    ...(isRemoveApplicantBlock
      ? { deactivation_reason: Yup.string().required(required) }
      : {
          ...validationBlockUntilDate,
          block_reason: Yup.string().required(required),
        }),
  });

  const methods = useForm({
    context: specifyBlockUntilDate,
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);

  const getLabelKey = () => {
    if (isModifyApplicantBlock) {
      return 'OPS_PORTAL.LABEL.REASON_FOR_MODIFYING_BLOCK';
    }
    if (isRemoveApplicantBlock) {
      return 'OPS_PORTAL.LABEL.REASON_FOR_REMOVING_BLOCK';
    }
    return 'OPS_PORTAL.LABEL.REASON_FOR_CREATING_BLOCK';
  };

  return (
    <FormProvider {...methods}>
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <TextBoxRHF
          label={translate(getLabelKey())}
          name={isRemoveApplicantBlock ? 'deactivation_reason' : 'block_reason'}
        />

        {isRemoveApplicantBlock ? null : (
          <>
            <ToggleButton
              className="form-group"
              isPressed={specifyBlockUntilDate}
              label={translate('OPS_PORTAL.LABEL.BLOCK_UNTIL_SPECIFIC_DATE')}
              onClick={toggleSpecifyBlockUntilDate}
            />

            {specifyBlockUntilDate ? (
              <DatePickerRHF
                label={translate('OPS_PORTAL.LABEL.BLOCK_APPLICANT_UNTIL_DATE')}
                minDate={minDate}
                name="date_to_unblock"
                showPopperArrow={false}
              />
            ) : null}
          </>
        )}
      </form>
    </FormProvider>
  );
};
