/* eslint-disable max-lines */
import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Permissions, ValueOf } from '@appTypes';
import { TranslateKey } from '@hooks/useI18n';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import {
  IAdjustmentRequest,
  IManualPaymentRequest,
  IPrepaymentRequest,
  IReimbursementRequest,
  PaymentSubmitRequest,
} from '@hooks/useModal/hooks/usePaymentModal/types';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails.d';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { PaymentTypes, QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { getErrorMessage } from '@utils/getErrorMessage';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { usePaymentModal } from '../usePaymentModal';

export interface IContractPaymentModalProps extends ISharedModalProps {
  contractUuid?: string;
  data?: IContractDetails;
  monetaData?: IOpsPortalMonetaContract;
  uuid?: string;
}

interface IContractPaymentModalSubProps extends IContractPaymentModalProps {
  buttonTranslationKey: TranslateKey;
  modalName: string;
  paymentType: ValueOf<typeof PaymentTypes>;
  permissions: Permissions;
  submitTextKey: TranslateKey;
  titleKey: TranslateKey;
}

export const useContractPaymentModal = (
  props: IContractPaymentModalSubProps,
): Modal => {
  const {
    buttonTranslationKey,
    data,
    modalName,
    monetaData,
    paymentType,
    permissions,
    submitTextKey,
    titleKey,
    uuid,
  } = props;
  const { translate } = useI18n();

  const modal = useModal({
    sharedProps: props,
    buttonTranslationKey,
    modalName,
    permissions,
  });
  const { resolveForm } = modal;

  const queryClient = useQueryClient();

  const { isLoading: isManualPaymentLoading, mutate: mutateManualPayment } =
    useMutation(
      (requestData: IManualPaymentRequest) => {
        const { date, ...rest } = requestData;
        return postDataToEndpoint({
          endpoint: Endpoints.MakeManualPayment,
          requestData: {
            ...rest,
            settlement_date: date,
          },
        });
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            QueryKeys.CONTRACT_DETAILS_DATA,
            uuid,
          ]);
          await queryClient.invalidateQueries([
            QueryKeys.MONETA_CONTRACT_DATA,
            uuid,
          ]);
          resolveForm(translate('OPS_PORTAL.LABEL.MANUAL_PAYMENT_SUCCESSFUL'));
        },
        onError: (error: AxiosError) => {
          resolveForm(
            getErrorMessage({
              error,
              genericErrorMessageKey:
                'OPS_PORTAL.LABEL.UNABLE_TO_PROCESS_MANUAL_PAYMENT',
              translate,
            }),
          );
        },
      },
    );

  const { isLoading: isPrepaymentLoading, mutate: mutatePrepayment } =
    useMutation(
      (requestData: IPrepaymentRequest) =>
        postDataToEndpoint({ endpoint: Endpoints.PrePayContract, requestData }),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            QueryKeys.CONTRACT_DETAILS_DATA,
            uuid,
          ]);
          await queryClient.invalidateQueries([
            QueryKeys.MONETA_CONTRACT_DATA,
            uuid,
          ]);
          resolveForm(translate('NOTIFICATION.PREPAYMENT_SUCCESSFULLY_ISSUED'));
        },
        onError: (error: AxiosError) => {
          resolveForm(
            getErrorMessage({
              error,
              genericErrorMessageKey: 'LABEL.UNABLE_TO_PROCESS_PREPAYMENT',
              translate,
            }),
          );
        },
      },
    );

  const { isLoading: isReimbursementLoading, mutate: mutateReimbursement } =
    useMutation<void, AxiosError, IReimbursementRequest>(
      requestData => {
        return postDataToEndpoint({
          endpoint: Endpoints.ReimburseSettlement,
          requestData: {
            ...requestData,
            amount: {
              amount: requestData.amount,
              currency: requestData.currency,
            },
          },
        });
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            QueryKeys.CONTRACT_DETAILS_DATA,
            uuid,
          ]);
          await queryClient.invalidateQueries([
            QueryKeys.MONETA_CONTRACT_DATA,
            uuid,
          ]);
          resolveForm(translate('OPS_PORTAL.LABEL.REIMBURSEMENT_SUCCESSFUL'));
        },
        onError: error => {
          resolveForm(
            getErrorMessage({
              error,
              genericErrorMessageKey:
                'OPS_PORTAL.LABEL.UNABLE_TO_PROCESS_REIMBURSEMENT',
              translate,
            }),
          );
        },
      },
    );

  const { isLoading: isAdjustmentLoading, mutate: mutateAdjustment } =
    useMutation<void, AxiosError, IAdjustmentRequest>(
      requestData => {
        return postDataToEndpoint({
          endpoint: Endpoints.AdjustSettlement,
          requestData: {
            ...requestData,
            amount: {
              amount: requestData.amount,
              currency: requestData.currency,
            },
          },
        });
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            QueryKeys.MONETA_CONTRACT_DATA,
            uuid,
          ]);
          resolveForm(translate('OPS_PORTAL.LABEL.ADJUSTMENT_SUCCESSFUL'));
        },
        onError: (error: AxiosError) => {
          resolveForm(
            getErrorMessage({
              error,
              genericErrorMessageKey:
                'OPS_PORTAL.LABEL.UNABLE_TO_PROCESS_ADJUSTMENT',
              translate,
            }),
          );
        },
      },
    );

  const isPaymentLoading =
    isReimbursementLoading ||
    isPrepaymentLoading ||
    isManualPaymentLoading ||
    isAdjustmentLoading;

  const handleManualPaymentSubmit = useCallback(
    values => mutateManualPayment(values),
    [mutateManualPayment],
  );

  const handleSubmit = useCallback(
    (values: PaymentSubmitRequest) => {
      const submitActions = {
        [PaymentTypes.ADJUSTMENTS]: mutateAdjustment,
        [PaymentTypes.REIMBURSEMENT]: mutateReimbursement,
        [PaymentTypes.PREPAYMENT]: mutatePrepayment,
        [PaymentTypes.MANUAL_PAYMENT]: handleManualPaymentSubmit,
      };

      submitActions[paymentType as keyof typeof submitActions](values);
    },
    [
      handleManualPaymentSubmit,
      mutateAdjustment,
      mutatePrepayment,
      mutateReimbursement,
      paymentType,
    ],
  );

  const submitText = translate(submitTextKey);

  const title = translate(titleKey);

  const modalProps = usePaymentModal({
    ...modal,
    data,
    handleSubmit,
    isPaymentLoading,
    monetaData,
    paymentType,
    submitText,
    title,
  });

  return getModalDetails({ modal, modalProps });
};
