import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ValueOf } from '@appTypes';
import { DropdownRHF as Dropdown } from '@components/Dropdown';
import { useI18n } from '@hooks/useI18n/useI18n';
import { SupportedLanguages } from '@utils/constants';
import { ValidationErrorKeys } from '@utils/enums';
import { sortByName } from '@utils/sortByName';
import { ISelectTemplateFormProps } from './types';

export const SelectTemplateForm = ({
  formID,
  handleSelectTemplate,
  language,
  templates = [],
  triggerId,
}: ISelectTemplateFormProps) => {
  const { languageCode, translate } = useI18n();

  const defaultValues = {
    language,
    template: triggerId,
  };

  const validationSchema = Yup.object({
    language: Yup.string().required(translate(ValidationErrorKeys.Required)),
    template: Yup.string().required(translate(ValidationErrorKeys.Required)),
  });

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, setValue, watch } = methods;

  const selectedTemplateName = watch('template');
  const selectedTemplate = templates.find(
    template => template.trigger_id === selectedTemplateName,
  );

  useEffect(() => {
    setValue('language', '');
  }, [selectedTemplateName, setValue]);

  const templateOptions = [
    {
      id: '',
      isPlaceholder: true,
      name: translate('OPS_PORTAL.LABEL.SELECT_EMAIL_TEMPLATE'),
    },
    ...templates
      .map(template => ({
        id: template.trigger_id,
        name: template.display_name,
      }))
      .sort((a, b) => sortByName(a, b, languageCode)),
  ];

  const languageOptions = [
    {
      id: '',
      isPlaceholder: true,
      name: translate('LABEL.SELECT_LANGUAGE'),
    },
    ...(selectedTemplate
      ? selectedTemplate.available_languages
          .map((lang: ValueOf<typeof SupportedLanguages>) => ({
            id: lang,
            name: translate(`OPS_PORTAL.LABEL.${lang.toUpperCase()}`),
          }))
          .sort((a, b) => sortByName(a, b, languageCode))
      : []),
  ];

  return (
    <FormProvider {...methods}>
      <form id={formID} onSubmit={handleSubmit(handleSelectTemplate)}>
        <Dropdown
          label={translate('OPS_PORTAL.LABEL.EMAIL_TEMPLATES')}
          name="template"
          options={templateOptions}
        />
        {selectedTemplateName ? (
          <Dropdown
            label={translate('LABEL.LANGUAGE')}
            name="language"
            options={languageOptions}
          />
        ) : null}
      </form>
    </FormProvider>
  );
};
