import { useField } from 'formik';
import { FormGroup, TextArea, Label } from '@components/Common.styles';
import { ValidationError } from '@components/ValidationError';
import { useI18n } from '@hooks/useI18n/useI18n';

interface ITextBoxProps {
  containerClassName?: string;
  id: string;
  label: string;
  name: string;
  readOnly?: boolean;
}

export const TextBox = ({
  containerClassName,
  label,
  readOnly,
  ...props
}: ITextBoxProps) => {
  const [field, meta] = useField(props);
  const { translate } = useI18n();

  return (
    <FormGroup extraClassName={containerClassName}>
      <Label htmlFor={props.id}>
        {label + (readOnly ? ` (${translate('LABEL.CANNOT_BE_EDITED')})` : '')}
      </Label>
      <TextArea {...field} {...props} />
      {meta.touched && meta.error ? (
        <ValidationError error={meta.error} />
      ) : null}
    </FormGroup>
  );
};
