import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useHasPermission } from '@hooks/useHasPermission';
import { IOpsPortalApplicantRiskOverrides } from '@schemas/opsPortal/types/applicantRiskOverrides';
import { IUuid } from '@schemas/opsPortal/types/applicationDetails';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseGetApplicantRiskOverrides {
  applicantUuid?: IUuid;
}

export const useGetApplicantRiskOverrides = ({
  applicantUuid,
}: IUseGetApplicantRiskOverrides) => {
  const hasPermission = useHasPermission([
    'ops_portal.can_create_risk_override',
  ]);

  const {
    data: applicantRiskOverrideData,
    error: applicantRiskOverrideError,
    isLoading: isLoadingApplicantRiskOverride,
  } = useQuery<IOpsPortalApplicantRiskOverrides, AxiosError>(
    [QueryKeys.APPLICANT_RISK_OVERRIDE, applicantUuid],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchApplicantRiskOverride}/${applicantUuid}`,
      ),
    {
      enabled: hasPermission && !!applicantUuid,
    },
  );

  return {
    applicantRiskOverrideData,
    applicantRiskOverrideError,
    isLoadingApplicantRiskOverride,
  };
};
