import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { NoDataAvailable } from '@components/NoDataAvailable';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { useOriginator } from '@hooks/useOriginator';
import { OrderView } from '@pages/enums';
import { IConsumerPortalArrearsData } from '@schemas/consumerPortal/types/arrearsData';
import { IConsumerPortalOrderDetails } from '@schemas/consumerPortal/types/ordersDetails';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { OrderDetailsDisplayConsumer } from './components';
import { viewConfig } from './config';

export const OrderDetailsConsumer = () => {
  const { isHeidiPay } = useOriginator();
  const { uuid } = useParams();

  const { endpoint, queryKey } = viewConfig[OrderView.Order];

  const {
    data: orderDetailsData,
    error: orderDetailsError,
    isLoading: isOrderDetailsLoading,
  } = useQuery<IConsumerPortalOrderDetails, AxiosError>([queryKey, uuid], () =>
    fetchDataFromEndpoint(endpoint, { search: uuid }),
  );

  const contractUUID = orderDetailsData?.[0]?.contract_uuid;

  const { data: arrearsData, isInitialLoading: isArrearsLoading } = useQuery<
    IConsumerPortalArrearsData,
    AxiosError
  >(
    [QueryKeys.ORDER_ARREARS_DATA, contractUUID],
    () =>
      fetchDataFromEndpoint(Endpoints.FetchOrdersArrearsData, {
        search: contractUUID,
      }),
    { enabled: Boolean(isHeidiPay && contractUUID) },
  );

  return (
    <QueryStateRouter
      error={orderDetailsError}
      isLoading={isArrearsLoading || isOrderDetailsLoading}
    >
      {orderDetailsData?.length ? (
        <OrderDetailsDisplayConsumer
          arrearsData={arrearsData}
          orderDetailsData={orderDetailsData}
          queryKey={queryKey}
        />
      ) : (
        <NoDataAvailable />
      )}
    </QueryStateRouter>
  );
};
