import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccessController } from '@components/AccessController';
import { CheckboxFieldRHF } from '@components/CheckboxField';
import { DatePickerRHF } from '@components/DatePicker';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ValidationErrorKeys } from '@utils/enums';

interface IHandlePausePaymentProps {
  date?: string;
  pause_payment_processing: boolean;
}

interface IPausePaymentFormProps {
  formID: string;
  handleSubmit: SubmitHandler<IHandlePausePaymentProps>;
}

export const PausePaymentForm = ({
  formID,
  handleSubmit,
}: IPausePaymentFormProps) => {
  const { translate } = useI18n();

  const defaultValues = {
    pause_payment_processing: false,
    date: '',
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(
      Yup.object({
        pause_payment_processing: Yup.boolean(),
        date: Yup.string().when('pause_payment_processing', {
          is: false,
          then: schema =>
            schema.required(translate(ValidationErrorKeys.Required)),
        }),
      }),
    ),
  });

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);
  const maxDate = new Date();
  maxDate.setDate(minDate.getDate() + 120);

  return (
    <FormProvider {...methods}>
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <AccessController
          scopes={['ops_portal.can_toggle_pause_payment_processing']}
        >
          <CheckboxFieldRHF
            label={translate('OPS_PORTAL.LABEL.PAUSE_PAYMENTS_INDEFINITELY')}
            name="pause_payment_processing"
          />
        </AccessController>

        {!methods.watch('pause_payment_processing') ? (
          <AccessController
            scopes={['ops_portal.can_pause_payment_processing_till_date']}
          >
            <DatePickerRHF
              label={translate('OPS_PORTAL.LABEL.PAUSE_PAYMENTS_UNTIL_DATE')}
              maxDate={maxDate}
              minDate={minDate}
              name="date"
              showPopperArrow={false}
            />
          </AccessController>
        ) : null}
      </form>
    </FormProvider>
  );
};
