import { GetLocaleDate, Translate } from '@hooks/useI18n';
import { IDocument } from '@schemas/opsPortal/types/contractDocumentsV2';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { OpsPortalDocumentTypes } from './enums';
import { IDocumentsTableColumn } from './types';

interface IMapDocumentsTableData {
  data?: IDocument[];
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const mapDocumentsTabTableData = ({
  data,
  getLocaleDate,
  translate,
}: IMapDocumentsTableData): IDocumentsTableColumn[] =>
  data?.map((row: IDocument) => {
    const { created, signed, type, uuid } = row;

    let isSigned;
    if (signed === true) {
      isSigned = translate('GENERAL.YES');
    } else if (signed === false) {
      isSigned = translate('GENERAL.NO');
    } else {
      isSigned = undefined;
    }

    const labelPostfix = `${type.toUpperCase().replaceAll(' ', '_')}`;
    const labelPrefix = [
      OpsPortalDocumentTypes.CustomerAgreement.toString(),
      OpsPortalDocumentTypes.TradeInOptionAgreement.toString(),
    ].includes(type)
      ? 'LABEL'
      : 'OPS_PORTAL.LABEL';

    return addPlaceholderForEmptyValues({
      documentType: type,
      documentTypeDesc: translate(`${labelPrefix}.${labelPostfix}`),
      isSigned,
      timestamp: getLocaleDate({ date: created }),
      uuid,
    });
  }) ?? [];
