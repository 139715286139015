import { Dd, Dl, Dt } from '@components/Common.styles';
import { useI18n } from '@hooks/useI18n/useI18n';

export const TooltipContent = () => {
  const { translate } = useI18n();

  return (
    <Dl>
      <Dt>{translate('GROUPS.SUPER_ADMIN')}</Dt>
      <Dd>{translate('GROUPS.SUPER_ADMIN_DESCRIPTION')}</Dd>
      <Dt>{translate('GROUPS.ADMIN')}</Dt>
      <Dd>{translate('GROUPS.ADMIN_DESCRIPTION')}</Dd>
      <Dt>{translate('GROUPS.SALES_ASSISTANT')}</Dt>
      <Dd>{translate('GROUPS.SALES_ASSISTANT_DESCRIPTION')}</Dd>
      <Dt>{translate('GROUPS.SALES_ASSISTANT_LIMITED')}</Dt>
    </Dl>
  );
};
