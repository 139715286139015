import { IProduct } from '@schemas/merchantPortal/types/tracking';

export const getFirstProduct = (products: IProduct[]) => {
  return products.length ? products[0] : null;
};

export const getMobilezoneImei = (products: IProduct[]) => {
  return getFirstProduct(products)?.external_id;
};

export const getMobilezoneProductName = (products: IProduct[]) => {
  return getFirstProduct(products)?.name;
};

export const getMobilezoneProductEan = (products: IProduct[]) => {
  return getFirstProduct(products)?.sku;
};

export const getMobilezonePrice = (products: IProduct[]) => {
  const price = getFirstProduct(products)?.price;
  return typeof price === 'object' && 'amount' in price ? price.amount : price;
};
