import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { IMapNotesTableDataProps, INotesTableDataRow } from './types';

export const mapNotesTableData = ({
  getLocaleDate,
  notesData,
  translate,
}: IMapNotesTableDataProps) =>
  notesData.contract_logs.map(dataRow => {
    const {
      created_at: date,
      created_by: createdBy,
      note: message,
      type,
    } = dataRow;

    return addPlaceholderForEmptyValues<INotesTableDataRow>({
      createdDate: date ? getLocaleDate({ date, includeTime: true }) : null,
      createdBy:
        createdBy === 'DAILY_TASK'
          ? translate('OPS_PORTAL.LABEL.SYSTEM_LOG')
          : createdBy,
      message,
      noteType: translate(
        `OPS_PORTAL.TITLE.${type.toUpperCase().replace(/\s+/, '_')}`,
      ),
    });
  });
