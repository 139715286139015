import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IOpsPortalCollectionAgencies } from '@schemas/opsPortal/types/collectionAgencies';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { updateAgencyToHeyLightRebrand } from './updateAgencyToHeyLightRebrand';

export const useCollectionAgencies = () => {
  const { data, isLoading } = useQuery<
    IOpsPortalCollectionAgencies,
    AxiosError
  >([QueryKeys.COLLECTION_AGENCIES_LIST], () =>
    fetchDataFromEndpoint(Endpoints.FetchCollectionAgencies),
  );

  const updatedData = updateAgencyToHeyLightRebrand(data ?? []);
  return {
    collectionAgencies:
      updatedData?.reduce(
        (obj, item) => ({
          ...obj,
          [item.key]: item.value,
        }),
        {},
      ) ?? {},
    isCollectionAgenciesLoading: isLoading,
  };
};
