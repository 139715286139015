import { IApplicationsTableData } from '@appTypes';
import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { IApplications } from '@schemas/opsPortal/types/applications';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { getFullName } from '@utils/getFullName';
import { getOriginatorDisplayNameHeyLight } from '@utils/getOriginatorDisplayNameHeyLight';
import { getTranslatedData } from '@utils/getTranslatedData';

interface IMapApplicationsTableDataProps {
  data?: IApplications;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  isMerchantPortal: boolean;
  translate: Translate;
}

export const mapApplicationsTableData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  isMerchantPortal,
  translate,
}: IMapApplicationsTableDataProps): IApplicationsTableData[] =>
  data?.map(dataRow => {
    const {
      amount: amountObj,
      application_reference: applicationReference,
      created: createdDate,
      crif_score: crifScore,
      external_contract_id: praticaId,
      external_contract_uuid: contractId,
      first_name: firstName,
      language_code: languageCode,
      last_name: lastName,
      merchant_name: merchant,
      merchant_order_reference: merchantOrderReference,
      origination_channel: originationChannel,
      originator_name: originator,
      payment_plan_reference: paymentPlanId,
      product_external_id: productExternalId,
      status: activityStatus,
      store_number: storeNumber,
      uuid: applicationUuid,
    } = dataRow;

    const amount = amountObj?.amount;
    const currency = amountObj?.currency;

    return addPlaceholderForEmptyValues({
      activityStatus: isMerchantPortal
        ? translate(`STATUS.TRACKING_${activityStatus.toUpperCase()}`)
        : getTranslatedData({ term: activityStatus, translate }),
      amount: getLocaleCurrencyValue({ currency, value: amount }),
      applicationReference,
      applicationUuid,
      contractId,
      createdDate:
        createdDate && getLocaleDate({ date: createdDate, includeTime: true }),
      crifScore: crifScore?.toString(),
      customerName: getFullName({ firstName, lastName }),
      language:
        languageCode &&
        translate(`OPS_PORTAL.LABEL.${languageCode.toUpperCase()}`),
      merchant,
      merchantOrderReference,
      originationChannel: getTranslatedData({
        term: originationChannel,
        translate,
      }),
      originator: getOriginatorDisplayNameHeyLight(originator),
      paymentPlanId,
      praticaId,
      rawStatus: activityStatus,
      storeNumber,
      productExternalId,
    });
  }) ?? [];
