import { useMemo } from 'react';
import objectPath from 'object-path';
import { useConfig } from '@hooks/useConfig';
import { useHtmlClassService } from '@metronic/layout/_core/MetronicLayout';
import { LanguageSelectorDropdown } from '@metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown';
import { QuickUserToggler } from '@metronic/layout/components/extras/QuickUserToggler';

export function Topbar() {
  const { showLanguageDropdown } = useConfig();

  const uiService = useHtmlClassService();
  const { viewLanguagesDisplay, viewUserDisplay } = useMemo(
    () => ({
      viewLanguagesDisplay:
        objectPath.get(uiService.config, 'extras.languages.display') &&
        showLanguageDropdown,
      viewUserDisplay: objectPath.get(uiService.config, 'extras.user.display'),
    }),
    [showLanguageDropdown, uiService],
  );

  return (
    <div className="topbar">
      {viewLanguagesDisplay ? <LanguageSelectorDropdown /> : null}

      {viewUserDisplay ? <QuickUserToggler /> : null}
    </div>
  );
}
