/* eslint-disable sonarjs/cognitive-complexity */
import { TableNames } from '@utils/constants';
import { getLimitAndOffset } from './getLimitAndOffset';
import { getQueryStateDateRangeValues } from './getQueryStateDateRangeValues';
import { getQueryStateValue } from './getQueryStateValue';
import { getSortList } from './getSortList';
import { getTableUsesCursorPagination } from './getTableUsesCursorPagination';
import { ITableNames, ITableParams, ITableParamsProps } from './types';

export const getTableParams = ({
  queryState,
  tableName,
}: ITableParamsProps): ITableParams => {
  const { limit, offset } = getLimitAndOffset(queryState);
  const ordering = getSortList({ queryState, table: tableName });
  const { cursor, globalFilter } = queryState;

  const activityStatus = getQueryStateValue({
    accessorKey: 'activityStatus',
    queryState,
  });

  const allocationType = getQueryStateValue({
    accessorKey: 'allocationType',
    queryState,
  });

  const communicationType = getQueryStateValue({
    accessorKey: 'communicationType',
    queryState,
  });

  const [createdDateFrom, createdDateTo] = getQueryStateDateRangeValues({
    accessorKey: 'createdDate',
    queryState,
  });

  const [timestampFrom, timestampTo] = getQueryStateDateRangeValues({
    accessorKey: 'timestamp',
    queryState,
  });

  const [transactionDateFrom, transactionDateTo] = getQueryStateDateRangeValues(
    {
      accessorKey: 'transactionDate',
      queryState,
    },
  );

  const [allocationDateFrom, allocationDateTo] = getQueryStateDateRangeValues({
    accessorKey: 'allocationDate',
    queryState,
  });

  const [latestTestDateFrom, latestTestDateTo] = getQueryStateDateRangeValues({
    accessorKey: 'latestTestDate',
    queryState,
  });

  const allocationStatus = getQueryStateValue({
    accessorKey: 'allocationStatus',
    queryState,
  });

  const [fromDate, toDate] = getQueryStateDateRangeValues({
    accessorKey: 'settlementDate',
    queryState,
  });

  const [lastLoginFrom, lastLoginTo] = getQueryStateDateRangeValues({
    accessorKey: 'lastLogin',
    queryState,
  });

  const [dateFrom, dateTo] = getQueryStateDateRangeValues({
    accessorKey: 'dateConfirmed',
    queryState,
  });

  const language = getQueryStateValue({
    accessorKey: 'language',
    queryState,
  });

  const merchant = getQueryStateValue({
    accessorKey: 'merchantName',
    queryState,
  });

  const originationChannel = getQueryStateValue({
    accessorKey: 'originationChannel',
    queryState,
  });

  const pending = getQueryStateValue({
    accessorKey: 'pending',
    queryState,
  });

  const unmatched = getQueryStateValue({
    accessorKey: 'unmatched',
    queryState,
  });

  const performanceStatus = getQueryStateValue({
    accessorKey: 'performanceStatus',
    queryState,
  });

  const responseCode = getQueryStateValue({
    accessorKey: 'responseCode',
    queryState,
  });

  const [sentDateFrom, sentDateTo] = getQueryStateDateRangeValues({
    accessorKey: 'sentAt',
    queryState,
  });

  const scheduleVersion = getQueryStateValue({
    accessorKey: 'scheduleVersion',
    queryState,
  });

  const isBankAccountReconciliationsPage = (
    [
      TableNames.BANK_ACCOUNT_RECONCILIATIONS,
      TableNames.SUSPENSE,
    ] as ITableNames[]
  ).includes(tableName);

  const isPendingAllocationsPage = tableName === TableNames.PENDING_ALLOCATIONS;

  const isAllCollectionsTab = tableName === TableNames.COLLECTIONS;

  const isCollectionsQueueTab = tableName === TableNames.COLLECTIONS_QUEUE;

  const isCommunicationsPage = tableName === TableNames.COMMUNICATIONS;

  const isAPICallsPage = tableName === TableNames.API_CALLS;

  const isApplicationsPage = tableName === TableNames.APPLICATIONS;

  const isUsersPage = tableName === TableNames.USERS;

  const isContractsPage = (
    [TableNames.AWAITING_DISPATCH, TableNames.CONTRACTS] as ITableNames[]
  ).includes(tableName);

  const isOrdersPage = tableName === TableNames.ORDERS;

  const isSettlementsPage = (
    [
      TableNames.MEDIOBANCA_RECONCILIATIONS,
      TableNames.SETTLEMENTS,
    ] as ITableNames[]
  ).includes(tableName);

  const isUnbalancedAllocations =
    tableName === TableNames.UNBALANCED_ALLOCATIONS;

  const isScheduleHistoryTab =
    tableName === TableNames.PAYMENT_SCHEDULE_HISTORY;

  const usesCursorPagination = getTableUsesCursorPagination(tableName);

  const hasGlobalSearch = tableName !== TableNames.UNBALANCED_ALLOCATIONS;

  return {
    // Generic filters all tables use
    ...(usesCursorPagination && typeof cursor !== 'undefined'
      ? {
          cursor,
          pageSize: limit.toString(),
        }
      : { limit: limit.toString(), offset: offset.toString() }),
    ordering,
    ...(hasGlobalSearch ? { search: globalFilter } : {}),

    // Table-specific filters
    ...(isPendingAllocationsPage
      ? {
          allocationDateFrom,
          allocationDateTo,
          allocationType,
        }
      : {}),
    ...(isApplicationsPage
      ? {
          activityStatus,
          createdDateFrom,
          createdDateTo,
        }
      : {}),
    ...(isAPICallsPage
      ? { merchant, responseCode, timestampFrom, timestampTo }
      : {}),
    ...(isBankAccountReconciliationsPage
      ? {
          allocationStatus,
          transactionDateFrom,
          transactionDateTo,
        }
      : {}),
    ...(isOrdersPage ? { dateFrom, dateTo, originationChannel } : {}),
    ...(isContractsPage
      ? {
          activityStatus,
          createdDateFrom,
          createdDateTo,
          performanceStatus,
        }
      : {}),
    ...(isApplicationsPage || isContractsPage
      ? {
          language,
          merchant,
        }
      : {}),
    ...(isAllCollectionsTab ? { merchant, performanceStatus } : {}),
    ...(isCollectionsQueueTab ? { language } : {}),
    ...(isCommunicationsPage
      ? {
          communicationType,
          sentDateFrom,
          sentDateTo,
        }
      : {}),
    ...(isSettlementsPage ? { fromDate, toDate } : {}),
    ...(isUnbalancedAllocations
      ? {
          latestTestDateFrom,
          latestTestDateTo,
          pending,
          unmatched,
        }
      : {}),
    ...(isUsersPage ? { lastLoginFrom, lastLoginTo } : {}),
    ...(isScheduleHistoryTab ? { scheduleVersion } : {}),
  };
};
