import { CSSProperties } from 'react';

export const menuStyles: CSSProperties = {
  border: '1px solid #e4e6ef',
  borderRadius: '5px',
  maxHeight: 80,
  overflowY: 'scroll',
  padding: '0.5rem',
  listStyle: 'none',
  position: 'relative',
  top: '-1.75rem',
};
