import { GetLocaleCurrencyValue, GetLocaleDate } from '@hooks/useI18n';
import { ICollection } from '@schemas/opsPortal/types/collections';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { ICollectionsTableData } from './types';

interface IMapCollectionsTableData {
  data?: ICollection[];
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
}

export const mapCollectionsTableData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
}: IMapCollectionsTableData) =>
  data?.map(dataRow => {
    const {
      arrears_amount: arrearsAmount,
      arrears_managed_by: arrearsManagedBy,
      consumer_data: { name: customerName },
      external_uuid: externalUUID,
      language,
      last_note_date: lastNoteDate,
      merchant: { short_name: merchantName },
      oldest_payment_due_date: paymentDueDate,
      payment_plan_reference: contractId,
      performance_status: performanceStatus,
    } = dataRow;

    const amountInArrears =
      arrearsAmount &&
      getLocaleCurrencyValue({
        currency: arrearsAmount?.currency,
        value: arrearsAmount?.amount,
      });

    return addPlaceholderForEmptyValues<ICollectionsTableData>({
      contractId,
      externalUUID,
      paymentDueDate: paymentDueDate && getLocaleDate({ date: paymentDueDate }),
      customerName,
      merchantName,
      language,
      amountInArrears,
      lastNoteDate: lastNoteDate && getLocaleDate({ date: lastNoteDate }),
      performanceStatus,
      arrearsManagedBy,
    });
  }) ?? [];
