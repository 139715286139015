import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import { reducer, saga } from '@auth/_redux';
import { originatorReducer } from './originatorRedux';

export const rootReducer = combineReducers({
  auth: reducer,
  originator: originatorReducer,
});

export function* rootSaga() {
  yield all([saga()]);
}
