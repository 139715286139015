import styled from 'styled-components';

export const AllocationGroupContainer = styled.section`
  border: 1px solid #e4e6ef;
  padding: 0.65rem 1rem;
  margin: 0.65rem 0 1rem;
  border-radius: 0.42rem;
`;

export const AllocationTable = styled.table`
  width: 100%;

  thead {
    display: none;
  }

  td {
    width: 33.33%;

    &:first-of-type {
      white-space: nowrap;
    }

    &:nth-last-child(-n + 2) {
      text-align: right;
    }

    &:last-of-type {
      padding-left: 5px;
    }
  }
`;

export const AllocationSectionHeader = styled.h4`
  font-size: 1rem;
  font-weight: normal;

  ::after {
    content: ':';
  }
`;

export const AllocationHeading = styled.h5`
  font-size: 1rem;
`;

export const Badge = styled.span.attrs(() => ({
  className: 'label label-lg label-light-warning label-inline',
}))`
  margin-left: 5px;
  height: 20px;
  line-height: 20px;
  vertical-align: middle;
  padding: 10px;
`;
