import { useParams } from 'react-router-dom';
import { ValueOf } from '@appTypes';
import { EarlySettlements } from '@components/EarlySettlements';
import { EventsTimeline } from '@components/EventsTimeline';
import { Notes } from '@components/Notes';
import { RiskTestsBreakdown } from '@components/RiskTestsBreakdown';
import { ShoppingCartDetails } from '@components/ShoppingCartDetails';
import { IShoppingCartOrderDetails } from '@components/ShoppingCartDetails/types';
import { useUserDetails } from '@hooks/useUserDetails';
import { ContractsByUuids } from '@pages/Contracts/ContractsByUuids';
import { QueryKeys, Views } from '@utils/constants';
import { DetailsCard } from './components';
import {
  useGetArrearsManagementQuery,
  useGetCollectionsBlockAutoReferralQuery,
  useGetCollectionsQuery,
  useGetContractQuery,
  useGetMonetaQuery,
  useGetPausedCommunicationsQuery,
} from './hooks/queries';
import { useGetContractRiskApplicationQuery } from './hooks/queries/useGetContractRiskApplicationQuery';

interface IContractDetailsProps {
  view?: ValueOf<typeof Views>;
}

export const ContractDetails = ({
  view = Views.CONTRACTS,
}: IContractDetailsProps) => {
  const { uuid: queryKeyContractUuid } = useParams();

  const { isInternalOpsUser } = useUserDetails();
  const isLiveContracts = view === Views.CONTRACTS;

  const contractDataQueryKey = [
    QueryKeys.CONTRACT_DETAILS_DATA,
    queryKeyContractUuid,
  ];

  const {
    contractData,
    contractDataError,
    contractDetails,
    contractUuid,
    externalContractUuid,
    isArrearsToBeManagedByHeidi,
    isContractDataFetching,
    isContractDataLoading,
    orderDetails,
  } = useGetContractQuery({
    contractDataQueryKey,
    queryKeyContractUuid,
    view,
  });

  const {
    isRiskApplicationLoading,
    riskApplicationData,
    riskApplicationError,
  } = useGetContractRiskApplicationQuery({
    contractUuid,
  });

  const { isArrearsCurrentlyAssignedToHeidi } = useGetArrearsManagementQuery({
    queryKeyContractUuid,
  });

  const { invalidateMonetaData, isLoadingMonetaData, monetaContractData } =
    useGetMonetaQuery({
      contractUuid,
      externalContractUuid,
    });

  const {
    collectionsData,
    isCollectionsDataError,
    isCollectionsDataLoading,
    isInArrears,
  } = useGetCollectionsQuery({
    contractUuid,
  });

  const {
    collectionsBlockAutoReferralData,
    collectionsBlockAutoReferralError,
    isAutoReferralLoading,
  } = useGetCollectionsBlockAutoReferralQuery({
    externalContractUuid,
    isArrearsToBeManagedByHeidi,
    isInArrears,
  });

  const {
    isPauseCommunicationsLoading,
    pauseCommunicationsData,
    pauseCommunicationsError,
  } = useGetPausedCommunicationsQuery({ externalContractUuid, isInArrears });

  // Wait for the moneta data to load as determines schedule history filter options
  return (
    <>
      <DetailsCard
        collectionsBlockAutoReferralData={collectionsBlockAutoReferralData}
        collectionsBlockAutoReferralError={collectionsBlockAutoReferralError}
        collectionsData={collectionsData}
        contractData={contractDetails}
        contractDataError={contractDataError}
        contractDataQueryKey={contractDataQueryKey}
        contractUuid={contractUuid}
        externalContractUUID={externalContractUuid}
        invalidateMonetaData={invalidateMonetaData}
        isArrearsCurrentlyAssignedToHeidi={isArrearsCurrentlyAssignedToHeidi}
        isArrearsToBeManagedByHeidi={isArrearsToBeManagedByHeidi}
        isCollectionsDataError={isCollectionsDataError}
        isInternalOpsUser={isInternalOpsUser}
        isLiveContracts={isLiveContracts}
        isLoading={
          isCollectionsDataLoading ||
          isContractDataLoading ||
          isContractDataFetching ||
          isAutoReferralLoading ||
          isPauseCommunicationsLoading ||
          isLoadingMonetaData
        }
        monetaContractData={monetaContractData}
        pauseCommunicationsData={pauseCommunicationsData}
        pauseCommunicationsError={pauseCommunicationsError}
        view={view}
      />

      {orderDetails?.products?.length ? (
        <ShoppingCartDetails
          orderDetails={orderDetails as IShoppingCartOrderDetails}
        />
      ) : null}

      {externalContractUuid ? <Notes uuid={externalContractUuid} /> : null}

      <EventsTimeline contractUuid={externalContractUuid} />

      {isInternalOpsUser ? (
        <>
          <RiskTestsBreakdown
            data={riskApplicationData}
            error={riskApplicationError}
            isLoading={isRiskApplicationLoading}
          />
          <ContractsByUuids
            isLoading={isRiskApplicationLoading}
            uuids={riskApplicationData?.matching_contract_uuids}
          />
        </>
      ) : null}

      <EarlySettlements
        contractUuid={contractUuid}
        externalContractUUID={externalContractUuid}
        remainingBalance={contractData?.contract_details?.system_remainder}
      />
    </>
  );
};
