import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ICardCTA } from '@appTypes';
import { InfoBadge } from '@components/Common.styles';
import { Translate } from '@hooks/useI18n';
import { IEditUserGroup } from '@schemas/merchantPortal/types/editUserGroups';
import { IMerchantPortalGroups } from '@schemas/merchantPortal/types/groups';
import { missingValuePlaceholder, SortingFunctions } from '@utils/constants';
import { dictionary } from '@utils/dictionary';
import { EditUserButton } from './components';
import { IGetUserTableData } from './types';

interface IGetUsersTableColumns {
  deleteProps?: ICardCTA;
  editProps?: ICardCTA;
  groups?: IMerchantPortalGroups;
  setUserProps: (userProps: IEditUserGroup) => void;
  translate: Translate;
}

export const getUsersTableColumns = ({
  deleteProps,
  editProps,
  groups,
  setUserProps,
  translate,
}: IGetUsersTableColumns) => {
  const columnHelper = createColumnHelper<IGetUserTableData>();

  return [
    columnHelper.accessor('userID', {
      header: translate('PAGE.USERS.USER_ID'),
    }),
    columnHelper.accessor('userName', {
      header: translate('PAGE.USERS.USER_NAME'),
    }),
    columnHelper.accessor('phoneNumber', {
      header: translate('LABEL.PHONE_NUMBER'),
      sortingFn: SortingFunctions.NUMERIC,
    }),
    columnHelper.accessor('lastLogin', {
      header: translate('PAGE.USERS.LAST_LOGIN'),
      sortingFn: SortingFunctions.DATETIME,
    }),
    columnHelper.accessor('group', {
      enableSorting: false,
      header: translate('PAGE.USERS.PERMISSIONS_GROUP'),
      cell: ({ getValue }) => {
        const value = getValue();
        const badges = groups
          ?.filter(group => value === group.id)
          .map(group => (
            <InfoBadge key={group.id}>
              {translate(
                dictionary.groups[
                  group.name as keyof typeof dictionary.groups
                ] || group.name,
              )}
            </InfoBadge>
          ));
        return badges?.length ? badges : missingValuePlaceholder;
      },
    }),
    columnHelper.display({
      id: 'ctaColumn',
      cell: ({ row }) => (
        <>
          {deleteProps ? (
            <EditUserButton
              ctaProps={deleteProps}
              setUserProps={setUserProps}
              userProps={row.original.ctaColumn}
            />
          ) : null}
          {editProps ? (
            <EditUserButton
              ctaProps={editProps}
              setUserProps={setUserProps}
              userProps={row.original.ctaColumn}
            />
          ) : null}
        </>
      ),
    }),
  ] as ColumnDef<IGetUserTableData, string>[];
};
