import { PageRoutes } from '@utils/enums';

interface IGetInitialRoute {
  canMerchantViewApplications: boolean;
  canMerchantViewMyApplications: boolean;
  canMerchantViewMyOrders: boolean;
  canViewOrders: boolean;
  isOpsPortal: boolean;
}

export const getInitialRoute = ({
  canMerchantViewApplications,
  canMerchantViewMyApplications,
  canMerchantViewMyOrders,
  canViewOrders,
  isOpsPortal,
}: IGetInitialRoute) => {
  if (isOpsPortal) {
    return PageRoutes.AccountSettings;
  }
  if (canViewOrders) {
    return PageRoutes.Orders;
  }
  if (canMerchantViewMyOrders) {
    return PageRoutes.MyOrders;
  }
  if (canMerchantViewApplications) {
    return PageRoutes.Applications;
  }
  if (canMerchantViewMyApplications) {
    return PageRoutes.MyApplications;
  }
  return PageRoutes.AccountSettings;
};
