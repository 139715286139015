import { getFullName } from '@utils/getFullName';

export const getBillingInfoProps = orderData => {
  const {
    bank_account_number: bankAccountNumber,
    card_details: cardDetails,
    consumer,
    order_details: {
      customer_details: customerDetails,
      shipping_address: shippingAddress,
    },
    payment_method: paymentMethod,
  } = orderData;

  const billingAddress = customerDetails?.billing_address;

  if (!consumer) {
    return {
      address: billingAddress || shippingAddress,
      bankAccountNumber,
      cardDetails,
      paymentMethod,
    };
  }

  const {
    email,
    first_name: firstName,
    last_name: lastName,
    phone_number: phone,
  } = consumer;

  const fullName = getFullName({ firstName, lastName });

  return {
    address: billingAddress || shippingAddress,
    bankAccountNumber,
    cardDetails,
    email,
    name: fullName,
    paymentMethod,
    phone,
  };
};
