import { KTUtil } from '@metronic/_assets/js/components/util';

const KTLayoutHeader = (function ktLayoutHeader() {
  // Private properties
  let element;
  let elementForMobile;
  let object;

  // Private functions
  // Get Height
  const getHeight = function getHeight() {
    let height = 0;

    if (element) {
      height = KTUtil.actualHeight(element) + 1;
    }

    return height;
  };

  // Get Height
  const getHeightForMobile = () => KTUtil.actualHeight(elementForMobile);

  // Public Methods
  return {
    init(id, idForMobile) {
      element = KTUtil.getById(id);
      elementForMobile = KTUtil.getById(idForMobile);
    },

    isFixed() {
      return KTUtil.hasClass(KTUtil.getBody(), 'header-fixed');
    },

    isFixedForMobile() {
      return KTUtil.hasClass(KTUtil.getBody(), 'header-mobile-fixed');
    },

    getElement() {
      return element;
    },

    getElementForMobile() {
      return elementForMobile;
    },

    getHeader() {
      return object;
    },

    getHeight() {
      return getHeight();
    },

    getHeightForMobile() {
      return getHeightForMobile();
    },
  };
})();

export default KTLayoutHeader;
