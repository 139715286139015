import { KTUtil } from '@metronic/_assets/js/components/util';

const KTLayoutFooter = (function ktLayoutFooter() {
  // Private properties
  let element;

  // Private functions
  const getHeight = function getHeight() {
    let height = 0;

    if (element) {
      height = KTUtil.actualHeight(element);
    }

    return height;
  };

  // Public methods
  return {
    init(id) {
      element = KTUtil.getById(id);
    },

    getHeight() {
      return getHeight();
    },

    getElement() {
      return element;
    },
  };
})();

export default KTLayoutFooter;
