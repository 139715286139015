import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import {
  ICollectionAssignmentManagedBy,
  IContractTableData,
} from '@pages/Contracts/types';
import { IConsumerDetailsContracts } from '@schemas/opsPortal/types/consumerDetails';
import { IContracts } from '@schemas/opsPortal/types/contracts';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { CollectionManagementEntities } from '@utils/enums';
import { getFullName } from '@utils/getFullName';
import { getTranslatedData } from '@utils/getTranslatedData';

interface IMapContractsTableData {
  data?: IContracts | IConsumerDetailsContracts;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  isCompass?: boolean;
  translate: Translate;
}

export const mapContractsTableData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  isCompass = false,
  translate,
}: IMapContractsTableData) =>
  data?.map(dataRow => {
    const {
      arrears_managed_by: arrearsManagedBy,
      consumer_name: consumerName,
      created_date: timestamp,
      external_uuid: contractId,
      managed_by_crabb: managedByCrabb,
      mediobanca_external_id: praticaId,
      merchant_name: merchantName,
      order_details: {
        amount: orderAmount,
        customer_details: customerDetails,
        origination_channel: originationChannel,
      },
      originator,
      payment_plan_reference: paymentPlanId,
      performance_status: performanceStatus,
      status: activityStatus,
      system_remainder: balance,
    } = dataRow;

    const createdDate = timestamp
      ? getLocaleDate({ date: timestamp, includeTime: true })
      : '—';

    const firstName = consumerName?.first_name ?? customerDetails?.first_name;
    const lastName = consumerName?.last_name ?? customerDetails?.last_name;

    const customerName = getFullName({ firstName, lastName });

    const originalAmount = getLocaleCurrencyValue({
      currency: orderAmount?.currency,
      value: orderAmount?.amount,
    });

    const outstandingBalance = getLocaleCurrencyValue({
      currency: balance?.currency,
      value: balance?.amount,
    });

    const collectionAssignmentManagedBy: ICollectionAssignmentManagedBy =
      managedByCrabb
        ? CollectionManagementEntities.Crabb
        : CollectionManagementEntities.HeyLight;

    const tableData: IContractTableData = addPlaceholderForEmptyValues({
      paymentPlanId,
      ...(isCompass ? { praticaId } : {}),
      collectionAssignmentManagedBy,
      contractId,
      createdDate,
      customerName,
      merchantName,
      rawActivityStatus: activityStatus,
      activityStatus: getTranslatedData({ term: activityStatus, translate }),
      rawPerformanceStatus: performanceStatus,
      performanceStatus: getTranslatedData({
        term: performanceStatus,
        translate,
      }),
      originalAmount,
      outstandingBalance,
      originationChannel: getTranslatedData({
        term: originationChannel,
        translate,
      }),
      originator,
      arrearsManagedBy,
    });

    return tableData;
  }) ?? [];
