import { TransformIfAvailable } from './types';

export const transformIfAvailable: TransformIfAvailable = (
  name,
  obj,
  transform,
) =>
  obj[name] !== undefined && obj[name] !== null
    ? { [name]: transform(obj[name]) }
    : {};
