import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';

export const getLatestPaymentDate = (monetaData?: IOpsPortalMonetaContract) => {
  const latestScheduledPayments = monetaData?.balances?.filter(
    x => x.latest_schedule,
  );
  const dateNow = new Date(Date.now());
  // Filter dates to include only those on or before today
  const paymentDatesBeforeNow = latestScheduledPayments
    ?.filter(x => x.amount_due && x.amount_due !== '0.00')
    .map(x => new Date(x.payment_date))
    .filter(date => date <= dateNow);

  if (paymentDatesBeforeNow?.length) {
    // Returns the latest payment date before current date.
    return paymentDatesBeforeNow?.reduce((latest, current) => {
      return latest > current ? latest : current;
    });
  }
  if (latestScheduledPayments?.length) {
    return new Date(latestScheduledPayments[0].payment_date);
  }
  return dateNow;
};
