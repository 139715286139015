import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { usePortalError } from '@hooks/usePortalError';
import {
  IEditUserGroup,
  IUuid,
} from '@schemas/merchantPortal/types/editUserGroups';
import { Endpoints } from '@utils/enums';
import { putDataToEndpoint } from '@utils/putDataToEndpoint';
import { useConfirmModal } from '../useConfirmModal';

interface IDeleteUserProps extends ISharedModalProps {
  queryKey: QueryKey;
  userProps?: IEditUserGroup;
}

interface IDeleteUserRequest {
  external_uuid: IUuid;
}

export const useDeleteUserModal = (props: IDeleteUserProps): Modal => {
  const { queryKey, userProps, variant } = props;
  const { translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast(translate);
  const queryClient = useQueryClient();

  const modal = useModal({
    sharedProps: props,
    modalName: 'deleteUserModal',
    permissions: ['merchant_portal.can_merchant_deactivate_user'],
    buttonTranslationKey: 'BUTTON.DELETE_USER',
  });

  const { mutate } = useMutation<
    string,
    AxiosError<IError>,
    IDeleteUserRequest
  >(
    requestData =>
      putDataToEndpoint({
        endpoint: Endpoints.DeleteUser,
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        message.success('PAGE.USERS.USER_SUCCESSFULLY_DELETED');
        modal.closeModal();
      },
      onError: error => {
        handleAxiosError(error);
        modal.closeModal();
      },
    },
  );

  const handleSubmit = useCallback(() => {
    if (userProps?.external_uuid) {
      const userObj = { external_uuid: userProps.external_uuid };
      mutate(userObj);
    }
  }, [mutate, userProps?.external_uuid]);

  const modalProps = useConfirmModal({
    children: <>{translate('GENERAL.ARE_YOU_SURE')}</>,
    onCancel: modal.closeModal,
    onConfirm: handleSubmit,
    title: translate('BUTTON.DELETE_USER'),
    variant,
  });

  return getModalDetails({ modal, modalProps });
};
