import { Permissions } from '@appTypes';
import { PageRoutes } from '@utils/enums';
import { scopes } from '@utils/scopes';

interface INavItem {
  iconPath: string;
  link: string;
  scopes: Permissions;
  target?: '_blank';
  translationKey: string;
}

interface INavItems {
  [key: string]: INavItem;
}

const permissionCanViewBankAccountStatementLines =
  'ops_portal.can_view_bank_account_statement_lines';
const svgProtectedShield = '/media/svg/icons/General/Shield-protected.svg';
const svgOrdersBlocks = '/media/svg/icons/Layout/Layout-4-blocks.svg';

export const NavItems: INavItems = Object.freeze({
  ACCOUNT_SETTINGS: {
    iconPath: '/media/svg/icons/Code/Settings4.svg',
    link: PageRoutes.AccountSettings,
    scopes: [
      'consumer_portal.change_consumeruser',
      'merchant_portal.can_view_account_info',
      'ops_portal.view_merchant',
    ],
    translationKey: 'TITLE.ACCOUNT_SETTINGS',
  },
  ALL_CONTRACTS: {
    iconPath: '/media/svg/icons/Files/Folder-check.svg',
    link: PageRoutes.AllContracts,
    scopes: ['ops_portal.view_contract'],
    translationKey: 'OPS_PORTAL.TITLE.ALL_CONTRACTS',
  },
  API_CALLS: {
    iconPath: '/media/svg/icons/Navigation/Route.svg',
    link: PageRoutes.ApiCalls,
    scopes: ['ops_portal.view_checkout_api_requests'],
    translationKey: 'OPS_PORTAL.TITLE.API_CALLS',
  },
  APPLICATIONS: {
    iconPath: '/media/svg/icons/Communication/Clipboard-list.svg',
    link: PageRoutes.Applications,
    scopes: [
      'ops_portal.view_application',
      'merchant_portal.can_view_applications',
    ],
    translationKey: 'TITLE.APPLICATIONS',
  },
  AWAITING_CONFIRMATION: {
    iconPath: '/media/svg/icons/Code/Time-schedule.svg',
    link: PageRoutes.AwaitingConfirmation,
    scopes: ['merchant_portal.can_view_awaiting_dispatch_orders'],
    translationKey: 'STATUS.AWAITING_CONFIRMATION',
  },
  AWAITING_DISPATCH: {
    iconPath: '/media/svg/icons/Code/Time-schedule.svg',
    link: PageRoutes.AwaitingDispatch,
    scopes: ['ops_portal.view_awaiting_dispatch_contracts'],
    translationKey: 'STATUS.AWAITING_DISPATCH',
  },
  BANK_ACCOUNT_RECONCILIATIONS: {
    iconPath: svgProtectedShield,
    link: PageRoutes.BankAccountReconciliations,
    scopes: [permissionCanViewBankAccountStatementLines],
    translationKey: 'OPS_PORTAL.TITLE.BANK_ACCOUNT_RECONCILIATIONS',
  },
  BLOCKED_APPLICANTS: {
    iconPath: '/media/svg/icons/Tools/Road-Cone.svg',
    link: PageRoutes.BlockedApplicants,
    scopes: ['ops_portal.can_view_blocked_applicants'],
    translationKey: 'OPS_PORTAL.TITLE.BLOCKED_APPLICANTS',
  },
  CARD_CHANGE_GUIDE: {
    iconPath: '/media/svg/icons/Code/Question-circle.svg',
    link: PageRoutes.CardChangeGuide,
    scopes: ['consumer_portal.can_change_card'],
    target: '_blank',
    translationKey: 'TITLE.CARD_CHANGE_GUIDE',
  },
  COLLECTIONS: {
    iconPath: '/media/svg/icons/Code/Warning-2.svg',
    link: PageRoutes.Collections,
    scopes: ['ops_portal.view_collections_contracts'],
    translationKey: 'OPS_PORTAL.TITLE.COLLECTIONS',
  },
  CONSUMERS: {
    iconPath: '/media/svg/icons/Communication/Group.svg',
    link: PageRoutes.Consumers,
    scopes: ['ops_portal.view_consumer'],
    translationKey: 'OPS_PORTAL.TITLE.CONSUMERS',
  },
  CONTRACTS: {
    iconPath: '/media/svg/icons/Files/Folder-check.svg',
    link: PageRoutes.Contracts,
    scopes: ['ops_portal.view_contract'],
    translationKey: 'OPS_PORTAL.TITLE.CONTRACTS',
  },
  CREATE_NEW_PLAN: {
    iconPath: '/media/svg/icons/Code/Compiling.svg',
    link: PageRoutes.CreateNewPlan,
    scopes: ['merchant_portal.can_create_a_new_plan'],
    translationKey: 'TITLE.CREATE_NEW_PLAN',
  },
  DOCUMENTS: {
    iconPath: '/media/svg/icons/Files/Folder.svg',
    link: PageRoutes.Documents,
    scopes: ['consumer_portal.can_view_documents'],
    translationKey: 'TITLE.DOCUMENTS',
  },
  INSTORE_SALES_GUIDE: {
    iconPath: '/media/svg/icons/Code/Question-circle.svg',
    link: PageRoutes.InStoreSalesGuide,
    scopes: ['merchant_portal.can_view_merchant_guide'],
    target: '_blank',
    translationKey: 'TITLE.INSTORE_SALES_GUIDE',
  },
  LOGOUT: {
    iconPath: '/media/svg/icons/Navigation/Sign-out.svg',
    link: PageRoutes.Logout,
    scopes: scopes.all,
    translationKey: 'TITLE.LOGOUT',
  },
  MEDIOBANCA_RECONCILIATIONS: {
    iconPath: '/media/svg/icons/General/Settings-1.svg',
    link: PageRoutes.Transactions,
    scopes: ['merchant_portal.can_view_mediobanca_reconciliation'],
    translationKey: 'TITLE.TRANSACTIONS',
  },
  MERCHANT_GUIDE: {
    iconPath: '/media/svg/icons/General/Visible.svg',
    link: PageRoutes.MerchantGuide,
    scopes: ['merchant_portal.can_view_merchant_guide'],
    translationKey: 'TITLE.MERCHANT_GUIDE',
  },
  MERCHANT_STORES: {
    iconPath: '/media/svg/icons/Home/Home.svg',
    link: PageRoutes.MerchantStores,
    scopes: ['merchant_portal.can_view_merchant_stores'],
    translationKey: 'TITLE.MERCHANT_STORES',
  },
  MERCHANT_PRIVACY_POLICY: {
    iconPath: '/media/svg/icons/Communication/Shield-user.svg',
    link: PageRoutes.MerchantPrivacyPolicy,
    scopes: scopes.merchant,
    target: '_blank',
    translationKey: 'TITLE.MERCHANT_PRIVACY_POLICY',
  },
  MERCHANTS: {
    iconPath: '/media/svg/icons/Shopping/Box2.svg',
    link: PageRoutes.Merchants,
    scopes: ['ops_portal.view_merchant'],
    translationKey: 'OPS_PORTAL.TITLE.MERCHANTS',
  },
  MY_APPLICATIONS: {
    iconPath: '/media/svg/icons/Communication/Clipboard-list.svg',
    link: PageRoutes.MyApplications,
    scopes: ['merchant_portal.can_view_sales_assistant_limited_access'],
    translationKey: 'TITLE.MY_APPLICATIONS',
  },
  MY_ORDERS: {
    iconPath: svgOrdersBlocks,
    link: PageRoutes.MyOrders,
    scopes: ['merchant_portal.can_view_sales_assistant_limited_access'],
    translationKey: 'TITLE.MY_ORDERS',
  },
  ORDERS: {
    iconPath: svgOrdersBlocks,
    link: PageRoutes.Orders,
    scopes: [
      'consumer_portal.can_view_payment_plans',
      'merchant_portal.can_view_payment_plans',
    ],
    translationKey: 'TITLE.ORDERS',
  },
  ORDERS_CONSUMER_PAGO: {
    iconPath: svgOrdersBlocks,
    link: PageRoutes.Orders,
    scopes: ['consumer_portal.can_view_payment_plans'],
    translationKey: 'TITLE.MY_ORDERS',
  },
  ORG_INFO: {
    iconPath: '/media/svg/icons/Shopping/Barcode-read.svg',
    link: PageRoutes.OrgInfo,
    scopes: ['merchant_portal.can_edit_merchant_settings'],
    translationKey: 'TITLE.ORG_INFO',
  },
  OVERPAID_CONTRACTS: {
    iconPath: '/media/svg/icons/Files/Folder-plus.svg',
    link: PageRoutes.OverpaidContracts,
    scopes: ['ops_portal.view_overpaid_contracts'],
    translationKey: 'OPS_PORTAL.TITLE.OVERPAID_CONTRACTS',
  },
  PAUSED_CONTRACTS: {
    iconPath: '/media/svg/icons/Media/Pause.svg',
    link: PageRoutes.PausedContracts,
    scopes: ['ops_portal.view_paused_contracts'],
    translationKey: 'OPS_PORTAL.TITLE.PAUSED_CONTRACTS',
  },
  PENDING_ALLOCATIONS: {
    iconPath: svgProtectedShield,
    link: PageRoutes.PendingAllocations,
    scopes: [permissionCanViewBankAccountStatementLines],
    translationKey:
      'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.PENDING_ALLOCATIONS',
  },
  SETTLEMENTS: {
    iconPath: '/media/svg/icons/General/Settings-1.svg',
    link: PageRoutes.Settlements,
    scopes: ['merchant_portal.can_view_reconciliation'],
    translationKey: 'TITLE.SETTLEMENTS',
  },
  SUSPENSE: {
    iconPath: svgProtectedShield,
    link: PageRoutes.Suspense,
    scopes: [permissionCanViewBankAccountStatementLines],
    translationKey: 'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.SUSPENSE',
  },
  TERMINABLE_CONTRACTS: {
    iconPath: '/media/svg/icons/Code/Stop.svg',
    link: PageRoutes.TerminableContracts,
    scopes: ['ops_portal.can_view_termination_eligible_contracts'],
    translationKey: 'OPS_PORTAL.TITLE.ELIGIBLE_FOR_TERMINATION',
  },
  UNBALANCED_ALLOCATIONS: {
    iconPath: svgProtectedShield,
    link: PageRoutes.UnbalancedAllocations,
    scopes: ['ops_portal.can_view_par_groups'],
    translationKey: 'OPS_PORTAL.TITLE.UNBALANCED_ALLOCATIONS',
  },
  USERS: {
    iconPath: '/media/svg/icons/Communication/Group.svg',
    link: PageRoutes.Users,
    scopes: ['merchant_portal.can_edit_user_settings'],
    translationKey: 'TITLE.USERS',
  },
  UTILITIES: {
    iconPath: '/media/svg/icons/General/Thunder.svg',
    link: PageRoutes.Utilities,
    scopes: ['ops_portal.can_access_utilities'],
    translationKey: 'OPS_PORTAL.TITLE.UTILITIES',
  },
});
