import styled from 'styled-components';

const IframeContainer = styled.div`
  position: relative;
  padding-bottom: 60.25%;
  padding-top: 25px;
  height: 0;

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;
  }
`;

interface ICardIframeContainer {
  url: string;
}

export const CardIframeContainer = ({ url }: ICardIframeContainer) => (
  <IframeContainer>
    <iframe src={url} title="Card Change Form" />
  </IframeContainer>
);
