import { RawStatus } from '@appTypes';
import { RawStatuses, StatusPillClasses } from '@utils/constants';

export const getPillCategory = (status: RawStatus) => {
  // Implementing a traffic light system for status colours.
  // For "green light"/positive statuses, return the "info" class.
  // For "red light"/negative statuses, return the "danger" class.
  // For all other statuses, return "dark" class.
  return StatusPillClasses[status] || StatusPillClasses[RawStatuses.UNKNOWN];
};
