import { Nullable } from '@heidi-pay/heidi-common-fe/types';
import { ModalSelect } from './ModalSelect';
import { IModal, IModalProps, IUseModalResponse } from './types';

interface IGetModalDetails {
  modal: Nullable<IUseModalResponse>;
  modalProps: IModalProps;
}

export const getModalDetails = ({
  modal,
  modalProps,
}: IGetModalDetails): IModal | null => {
  if (!modal?.canUseModal) {
    return null;
  }

  return {
    ctaProps: {
      additionalButtonContent: modalProps.additionalButtonContent,
      clickHandler: modal.openModal,
      key: modal.modalName,
      text: modal.ctaText,
      variant: modalProps.variant,
    },
    Modal: <ModalSelect modal={modal} modalProps={modalProps} />,
  };
};
