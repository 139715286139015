import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';

interface IStep {
  avatar: JSX.Element;
  shouldRepeatStep: boolean;
  translationKey: string;
}

interface IInstructions {
  steps: IStep[];
}

const InstructionIcon = styled('div')`
  display: flex;
  justify-content: center;
  color: grey;
  & > span {
    font-size: 3rem;
    font-weight: bold;
    line-height: 70px;
  }
  & > svg {
    height: 70px;
    width: 70px;
  }
`;

InstructionIcon.defaultProps = { 'aria-hidden': true };

const InstructionText = styled('p')`
  display: flex;
  margin-block-end: 0;
  text-align: center;
`;

const StyledListItem = styled(ListItem)`
  flex-direction: column;
  font-size: 1.25rem;
`;

export const Instructions = ({ steps }: IInstructions) => (
  <List>
    {steps.map(({ avatar, shouldRepeatStep, translationKey }) => (
      <StyledListItem key={translationKey}>
        <InstructionIcon>
          {shouldRepeatStep ? <span>2 x</span> : null}
          {avatar}
        </InstructionIcon>
        <InstructionText>{translationKey}</InstructionText>
      </StyledListItem>
    ))}
  </List>
);
