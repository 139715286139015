import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { NoTranslateWrapper } from '@components/Common.styles';
import { TableBody, TableHeader } from './components';
import { Table, TableWrapper, TFoot } from './Table.styles';

interface IStaticTableProps<TData, TCell> {
  columns: ColumnDef<TData, TCell>[];
  data: TData[];
  tfoot?: string[];
}

export const StaticTable = <TData, TCell>({
  columns,
  data,
  tfoot = [],
}: IStaticTableProps<TData, TCell>) => {
  const tableInstance = useReactTable({
    columns,
    data,
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
  });

  const { getHeaderGroups, getRowModel } = tableInstance;

  const tfootLength = tfoot?.length || 0;
  const colLength = columns.length;
  const headerGroups = getHeaderGroups();
  const { rows } = getRowModel();

  return (
    <NoTranslateWrapper>
      <TableWrapper>
        <Table>
          <TableHeader flexRender={flexRender} headerGroups={headerGroups} />
          <TableBody
            flexRender={flexRender}
            headerGroups={headerGroups}
            rows={rows}
          />
          {tfootLength ? (
            <TFoot>
              <tr>
                {tfoot.map((item, idx) => {
                  if (idx === 0) {
                    return (
                      <th
                        colSpan={colLength - (tfootLength - 1)}
                        key={item}
                        scope="row"
                      >
                        {item}
                      </th>
                    );
                  }
                  return <td key={item}>{item}</td>;
                })}
              </tr>
            </TFoot>
          ) : null}
        </Table>
      </TableWrapper>
    </NoTranslateWrapper>
  );
};
