interface IGetLanguageStorageKey {
  isCompass?: boolean;
  isOpsPortal?: boolean;
  originator?: string;
  portal?: string;
}

export const getLanguageStorageKey = ({
  isCompass,
  isOpsPortal,
  originator,
  portal,
}: IGetLanguageStorageKey): string | null => {
  if (isCompass) {
    return 'i18nConfig-compass';
  }

  if (isOpsPortal) {
    return 'i18nConfig-ops';
  }

  if (!(originator && portal)) {
    return null;
  }

  return `i18nConfig-${originator.toLowerCase()}-${portal}`;
};
