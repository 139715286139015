import * as privacyPolicies from '@assets/privacyPolicies';
import { getNavData } from '@hooks/useConfig/utils';
import { ConfigFunc } from './types';

const { NavItems, NavSectionHeadingKeys } = getNavData();

export const configMerchantPagolightpro: ConfigFunc = ({
  merchantSettingGroup,
}) => {
  const { hideOrdersPage, showMerchantStoresPage } = merchantSettingGroup;

  // TODO - add back in when links available HC-13613
  const showHelpGuides = false;

  return Object.freeze({
    canChangePassword: true,
    canEditAddress: false,
    canEditName: false,
    canEditOrgInfo: false,
    canEditPhone: false,
    navigation: [
      {
        heading: NavSectionHeadingKeys.CUSTOMERS,
        items: [
          NavItems.CREATE_NEW_PLAN,
          NavItems.APPLICATIONS,
          ...(hideOrdersPage ? [] : [NavItems.ORDERS, NavItems.SETTLEMENTS]),
        ],
      },
      {
        heading: NavSectionHeadingKeys.ORGANISATION,
        items: [
          NavItems.ORG_INFO,
          NavItems.USERS,
          ...(showMerchantStoresPage ? [NavItems.MERCHANT_STORES] : []),
        ],
      },
      ...(showHelpGuides
        ? [
            {
              heading: NavSectionHeadingKeys.HELP,
              items: [NavItems.MERCHANT_GUIDE, NavItems.INSTORE_SALES_GUIDE],
            },
          ]
        : []),
      {
        heading: NavSectionHeadingKeys.ACCOUNT_MANAGEMENT,
        items: [
          NavItems.ACCOUNT_SETTINGS,
          NavItems.MERCHANT_PRIVACY_POLICY,
          NavItems.LOGOUT,
        ],
      },
    ],
    privacyPolicy: privacyPolicies.PagolightPro,
    showCompassOrders: true,
    showFooter: false,
    showFooterPrivacyLink: false,
    showInvoiceDetails: false,
    showLanguageDropdown: false,
    showOrgIndustry: false,
    showOrgPrimaryColour: false,
    showOrgSMS: false,
    showProfilePicker: true,
  });
};
