import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

interface IPostDataToEndpointProps<T> {
  config?: AxiosRequestConfig;
  endpoint: string;
  options?: { search: string };
  requestData?: T;
}

export const postDataToEndpoint = async <T>({
  config,
  endpoint,
  options,
  requestData,
}: IPostDataToEndpointProps<T>) => {
  const { data }: AxiosResponse = await axios.post(
    `/api/${endpoint}/${options?.search ?? ''}`,
    requestData,
    ...(config ? [config] : []),
  );
  return data;
};
