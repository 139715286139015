import { Translate } from '@hooks/useI18n';
import { dictionary } from '@utils/dictionary';

type GetTranslatedDataProps = {
  dict?: Record<string, string>;
  term?: string;
  translate: Translate;
};

export const getTranslatedData = ({
  dict = dictionary?.api_label,
  term,
  translate,
}: GetTranslatedDataProps) => {
  return term ? translate(dict[term] || term) : undefined;
};
