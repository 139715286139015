import { ITabData } from '@components/Tabs/types';
import { TabEventKeys, TableNames } from '@utils/constants';
import { getDataList } from '@utils/getDataList';
import { getContractDetailsFieldNames } from './getContractDetailsFieldNames';
import { IGenerateTabDataProps } from './types';

export const generateTabData = ({
  contractActivePaymentScheduleColumns,
  contractDetailsData,
  contractPaymentScheduleData,
  contractPaymentsColumns,
  contractPaymentsData,
  contractPerformanceData,
  customerDetailsData,
  translate,
}: IGenerateTabDataProps): ITabData[] => {
  const fieldNames = getContractDetailsFieldNames({
    translate,
  });

  const contractDetails = {
    eventKey: TabEventKeys.CONTRACT_DETAILS,
    title: translate('OPS_PORTAL.TITLE.DETAILS'),
    dataList: getDataList({
      data: contractDetailsData,
      labels: fieldNames.contractDetails,
    }),
  };

  const contractPerformance = {
    eventKey: TabEventKeys.CONTRACT_PERFORMANCE,
    title: translate('OPS_PORTAL.TITLE.PERFORMANCE'),
    dataList: getDataList({
      data: contractPerformanceData,
      labels: fieldNames.contractPerformance,
    }),
  };

  const customerDetails = {
    eventKey: TabEventKeys.CONSUMER_DETAILS,
    title: translate('TITLE.CUSTOMER_DETAILS'),
    dataList: getDataList({
      data: customerDetailsData,
      labels: fieldNames.customerDetails,
    }),
  };

  const contractPaymentSchedule = {
    eventKey: TabEventKeys.CONTRACT_PAYMENT_SCHEDULE,
    title: translate('OPS_PORTAL.TITLE.ACTIVE_SCHEDULE'),
    data: contractPaymentScheduleData,
    columns: contractActivePaymentScheduleColumns,
    tableName: TableNames.PAYMENT_SCHEDULE,
    className: 'payment-schedule',
    rowClasses: contractPaymentScheduleData.map(entry =>
      !entry.focused ? 'future-entry' : '',
    ),
  };

  const contractPayments = {
    eventKey: TabEventKeys.CONTRACT_PAYMENTS,
    title: translate('OPS_PORTAL.TITLE.PAYMENTS'),
    data: contractPaymentsData,
    columns: contractPaymentsColumns,
    tableName: TableNames.PAYMENTS,
  };

  // Keep in this order for tab display purposes
  return [
    contractDetails,
    contractPerformance,
    customerDetails,
    contractPaymentSchedule,
    contractPayments,
  ];
};
