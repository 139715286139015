import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actions, login } from '@auth/_redux';
import { useI18n } from '@hooks/useI18n/useI18n';
import { usePortal } from '@hooks/usePortal';
import { ValidationErrorKeys } from '@utils/enums';
import { AuthPageHeader, ContentInnerWrapper } from './Auth.styles';
import { AuthForm } from './components/AuthForm';

export const Login = () => {
  const { translate } = useI18n();
  const dispatch = useDispatch();
  const { isConsumerPortal } = usePortal();

  const handleSubmit = useCallback(
    (values, { setStatus, setSubmitting }) => {
      login(values.email, values.password)
        .then(({ data: { access } }) => {
          dispatch(actions.login(access));
        })
        .catch(() => {
          setStatus(translate(ValidationErrorKeys.InvalidLogin));
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [dispatch, translate],
  );

  return (
    <ContentInnerWrapper>
      <AuthPageHeader>
        <h2>{translate('AUTH.LOGIN')}</h2>
        <p>{translate('AUTH.ENTER_YOUR_EMAIL_AND_PASSWORD')}</p>
      </AuthPageHeader>

      <AuthForm
        fields={['email', 'password']}
        handleSubmit={handleSubmit}
        initialValues={{ email: '', password: '' }}
        isConsumerPortal={isConsumerPortal}
        isLogin={true}
      />
    </ContentInnerWrapper>
  );
};
