import { Formik, Form } from 'formik';

export const ConfirmDispatchForm = ({ formID, handleSubmit, uuid }) => {
  const initialFormikValues = { contract_uuid: uuid };

  return (
    <Formik initialValues={initialFormikValues} onSubmit={handleSubmit}>
      <Form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
      ></Form>
    </Formik>
  );
};
