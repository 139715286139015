import escapeHtml from 'escape-html';
import { ValueOf } from '@appTypes';
import { NodeTypes } from '@utils/constants';
import {
  CustomElement,
  IFormattedText,
  ILinkElement,
  UnserializedHtml,
} from './types';

const getStyledNodeText = (node: IFormattedText) => {
  let nodeText = escapeHtml(node.text).replaceAll(/[\n\r\t]+/g, '');

  if (node.underline) {
    nodeText = `<u>${nodeText}</u>`;
  }

  if (node.italic) {
    nodeText = `<em>${nodeText}</em>`;
  }

  if (node.bold) {
    nodeText = `<strong>${nodeText}</strong>`;
  }

  return nodeText;
};

const nodeWrappers = {
  [NodeTypes.LINK]: (node: CustomElement) => [
    `<a href="${escapeHtml((node as ILinkElement).url)}">`,
    '</a>',
  ],
  [NodeTypes.LIST_ITEM]: () => ['<li>', '</li>'],
  // Plate creates a node type "lic" which seems to wrap LI content
  [NodeTypes.LIST_ITEM_CONTENT]: () => ['', ''],
  [NodeTypes.ORDERED_LIST]: () => ['<ol>', '</ol>'],
  [NodeTypes.PARAGRAPH]: () => ['<p>', '</p>'],
  [NodeTypes.UNORDERED_LIST]: () => ['<ul>', '</ul>'],
};

// regular expression to capture empty elements, such as <p></p> or <p>&nbsp;</>
const reEmptyElement = /<([a-z]+)>(?:\s|&nbsp;)*<\/\1>/gi;

export const serializeHtml = (nodes: UnserializedHtml): string =>
  nodes
    .reduce((acc, node) => {
      if ('type' in node) {
        const [openTag, closeTag] =
          nodeWrappers[node.type as ValueOf<typeof NodeTypes>](node);
        const nodeContent = serializeHtml(node.children);

        return acc + openTag + nodeContent + closeTag;
      }

      return acc + getStyledNodeText(node);
    }, '')
    .replaceAll(reEmptyElement, '');
