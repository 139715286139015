import { Formik, FormikHelpers, Form } from 'formik';
import * as Yup from 'yup';
import {
  ButtonWrapper,
  CTAWrapper,
  FormError,
  LinkWrapper,
  Spinner,
  SpinnerButton,
} from '@auth/pages/Auth.styles';
import { Button } from '@components/Button';
import { TextField } from '@components/TextField';
import { useI18n } from '@hooks/useI18n/useI18n';
import { AuthPathnames } from '@utils/constants';
import { ValidationErrorKeys, ButtonVariant } from '@utils/enums';
import { OriginatorRouterLink } from './OriginatorRouterLink';

type AuthField = 'email' | 'password' | 'passwordconfirm';
type Field = AuthField | 'language' | 'theme';
type Schemas = Partial<Record<Field, Yup.StringSchema>>;
type Values = Partial<Record<Field, string>>;

interface IAuthFormProps {
  fields: Array<AuthField>;
  handleSubmit: (
    values: Values,
    { setStatus, setSubmitting }: FormikHelpers<Values>,
  ) => void;
  initialValues: Values;
  isConsumerPortal?: boolean;
  isLogin?: boolean;
}

export const AuthForm = ({
  fields,
  handleSubmit,
  initialValues,
  isConsumerPortal = false,
  isLogin = false,
}: IAuthFormProps) => {
  const { translate } = useI18n();

  const validations: Record<AuthField | 'passwordExisting', Yup.StringSchema> =
    {
      email: Yup.string()
        .email(translate(ValidationErrorKeys.InvalidEmailFormat))
        .required(translate(ValidationErrorKeys.Required)),
      password: Yup.string()
        .required(translate(ValidationErrorKeys.Required))
        .min(8, translate(ValidationErrorKeys.MinCharsX, { min: 8 }))
        .max(250, translate(ValidationErrorKeys.MaxCharsX, { max: 250 }))
        .matches(/[a-z]+/, translate(ValidationErrorKeys.MinLowerCase))
        .matches(/[A-Z]+/, translate(ValidationErrorKeys.MinUpperCase))
        .matches(/\d+/, translate(ValidationErrorKeys.MinNumbers))
        .matches(/[^a-zA-Z\d\s]/, translate(ValidationErrorKeys.MinSymbols)),
      passwordconfirm: Yup.string().test(
        'passwords-match',
        translate(ValidationErrorKeys.PasswordsMustMatch),
        function confirmPasswords(value) {
          return this.parent.password === value;
        },
      ),
      passwordExisting: Yup.string().required(
        translate(ValidationErrorKeys.Required),
      ),
    };

  const schemaShape: Schemas = {};

  fields.forEach(field => {
    if (isLogin && field === 'password') {
      schemaShape.password = validations.passwordExisting;
    } else {
      schemaShape[field] = validations[field];
    }
  });

  const labels = {
    email: translate('LABEL.EMAIL_ADDRESS'),
    password: translate('LABEL.PASSWORD'),
    passwordconfirm: translate('LABEL.CONFIRM_PASSWORD'),
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape(schemaShape)}
    >
      {({ errors, isSubmitting, status, touched }) => {
        const getInputClasses = (fieldname: Field) => {
          const touchedClassName = errors[fieldname]
            ? 'is-invalid'
            : 'is-valid';

          return touched[fieldname] ? touchedClassName : '';
        };

        return (
          <Form>
            {status ? <FormError>{status}</FormError> : null}

            {fields.map(field => {
              const attributes = {
                className: getInputClasses(field),
                id: field,
                label: labels[field],
                name: field,
                ...(field === 'email' ? {} : { type: 'password' }),
              };

              return <TextField {...attributes} key={field} />;
            })}
            <CTAWrapper>
              <LinkWrapper>
                {isLogin ? (
                  <OriginatorRouterLink
                    className="text-dark-50 text-hover-primary"
                    id="kt_login_forgot"
                    to={AuthPathnames.FORGOT_PASSWORD}
                  >
                    {translate('AUTH.FORGOT_PASSWORD')}
                  </OriginatorRouterLink>
                ) : null}
                {isLogin && isConsumerPortal ? (
                  <OriginatorRouterLink
                    className="text-dark-50 text-hover-primary"
                    id="kt_login_forgot"
                    to={AuthPathnames.REQUEST_REGISTRATION}
                  >
                    {translate('AUTH.REQUEST_REGISTRATION_EMAIL')}
                  </OriginatorRouterLink>
                ) : null}
              </LinkWrapper>

              <ButtonWrapper>
                {isLogin ? null : (
                  <Button
                    as={OriginatorRouterLink}
                    to={AuthPathnames.LOGIN}
                    variant={ButtonVariant.Secondary}
                  >
                    {translate('BUTTON.CANCEL')}
                  </Button>
                )}

                <SpinnerButton
                  disabled={isSubmitting}
                  id="kt_login_signin_submit"
                  type="submit"
                  variant={ButtonVariant.Primary}
                >
                  <span>
                    {translate(isLogin ? 'AUTH.SIGN_IN' : 'BUTTON.SUBMIT')}
                  </span>
                  {isSubmitting ? <Spinner /> : null}
                </SpinnerButton>
              </ButtonWrapper>
            </CTAWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};
