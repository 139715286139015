import { CSSProperties, useCallback, useMemo, useRef } from 'react';
import {
  createBoldPlugin,
  createItalicPlugin,
  createLinkPlugin,
  createListPlugin,
  createPlateUI,
  createPlugins,
  createSoftBreakPlugin,
  createUnderlinePlugin,
  Plate,
  PlateProvider,
  TEditableProps,
} from '@udecode/plate';
import { DefaultElement } from './DefaultElement';
import { deserializeHtml } from './deserializeHtml';
import { linkPlugin } from './linkPlugin';
import { serializeHtml } from './serializeHtml';
import { TopToolbar } from './TopToolbar';
import { IRichTextEditorProps, Value } from './types';

const components = createPlateUI();

const styles: Record<string, CSSProperties> = {
  container: { position: 'relative' },
};

export const RichTextEditor = ({
  setFormValue,
  value,
}: IRichTextEditorProps) => {
  const containerRef = useRef(null);

  const plugins = useMemo(
    () =>
      createPlugins<Value>(
        [
          createLinkPlugin(linkPlugin),
          createListPlugin(),
          createBoldPlugin(),
          createItalicPlugin(),
          createSoftBreakPlugin(),
          createUnderlinePlugin(),
        ],
        {
          components,
        },
      ),
    [],
  );

  const html = useMemo(() => deserializeHtml(value), [value]);

  const changeHandler = useCallback(
    val => setFormValue(serializeHtml(val)),
    [setFormValue],
  );

  const renderElement = useCallback(
    elProps => <DefaultElement {...elProps} />,
    [],
  );

  const editableProps: TEditableProps<Value> = {
    autoFocus: false,
    placeholder: '',
    spellCheck: false,
    style: {
      padding: '1rem 0 0',
    },
  };

  return (
    <PlateProvider<Value>
      initialValue={html as Value}
      onChange={changeHandler}
      plugins={plugins}
      renderElement={renderElement}
    >
      <TopToolbar />
      <div ref={containerRef} style={styles.container}>
        <Plate<Value> editableProps={editableProps} />
      </div>
    </PlateProvider>
  );
};
