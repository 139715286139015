import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckboxFieldRHF } from '@components/CheckboxField';
import { DropdownRHF } from '@components/Dropdown';
import { TextFieldRHF } from '@components/TextField';
import { useI18n } from '@hooks/useI18n/useI18n';
import { PaymentTypes } from '@utils/constants';
import { ValidationErrorKeys } from '@utils/enums';
import { useGetSettlementAmount } from '../usePaymentModal/useGetSettlementAmount';
import { getSettlementUuids } from '../usePaymentModal/utils/getSettlementUuid';
import { ISettlementAdjustmentFormProps } from './types';

export const SettlementAdjustmentForm = ({
  contractSettlements = [],
  formID,
  handleSubmit,
}: ISettlementAdjustmentFormProps) => {
  const { getLocaleDate, translate } = useI18n();

  const settlements = contractSettlements.filter(
    settlement => settlement.transaction_invalidation_date === null,
  );

  const { handleSettlementChanged, settlementAmount, settlementUuid } =
    useGetSettlementAmount(PaymentTypes.ADJUSTMENTS, contractSettlements);

  const currency = settlementAmount?.currency;
  const defaultValues = {
    amount: null,
    delete_settlement: true,
    settlement_amount: settlementAmount?.amount,
    settlement_uuid: settlementUuid,
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(
      Yup.object({
        delete_settlement: Yup.boolean(),
        amount: Yup.number()
          .nullable()
          .when('delete_settlement', {
            is: false,
            then: schema =>
              schema
                .required(translate(ValidationErrorKeys.Required))
                .max(
                  Number(settlementAmount?.amount),
                  translate(
                    ValidationErrorKeys.AmountCannotExceedSettlementValue,
                  ),
                )
                .typeError(translate(ValidationErrorKeys.AmountMustBeNumber))
                .moreThan(0, translate(ValidationErrorKeys.MinNum1)),
          }),
      }),
    ),
    mode: 'onChange',
  });

  useEffect(() => {
    if (settlementAmount?.amount) {
      methods.clearErrors();
      methods.setValue('settlement_amount', settlementAmount.amount);
      methods.setValue('amount', null);
    }
  }, [settlementAmount, methods]);

  const deleteSettlement = methods.watch('delete_settlement');

  return (
    <FormProvider {...methods}>
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <DropdownRHF
          id="settlement_uuid"
          label={translate('OPS_PORTAL.TITLE.SETTLEMENT')}
          name="settlement_uuid"
          onChange={handleSettlementChanged}
          options={getSettlementUuids(settlements, getLocaleDate, translate)}
        />
        <TextFieldRHF
          disabled={true}
          id="settlement_amount"
          label={`${translate(
            'OPS_PORTAL.TITLE.SETTLEMENT_ORIGINAL_AMOUNT',
          )} (${currency})`}
          name="settlement_amount"
          readOnly={true}
        />
        <CheckboxFieldRHF
          label={translate('OPS_PORTAL.BUTTON.DELETE_SETTLEMENT')}
          name="delete_settlement"
        />
        {!deleteSettlement ? (
          <TextFieldRHF
            id="amount"
            label={`${translate(
              'OPS_PORTAL.TITLE.SETTLEMENT_NEW_AMOUNT',
            )} (${currency})`}
            name="amount"
          />
        ) : null}
      </form>
      <p className="text-danger text-center">
        <strong>{translate('GENERAL.ARE_YOU_SURE')}</strong>
      </p>
    </FormProvider>
  );
};
