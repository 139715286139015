import { FilterTypes, TableNames } from '@utils/constants';
import { getStatusOptions } from './getStatusOptions';
import { IStatusDropdownProps } from './types';

export const getStatusDropdown = ({
  isMerchantPortal,
  queryParams,
  tableName,
  translate,
}: IStatusDropdownProps) => {
  const { activityStatusOptions, performanceStatusOptions } = getStatusOptions({
    tableName,
    translate,
    isMerchantPortal,
  });

  const isApplications = tableName === TableNames.APPLICATIONS;

  const activityStatusDropdown = {
    accessorKey: 'activityStatus',
    id: 'activityStatus',
    label: translate(
      isApplications ? 'TITLE.STATUS' : 'OPS_PORTAL.LABEL.ACTIVITY_STATUS',
    ),
    options: activityStatusOptions,
    type: FilterTypes.DROPDOWN,
    value: queryParams?.activityStatus,
  };

  const performanceStatusDropdown = {
    accessorKey: 'performanceStatus',
    id: 'performanceStatus',
    label: translate('OPS_PORTAL.LABEL.PERFORMANCE_STATUS'),
    options: performanceStatusOptions,
    type: FilterTypes.DROPDOWN,
    value: queryParams?.performanceStatus,
  };

  return { activityStatusDropdown, performanceStatusDropdown };
};
