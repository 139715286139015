import { Translate } from '@hooks/useI18n';
import { FilterTypes } from '@utils/constants';

interface IGetEventTimelineFiltersProps {
  translate: Translate;
}

const getTranslatedOptions = (translate: Translate) => {
  const eventTypeOptions = [
    { id: '', name: '-' },
    {
      id: 'COMMUNICATIONS',
      name: translate('OPS_PORTAL.PAGE.EVENT_TIMELINE.COMMUNICATIONS'),
    },
    {
      id: 'FAILED_SCHEDULED_PAYMENT',
      name: translate(
        'OPS_PORTAL.PAGE.EVENT_TIMELINE.FAILED_SCHEDULED_PAYMENT',
      ),
    },
    {
      id: 'INITIAL_PAYMENT',
      name: translate('OPS_PORTAL.PAGE.EVENT_TIMELINE.INITIAL_PAYMENT'),
    },
    {
      id: 'MANUAL_PAYMENT',
      name: translate('OPS_PORTAL.PAGE.EVENT_TIMELINE.MANUAL_PAYMENT'),
    },
    {
      id: 'OTHER_PAYMENT',
      name: translate('OPS_PORTAL.PAGE.EVENT_TIMELINE.OTHER_PAYMENT'),
    },
    {
      id: 'RESOLVED_SCHEDULED_PAYMENT',
      name: translate(
        'OPS_PORTAL.PAGE.EVENT_TIMELINE.RESOLVED_SCHEDULED_PAYMENT',
      ),
    },
    {
      id: 'SUCCESSFUL_SCHEDULED_PAYMENT',
      name: translate(
        'OPS_PORTAL.PAGE.EVENT_TIMELINE.SUCCESSFUL_SCHEDULED_PAYMENT',
      ),
    },
  ];

  const communicationDirectionOptions = [
    { id: '', name: '-' },
    {
      id: 'inbound',
      name: translate('OPS_PORTAL.PAGE.EVENT_TIMELINE.INBOUND'),
    },
    {
      id: 'outbound',
      name: translate('OPS_PORTAL.PAGE.EVENT_TIMELINE.OUTBOUND'),
    },
  ];

  const communicationTypeOptions = [
    { id: '', name: '-' },
    { id: 'email', name: translate('OPS_PORTAL.PAGE.COMMUNICATIONS.EMAIL') },
    {
      id: 'text_message',
      name: translate('OPS_PORTAL.PAGE.COMMUNICATIONS.TEXT_MESSAGE'),
    },
    { id: 'letter', name: translate('OPS_PORTAL.PAGE.COMMUNICATIONS.LETTER') },
  ];

  const communicationStatusOptions = [
    { id: '', name: '-' },
    { id: 'sent', name: translate('OPS_PORTAL.STATUS.SENT') },
  ];

  const paymentCaptureStatusOptions = [
    { id: '', name: '-' },
    { id: 'complete', name: translate('OPS_PORTAL.STATUS.COMPLETE') },
    { id: 'outstanding', name: translate('OPS_PORTAL.STATUS.OUTSTANDING') },
    { id: 'partial', name: translate('OPS_PORTAL.STATUS.PARTIAL') },
  ];

  const paymentProcessingStatusOptions = [
    { id: '', name: '-' },
    { id: 'cancelled', name: translate('OPS_PORTAL.STATUS.CANCELLED') },
    { id: 'complete', name: translate('OPS_PORTAL.STATUS.COMPLETE') },
    { id: 'expired', name: translate('OPS_PORTAL.STATUS.EXPIRED') },
    { id: 'failed', name: translate('OPS_PORTAL.STATUS.FAILED') },
    { id: 'pending', name: translate('OPS_PORTAL.STATUS.PENDING') },
    { id: 'processing', name: translate('OPS_PORTAL.STATUS.PROCESSING') },
  ];

  return {
    eventTypeOptions,
    communicationDirectionOptions,
    communicationTypeOptions,
    communicationStatusOptions,
    paymentCaptureStatusOptions,
    paymentProcessingStatusOptions,
  };
};

export const getEventTimelineFilters = ({
  translate,
}: IGetEventTimelineFiltersProps) => {
  const {
    communicationDirectionOptions,
    communicationStatusOptions,
    communicationTypeOptions,
    eventTypeOptions,
    paymentCaptureStatusOptions,
    paymentProcessingStatusOptions,
  } = getTranslatedOptions(translate);

  const eventTypeDropdown = {
    accessorKey: 'type',
    id: 'type',
    label: translate('OPS_PORTAL.LABEL.EVENT_TYPE'),
    options: eventTypeOptions,
    type: FilterTypes.DROPDOWN,
    value: '',
  };

  const communicationDirectionDropdown = {
    accessorKey: 'communicationDirection',
    id: 'communicationDirection',
    label: translate('OPS_PORTAL.LABEL.EVENT_COMMUNICATION_DIRECTION'),
    options: communicationDirectionOptions,
    type: FilterTypes.DROPDOWN,
    value: '',
  };

  const communicationTypeDropdown = {
    accessorKey: 'communicationType',
    id: 'communicationType',
    label: translate('OPS_PORTAL.LABEL.EVENT_COMMUNICATION_TYPE'),
    options: communicationTypeOptions,
    type: FilterTypes.DROPDOWN,
    value: '',
  };

  const communicationStatusDropdown = {
    accessorKey: 'communicationStatus',
    id: 'communicationStatus',
    label: translate('OPS_PORTAL.LABEL.EVENT_COMMUNICATION_STATUS'),
    options: communicationStatusOptions,
    type: FilterTypes.DROPDOWN,
    value: '',
  };

  const paymentCaptureStatusDropdown = {
    accessorKey: 'paymentCaptureStatus',
    id: 'paymentCaptureStatus',
    label: translate('OPS_PORTAL.LABEL.EVENT_PAYMENT_CAPTURE_STATUS'),
    options: paymentCaptureStatusOptions,
    type: FilterTypes.DROPDOWN,
    value: '',
  };

  const paymentProcessingStatusDropdown = {
    accessorKey: 'paymentProcessingStatus',
    id: 'paymentProcessingStatus',
    label: translate('OPS_PORTAL.LABEL.EVENT_PAYMENT_PROCESSING_STATUS'),
    options: paymentProcessingStatusOptions,
    type: FilterTypes.DROPDOWN,
    value: '',
  };

  return {
    communicationDirectionDropdown,
    communicationStatusDropdown,
    communicationTypeDropdown,
    eventTypeDropdown,
    paymentCaptureStatusDropdown,
    paymentProcessingStatusDropdown,
  };
};
