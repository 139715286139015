import { ITransactionEntityType } from '@schemas/merchantPortal/types/settlementTransactions';
import { TransactionEntityTypes } from '@utils/constants';

interface IGetReceivableSpecifics {
  amount?: string;
  payorType: ITransactionEntityType;
}

export const getReceivableValue = ({
  amount = '0.00',
  payorType,
}: IGetReceivableSpecifics) => {
  const signedAmount =
    payorType === TransactionEntityTypes.MERCHANT
      ? Number(amount) * -1
      : Number(amount);

  return signedAmount.toFixed(2);
};
