import { Translate } from '@hooks/useI18n';

export const convertBooleanToYesOrNo = (
  val: boolean | undefined | null,
  translate: Translate,
): string => {
  if (val === undefined || val === null) {
    return translate('LABEL.NOT_AVAILABLE_ABBR');
  }
  return translate(val ? 'GENERAL.YES' : 'GENERAL.NO');
};
