import * as Yup from 'yup';
import { ValueOf } from '@appTypes';
import { Translate } from '@hooks/useI18n';
import { PaymentTypes } from '@utils/constants';
import { ValidationErrorKeys } from '@utils/enums';
import { validateEquality } from '@utils/validateEquality';

type PaymentType = ValueOf<typeof PaymentTypes>;

interface IValidationSchemaProps {
  isFullRefund: boolean;
  max?: string;
  paymentPlanId?: string;
  paymentType: PaymentType;
  translate: Translate;
}

const maxAmountValidation: Partial<Record<PaymentType, ValidationErrorKeys>> = {
  [PaymentTypes.PREPAYMENT]: ValidationErrorKeys.AmountCannotExceedOrderBalance,
  [PaymentTypes.REFUND]: ValidationErrorKeys.AmountCannotExceedOrderValue,
  [PaymentTypes.ADJUSTMENTS]:
    ValidationErrorKeys.AmountCannotExceedSettlementValue,
  [PaymentTypes.REIMBURSEMENT]:
    ValidationErrorKeys.AmountCannotExceedRemainingBalance,
};

export const getValidationSchema = ({
  isFullRefund,
  max,
  paymentPlanId,
  paymentType,
  translate,
}: IValidationSchemaProps) =>
  Yup.object({
    ...(isFullRefund
      ? {}
      : {
          amount: Yup.number()
            .typeError(translate(ValidationErrorKeys.AmountMustBeNumber))
            .required(translate(ValidationErrorKeys.Required))
            .moreThan(0, translate(ValidationErrorKeys.MinNum1))
            .when([], {
              is: () => paymentType !== PaymentTypes.MANUAL_PAYMENT,
              then: schema =>
                max !== undefined
                  ? schema.max(
                      Number(max),
                      translate(maxAmountValidation[paymentType] as string),
                    )
                  : schema,
            }),
        }),
    currency: Yup.string().required(translate(ValidationErrorKeys.Required)),
    external_uuid: Yup.string().required(
      translate(ValidationErrorKeys.Required),
    ),
    ...([
      PaymentTypes.ADJUSTMENTS,
      PaymentTypes.MANUAL_PAYMENT,
      PaymentTypes.REIMBURSEMENT,
    ].some(type => type === paymentType)
      ? {}
      : {
          payment_plan_reference: Yup.string()
            .required(translate(ValidationErrorKeys.Required))
            .test(
              'payment-plan-id',
              translate(ValidationErrorKeys.PaymentPlanIdMustMatch, {
                paymentPlanId,
              }),
              value => validateEquality(value, paymentPlanId),
            ),
        }),
    ...(paymentType === PaymentTypes.MANUAL_PAYMENT
      ? {
          payment_reference: Yup.string()
            .required(translate(ValidationErrorKeys.Required))
            .min(7, translate(ValidationErrorKeys.MinCharsX, { min: 7 })),
        }
      : {}),
    ...(paymentType === PaymentTypes.REIMBURSEMENT
      ? {
          settlement_reference: Yup.string().required(
            translate(ValidationErrorKeys.Required),
          ),
        }
      : {}),
    ...(paymentType === PaymentTypes.MANUAL_PAYMENT ||
    paymentType === PaymentTypes.REIMBURSEMENT
      ? { date: Yup.string().required(translate(ValidationErrorKeys.Required)) }
      : {}),
  });
