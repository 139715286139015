import { useHasPermission } from '@hooks/useHasPermission';
import { AdjustContractValueFormV2 } from './adjusstContractValueFormV2';
import { AdjustContractValueForm } from './AdjustContractValueForm';
import { IAdjustContractValueFormProps } from './types';

export const AdjustContractValueModalContent = ({
  amount,
  contractUuid,
  currency,
  formID,
  handleSubmit,
  isCancel,
  resolveForm,
}: IAdjustContractValueFormProps) => {
  const canAdjustContractWithSimulation = useHasPermission([
    'merchant_portal.can_adjust_contract_value_with_simulation',
  ]);
  const canUseAdjustContractValueV2 =
    !isCancel && canAdjustContractWithSimulation;

  return (
    <>
      {canUseAdjustContractValueV2 ? (
        <AdjustContractValueFormV2
          contractAmount={amount}
          contractUuid={contractUuid}
          currency={currency}
          formID={formID}
          handleSubmit={handleSubmit}
          resolveForm={resolveForm}
        />
      ) : (
        <AdjustContractValueForm
          amount={amount}
          contractUuid={contractUuid}
          currency={currency}
          formID={formID}
          handleSubmit={handleSubmit}
          isCancel={isCancel}
          resolveForm={resolveForm}
        />
      )}
    </>
  );
};
