import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextBox } from '@components/TextBox';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ValidationErrorKeys } from '@utils/enums';

interface IAddNoteFormProps {
  formID: string;
  handleSubmit: (values: { note: string }) => void;
}

export const AddNoteForm = ({ formID, handleSubmit }: IAddNoteFormProps) => {
  const { translate } = useI18n();

  const initialFormikValues = {
    note: '',
  };

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object({
        note: Yup.string().required(translate(ValidationErrorKeys.Required)),
      })}
    >
      <Form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
      >
        <TextBox
          id="note"
          label={translate('OPS_PORTAL.TITLE.MESSAGE')}
          name="note"
        />
      </Form>
    </Formik>
  );
};
