import { useEffect, useMemo, useRef } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Card } from '@components/Card';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ServersideTable } from '@components/Table/ServersideTable';
import { ITableRefObject } from '@components/Table/types';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useQueryState } from '@hooks/useQueryState';
import { IOpsPortalOverpaidContracts } from '@schemas/opsPortal/types/overpaidContracts';
import { QueryKeys, Views } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { getOverpaidContractsTableColumns } from './getOverpaidContractColumns';
import { mapOverpaidContractsTableData } from './mapOverpaidContractsTableData';
import { IOverpaidContractTableColumns } from './types';

export const OverpaidContracts = ({ view = Views.OVERPAID_CONTRACTS }) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const refToDataTable =
    useRef<ITableRefObject<IOverpaidContractTableColumns>>(null);

  const {
    activateQueryStateHook,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(view);

  const {
    data: contractsData,
    error: contractsError,
    isLoading: isContractsLoading,
  } = useQuery<IOpsPortalOverpaidContracts>(
    [QueryKeys.OVERPAID_CONTRACTS, stringifiedQueryParams],
    () =>
      fetchDataFromEndpoint(Endpoints.FetchOverpaidContracts, {
        query: { ...queryParams },
      }),
  );

  const columns = useMemo(
    () => getOverpaidContractsTableColumns(translate),
    [translate],
  );

  const contractsTableData = mapOverpaidContractsTableData({
    data: contractsData?.results,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
  });

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  return (
    <QueryStateRouter
      error={contractsError as AxiosError}
      isLoading={isContractsLoading}
    >
      <Card title={translate('OPS_PORTAL.TITLE.OVERPAID_CONTRACTS')}>
        <ServersideTable
          columns={columns}
          data={contractsTableData}
          manageControlledState={updateQueryParams}
          pagination={{
            limit: queryParams.limit,
            totalItems: contractsData?.count,
          }}
          ref={refToDataTable}
          resetFilters={resetFilters}
          tableState={queryState}
        />
      </Card>
    </QueryStateRouter>
  );
};
