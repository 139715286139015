import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { errorHandler } from '@heidi-pay/heidi-common-fe/utils';
import { useI18n } from '@hooks/useI18n/useI18n';

export const usePortalError = () => {
  const { translate } = useI18n();
  const { message } = useToast();
  const { handleAxiosError } = errorHandler({
    errorCodes: {
      unhandledErrorCode: 'ERROR.UNEXPECTED_ERROR',
      additionalErrorCodes: [
        { statusCode: 403, errorCode: 'ERROR.UNAUTHORISED_REQUEST' },
        { statusCode: 404, errorCode: 'ERROR.RESOURCE_NOT_FOUND' },
      ],
    },
    onError: error => message.error(error, { noTimeout: true }),
    translate,
  });
  return { handleAxiosError };
};
