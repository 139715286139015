import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { isValid, isFuture } from 'date-fns';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IFormData } from '../types';

export const useFormValidation = (methods: UseFormReturn<IFormData>) => {
  const { translate } = useI18n();

  const validateDate = useCallback(
    (value: Date | null): string | true => {
      if (!value || !isValid(value)) {
        return translate('OPS_PORTAL.INVALID_DATE_FORMAT');
      }
      if (!isFuture(value)) {
        return translate('OPS_PORTAL.DATE_MUST_BE_FUTURE');
      }
      return true;
    },
    [translate],
  );

  const handleDateError = useCallback(
    (error: string | null) => {
      if (error === 'invalidDate') {
        methods.setError('expiryTime', {
          type: 'manual',
          message: translate('OPS_PORTAL.INVALID_DATE_FORMAT'),
        });
      } else if (error === 'minDateTime') {
        methods.setError('expiryTime', {
          type: 'manual',
          message: translate('OPS_PORTAL.DATE_MUST_BE_FUTURE'),
        });
      } else {
        methods.clearErrors('expiryTime');
      }
    },
    [methods, translate],
  );

  return { handleDateError, validateDate };
};
