import {
  IMerchantPortalTracking,
  IProduct,
} from '@schemas/merchantPortal/types/tracking';

export const getFirstProduct = (products: IProduct[]) => {
  return products.length ? products[0] : null;
};

export const getMediamarktShoppingCardNumber = (
  data: IMerchantPortalTracking,
) => {
  return data?.externalConsumerId;
};

export const getMediamarktArticleNumber = (data: IMerchantPortalTracking) => {
  return getFirstProduct(data.products)?.external_id;
};
