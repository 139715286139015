import { useCallback } from 'react';
import { ICardCTA } from '@appTypes';
import { CardCTA } from '@components/CardCTA';

interface IViewRawDataButtonProps {
  ctaProps: ICardCTA;
  setUuid: (uuid: string) => void;
  uuid: string;
}

export const ViewRawDataButton = ({
  ctaProps,
  setUuid,
  uuid,
}: IViewRawDataButtonProps) => {
  const clickHandler = useCallback(() => {
    setUuid(uuid);
    ctaProps.clickHandler();
  }, [uuid, ctaProps, setUuid]);

  return <CardCTA {...ctaProps} clickHandler={clickHandler} />;
};
