import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { useQueries } from '@tanstack/react-query';
import { Card } from '@components/Card';
import { NoDataAvailable } from '@components/NoDataAvailable';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ServersideTable } from '@components/Table/ServersideTable';
import { ITableRefObject } from '@components/Table/types';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useDeleteUserModal } from '@hooks/useModal/hooks/useDeleteUserModal';
import {
  useCreateUserModal,
  useEditUserModal,
} from '@hooks/useModal/hooks/useUserModal/useUserModal';
import { useOriginator } from '@hooks/useOriginator';
import { useQueryState } from '@hooks/useQueryState';
import {
  IEditUserGroup,
  IMerchantPortalEditUserGroups,
} from '@schemas/merchantPortal/types/editUserGroups';
import { IMerchantPortalGroups } from '@schemas/merchantPortal/types/groups';
import { selectTimeToAuthExpiry } from '@selectors/selectTimeToAuthExpiry';
import { QueryKeys, TableNames } from '@utils/constants';
import { ButtonVariant, Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { getCursorPaginationCallbacks } from '@utils/getCursorPaginationCallbacks';
import { getUsersTableColumns } from './getUsersTableColumns';
import { getUsersTableData } from './getUsersTableData';
import { IGetUserTableData } from './types';

export const Users = () => {
  const { getLocaleDate, translate } = useI18n();
  const { originator } = useOriginator();

  const [userProps, setUserProps] = useState<IEditUserGroup>();
  const msUntilTokenExpiry = useSelector(selectTimeToAuthExpiry);
  const refToDataTable = useRef<ITableRefObject<IGetUserTableData>>(null);

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(TableNames.USERS);

  const userQueryKey = [
    QueryKeys.ACCOUNT_USER_GROUPS,
    stringifiedQueryParams,
    originator,
  ];

  const [
    { data: userData, error: userError, isLoading: isUserLoading },
    { data: groupsData, error: groupsError, isLoading: isGroupsLoading },
  ] = useQueries<
    [
      [IMerchantPortalEditUserGroups, AxiosError],
      [IMerchantPortalGroups, AxiosError],
    ]
  >({
    queries: [
      {
        queryKey: userQueryKey,
        queryFn: () =>
          fetchDataFromEndpoint(Endpoints.EditUser, {
            query: { ...queryParams },
          }),
      },
      {
        queryKey: [QueryKeys.ACCOUNT_GROUPS, originator],
        queryFn: () =>
          fetchDataFromEndpoint(Endpoints.FetchUserGroupsData, {
            query: { originator },
          }),
        cacheTime: msUntilTokenExpiry,
        staleTime: msUntilTokenExpiry,
      },
    ],
  });

  const userTableData = getUsersTableData({
    getLocaleDate,
    users: userData?.results,
  });

  // TODO: Go back to using groupsData once HeyLight goes live and the `mp_heylight` group is removed.
  // (https://heidi-pay.atlassian.net/browse/HC-12532)
  const filteredGroups = groupsData?.filter(
    group => group.name !== 'mp_heylight',
  );

  const createUserModal = useCreateUserModal({
    groups: filteredGroups,
    queryKey: userQueryKey,
  });

  const deleteUserModal = useDeleteUserModal({
    queryKey: userQueryKey,
    userProps,
    variant: [ButtonVariant.Danger, ButtonVariant.Sm],
  });

  const editUserModal = useEditUserModal({
    groups: filteredGroups,
    queryKey: userQueryKey,
    userProps,
    variant: [ButtonVariant.Primary, ButtonVariant.Sm],
  });

  const filters = getFilters({});

  const columns = useMemo(
    () =>
      getUsersTableColumns({
        deleteProps: deleteUserModal?.ctaProps,
        editProps: editUserModal?.ctaProps,
        groups: filteredGroups,
        setUserProps,
        translate,
      }),
    [
      deleteUserModal?.ctaProps,
      editUserModal?.ctaProps,
      filteredGroups,
      translate,
    ],
  );

  const { gotoNextPage, gotoPrevPage } = getCursorPaginationCallbacks({
    nextCursor: userData?.next_cursor,
    prevCursor: userData?.previous_cursor,
    queryState,
    updateQueryParams,
  });

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  return (
    <QueryStateRouter
      error={groupsError || userError}
      isLoading={isGroupsLoading || isUserLoading}
    >
      <>
        <Card
          buttons={[createUserModal?.ctaProps].filter(Boolean)}
          title={translate('TITLE.USERS')}
        >
          {userTableData && userData ? (
            <ServersideTable
              columns={columns}
              data={userTableData}
              enableSorting={true}
              filters={filters}
              isCursorPagination={true}
              manageControlledState={updateQueryParams}
              pagination={{
                gotoNextPage,
                gotoPrevPage,
                pageSize: queryParams.pageSize,
                totalItems: userData.count,
              }}
              ref={refToDataTable}
              resetFilters={resetFilters}
              tableState={queryState}
            />
          ) : (
            <NoDataAvailable />
          )}
        </Card>
        {createUserModal?.Modal}
        {deleteUserModal?.Modal}
        {editUserModal?.Modal}
      </>
    </QueryStateRouter>
  );
};
