import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IBucket } from '@schemas/opsPortal/types/collectionsListManualBuckets';
import { IUuid } from '@schemas/opsPortal/types/contractDetails';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseGetCurrentBucketQuery {
  contractUuid: IUuid;
}

export const useGetCurrentBucketQuery = ({
  contractUuid,
}: IUseGetCurrentBucketQuery) => {
  const {
    data: currentBucketData,
    error: currentBucketError,
    isLoading: isCurrentBucketLoading,
  } = useQuery<IBucket, AxiosError>([QueryKeys.BUCKET_GET], () =>
    fetchDataFromEndpoint(`${Endpoints.BucketGet}/${contractUuid}`),
  );

  return {
    currentBucketData,
    currentBucketError,
    isCurrentBucketLoading,
  };
};
