import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { Permissions } from '@appTypes';
import { TranslateKey } from '@hooks/useI18n';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { usePortalError } from '@hooks/usePortalError';
import { QueryKeys } from '@utils/constants';
import { ApplicantBlockActions, Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { ApplicantBlockForm } from './ApplicantBlockForm';
import { ICreateOrModifyBlockPayload, IRemoveBlockPayload } from './types';

export interface IBlockApplicantModalProps extends ISharedModalProps {
  applicantUuid?: string;
  consumerUuid?: string;
  contractUuid?: string;
}

interface IBlockApplicantModalSubProps extends IBlockApplicantModalProps {
  action: ApplicantBlockActions;
  modalName: string;
  permissions: Permissions;
  title: TranslateKey;
}

interface IMutationFailure {
  details: string;
}

interface IMutationSuccess {
  is_blocked: boolean;
  message: string;
}

type MutationResponse = IMutationSuccess | IMutationFailure;

export const useApplicantBlockModal = (
  props: IBlockApplicantModalSubProps,
): Modal => {
  const {
    action,
    applicantUuid,
    consumerUuid,
    contractUuid,
    modalName,
    permissions,
    title,
  } = props;
  const { translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const queryClient = useQueryClient();
  const { message } = useToast(translate);

  const uuid = applicantUuid || consumerUuid || contractUuid;

  const modal = useModal({
    sharedProps: props,
    modalName,
    buttonTranslationKey: title,
    permissions,
  });

  const { isLoading: isCreatingBlock, mutate: createApplicantBlock } =
    useMutation<
      MutationResponse,
      AxiosError<IError>,
      ICreateOrModifyBlockPayload
    >(
      requestData =>
        postDataToEndpoint({
          endpoint: Endpoints.CreateApplicantBlock,
          requestData,
        }),
      {
        onSuccess: () => {
          message.success('OPS_PORTAL.STATUS.APPLICANT_BLOCK_SUCCESSFUL');
          queryClient.invalidateQueries([QueryKeys.IS_BLOCKED, uuid]);
          queryClient.invalidateQueries([QueryKeys.BLOCKED_APPLICANTS]);
          modal.closeModal();
        },
        onError: error => handleAxiosError(error),
      },
    );

  const { isLoading: isModifyingBlock, mutate: modifyApplicantBlock } =
    useMutation<
      MutationResponse,
      AxiosError<IError>,
      ICreateOrModifyBlockPayload
    >(
      requestData =>
        postDataToEndpoint({
          endpoint: Endpoints.ModifyApplicantBlock,
          requestData,
        }),
      {
        onSuccess: () => {
          message.success(
            'OPS_PORTAL.STATUS.APPLICANT_MODIFY_BLOCK_SUCCESSFUL',
          );
          queryClient.invalidateQueries([QueryKeys.IS_BLOCKED, applicantUuid]);
          queryClient.invalidateQueries([QueryKeys.BLOCKED_APPLICANTS]);
          modal.closeModal();
        },
        onError: error => handleAxiosError(error),
      },
    );

  const { isLoading: isRemovingBlock, mutate: removeApplicantBlock } =
    useMutation<MutationResponse, AxiosError<IError>, IRemoveBlockPayload>(
      requestData =>
        postDataToEndpoint({
          endpoint: Endpoints.RemoveApplicantBlock,
          requestData,
        }),
      {
        onSuccess: () => {
          message.success('OPS_PORTAL.STATUS.APPLICANT_UNBLOCK_SUCCESSFUL');
          queryClient.invalidateQueries([QueryKeys.IS_BLOCKED, applicantUuid]);
          queryClient.invalidateQueries([QueryKeys.BLOCKED_APPLICANTS]);
          modal.closeModal();
        },
        onError: error => handleAxiosError(error),
      },
    );

  const filterValues = <
    T extends ICreateOrModifyBlockPayload | IRemoveBlockPayload,
  >(
    values: T,
  ) =>
    Object.keys(values).reduce((obj, key) => {
      const value = values[key as keyof typeof values];
      return value ? { ...obj, [key]: value } : obj;
    }, {} as T);

  const handleSubmit = useCallback(
    values => {
      const filteredValues = filterValues(values);
      if (action === ApplicantBlockActions.Modify) {
        modifyApplicantBlock(filteredValues);
      } else if (action === ApplicantBlockActions.Remove) {
        removeApplicantBlock(filteredValues);
      } else {
        createApplicantBlock(filteredValues);
      }
    },
    [createApplicantBlock, modifyApplicantBlock, removeApplicantBlock, action],
  );

  const isLoading = isCreatingBlock || isModifyingBlock || isRemovingBlock;
  const formID = `${action}-applicant-block`;

  const modalContent = (
    <ModalStatusRouter isLoading={isLoading}>
      <ApplicantBlockForm
        applicantUuid={applicantUuid}
        consumerUuid={consumerUuid}
        contractUuid={contractUuid}
        formID={formID}
        handleSubmit={handleSubmit}
        isModifyApplicantBlock={action === ApplicantBlockActions.Modify}
        isRemoveApplicantBlock={action === ApplicantBlockActions.Remove}
      />
    </ModalStatusRouter>
  );

  const modalProps = {
    allowoverflow: true,
    formID,
    isLoading,
    modalContent,
    title: translate(title),
  };

  return getModalDetails({ modal, modalProps });
};

export const useCreateApplicantBlockModal = (
  props: IBlockApplicantModalProps,
) =>
  useApplicantBlockModal({
    ...props,
    permissions: ['ops_portal.can_block_applicant'],
    modalName: 'createApplicantBlockModal',
    action: ApplicantBlockActions.Create,
    title: 'OPS_PORTAL.TITLE.CREATE_APPLICANT_BLOCK',
  });

export const useModifyApplicantBlockModal = (
  props: IBlockApplicantModalProps,
) =>
  useApplicantBlockModal({
    ...props,
    permissions: ['ops_portal.can_block_applicant'],
    modalName: 'modifyApplicantBlockModal',
    action: ApplicantBlockActions.Modify,
    title: 'OPS_PORTAL.TITLE.MODIFY_APPLICANT_BLOCK',
  });

export const useRemoveApplicantBlockModal = (
  props: IBlockApplicantModalProps,
) =>
  useApplicantBlockModal({
    ...props,
    permissions: ['ops_portal.can_unblock_applicant'],
    modalName: 'removeApplicantBlockModal',
    action: ApplicantBlockActions.Remove,
    title: 'OPS_PORTAL.TITLE.REMOVE_APPLICANT_BLOCK',
  });
