import { PaymentTypes } from '@utils/constants';
import {
  IContractPaymentModalProps,
  useContractPaymentModal,
} from './useContractPaymentModal';

export const useContractReimbursementModal = (
  props: IContractPaymentModalProps,
) =>
  useContractPaymentModal({
    ...props,
    buttonTranslationKey: 'OPS_PORTAL.BUTTON.REIMBURSEMENT',
    paymentType: PaymentTypes.REIMBURSEMENT,
    modalName: 'contractReimbursementModal',
    permissions: ['ops_portal.record_payment'],
    submitTextKey: 'OPS_PORTAL.BUTTON.REIMBURSE_AMOUNT',
    titleKey: 'OPS_PORTAL.TITLE.REIMBURSEMENT',
  });

export const useContractPrepaymentModal = (props: IContractPaymentModalProps) =>
  useContractPaymentModal({
    ...props,
    buttonTranslationKey: 'BUTTON.PREPAY',
    paymentType: PaymentTypes.PREPAYMENT,
    modalName: 'contractPrepaymentModal',
    permissions: ['ops_portal.execute_prepayment'],
    submitTextKey: 'BUTTON.PREPAY_NOW',
    titleKey: 'TITLE.PREPAYMENT',
  });

export const useContractManualPaymentsModal = (
  props: IContractPaymentModalProps,
) =>
  useContractPaymentModal({
    ...props,
    buttonTranslationKey: 'OPS_PORTAL.BUTTON.MAKE_MANUAL_PAYMENT',
    paymentType: PaymentTypes.MANUAL_PAYMENT,
    modalName: 'contractManualPaymentModal',
    permissions: ['ops_portal.record_payment'],
    submitTextKey: 'OPS_PORTAL.BUTTON.MAKE_MANUAL_PAYMENT',
    titleKey: 'OPS_PORTAL.TITLE.MANUAL_PAYMENT',
  });
