import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n';
import { usePortalError } from '@hooks/usePortalError';
import { Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';

interface IUseSendCheckoutSms {
  externalContractUUID?: string;
  lang?: string;
}

export const useSendCheckoutSms = ({
  externalContractUUID,
  lang,
}: IUseSendCheckoutSms) => {
  const { translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast();

  const { mutate: sendCheckoutSms } = useMutation<
    ArrayBuffer,
    AxiosError<IError>,
    void
  >(
    () =>
      postDataToEndpoint({
        endpoint: `${Endpoints.SendCheckoutSms}/${externalContractUUID}`,
        requestData: { lang },
      }),
    {
      onSuccess: () => {
        message.success(translate('LABEL.SEND_CHECKOUT_SMS_SUCCESS'));
      },
      onError: error => {
        handleAxiosError(error);
      },
    },
  );

  return sendCheckoutSms;
};
