import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { IOverpaidContract } from '@schemas/opsPortal/types/overpaidContracts';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { getTranslatedData } from '@utils/getTranslatedData';
import { IOverpaidContractTableColumns } from './types';

interface IMapOverpaidContractTableData {
  data?: IOverpaidContract[];
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const mapOverpaidContractsTableData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  translate,
}: IMapOverpaidContractTableData): IOverpaidContractTableColumns[] =>
  data?.map((dataRow: IOverpaidContract) => {
    const {
      amount,
      contract,
      date,
      outstanding_amount: outstandingAmount,
      payor,
    } = dataRow;

    return addPlaceholderForEmptyValues({
      contractUuid: contract.uuid,
      date: getLocaleDate({ date: date as string }),
      amount: getLocaleCurrencyValue({
        currency: amount.currency,
        value: amount.amount,
      }),
      outstandingAmount: getLocaleCurrencyValue({
        currency: outstandingAmount.currency,
        value: outstandingAmount.amount,
      }),
      payor: getTranslatedData({ term: payor.type, translate }),
    });
  }) ?? [];
