import { useCallback, useMemo } from 'react';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { useI18n } from '@hooks/useI18n';
import { useModal } from '@hooks/useModal';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { useOriginator } from '@hooks/useOriginator';
import { usePortalError } from '@hooks/usePortalError';
import { useQueryOnClick } from '@hooks/useQueryOnClick';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpointCsv } from '@utils/fetchDataFromEndpoint';
import { DownloadSettlementsReportForm } from './DownloadSettlementsReportForm';
import { ICsvRequest } from './types';

export const useDownloadSettlementsReportModal = (
  props: ISharedModalProps = {},
): Modal => {
  const { selectedLanguage, translate } = useI18n();
  const { originator } = useOriginator();
  const { handleAxiosError } = usePortalError();

  const modal = useModal({
    sharedProps: props,
    buttonTranslationKey: 'TITLE.DOWNLOAD_SETTLEMENTS_REPORT',
    modalName: 'downloadSettlementsReportModal',
    permissions: ['merchant_portal.can_view_reconciliation'],
  });

  const useQueryCallbacks = useMemo(
    () => ({
      onSuccess: modal.closeModal,
      onError: (error: AxiosError<IError>) => handleAxiosError(error),
    }),
    [handleAxiosError, modal.closeModal],
  );

  const { execute, isLoading } = useQueryOnClick<void, ICsvRequest>(
    [QueryKeys.SETTLEMENT_CSV_DOWNLOAD],
    ({ endDate, startDate }) => {
      const fromDate = startDate.toISOString().split('T')[0];
      const toDate = endDate.toISOString().split('T')[0];
      return fetchDataFromEndpointCsv(
        Endpoints.DownloadAllSettlements,
        {
          query: {
            fromDate,
            toDate,
            lang: selectedLanguage,
            originator,
          },
        },
        `settlement_transactions_${fromDate}_${toDate}`,
      );
    },
    useQueryCallbacks,
  );

  const handleSubmit = useCallback(
    (request: ICsvRequest) => execute(request),
    [execute],
  );

  const formID = 'settlementsDownload';

  const modalContent = (
    <QueryStateRouter isLoading={isLoading}>
      <DownloadSettlementsReportForm
        formID={formID}
        handleSubmit={handleSubmit}
      />
    </QueryStateRouter>
  );

  const modalProps = {
    allowoverflow: true,
    formID,
    isLoading,
    modalContent,
    submitText: translate('BUTTON.DOWNLOAD'),
    title: translate('TITLE.DOWNLOAD_SETTLEMENTS_REPORT'),
  };

  return getModalDetails({ modal, modalProps });
};
