import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Nullable } from '@heidi-pay/heidi-common-fe/types';

type URLSearchParamsQuery =
  | string
  | string[][]
  | Record<string, string>
  | URLSearchParams
  | undefined;

interface IFetchOptions {
  axiosArgs?: AxiosRequestConfig;
  query?: URLSearchParamsQuery;
  search?: string;
}

export const fetchDataFromEndpoint = async (
  endpoint: string,
  options?: IFetchOptions,
) => {
  const searchPath = `/api/${endpoint}/${options?.search ?? ''}`;
  const hasQuery = Boolean(options?.query);
  const queryString = hasQuery
    ? `?${new URLSearchParams(options?.query).toString()}`
    : '';

  let response: AxiosResponse;
  const url = searchPath + queryString;
  if (options?.axiosArgs) {
    response = await axios.get(url, options.axiosArgs);
  } else {
    response = await axios.get(url);
  }

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }

  return response.data;
};

export const getBlobText = async (response: AxiosResponse) =>
  response.data.text();

export const downloadCsv = async (response: AxiosResponse, name: string) => {
  // Add BOM to start of string to fix issue with opening CSV on windows
  // https://camillehdl.dev/excel-utf8-csv/
  const blobData = await getBlobText(response);
  const bom = '\ufeff';
  const csvWithBom = bom + blobData;

  const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', `${name}.csv`);

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const fetchDataFromEndpointCsv = async (
  endpoint: string,
  options: IFetchOptions,
  name: string,
  isPost = false,
  body: Nullable<object> = null,
) => {
  const searchPath = `/api/${endpoint}/${options?.search ?? ''}`;
  const hasQuery = Boolean(options?.query);
  const queryString = hasQuery
    ? `?${new URLSearchParams(options?.query).toString()}`
    : '';

  let response: AxiosResponse;
  const url = searchPath + queryString;

  if (isPost) {
    response = await axios.post(url, body, {
      responseType: 'blob',
    });
  } else {
    response = await axios.get(url, {
      responseType: 'blob',
    });
  }

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }

  await downloadCsv(response, name);

  return response.data;
};
