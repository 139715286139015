import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IOpsPortalContractsCursor } from '@schemas/opsPortal/types/contractsCursor';
import { QueryKeys, matchingContractUuidsDisplayLimit } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseGetContractsByUuidsQuery {
  stringifiedQueryParams: string;
  uuids?: string[] | null;
}

export const useGetContractsByUuidsQuery = ({
  stringifiedQueryParams,
  uuids,
}: IUseGetContractsByUuidsQuery) => {
  const {
    data: contractsByUuidsData,
    error: contractsByUuidsError,
    isFetching: isContractsByUuidsFetching,
    isLoading: isContractsByUuidsLoading,
  } = useQuery<IOpsPortalContractsCursor, AxiosError>(
    [QueryKeys.CONTRACTS_BY_UUIDS, uuids, stringifiedQueryParams],
    () =>
      fetchDataFromEndpoint(`${Endpoints.FetchContractsByUuids}`, {
        query: `uuids=${uuids
          ?.slice(0, matchingContractUuidsDisplayLimit)
          ?.join(',')}&${stringifiedQueryParams}`,
      }),
    {
      enabled: Boolean(uuids?.length),
    },
  );

  return {
    contractsByUuidsData,
    contractsByUuidsError,
    isContractsByUuidsFetching,
    isContractsByUuidsLoading,
  };
};
