import { useI18n } from '@hooks/useI18n/useI18n';
import { PaginationCounter } from './Pagination.styled';
import { IPaginationCounts } from './types';
import { getPaginationCounts } from './utils';

export const PaginationCounts = ({
  isCursorPagination,
  pageCount,
  pageIndex,
  pageSize,
  rowCount,
  totalItems,
}: IPaginationCounts) => {
  const { translate } = useI18n();

  if (isCursorPagination) {
    return (
      <PaginationCounter>{`${translate(
        'LABEL.TOTAL_ITEMS',
      )}: ${totalItems}`}</PaginationCounter>
    );
  }

  const { from, to, total } = getPaginationCounts({
    pageCount,
    pageIndex,
    pageSize,
    rowCount,
    totalItems,
  });

  const paginationText = translate(
    total === 1
      ? 'TABLE.SHOWING_1_TO_1_OF_1_ENTRY'
      : 'TABLE.SHOWING_X_TO_Y_OF_Z_ENTRIES',
    {
      x: from,
      y: to,
      z: total,
    },
  );

  return <PaginationCounter>{paginationText}</PaginationCounter>;
};
