import { useCallback } from 'react';
import { ICardCTA } from '@appTypes';
import { CardCTA } from '@components/CardCTA';
import { IEditUserGroup } from '@schemas/merchantPortal/types/editUserGroups';

interface IEditUserButtonProps {
  ctaProps: ICardCTA;
  setUserProps: (userProps: IEditUserGroup) => void;
  userProps: IEditUserGroup;
}

export const EditUserButton = ({
  ctaProps,
  setUserProps,
  userProps,
}: IEditUserButtonProps) => {
  const clickHandler = useCallback(() => {
    setUserProps(userProps);
    ctaProps.clickHandler();
  }, [ctaProps, setUserProps, userProps]);

  return <CardCTA {...ctaProps} clickHandler={clickHandler} />;
};
