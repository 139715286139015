import { Navigate, Route, Routes } from 'react-router-dom';
import { ForgotPassword } from './ForgotPassword';
import { Login } from './Login';
import { RequestRegistrationEmail } from './RequestRegistrationEmail';
import { ResetPassword } from './ResetPassword';
import { Success } from './Success';

export const AuthRoutes = () => (
  <Routes>
    <Route element={<Navigate replace={true} to="/auth/login" />} path="/" />
    <Route element={<ResetPassword />} path="/activation/:token" />
    <Route element={<ForgotPassword />} path="/forgot-password" />
    <Route element={<Login />} path="/login" />
    <Route
      element={<RequestRegistrationEmail />}
      path="/request-registration"
    />
    <Route element={<ResetPassword />} path="/reset-password/:token" />
    <Route element={<Success />} path="/success" />
  </Routes>
);
