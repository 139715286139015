import { useFormContext } from 'react-hook-form';
import { InputCheckbox, Label } from '@components/Common.styles';
import { ValidationError } from '@components/ValidationError';

interface ICheckboxFieldProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label: string;
  name: string;
}

export const CheckboxFieldRHF = ({
  label,
  name,
  ...props
}: ICheckboxFieldProps) => {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  const { className: propsClassName, ...remainingProps } = props;

  const error = errors[name]?.message;

  return (
    <div className={propsClassName}>
      <Label checkbox={true} htmlFor={name}>
        <InputCheckbox id={name} {...remainingProps} {...register(name)} />{' '}
        {label}
      </Label>
      {error ? <ValidationError error={error} /> : null}
    </div>
  );
};
