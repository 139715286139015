import { useI18n } from '@hooks/useI18n/useI18n';

interface IModalStatusRouter {
  children: JSX.Element;
  formStatusMessage?: string;
  isLoading: boolean;
  loadingMessage?: string;
}

export const ModalStatusRouter = ({
  children,
  formStatusMessage,
  isLoading,
  loadingMessage,
}: IModalStatusRouter) => {
  const { translate } = useI18n();

  if (formStatusMessage) {
    return <p>{formStatusMessage}</p>;
  }

  if (isLoading) {
    // We don't want to allow empty string, so using || instead of ??.
    return <p>{loadingMessage || translate('STATUS.FORM_SUBMITTING')}</p>;
  }

  return children;
};
