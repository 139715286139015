import { IQueryState } from '@hooks/useQueryState/utils/types';

interface IGetCursorPaginationCallbacks {
  nextCursor?: string | null;
  prevCursor?: string | null;
  queryState: IQueryState;
  updateQueryParams: (newQueryState: object) => void;
}

export const getCursorPaginationCallbacks = ({
  nextCursor,
  prevCursor,
  queryState,
  updateQueryParams,
}: IGetCursorPaginationCallbacks) => ({
  gotoNextPage: nextCursor
    ? () => updateQueryParams({ ...queryState, cursor: nextCursor })
    : null,
  gotoPrevPage: prevCursor
    ? () => updateQueryParams({ ...queryState, cursor: prevCursor })
    : null,
});
