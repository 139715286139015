import {
  LoginGreetingHeader,
  LoginGreetingWrapper,
} from '@auth/pages/Auth.styles';
import { Logo } from '@components/Logo';
import { useI18n } from '@hooks/useI18n/useI18n';
import { LogoVariants } from '@utils/constants';

export const LoginGreeting = () => {
  const { translate } = useI18n();

  return (
    <LoginGreetingWrapper>
      <LoginGreetingHeader>
        {translate('GENERAL.WELCOME_TO')}
        <Logo fill={LogoVariants.DARK} justifyContent="flex-end" />
      </LoginGreetingHeader>
    </LoginGreetingWrapper>
  );
};
