import { Form } from 'formik';
import styled from 'styled-components';

export const FiltersForm = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ isInTab, ...props }) => <Form {...props} />,
  {
    shouldForwardProp: prop => prop !== 'isInTab',
  },
).attrs({ className: 'filtersForm' })(({ isInTab = false }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  marginBlockStart: '-2rem',
  paddingTop: isInTab ? '13px' : '0',
}));

export const FiltersLegend = styled.legend.attrs({ className: 'sr-only' })``;

export const FiltersRow = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-block-end: 0.5rem;
`;
