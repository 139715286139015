import { jsx } from 'slate-hyperscript';
import { JSXTypes, NodeNames, NodeTypes } from '@utils/constants';
import { IDeserializeProps, IDeserializeReturn } from './types';

const deserialize = ({
  el,
  markAttributes,
}: IDeserializeProps): IDeserializeReturn => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx(JSXTypes.TEXT, markAttributes, el.textContent);
  }

  if (el.nodeType !== Node.ELEMENT_NODE) {
    return undefined;
  }

  const nodeAttributes = { ...markAttributes };

  // define attributes for text nodes
  if (el.nodeName === NodeNames.EMPHASIS) {
    nodeAttributes.italic = true;
  }
  if (el.nodeName === NodeNames.STRONG) {
    nodeAttributes.bold = true;
  }
  if (el.nodeName === NodeNames.UNDERLINE) {
    nodeAttributes.underline = true;
  }

  const children = Array.from(el.childNodes)
    .map(node =>
      deserialize({ el: node as HTMLElement, markAttributes: nodeAttributes }),
    )
    .flat();

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  switch (el.nodeName) {
    case NodeNames.ANCHOR:
      return jsx(
        JSXTypes.ELEMENT,
        { type: NodeTypes.LINK, url: el.getAttribute('href') },
        children,
      );
    case NodeNames.BODY:
      return jsx(JSXTypes.FRAGMENT, {}, children);
    case NodeNames.LIST_ITEM:
      return jsx(JSXTypes.ELEMENT, { type: NodeTypes.LIST_ITEM }, children);
    case NodeNames.ORDERED_LIST:
      return jsx(JSXTypes.ELEMENT, { type: NodeTypes.ORDERED_LIST }, children);
    case NodeNames.PARAGRAPH:
      return jsx(JSXTypes.ELEMENT, { type: NodeTypes.PARAGRAPH }, children);
    case NodeNames.UNORDERED_LIST:
      return jsx(
        JSXTypes.ELEMENT,
        { type: NodeTypes.UNORDERED_LIST },
        children,
      );
    default:
      return children;
  }
};

export const deserializeHtml = (html: string): IDeserializeReturn => {
  const cleanedHtml = html.replace(/[\n\r\t]+/g, '');
  const document = new DOMParser().parseFromString(cleanedHtml, 'text/html');
  return deserialize({ el: document.body });
};
