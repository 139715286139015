import { Days } from '@appTypes';
import { GetLocaleDate } from '@hooks/useI18n';
import { defaultDateOptions } from './constants';
import { DaysAndZero, IDateOption } from './types';

interface IGeneratePaymentDateOptions {
  date?: Date;
  dateOptions?: Days[];
  disabledOptionDay?: Days | -1;
  getLocaleDate: GetLocaleDate;
}

const calculateOptionGenerationStartIndex = (
  dateOptions: Days[],
  currentDay: number,
): [number, number] => {
  let index = dateOptions.findIndex(dateOption => dateOption > currentDay);
  let monthAdjustment = 0;

  if (index === -1) {
    // If no future dates in the array are found, start from the first date in the generated month.
    index = 0;
    monthAdjustment = 1;
  }
  return [index, monthAdjustment];
};

const updateMonthAdjustmentForRollover = (
  currentIndex: number,
  currentMonthAdjustment: number,
  dateOptions: Days[],
  startIndex: number,
): number => {
  if (
    (startIndex + currentIndex) % dateOptions.length < startIndex &&
    currentMonthAdjustment === 0
  ) {
    return 1;
  }
  return currentMonthAdjustment;
};

const adjustOptionDateMonthIfThereHasBeenARollover = (
  currentIndex: number,
  optionDate: Date,
  generatedDates: Date[],
) => {
  if (
    currentIndex > 0 &&
    optionDate.getDate() < generatedDates[currentIndex - 1].getDate()
  ) {
    optionDate.setMonth(optionDate.getMonth() + 1);
  }
};

export const generatePaymentDateOptions = ({
  date = new Date(Date.now()),
  dateOptions = defaultDateOptions,
  disabledOptionDay = -1,
  getLocaleDate,
}: IGeneratePaymentDateOptions) => {
  const currentDay = date.getDate();
  const dateForCalculation = new Date(date);
  const generatedDates: Date[] = [];

  const [startIndex, initialMonthAdjustment] =
    calculateOptionGenerationStartIndex(dateOptions, currentDay);
  let monthAdjustment = initialMonthAdjustment;

  const generatedDateOptions: IDateOption[] = dateOptions.map((_, i) => {
    const dateOptionIndexForGeneration = (startIndex + i) % dateOptions.length;
    const optionDay = dateOptions[dateOptionIndexForGeneration];
    const optionDate = new Date(
      dateForCalculation.getFullYear(),
      dateForCalculation.getMonth() + monthAdjustment,
      optionDay,
    );

    monthAdjustment = updateMonthAdjustmentForRollover(
      i,
      monthAdjustment,
      dateOptions,
      startIndex,
    );

    adjustOptionDateMonthIfThereHasBeenARollover(i, optionDate, generatedDates);

    const optionDateString = optionDate.toDateString();
    const optionDateLocale = getLocaleDate({ date: optionDateString });

    generatedDates[i] = optionDate;
    return {
      id: optionDay as DaysAndZero,
      name: optionDateLocale,
      disabled: optionDay === disabledOptionDay,
    };
  });
  return generatedDateOptions;
};
