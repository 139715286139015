import { createColumnHelper } from '@tanstack/react-table';
import { Link } from '@components/Common.styles';
import { Translate } from '@hooks/useI18n';
import { IUnbalancedAllocationsTableColumns } from './types';

export const getUnbalancedAllocationsColumns = (translate: Translate) => {
  const columnHelper = createColumnHelper<IUnbalancedAllocationsTableColumns>();

  return [
    columnHelper.accessor('parGroup', {
      header: translate('OPS_PORTAL.LABEL.PAR_GROUP'),
      cell: ({ row }) => (
        <Link to={row.original.uuid}>{row.original.parGroup}</Link>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor('paymentPlanReference', {
      header: translate('OPS_PORTAL.LABEL.PAYMENT_PLAN_REFERENCE'),
      cell: ({ row }) => (
        <Link to={`/all_contracts/${row.original.contractUuid}`}>
          {row.original.paymentPlanReference}
        </Link>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor('monetaBalance', {
      header: translate('OPS_PORTAL.LABEL.MONETA_BALANCE'),
      enableSorting: false,
    }),
    columnHelper.accessor('allocationBalance', {
      header: translate('OPS_PORTAL.LABEL.ALLOCATION_BALANCE'),
      enableSorting: false,
    }),
    columnHelper.accessor('difference', {
      header: translate('OPS_PORTAL.LABEL.DIFFERENCE'),
      enableSorting: false,
    }),
    columnHelper.accessor('latestTestDate', {
      header: translate('OPS_PORTAL.LABEL.LATEST_TEST_DATE'),
    }),
  ];
};
