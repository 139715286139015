import { DataValue, IDataRow } from '@components/DataDisplay/DataDisplay';
import { missingValuePlaceholder } from '@utils/constants';

interface IGetDataList<T> {
  data: T;
  labels: Partial<Record<keyof T, string>>;
}

export const getDataList = <T>({ data, labels }: IGetDataList<T>): IDataRow[] =>
  Object.keys(data as Record<string, unknown>).map(key => ({
    key: labels[key as keyof T] as string,
    value: (data[key as keyof T] || missingValuePlaceholder) as DataValue,
  }));
