import { useSelector } from 'react-redux';
import { selectUser } from '@selectors/index';
import { UserType } from '@utils/enums';
import { getFullName } from '@utils/getFullName';

export const useUserDetails = () => {
  const user = useSelector(selectUser);
  const hasInstoreProfileGroup =
    'extra_settings' in user &&
    Boolean(user.extra_settings?.has_active_instore_order_profile_group);
  const userName = getFullName({
    firstName: user.first_name,
    lastName: user.last_name,
  });
  const userType = 'user_type' in user && user.user_type;

  return {
    email: user?.email,
    hasInstoreProfileGroup,
    isAgencyUser: userType === UserType.Agency,
    isInternalOpsUser: userType === UserType.Ops,
    userType,
    userName,
  };
};
