export const addHeadersToRequest = ({ config, store }) => {
  const {
    auth: { authToken },
  } = store.getState();

  return {
    ...config,
    headers: {
      ...config.headers,
      'Content-Type': config.headers['Content-Type'] ?? 'application/json',
      ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
    },
  };
};
