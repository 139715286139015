import { createColumnHelper } from '@tanstack/react-table';
import { ICardCTA, ReactTableColumns } from '@appTypes';
import { Link } from '@components/Common.styles';
import { Translate } from '@hooks/useI18n';
import { IApiInitCalls } from './types';
import { ViewRawDataButton } from './ViewRawDataButton';

interface IGetColumnsProps {
  ctaProps: ICardCTA | undefined;
  setUuid: (uuid: string) => void;
  translate: Translate;
}

export const getColumns = ({
  ctaProps,
  setUuid,
  translate,
}: IGetColumnsProps): ReactTableColumns<IApiInitCalls> => {
  const columnHelper = createColumnHelper<IApiInitCalls>();

  return [
    columnHelper.accessor('timestamp', {
      header: translate('OPS_PORTAL.LABEL.TIMESTAMP'),
    }),
    columnHelper.accessor('merchantName', {
      header: translate('OPS_PORTAL.LABEL.MERCHANT_NAME'),
      cell: ({ row }) => (
        <Link to={`/merchants/${row.original.merchantUUID}`}>
          {row.original.merchantName}
        </Link>
      ),
    }),
    columnHelper.accessor('responseCode', {
      header: translate('OPS_PORTAL.LABEL.RESPONSE_CODE'),
    }),
    columnHelper.accessor('consumerName', {
      header: translate('TITLE.CUSTOMER_NAME'),
      cell: ({ row }) => (
        <Link to={`/consumers/${row.original.consumerUUID}`}>
          {row.original.consumerName}
        </Link>
      ),
    }),
    columnHelper.accessor('application', {
      header: translate('OPS_PORTAL.TITLE.APPLICATION'),
      cell: ({ row }) => (
        <Link to={`/applications/${row.original.applicationUUID}`}>
          {row.original.applicationReference}
        </Link>
      ),
    }),
    columnHelper.display({
      id: 'ctaColumn',
      cell: ({ row }) =>
        ctaProps ? (
          <ViewRawDataButton
            ctaProps={ctaProps}
            setUuid={setUuid}
            uuid={row.original.apiCallUUID}
          />
        ) : null,
    }),
  ];
};
