import { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { useConfig } from '@hooks/useConfig';

interface IStyledComponentsTheme {
  children: ReactNode;
}

export const StyledComponentsTheme = ({ children }: IStyledComponentsTheme) => {
  const { theme } = useConfig();
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
