import { getLocale } from '@utils/getLocale';

export const getFormattedDate = (dateStr: string | null) => {
  const formatted = dateStr;
  if (dateStr) {
    const dateObj = new Date(dateStr);

    return new Date(dateObj).toLocaleDateString(getLocale(), {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }
  return formatted;
};
