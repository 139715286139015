import { createColumnHelper } from '@tanstack/react-table';
import { Link } from '@components/Common.styles';
import { Translate } from '@hooks/useI18n';
import { ISettlementsTableColumns } from './types';

export const getSettlementsTableColumns = (translate: Translate) => {
  const columnHelper = createColumnHelper<ISettlementsTableColumns>();

  return [
    columnHelper.accessor('settlementRef', {
      header: translate('PAGE.SETTLEMENTS.SETTLEMENT_REFERENCE'),
      cell: ({ row }) => (
        <Link to={row.original.settlementId}>{row.original.settlementRef}</Link>
      ),
    }),

    columnHelper.accessor('settlementType', {
      header: translate('PAGE.SETTLEMENTS.SETTLEMENT_TYPE'),
    }),

    columnHelper.accessor('settlementAmount', {
      header: translate('PAGE.SETTLEMENTS.SETTLEMENT_AMOUNT'),
    }),

    columnHelper.accessor('settlementDate', {
      header: translate('PAGE.SETTLEMENTS.SETTLEMENT_DATE'),
    }),

    columnHelper.accessor('payor', {
      header: translate('PAGE.SETTLEMENTS.PAYOR'),
    }),

    columnHelper.accessor('payee', {
      header: translate('PAGE.SETTLEMENTS.PAYEE'),
    }),
  ];
};
