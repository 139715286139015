import styled from 'styled-components';
import { NoDataAvailable } from '@components/NoDataAvailable';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IOpsPortalApiCallDetails } from '@schemas/opsPortal/types/apiCallDetails';

interface IViewRawDataContentProps {
  data: IOpsPortalApiCallDetails | undefined;
}

const RawDataContainer = styled.pre`
  border: 1px solid #aaa;
  border-radius: 4px;
  margin-block-end: 1.75rem;
  max-height: 25vh;
  padding: 1rem;
  resize: vertical;
`;

export const ViewRawDataContent = ({ data }: IViewRawDataContentProps) => {
  const { translate } = useI18n();

  if (!data) {
    return <NoDataAvailable />;
  }

  return (
    <>
      <h3>{translate('OPS_PORTAL.TITLE.REQUEST')}</h3>
      <RawDataContainer>
        {JSON.stringify(data.raw_request_data, null, 2)}
      </RawDataContainer>
      <h3>{translate('OPS_PORTAL.TITLE.RESPONSE')}</h3>
      <RawDataContainer>
        {JSON.stringify(data.raw_response_data, null, 2)}
      </RawDataContainer>
    </>
  );
};
