// DOCS: https://javascript.info/cookie

// Component Definition
export const KTCookie = (function ktCookie() {
  return {
    // returns the cookie with the given name,
    // or undefined if not found
    getCookie(name) {
      const matches = document.cookie.match(
        new RegExp(
          `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`,
        ),
      );
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },

    // Please note that a cookie value is encoded,
    // so getCookie uses a built-in decodeURIComponent function to decode it.
    setCookie(name, value, options = {}) {
      const cookieOptions = { path: '/', ...options };

      if (options.expires instanceof Date) {
        cookieOptions.expires = options.expires.toUTCString();
      }

      let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(
        value,
      )}`;

      Object.keys(cookieOptions).forEach(key => {
        updatedCookie += `; ${key}`;
        const optionValue = options[key];
        if (optionValue !== true) {
          updatedCookie += `=${optionValue}`;
        }
      });

      document.cookie = updatedCookie;
    },

    // To delete a cookie, we can call it with a negative expiration date:
    deleteCookie(name) {
      KTCookie.setCookie(name, '', {
        'max-age': -1,
      });
    },
  };
})();
