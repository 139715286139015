import { Formik, Form } from 'formik';
import { v4 as getUuid } from 'uuid';
import * as Yup from 'yup';
import { TextField } from '@components/TextField';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ValidationErrorKeys } from '@utils/enums';
import { AdjustContractValueWarning } from './AdjustContractValueWarning';
import { IAdjustContractValueFormProps } from './types';

export const AdjustContractValueForm = ({
  amount,
  currency,
  formID,
  handleSubmit,
  isCancel,
}: IAdjustContractValueFormProps) => {
  const { translate } = useI18n();

  const initialFormikValues = {
    contract_value: isCancel ? '0' : '',
    currency,
    intervention_uuid: getUuid(),
  };

  const validationSchema = Yup.object({
    contract_value: Yup.number()
      .typeError(translate(ValidationErrorKeys.AmountMustBeNumber))
      .required(translate(ValidationErrorKeys.Required))
      .lessThan(
        Number(amount),
        translate(ValidationErrorKeys.AmountCannotExceedOrderValue),
      )
      .min(0, translate(ValidationErrorKeys.MinNum0)),
  });

  return (
    <>
      {isCancel ? null : (
        <p>
          {translate(
            'PAGE.ORDERS.CANCEL_ALL_OR_PART_OF_AN_ORDER_BY_ADJUSTING_THE_CONTRACT_VALUE',
          )}
        </p>
      )}
      <AdjustContractValueWarning />
      <Formik
        initialValues={initialFormikValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Form
          className="form fv-plugins-bootstrap fv-plugins-framework"
          id={formID}
        >
          {isCancel ? null : (
            <TextField
              id="contract_value"
              label={translate('LABEL.ADJUSTED_CONTRACT_VALUE', { currency })}
              name="contract_value"
            />
          )}
        </Form>
      </Formik>
    </>
  );
};
