import * as Yup from 'yup';
import { Translate } from '@hooks/useI18n';
import { ValidationErrorKeys } from '@utils/enums';
import { customPaymentDateId, defaultDateOptions } from './constants';

interface IChangePaymentDate {
  currentPaymentDay: number;
}

export const getValidationSchema =
  (translate: Translate) =>
  ({ currentPaymentDay }: IChangePaymentDate) =>
    Yup.object({
      requested_payment_day: Yup.mixed().when('override_dates', {
        is: false,
        then: () =>
          Yup.number()
            .required(translate(ValidationErrorKeys.Required))
            .typeError(translate(ValidationErrorKeys.AmountMustBeNumber))
            .oneOf(
              [customPaymentDateId, ...defaultDateOptions],
              translate(ValidationErrorKeys.InvalidPaymentDate),
            ),
        otherwise: () => Yup.number().notRequired().nullable(),
      }),
      custom_payment_day: Yup.mixed().when(
        ['requested_payment_day', 'override_dates'],
        {
          is: (requestedPaymentDay: number, overrideDates: boolean) =>
            requestedPaymentDay === 0 && overrideDates === false,
          then: () =>
            Yup.number()
              .required(translate(ValidationErrorKeys.Required))
              .typeError(translate(ValidationErrorKeys.AmountMustBeNumber))
              .max(31, translate(ValidationErrorKeys.MaxNumX, { max: 31 }))
              .min(1, translate(ValidationErrorKeys.MinNumX, { min: 1 }))
              .test(
                'not-duplicate',
                translate(ValidationErrorKeys.DateHasNotChanged),
                value => currentPaymentDay !== value,
              ),
          otherwise: () => Yup.number().notRequired().nullable(),
        },
      ),
      payment_dates: Yup.array().when('override_dates', {
        is: true,
        then: () =>
          Yup.array()
            .of(Yup.string().required(translate(ValidationErrorKeys.Required)))
            .required(translate(ValidationErrorKeys.Required)),
        otherwise: () => Yup.array().of(Yup.string().notRequired().nullable()),
      }),
    });
