import { useMemo, useRef } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Card } from '@components/Card';
import { NoDataAvailable } from '@components/NoDataAvailable';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ClientsideTable } from '@components/Table/ClientsideTable';
import { ITableRefObject } from '@components/Table/types';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IOpsPortalBlockedApplicants } from '@schemas/opsPortal/types/blockedApplicants';
import { QueryKeys, TableNames } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { getColumns } from './getColumns';
import { mapData } from './mapData';
import { IBlockedApplicantsTableColumns } from './types';

export const BlockedApplicants = () => {
  const { getLocaleDate, translate } = useI18n();
  const tableName = TableNames.BLOCKED_APPLICANTS;

  const refToDataTable =
    useRef<ITableRefObject<IBlockedApplicantsTableColumns>>(null);

  const { data, error, isLoading } = useQuery<
    IOpsPortalBlockedApplicants,
    AxiosError
  >([QueryKeys.BLOCKED_APPLICANTS], () =>
    fetchDataFromEndpoint(Endpoints.FetchBlockedApplicants),
  );

  const canBlockOrUnblock = useHasPermission([
    'ops_portal.can_block_applicant',
    'ops_portal.can_unblock_applicant',
  ]);

  const columns = useMemo(
    () => getColumns(translate, canBlockOrUnblock),
    [canBlockOrUnblock, translate],
  );

  const tableData: IBlockedApplicantsTableColumns[] = data
    ? mapData({
        data,
        getLocaleDate,
      })
    : [];

  return (
    <Card title={translate('OPS_PORTAL.TITLE.BLOCKED_APPLICANTS')}>
      <QueryStateRouter error={error} isLoading={isLoading}>
        {data ? (
          <ClientsideTable
            columns={columns}
            data={tableData}
            ref={refToDataTable}
            tableName={tableName}
          />
        ) : (
          <NoDataAvailable />
        )}
      </QueryStateRouter>
    </Card>
  );
};
