import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IntlProvider, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useOriginator } from '@hooks/useOriginator';
import { usePortal } from '@hooks/usePortal';
import { supportedLanguages, SupportedLanguages } from '@utils/constants';
import { getIsAuthPage } from '@utils/getIsAuthPage';
import { heyLightTranslationConstants } from './constants';
import { translations } from './translations';
import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  SupportedLanguage,
  Translate,
} from './types';
import { getInitialLanguageAndLocale } from './utils/getInitialLanguageAndLocale';
import { getLanguageCode } from './utils/getLanguageCode';
import { getLanguageStorageKey } from './utils/getLanguageStorageKey';
import { getLocaleCurrencyValue as getLocaleCurrencyVal } from './utils/getLocaleCurrencyValue';
import { getLocaleDate } from './utils/getLocaleDate';
import { getStoredLanguage } from './utils/getStoredLanguage';
import { setStoredLanguage } from './utils/setStoredLanguage';

interface II18nContext {
  selectedLanguage: SupportedLanguage;
  setSelectedLanguage: (language: SupportedLanguage) => void;
}

interface II18nProvider {
  children: JSX.Element | JSX.Element[];
}

const I18nContext = createContext<II18nContext>({
  selectedLanguage: SupportedLanguages.ENGLISH,
  setSelectedLanguage: () => {},
});

const { initialLanguage, locale } = getInitialLanguageAndLocale(
  navigator?.languages,
);

export const I18nProvider = ({ children }: II18nProvider) => {
  const { pathname } = useLocation();
  const isAuthPage = getIsAuthPage(pathname);

  const { isCompass, originator } = useOriginator();
  const { isOpsPortal, portal } = usePortal();

  const isItalian = isCompass || /IT/.test(pathname);

  const [selectedLanguage, setSelectedLanguage] = useState<SupportedLanguage>(
    isItalian ? SupportedLanguages.ITALIAN : initialLanguage,
  );

  const messages = translations[selectedLanguage];

  useEffect(() => {
    if (isOpsPortal) {
      setSelectedLanguage(SupportedLanguages.ENGLISH);
    } else if (portal && isCompass) {
      setSelectedLanguage(SupportedLanguages.ITALIAN);
    } else {
      const languageKey = getLanguageStorageKey({
        isCompass,
        isOpsPortal,
        originator,
        portal,
      });
      const storedLanguage = getStoredLanguage(languageKey);

      if (storedLanguage) {
        setSelectedLanguage(storedLanguage);
      }
    }
  }, [isCompass, isOpsPortal, originator, portal]);

  useEffect(() => {
    const canStoreLanguage = isCompass || isOpsPortal || !isAuthPage;

    if (canStoreLanguage) {
      const languageKey = getLanguageStorageKey({
        isCompass,
        isOpsPortal,
        originator,
        portal,
      });

      setStoredLanguage({
        key: languageKey,
        language: selectedLanguage,
      });
    }
  }, [
    isAuthPage,
    isCompass,
    isOpsPortal,
    originator,
    portal,
    selectedLanguage,
  ]);

  const value = useMemo(
    () => ({
      selectedLanguage,
      setSelectedLanguage,
    }),
    [selectedLanguage],
  );

  return (
    <I18nContext.Provider value={value}>
      <IntlProvider locale={selectedLanguage} messages={messages}>
        {children}
      </IntlProvider>
    </I18nContext.Provider>
  );
};

interface IUseI18n extends II18nContext {
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  languageCode: string;
  translate: Translate;
}

export const useI18n = (): IUseI18n => {
  const translationConstants = heyLightTranslationConstants;
  const intl = useIntl();
  const context = useContext(I18nContext);

  if (context === undefined) {
    throw new Error('useI18n must be used within an I18nProvider');
  }

  const languageCode = getLanguageCode({
    language: context.selectedLanguage,
    locale,
  });

  const getLocaleCurrencyValue = useCallback(
    ({ currency, value }) =>
      getLocaleCurrencyVal({ currency, languageCode, value }),
    [languageCode],
  );

  // TODO - remove languageCodeOverride once ops portal has translations HC-14422
  const translate: Translate = useCallback(
    (id, interpolations, languageCodeOverride) => {
      // if we have a language override, we can only do when no interpolations
      // also we check that the language passed in is in the supported language list
      const shouldUseLanguageOverride =
        languageCodeOverride &&
        !interpolations?.length &&
        supportedLanguages.includes(languageCodeOverride);

      if (shouldUseLanguageOverride) {
        const targetMessages: Record<string, string> =
          translations[languageCodeOverride];
        return targetMessages[id] ?? id;
      }

      return intl.formatMessage(
        { id },
        { ...interpolations, ...translationConstants },
      );
    },
    [intl, translationConstants],
  );

  return {
    ...context,

    getLocaleCurrencyValue,

    getLocaleDate: ({ date, formatOverride, includeTime }) =>
      getLocaleDate({ date, formatOverride, includeTime, languageCode }),

    languageCode,

    translate,
  };
};
