import styled from 'styled-components';
import { LabelPosition } from './enums';
import { IToggleProps } from './types';

export const ToggleWrapper = styled.button.attrs({ type: 'button' })`
  border: 0;
  padding: 0;
  background: transparent;
  font: inherit;

  &:focus {
    outline: 0;
  }
`;

export const ToggleLabel = styled.span<IToggleProps>`
  ${props =>
    props.labelPosition === LabelPosition.Left
      ? 'margin-inline-end: 0.5rem'
      : 'margin-inline-start: 0.5rem'};
`;

export const Toggle = styled.span`
  --offset: 0.25em;
  --diameter: 1.25em;

  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  width: calc(var(--diameter) * 2 + var(--offset) * 2);
  height: calc(var(--diameter) + var(--offset) * 2);
  box-sizing: content-box;
  border: 0.1em solid rgb(0 0 0 / 0.2);
  position: relative;
  border-radius: 100vw;
  background-color: #fbe4e2;
  transition: 250ms;
  cursor: pointer;

  &::before {
    content: '';
    width: var(--diameter);
    height: var(--diameter);
    border-radius: 50%;
    box-sizing: border-box;
    border: 0.1em solid rgb(0 0 0 / 0.2);
    position: absolute;
    z-index: 2;
    top: 50%;
    left: var(--offset);
    transform: translate(0, -50%);
    background-color: #fff;
    transition: inherit;
  }

  ${ToggleWrapper}:focus & {
    outline: 1px dotted #212121;
    outline: 1px auto -webkit-focus-ring-color;
  }

  ${ToggleWrapper}:focus:not(:focus-visible) & {
    outline: 0;
  }

  ${ToggleWrapper}[aria-pressed=true] & {
    background-color: #e3f5eb;
  }

  ${ToggleWrapper}[aria-pressed=true] &::before {
    transform: translate(100%, -50%);
  }

  & .Toggle__icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    color: inherit;
    fill: currentcolor;
  }

  & .Toggle__icon--cross {
    color: #e74c3c;
    font-size: 85%;
  }

  & .Toggle__icon--checkmark {
    color: #1fb978;
  }

  @media (prefers-reduced-motion: reduce) {
    & {
      transition-duration: 0ms;
    }
  }
`;
