import * as Yup from 'yup';
import { Translate } from '@hooks/useI18n';
import { ValidationErrorKeys } from '@utils/enums';

export const getValidationSchema = (translate: Translate) =>
  Yup.object({
    email: Yup.string()
      .email(translate(ValidationErrorKeys.InvalidEmailFormat))
      .required(translate(ValidationErrorKeys.Required)),
    first_name: Yup.string(),
    group: Yup.string(),
    last_name: Yup.string().required(translate(ValidationErrorKeys.Required)),
    phone_number: Yup.string(),
    store_id: Yup.string(),
    store_uuid: Yup.string(),
  });
