import { ValueOf } from '@appTypes';
import { PaymentTypes, SettlementTypes } from '@utils/constants';

interface IGetInitialValuesProps {
  contractUuid?: string;
  currency: string;
  max?: string;
  paymentType: ValueOf<typeof PaymentTypes>;
  settlementAmountValue?: string;
  settlementUuid?: string;
  uuid?: string;
}

export const getInitialValues = ({
  contractUuid,
  currency,
  max,
  paymentType,
  settlementAmountValue = '',
  settlementUuid = '',
  uuid,
}: IGetInitialValuesProps) => {
  const mappings = {
    [PaymentTypes.ADJUSTMENTS]: {
      contract_uuid: contractUuid,
      settlement_amount: settlementAmountValue,
      settlement_uuid: settlementUuid,
    },
    [PaymentTypes.REIMBURSEMENT]: {
      contract_uuid: contractUuid,
      date: '',
      settlement_reference: '',
      settlement_type: SettlementTypes.BANK_TRANSFER,
    },
    [PaymentTypes.MANUAL_PAYMENT]: {
      date: new Date().toISOString().split('T')[0],
      payment_reference: '',
      settlement_type: SettlementTypes.BANK_TRANSFER,
    },
    [PaymentTypes.PREPAYMENT]: { payment_plan_reference: '' },
    [PaymentTypes.REFUND]: { max, payment_plan_reference: '' },
  };

  return {
    amount: '',
    currency,
    external_uuid: uuid,
    ...mappings[paymentType],
  };
};
