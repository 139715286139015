import { useCallback, forwardRef } from 'react';

export const DropdownTopbarItemToggler = forwardRef(
  ({ children, onClick }, ref) => {
    const handler = useCallback(
      e => {
        e.preventDefault();
        onClick(e);
      },
      [onClick],
    );

    return (
      <button
        className="topbar-item btn btn-clear px-0"
        onClick={handler}
        ref={ref}
      >
        {children}
      </button>
    );
  },
);

DropdownTopbarItemToggler.displayName = 'DropdownTopbarItemToggler';
