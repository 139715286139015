import { useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { AxiosError } from 'axios';
import { isValid, format, isFuture } from 'date-fns';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { Modal } from '@hooks/useModal/types';
import { usePortalError } from '@hooks/usePortalError';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { ApplicantOverrideForm } from './components/ApplicantOverrideForm';
import {
  IUseModifyApplicantOverrideModal,
  IFormData,
  IRequestData,
} from './types';

export const useModifyApplicantOverrideModal = (
  props: IUseModifyApplicantOverrideModal,
): Modal => {
  const { applicantUuid } = props;
  const { translate } = useI18n();
  const { message } = useToast();
  const { handleAxiosError } = usePortalError();
  const queryClient = useQueryClient();
  const formID = 'modifyApplicantOverrideForm';

  const modal = useModal({
    sharedProps: props,
    modalName: 'createApplicantRiskOverrideModal',
    permissions: ['ops_portal.can_create_risk_override'],
    buttonTranslationKey: 'OPS_PORTAL.TITLE.CREATE_APPLICANT_RISK_OVERRIDE',
  });

  const { isLoading, mutate } = useMutation<
    void,
    AxiosError<IError>,
    IFormData
  >(
    useCallback(
      (data: IFormData) => {
        const requestData: IRequestData = {
          applicant_uuid: applicantUuid,
          expiry_time: data.expiryTime
            ? format(data.expiryTime, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
            : null,
          override_lists: data.overrideLists,
        };
        return postDataToEndpoint({
          endpoint: Endpoints.ModifyApplicantOverride,
          requestData,
        });
      },
      [applicantUuid],
    ),
    {
      onSuccess: useCallback(() => {
        message.success(
          translate('OPS_PORTAL.SUCCESSFULLY_CREATED_APPLICANT_RISK_OVERRIDE'),
        );
        queryClient.invalidateQueries([
          QueryKeys.APPLICANT_RISK_OVERRIDE,
          applicantUuid,
        ]);
        modal.closeModal();
      }, [message, translate, queryClient, applicantUuid, modal]),
      onError: useCallback(
        error =>
          handleAxiosError(
            error,
            'OPS_PORTAL.ERROR_CREATING_APPLICANT_RISK_OVERRIDE',
          ),
        [handleAxiosError],
      ),
    },
  );

  const onSubmit: SubmitHandler<IFormData> = useCallback(
    data => {
      if (
        data.expiryTime &&
        isValid(data.expiryTime) &&
        isFuture(data.expiryTime)
      ) {
        mutate(data);
      } else {
        message.error(translate('OPS_PORTAL.INVALID_FUTURE_DATE_TIME'));
      }
    },
    [mutate, message, translate],
  );

  const modalContent = useCallback(
    () => <ApplicantOverrideForm formID={formID} onSubmit={onSubmit} />,
    [onSubmit],
  );

  const modalProps = {
    allowOverflow: true,
    modalContent: modalContent(),
    submitText: translate('BUTTON.SUBMIT'),
    title: translate('OPS_PORTAL.TITLE.CREATE_APPLICANT_RISK_OVERRIDE'),
    formID,
    isLoading,
  };

  return getModalDetails({ modal, modalProps });
};
