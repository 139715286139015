import { IQueryState } from './types';

export const getLimitAndOffset = (queryState: IQueryState) => {
  const { pageIndex, pageSize } = queryState.pagination;

  return {
    limit: pageSize,
    offset: pageIndex * pageSize,
  };
};
