import { useParams } from 'react-router-dom';
import { useCollectionAgencies } from '@hooks/useCollectionAgencies';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IOpsPortalBlockExternalCollectionsReferral } from '@schemas/opsPortal/types/blockExternalCollectionsReferral';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { IOpsPortalContractRiskAndWaterfall } from '@schemas/opsPortal/types/contractRiskAndWaterfall';
import { IOpsPortalPauseCommunications } from '@schemas/opsPortal/types/pauseCommunications';
import { TabEventKeys } from '@utils/constants';
import { getDataList } from '@utils/getDataList';
import { useGetPaymentLinkQuery, useGetRiskAndWaterfall } from '../queries';
import { useGetContractRiskApplicationQuery } from '../queries/useGetContractRiskApplicationQuery';
import { getContractOverviewFieldNames } from './getContractOverviewFieldNames';
import { mapContractOverviewData } from './mapContractOverviewData';

export interface IUseContractOverviewTab {
  collectionsBlockAutoReferralData?: IOpsPortalBlockExternalCollectionsReferral;
  contractData: IContractDetails;
  contractUuid?: string;
  isArrearsToBeManagedByHeidi: boolean;
  isCompass: boolean;
  isInternalOpsUser: boolean;
  pauseCommunicationsData?: IOpsPortalPauseCommunications;
  riskAndWaterfallData?: IOpsPortalContractRiskAndWaterfall;
}

export const useContractOverviewTab = ({
  collectionsBlockAutoReferralData,
  contractData,
  contractUuid,
  isArrearsToBeManagedByHeidi,
  isCompass,
  isInternalOpsUser,
  pauseCommunicationsData,
}: IUseContractOverviewTab) => {
  const { uuid: externalContractUuid } = useParams();
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const { canUsePaymentLink, paymentLinkData, paymentLinkDataLoading } =
    useGetPaymentLinkQuery({
      contractUuid,
    });

  const { riskAndWaterfallData } = useGetRiskAndWaterfall({
    externalContractUuid,
  });

  const { riskApplicationData } = useGetContractRiskApplicationQuery({
    contractUuid,
  });

  const contractOverviewFieldNames = getContractOverviewFieldNames({
    collectionsBlockAutoReferralData,
    isArrearsToBeManagedByHeidi,
    isCompass,
    pauseCommunicationsData,
    translate,
  });

  const { collectionAgencies } = useCollectionAgencies();

  const contractOverviewData = mapContractOverviewData({
    canUsePaymentLink: canUsePaymentLink && !paymentLinkDataLoading,
    collectionAgencies,
    data: contractData,
    getLocaleCurrencyValue,
    getLocaleDate,
    isCompass,
    isInternalOpsUser,
    translate,
    collectionsBlockAutoReferralData,
    isArrearsToBeManagedByHeidi,
    pauseCommunicationsData,
    paymentLinkData,
    riskAndWaterfallData,
    riskApplicationData,
  });

  return {
    eventKey: TabEventKeys.CONTRACT_OVERVIEW,
    title: translate('OPS_PORTAL.TITLE.OVERVIEW'),
    dataList: getDataList({
      data: contractOverviewData,
      labels: contractOverviewFieldNames,
    }),
  };
};
