import { useCallback, useState, ReactNode } from 'react';
import { SortingButton } from '@components/Table/Table.styles';

type SortOrder = 'ascending' | 'descending';

interface ISortButton {
  children: ReactNode;
  sort?: SortOrder;
  toggleSort: () => void;
}

export const SortButton = ({ children, sort, toggleSort }: ISortButton) => {
  const [isHighlighted, setIsHighlighted] = useState(false);

  const highlight = useCallback(() => {
    setIsHighlighted(true);
  }, []);

  const unHighlight = useCallback(() => {
    setIsHighlighted(false);
  }, []);

  const sortIcon: Record<SortOrder, string> = {
    ascending: '▲',
    descending: '▼',
  };

  return (
    <SortingButton
      onBlur={unHighlight}
      onClick={toggleSort}
      onFocus={highlight}
      onMouseOut={unHighlight}
      onMouseOver={highlight}
      variant={isHighlighted ? ['primary', 'sm'] : ['transparent', 'sm']}
    >
      {children}
      <span aria-hidden="true">
        {isHighlighted && !sort ? sortIcon.ascending : sort && sortIcon[sort]}
      </span>
    </SortingButton>
  );
};
