import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { ModalSize } from './enums';
import { IModalContainerProps } from './types';

export const ModalBody = styled(Modal.Body)`
  @media (min-width: 576px) {
    overflow-y: ${props => (props.$allowoverflow ? 'visible' : 'auto')};
  }
`;

const getWidthStyle = (width: ModalSize) => {
  const widths = {
    [ModalSize.Sm]: '600px',
    [ModalSize.Md]: '900px',
    [ModalSize.Lg]: '1200px',
  };
  return `
    .modal-dialog {
      max-width: ${widths[width]};
    }
  `;
};

export const ModalContainer = styled(Modal)<IModalContainerProps>`
  @media (min-width: 576px) {
    & .modal-content {
      max-height: calc(100vh - 3.5rem);
    }
  }

  ${props =>
    props.fullscreen === 'true' &&
    `
    padding-left: 0;

    .modal-dialog {
      width: 100vw;
      max-width: 100vw;
      height: 100vh;
      margin: 0;
      padding: 0;
    }

    .modal-content {
      height: auto;
      width: 100%;
      min-height: 100%;
      border-radius: 0;

      .modal-body {
        padding: 0
      }

      iframe {
        height: 85vh;
      }
    }
  `};

  ${props => props.width && getWidthStyle(props.width)};

  ${props =>
    props.extratall === 'true' &&
    `
    .modal-dialog {
      height: 100vh;
    }

    .modal-content {
      height: 100%;
    }
  `};
<IModalContainer>`;

export const ModalFooter = styled(Modal.Footer)``;

export const ModalHeader = styled(Modal.Header)``;

export const ModalTitle = styled(Modal.Title)``;
