import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { usePortalError } from '@hooks/usePortalError';
import { Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';

interface IUseConfirmContractQuery {
  contractDataQueryKey?: QueryKey;
  contractUuid?: string;
}

export const useConfirmContractQuery = ({
  contractDataQueryKey,
  contractUuid,
}: IUseConfirmContractQuery) => {
  const { translate } = useI18n();
  const { message } = useToast(translate);
  const { handleAxiosError } = usePortalError();
  const queryClient = useQueryClient();

  const { isLoading: isConfirmingContract, mutate: confirmContract } =
    useMutation<unknown, AxiosError<IError>>(
      () => {
        return postDataToEndpoint({
          endpoint: Endpoints.ConfirmCompassContract,
          requestData: { contract_uuid: contractUuid, is_external_uuid: true },
        });
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([contractDataQueryKey]);
          message.success(
            'OPS_PORTAL.STATUS.COMPASS_CONTRACT_DISPATCH_CONFIRMED',
          );
        },
        onError: error => {
          handleAxiosError(
            error,
            'OPS_PORTAL.STATUS.UNABLE_TO_CONFIRM_COMPASS_CONTRACT_DISPATCH',
          );
        },
      },
    );

  return {
    isConfirmingContract,
    confirmContract,
  };
};
