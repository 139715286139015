import { KTUtil } from './util';

// Component Definition
const KTScrolltop = function ktScrolltop(elementId, options) {
  // Main object
  let the = this;

  // Get element object
  const element = KTUtil.getById(elementId);
  const body = KTUtil.getBody();

  if (!element) {
    return;
  }

  // Default options
  let defaultOptions = {
    offset: 300,
    speed: 6000,
  };

  /// /////////////////////////
  // ** Private Methods  ** //
  /// /////////////////////////

  const Plugin = {
    /**
     * Run plugin
     * @returns {mscrolltop}
     */
    construct(opts) {
      if (KTUtil.data(element).has('scrolltop')) {
        the = KTUtil.data(element).get('scrolltop');
      } else {
        // reset scrolltop
        Plugin.init(opts);

        // build scrolltop
        Plugin.build();

        KTUtil.data(element).set('scrolltop', the);
      }

      return the;
    },

    /**
     * Handles subscrolltop click toggle
     * @returns {mscrolltop}
     */
    init(opts) {
      the.events = [];

      // merge default and user defined options
      the.options = KTUtil.deepExtend({}, defaultOptions, opts);
    },

    build() {
      let timer;

      window.addEventListener('scroll', () => {
        KTUtil.throttle(
          timer,
          () => {
            Plugin.handle();
          },
          200,
        );
      });

      // handle button click
      KTUtil.addEvent(element, 'click', Plugin.scroll);
    },

    /**
     * Handles scrolltop click scrollTop
     */
    handle() {
      const pos = KTUtil.getScrollTop(); // current vertical position

      if (pos > the.options.offset) {
        if (body.hasAttribute('data-scrolltop') === false) {
          body.setAttribute('data-scrolltop', 'on');
        }
      } else if (body.hasAttribute('data-scrolltop') === true) {
        body.removeAttribute('data-scrolltop');
      }
    },

    /**
     * Handles scrolltop click scrollTop
     */
    scroll(e) {
      e.preventDefault();

      KTUtil.scrollTop(0, the.options.speed);
    },

    eventTrigger(name, args) {
      // eslint-disable-next-line consistent-return
      the.events.forEach((event, i) => {
        if (event.name === name) {
          if (event.one) {
            if (!event.fired) {
              the.events[i].fired = true;
              return event.handler.call(this, the, args);
            }
          } else {
            return event.handler.call(this, the, args);
          }
        }
      });
    },

    addEvent(name, handler, one) {
      the.events.push({
        name,
        handler,
        one,
        fired: false,
      });
    },
  };

  /// ///////////////////////
  // ** Public Methods ** //
  /// ///////////////////////

  /**
   * Set default options
   */

  the.setDefaults = function setDefaults(opts) {
    defaultOptions = opts;
  };

  /**
   * Get subscrolltop mode
   */
  the.on = (name, handler) => Plugin.addEvent(name, handler);

  /**
   * Set scrolltop content
   * @returns {mscrolltop}
   */
  the.one = (name, handler) => Plugin.addEvent(name, handler, true);

  /// ////////////////////////////
  // ** Plugin Construction ** //
  /// ////////////////////////////

  // Run plugin
  Plugin.construct.apply(the, [options]);
};

export default KTScrolltop;
