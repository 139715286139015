import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Translate } from '@hooks/useI18n/types';
import {
  IActionLogsActionName,
  IActionLogsActionSource,
  IActionLogsLogType,
  IDateTimeNotNull,
  IActionLogsPhase,
  IActionLogsPhasePriority,
  IActionLogsProgressStep,
} from '@schemas/opsPortal/types/actionLogs';
import { SortingFunctions } from '@utils/constants';

export const ActionLogsColumnAccessor = Object.freeze({
  ACTION_NAME: 'actionName',
  ACTION_SOURCE: 'actionSource',
  CREATED_DATE: 'createdDate',
  LOG_TYPE: 'logType',
  PHASE: 'phase',
  PHASE_PRIORITY: 'phasePriority',
  PROGRESS_STEP: 'progressStep',
});

export interface IActionLogsTable {
  [ActionLogsColumnAccessor.ACTION_NAME]: IActionLogsActionName;
  [ActionLogsColumnAccessor.ACTION_SOURCE]: IActionLogsActionSource;
  [ActionLogsColumnAccessor.CREATED_DATE]: IDateTimeNotNull;
  [ActionLogsColumnAccessor.LOG_TYPE]: IActionLogsLogType;
  [ActionLogsColumnAccessor.PHASE]: IActionLogsPhase;
  [ActionLogsColumnAccessor.PHASE_PRIORITY]: IActionLogsPhasePriority;
  [ActionLogsColumnAccessor.PROGRESS_STEP]: IActionLogsProgressStep;
}

export const getActionLogsColumns = (translate: Translate) => {
  const columnHelper = createColumnHelper<IActionLogsTable>();

  return [
    columnHelper.accessor(ActionLogsColumnAccessor.LOG_TYPE, {
      header: translate('OPS_PORTAL.LABEL.LOG_TYPE'),
    }),
    columnHelper.accessor(ActionLogsColumnAccessor.ACTION_NAME, {
      header: translate('OPS_PORTAL.LABEL.ACTION_NAME'),
    }),
    columnHelper.accessor(ActionLogsColumnAccessor.PHASE, {
      header: translate('OPS_PORTAL.LABEL.PHASE'),
    }),
    columnHelper.accessor(ActionLogsColumnAccessor.PHASE_PRIORITY, {
      header: translate('OPS_PORTAL.LABEL.PHASE_PRIORITY'),
    }),
    columnHelper.accessor(ActionLogsColumnAccessor.PROGRESS_STEP, {
      header: translate('OPS_PORTAL.LABEL.PROGRESS_STEP'),
    }),
    columnHelper.accessor(ActionLogsColumnAccessor.ACTION_SOURCE, {
      header: translate('OPS_PORTAL.LABEL.ACTION_SOURCE'),
    }),
    columnHelper.accessor(ActionLogsColumnAccessor.CREATED_DATE, {
      header: translate('OPS_PORTAL.TITLE.CREATED_DATE'),
      sortingFn: SortingFunctions.DATETIME,
    }),
  ] as ColumnDef<IActionLogsTable, string>[];
};
