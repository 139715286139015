import { Dd, Dt } from '@components/Common.styles';
import { Translate } from '@hooks/useI18n';
import { IManualAgreementSigningOverview } from '@schemas/merchantPortal/types/tracking';
import { CompeteIcon } from './CompeteIcon';

interface IManualSigningSummary {
  manualAgreementSigningOverview: IManualAgreementSigningOverview;
  translate: Translate;
}

export const ManualSigningSummary = ({
  manualAgreementSigningOverview,
  translate,
}: IManualSigningSummary) => {
  const {
    has_downloaded_agreement: hasDownloadedAgreement,
    has_uploaded_signed_agreement: hasUploadedSignedAgreement,
    is_manual_signing_required: isManualSigningRequired,
  } = manualAgreementSigningOverview;

  return isManualSigningRequired ? (
    <>
      <Dt>{translate('PAGE.TRACKING.HAS_DOWNLOADED_AGREEMENT')}</Dt>
      <Dd>
        <CompeteIcon complete={hasDownloadedAgreement} translate={translate} />
      </Dd>
      <Dt>{translate('PAGE.TRACKING.HAS_UPLOADED_SIGNED_AGREEMENT')}</Dt>
      <Dd>
        <CompeteIcon
          complete={hasUploadedSignedAgreement}
          translate={translate}
        />
      </Dd>
    </>
  ) : null;
};
