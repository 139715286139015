import { IGetPagesToDisplayProps, IPageToDisplay } from '../types';

export const getPagesToDisplay = ({
  currentPageIndex,
  pages,
}: IGetPagesToDisplayProps): IPageToDisplay[] => {
  const numberOfPages = pages.length;

  const paginationObjects = pages.map(page => ({
    id: Math.random(),
    idx: page,
    label: (page + 1).toString(),
  }));

  if (numberOfPages <= 7) {
    return paginationObjects;
  }

  const firstPage = paginationObjects.slice(0, 1)[0];
  const lastPage = paginationObjects.slice(-1)[0];

  const currentPageIndexNumber = Number(currentPageIndex);

  const firstFivePages = paginationObjects.slice(0, 5);
  const middleThreePages = paginationObjects.slice(
    currentPageIndexNumber - 1,
    currentPageIndexNumber + 2,
  );
  const lastFivePages = paginationObjects.slice(-5);

  if (currentPageIndexNumber <= 3) {
    return [...firstFivePages, { id: Math.random(), label: '...' }, lastPage];
  }

  if (currentPageIndexNumber >= lastPage.idx - 3) {
    return [firstPage, { id: Math.random(), label: '...' }, ...lastFivePages];
  }

  return [
    firstPage,
    { id: Math.random(), label: '...' },
    ...middleThreePages,
    { id: Math.random(), label: '...' },
    lastPage,
  ];
};
