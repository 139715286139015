import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FormGroup, Label } from '@components/Common.styles';
import { Tooltip } from '@components/Tooltip';
import { ITooltipContent } from '@components/types.d';
import { ValidationError } from '@components/ValidationError';

export interface IRadioGroupOptions {
  disabled?: boolean;
  id: number;
  name: string;
}

interface IRadioGroupProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  defaultValue?: number;
  handleChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  label: string;
  name: string;
  options: IRadioGroupOptions[];
  tooltip?: ITooltipContent;
}

export const RadioGroupRHF = ({
  defaultValue,
  label,
  name,
  options,
  tooltip,
}: IRadioGroupProps) => {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  const error = errors[name]?.message;
  const updatedDefaultValue =
    defaultValue ?? (options.length ? options[0].id : '');

  return (
    <FormGroup>
      <Label htmlFor={name}>{label}</Label>
      {tooltip ? <Tooltip tip={tooltip} /> : null}
      <RadioGroup
        aria-labelledby={`${name}-radio-group`}
        defaultValue={updatedDefaultValue}
        id={name}
        {...register(name)}
      >
        {options.map(
          ({ disabled = false, id, name: optionLabel }: IRadioGroupOptions) => (
            <FormControlLabel
              control={<Radio {...register(name)} disabled={disabled} />}
              disabled={disabled}
              key={id}
              label={optionLabel}
              value={id}
            />
          ),
        )}
      </RadioGroup>
      {error ? <ValidationError error={error} /> : null}
    </FormGroup>
  );
};
