interface IObfuscateTextProps {
  showLastX?: number;
  showText?: boolean;
  text: string;
}

export const obfuscateText = ({
  showLastX = 0,
  showText = false,
  text,
}: IObfuscateTextProps) => {
  if (showText) {
    return text;
  }

  const index = text.length - showLastX;

  return '•'.repeat(index) + text.substring(index);
};
