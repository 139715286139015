import styled from 'styled-components';

interface IViewerProps {
  isEmail: boolean;
}

export const Viewer = styled.div<IViewerProps>`
  flex: 1;
  margin-inline-start: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f3f6f9;
  border-radius: 6px;
  min-height: ${props => (props.isEmail ? '45rem' : '0')};
  max-width: 50%;
  @media (max-width: 991.98px) {
    max-width: none;
  }
`;

export const ViewerSelector = styled.div`
  max-width: 60%;
  @media (max-width: 991.98px) {
    max-width: none;
  }
`;

export const ViewerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
`;
