import { Card } from '@components/Card';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ShoppingCartTable } from './ShoppingCartTable';
import { IShoppingCartDetailsProps } from './types';

export const ShoppingCartDetails = ({
  displayTitle = true,
  orderDetails,
}: IShoppingCartDetailsProps) => {
  const { translate } = useI18n();

  return (
    <Card
      hasHiddenTitle={!displayTitle}
      title={translate('TITLE.SHOPPING_CART_DETAILS')}
    >
      <ShoppingCartTable orderDetails={orderDetails} />
    </Card>
  );
};
