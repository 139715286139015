import KTMenu from '@metronic/_assets/js/components/menu';
import KTOffcanvas from '@metronic/_assets/js/components/offcanvas';
import { KTUtil } from '@metronic/_assets/js/components/util';

const KTLayoutHeaderMenu = (function ktLayoutHeaderMenu() {
  // Private properties
  let menuElement;
  let menuObject;
  let offcanvasElement;
  let offcanvasObject;

  // Private functions
  const init = function init() {
    offcanvasObject = new KTOffcanvas(offcanvasElement, {
      overlay: true,
      baseClass: 'header-menu-wrapper',
      closeBy: 'kt_header_menu_mobile_close_btn',
      toggleBy: {
        target: 'kt_header_mobile_toggle',
        state: 'mobile-toggle-active',
      },
    });

    menuObject = new KTMenu(menuElement, {
      submenu: {
        desktop: 'dropdown',
        tablet: 'accordion',
        mobile: 'accordion',
      },
      accordion: {
        slideSpeed: 200, // accordion toggle slide speed in milliseconds
        expandAll: false, // allow having multiple expanded accordions in the menu
      },
    });

    // Close aside offcanvas panel before page reload On tablet and mobile
    menuObject.on('linkClick', () => {
      if (KTUtil.isBreakpointDown('lg')) {
        // Tablet and mobile mode
        offcanvasObject.hide(); // Hide offcanvas after general link click
      }
    });
  };

  // Public methods
  return {
    init(menuId, offcanvasId) {
      menuElement = KTUtil.getById(menuId);
      offcanvasElement = KTUtil.getById(offcanvasId);

      if (!menuElement) {
        return;
      }

      // Initialize menu
      init();
    },

    getMenuElement() {
      return menuElement;
    },

    getOffcanvasElement() {
      return offcanvasElement;
    },

    getMenu() {
      return menuObject;
    },

    pauseDropdownHover(time) {
      if (menuObject) {
        menuObject.pauseDropdownHover(time);
      }
    },

    getOffcanvas() {
      return offcanvasObject;
    },

    closeMobileOffcanvas() {
      if (menuObject && KTUtil.isMobileDevice()) {
        offcanvasObject.hide();
      }
    },
  };
})();

export default KTLayoutHeaderMenu;
