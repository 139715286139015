import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { Modal } from '@hooks/useModal/types';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { getErrorMessage } from '@utils/getErrorMessage';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import {
  IArrearsManagedByRequest,
  IUpdateArrearsManagedByModal,
} from './types';
import { UpdateArrearsManagedByForm } from './UpdateArrearsManagedByForm';

export const useUpdateArrearsManagedByModal = (
  props: IUpdateArrearsManagedByModal,
): Modal => {
  const {
    arrearsManagedBy,
    collectionAgencies,
    contractDataQueryKey,
    externalContractUUID,
  } = props;
  const { translate } = useI18n();

  const modal = useModal({
    sharedProps: props,
    buttonTranslationKey: 'OPS_PORTAL.LABEL.UPDATE_ARREARS_MANAGED_BY',
    modalName: 'updateArrearsManagedByModal',
    permissions: ['ops_portal.can_update_contract_arrears_managed_by'],
  });
  const { formStatusMessage, resolveForm } = modal;

  const queryClient = useQueryClient();

  const collectionsQueryKey = [
    QueryKeys.COLLECTIONS_BLOCK_AUTO_REFERRAL,
    externalContractUUID,
  ];

  const {
    isLoading: isUpdatingArrearsManagedBy,
    mutate: updateArrearsManagedBy,
  } = useMutation<unknown, AxiosError, IArrearsManagedByRequest>(
    requestData => {
      return postDataToEndpoint({
        endpoint: `${Endpoints.UpdateArrearsManagedBy}/${externalContractUUID}`,
        requestData,
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(contractDataQueryKey);
        await queryClient.invalidateQueries(collectionsQueryKey);
        resolveForm(
          translate('OPS_PORTAL.STATUS.UPDATE_ARREARS_MANAGED_BY_SUCCESSFUL'),
        );
      },
      onError: error => {
        resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey:
              'OPS_PORTAL.STATUS.UNABLE_TO_UPDATE_ARREARS_MANAGED_BY',
            translate,
          }),
        );
      },
    },
  );

  const handleSubmit = useCallback(
    data => updateArrearsManagedBy(data),
    [updateArrearsManagedBy],
  );

  const formID = 'updateArrearsManagedBy';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isUpdatingArrearsManagedBy}
    >
      <UpdateArrearsManagedByForm
        arrearsManagedBy={arrearsManagedBy}
        collectionAgencies={collectionAgencies}
        formID={formID}
        handleSubmit={handleSubmit}
      />
    </ModalStatusRouter>
  );

  const modalProps = {
    allowoverflow: true,
    formID,
    isDangerousSubmit: true,
    isLoading: isUpdatingArrearsManagedBy,
    modalContent,
    title: translate('OPS_PORTAL.LABEL.UPDATE_ARREARS_MANAGED_BY'),
  };

  return getModalDetails({ modal, modalProps });
};
