export enum AllocationType {
  FailedMerchantTransaction = 'FAILED_MERCHANT_TRANSACTION',
  ManualPayment = 'MANUAL_PAYMENT',
  ManualRefund = 'MANUAL_REFUND',
  MerchantPayment = 'MERCHANT_PAYMENT',
  MerchantSettlement = 'MERCHANT_SETTLEMENT',
  StripePayout = 'STRIPE_PAYOUT',
  Suspense = 'SUSPENSE',
  TransferCompass = 'TRANSFER_COMPASS',
  TransferCurrent = 'TRANSFER_CURRENT',
  TransferMagicpass = 'TRANSFER_MAGICPASS',
  TransferNcp = 'TRANSFER_NCP',
  Unallocated = 'UNALLOCATED',
}

export enum ApprovalStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export enum RecordedStatus {
  Complete = 'COMPLETE',
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Skipped = 'SKIPPED',
}
