import { Translate } from '@hooks/useI18n';
import { IId, IIndustryName } from '@schemas/merchantPortal/types/industries';
import { IMerchantPortalOrgSettings } from '@schemas/merchantPortal/types/orgSettings';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { dictionary } from '@utils/dictionary';
import { getDisplayKeys } from '@utils/getDisplayKeys';

interface IIndustryChoices {
  id: IId;
  name: IIndustryName;
}

interface IGetDisplayDataListProps {
  config: Record<string, unknown>;
  industryChoices: IIndustryChoices[];
  organisationData: IMerchantPortalOrgSettings;
  translate: Translate;
}

export const getDisplayDataList = ({
  config,
  industryChoices,
  organisationData,
  translate,
}: IGetDisplayDataListProps) => {
  // Set keys in a specific order, which is why this doesn't spread organisationData.
  const items = {
    legal_name: organisationData.legal_name,
    short_name: organisationData.short_name,
    ...(config.showOrgSMS
      ? { sms_sender_id: organisationData.sms_sender_id }
      : {}),
    billing_address: organisationData.billing_address,
    vat_number: organisationData.vat_number,
    ...(config.showOrgIndustry
      ? {
          industry: industryChoices.find(
            choice => choice.id === organisationData.merchant_industry,
          )?.name,
        }
      : {}),
    ...(config.showOrgPrimaryColour
      ? { primary_colour: organisationData.primary_colour }
      : {}),
  };

  const keys = getDisplayKeys({ data: items, category: 'organisation' });
  const { organisation } = dictionary;

  return keys.map(key =>
    addPlaceholderForEmptyValues({
      key: translate(organisation[key as keyof typeof organisation]),
      value: items[key as keyof typeof items],
    }),
  );
};
