import { useI18n } from '@hooks/useI18n/useI18n';

export const AdjustContractValueWarning = () => {
  const { translate } = useI18n();

  return (
    <p className="text-danger text-center">
      <strong>{translate('GENERAL.ARE_YOU_SURE')}</strong>
    </p>
  );
};
