import { ValueOf } from '@appTypes';
import { Translate } from '@hooks/useI18n';
import {
  FilterTypes,
  SupportedLanguages,
  supportedLanguages,
} from '@utils/constants';

export interface IGetLanguageDropdownParams {
  queryParams?: {
    language?: ValueOf<typeof SupportedLanguages>;
  };
  translate: Translate;
}

export const getLanguageDropdown = ({
  queryParams,
  translate,
}: IGetLanguageDropdownParams) => {
  const languageOptions = [
    { id: '', name: '—' },
    ...supportedLanguages.map(languageCode => ({
      id: languageCode,
      name: translate(`OPS_PORTAL.LABEL.${languageCode.toUpperCase()}`),
    })),
  ];

  return {
    accessorKey: 'language',
    id: 'language',
    label: translate('LABEL.LANGUAGE'),
    options: languageOptions,
    type: FilterTypes.DROPDOWN,
    value: queryParams?.language,
  };
};
