export const getModalKeys = (config: Record<string, unknown>) => {
  const { canChangePassword, canEditAddress, canEditName, canEditPhone } =
    config;

  return [
    ...(canEditAddress ? ['editAddressModal'] : []),
    ...(canEditPhone ? ['editPhoneModal'] : []),
    ...(canEditName ? ['editNameModal'] : []),
    ...(canChangePassword ? ['passwordModal'] : []),
  ];
};
