import { useMemo } from 'react';
import objectPath from 'object-path';
import { useHtmlClassService } from '@metronic/layout/_core/MetronicLayout';
import { Topbar } from './Topbar';

export function Header() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(
    () => ({
      headerClasses: uiService.getClasses('header', true),
      headerAttributes: uiService.getAttributes('header'),
      headerContainerClasses: uiService.getClasses('header_container', true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        'header.menu.self.display',
      ),
    }),
    [uiService],
  );

  return (
    <div
      className={`header ${layoutProps.headerClasses}`}
      id="kt_header"
      {...layoutProps.headerAttributes}
    >
      <div
        className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-end`}
      >
        <Topbar />
      </div>
    </div>
  );
}
