import { IProgressDataItem } from '@schemas/merchantPortal/types/tracking';
import { ProgressSVG } from './ProgressSVG';
import { StepItem } from './Tracking.styles';

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

interface IStep {
  data: IProgressDataItem;
}

export const Step = ({
  data: { step_display: stepDisplay, step_state: stepState },
}: IStep) => {
  return (
    <StepItem
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <ProgressSVG stepState={stepState} />
      <span className="menu-text">{stepDisplay}</span>
    </StepItem>
  );
};
