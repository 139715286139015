import { Days } from '@appTypes';
import { IDateOption } from './types';

interface IGetDefaultRequestPaymentDay {
  options: IDateOption[];
  paymentDate: Days;
}

export const getDefaultRequestPaymentDay = ({
  options,
  paymentDate,
}: IGetDefaultRequestPaymentDay) => {
  const paymentDateOptionIndex = options.findIndex(
    (option: IDateOption) => option.id === paymentDate,
  );
  return paymentDateOptionIndex === 0 && options.length > 1
    ? options[1].id
    : options[0]?.id;
};
