import { createColumnHelper } from '@tanstack/react-table';
import { Link } from '@components/Common.styles';
import { Translate } from '@hooks/useI18n';
import { IOverpaidContractTableColumns } from './types';

export const getOverpaidContractsTableColumns = (translate: Translate) => {
  const columnHelper = createColumnHelper<IOverpaidContractTableColumns>();

  return [
    columnHelper.accessor('contractUuid', {
      header: translate('OPS_PORTAL.LABEL.CONTRACT_UUID'),
      cell: ({ row }) => (
        <Link to={`/overpaid_contracts/${row.original.contractUuid}`}>
          {row.original.contractUuid}
        </Link>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor('date', {
      header: translate('TITLE.DATE'),
    }),
    columnHelper.accessor('outstandingAmount', {
      header: translate('OPS_PORTAL.LABEL.OUTSTANDING'),
    }),
    columnHelper.accessor('amount', {
      header: translate('LABEL.AMOUNT'),
    }),
    columnHelper.accessor('payor', {
      header: translate('PAGE.SETTLEMENTS.PAYOR'),
    }),
  ];
};
