import { useI18n } from '@hooks/useI18n/useI18n';
import { PaginationStepButton } from './Pagination.styled';
import { ICursorPaginationButtonsProps } from './types';

export const CursorPaginationButtons = ({
  paginationHandlers,
}: ICursorPaginationButtonsProps) => {
  const { translate } = useI18n();
  const { gotoNextPage, gotoPrevPage } = paginationHandlers;

  return (
    <div>
      <PaginationStepButton
        disabled={!gotoPrevPage}
        onClick={gotoPrevPage ?? (() => {})}
      >
        {translate('BUTTON.PREVIOUS')}
      </PaginationStepButton>
      <PaginationStepButton
        disabled={!gotoNextPage}
        onClick={gotoNextPage ?? (() => {})}
      >
        {translate('BUTTON.NEXT')}
      </PaginationStepButton>
    </div>
  );
};
