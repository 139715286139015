import { AccessController } from '@components/AccessController';
import { useI18n } from '@hooks/useI18n/useI18n';
import { getCombinedScopes } from '@utils/getCombinedScopes';
import { Heading, List, StyledSection } from './Menu.styled';
import { MenuItem } from './MenuItem';
import { IMenuSectionProps } from './types';

export const MenuSection = ({
  currentPathname,
  heading = '',
  items,
}: IMenuSectionProps) => {
  const { translate } = useI18n();

  return (
    <AccessController scopes={getCombinedScopes(items)}>
      <StyledSection>
        {heading ? <Heading>{translate(heading)}</Heading> : null}
        <List>
          {items.map(item => (
            <MenuItem
              currentPathname={currentPathname}
              item={item}
              key={`${heading}-item-${item.translationKey}`}
            />
          ))}
        </List>
      </StyledSection>
    </AccessController>
  );
};
