import { useCallback, useEffect, useMemo, useRef } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Row } from '@tanstack/react-table';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ServersideTable } from '@components/Table/ServersideTable';
import { useI18n } from '@hooks/useI18n/useI18n';
import { usePortalError } from '@hooks/usePortalError';
import { useQueryState } from '@hooks/useQueryState';
import { IOpsPortalCertificatesOfInterest } from '@schemas/opsPortal/types/certificatesOfInterest';
import { IDate } from '@schemas/opsPortal/types/contractDetails';
import { QueryKeys, TabEventKeys, TableNames } from '@utils/constants';
import { downloadFile } from '@utils/downloadFile';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { getCursorPaginationCallbacks } from '@utils/getCursorPaginationCallbacks';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { getCertificateOfInterestColumns } from './utils/getCertificateOfInterestColumns';
import { mapCertificateOfInterestTableData } from './utils/mapCertificateOfInterestTableData';

interface IUseCertificatesOfInterestTab {
  externalContractUUID?: string;
}

export interface ICertificatesOfInterestTableData {
  accountingBalance: string;
  createdDate: IDate;
  debitInterest: string;
  pdfUrl: string;
  status: string;
  uuid: string;
}

export const useCertificatesOfInterestTab = ({
  externalContractUUID,
}: IUseCertificatesOfInterestTab) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();
  const { message } = useToast();
  const { handleAxiosError } = usePortalError();
  const queryClient = useQueryClient();

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(TableNames.CERTIFICATE_OF_INTEREST);

  const queryKey = [
    QueryKeys.LIST_CERTIFICATE_OF_INTEREST,
    externalContractUUID,
    stringifiedQueryParams,
  ];

  const refToDataTable = useRef(null);

  const { data, error, isLoading } = useQuery<
    IOpsPortalCertificatesOfInterest,
    AxiosError
  >(
    queryKey,
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.ListCertificateOfInterest}/${externalContractUUID}`,
        { query: { ...queryParams } },
      ),
    {
      enabled: Boolean(externalContractUUID),
    },
  );

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  const { gotoNextPage, gotoPrevPage } = getCursorPaginationCallbacks({
    nextCursor: data?.next_cursor,
    prevCursor: data?.previous_cursor,
    queryState,
    updateQueryParams,
  });

  const filters = getFilters({});

  const { mutate: sendCertificateOfInterestEmail } = useMutation<
    void,
    AxiosError<IError>,
    string
  >(
    uuid =>
      postDataToEndpoint({
        endpoint: `${Endpoints.SendCertificateOfInterest}/${uuid}`,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKey);
        message.success(translate('OPS_PORTAL.SUCCESSFULLY_SENT_EMAIL'));
      },
      onError: err => {
        handleAxiosError(err, 'OPS_PORTAL.ERROR_SENDING_EMAIL');
      },
    },
  );

  const handleDownload = useCallback(
    (row: Row<ICertificatesOfInterestTableData>) => () => {
      downloadFile(row.original.pdfUrl);
    },
    [],
  );

  const handleSendEmail = useCallback(
    (row: Row<ICertificatesOfInterestTableData>) => () => {
      sendCertificateOfInterestEmail(row.original.uuid);
    },
    [sendCertificateOfInterestEmail],
  );

  const certificatesOfInterestTableData = useMemo(
    () =>
      mapCertificateOfInterestTableData({
        data,
        getLocaleDate,
        getLocaleCurrencyValue,
      }),
    [data, getLocaleDate, getLocaleCurrencyValue],
  );

  const certificateOfInterestColumns = useMemo(
    () =>
      getCertificateOfInterestColumns({
        translate,
        handleDownload,
        handleSendEmail,
      }),
    [handleDownload, handleSendEmail, translate],
  );

  const component = (
    <QueryStateRouter error={error} isLoading={isLoading}>
      <ServersideTable
        columns={certificateOfInterestColumns}
        data={certificatesOfInterestTableData}
        filters={filters}
        isCursorPagination={true}
        manageControlledState={updateQueryParams}
        pagination={{
          gotoNextPage,
          gotoPrevPage,
          pageSize: queryParams.pageSize,
          totalItems: data?.count,
        }}
        ref={refToDataTable}
        resetFilters={resetFilters}
        tableState={queryState}
      />
    </QueryStateRouter>
  );

  return {
    eventKey: TabEventKeys.CERTIFICATE_OF_INTEREST,
    error,
    isLoading,
    tableName: TableNames.CERTIFICATE_OF_INTEREST,
    title: translate('OPS_PORTAL.TITLE.CERTIFICATE_OF_INTEREST'),
    component,
    queryKey,
  };
};
