import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { IBankAccountReconciliation } from '@schemas/opsPortal/types/bankAccountReconciliations';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { IBankAccountReconciliationTableColumns } from './types';

interface IMapOverpaidContractTableData {
  data?: IBankAccountReconciliation[];
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

const translationPrefix = 'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS';

export const mapBankAccountReconciliationsTableData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  translate,
}: IMapOverpaidContractTableData): IBankAccountReconciliationTableColumns[] =>
  data?.map((dataRow: IBankAccountReconciliation) => {
    const {
      allocation_details: allocationDetails,
      allocation_status: allocationStatus,
      amount,
      debitor_party: { address, name },
      reference,
      timestamp,
      total_allocated: totalAllocated,
      uuid,
    } = dataRow;

    return addPlaceholderForEmptyValues({
      address,
      allocationDetails: allocationDetails
        ?.map(details => translate(`${translationPrefix}.${details}`))
        .join('\r\n'),
      allocationStatus: translate(`${translationPrefix}.${allocationStatus}`),
      amount: getLocaleCurrencyValue({
        currency: amount.currency,
        value: amount.amount,
      }),
      name,
      reference,
      timestamp: getLocaleDate({ date: timestamp }),
      totalAllocated: getLocaleCurrencyValue({
        currency: totalAllocated?.currency,
        value: totalAllocated?.amount,
      }),
      uuid,
    });
  }) ?? [];
