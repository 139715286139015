interface IDownloadPDF {
  filename?: string;
  pdfData: ArrayBuffer;
}

export const downloadPdfFromBlob = ({
  filename = 'download',
  pdfData,
}: IDownloadPDF) => {
  const blob = new Blob([pdfData], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);

  const downloadLink = document.createElement('a');
  downloadLink.style.display = 'none';
  downloadLink.href = url;
  downloadLink.download = `${filename}.pdf`;
  document.body.appendChild(downloadLink);
  downloadLink.click();

  URL.revokeObjectURL(url);
};
