import { ReactNode } from 'react';
import { useI18n } from '@hooks/useI18n/useI18n';
import { PaymentTypes } from '@utils/constants';

interface IPaymentModalContentProps {
  children: ReactNode;
  paymentPlanId: string | undefined;
  paymentType: string;
}

export const PaymentModalContent = ({
  children,
  paymentPlanId,
  paymentType,
}: IPaymentModalContentProps) => {
  const { translate } = useI18n();

  const ModalText = {
    [PaymentTypes.PREPAYMENT]: (
      <>
        <p>{translate('PAGE.ORDERS.WHEN_PREPAYMENT_APPLIED')}</p>
        <p>{translate('PAGE.ORDERS.WHEN_PREPAYMENT_APPLIED_EXCEPTION')}</p>
        <p>
          {translate('PAGE.ORDERS.THIS_ACTION_IS_IRREVERSIBLE', {
            paymentPlanId: <strong key="paymentPlanId">{paymentPlanId}</strong>,
          })}
        </p>
      </>
    ),
    [PaymentTypes.REFUND]: (
      <p>
        {translate('PAGE.ORDERS.THIS_ACTION_IS_IRREVERSIBLE', {
          paymentPlanId: <strong key="paymentPlanId">{paymentPlanId}</strong>,
        })}
      </p>
    ),
    [PaymentTypes.MANUAL_PAYMENT]: null,
  };

  return (
    <>
      {ModalText[paymentType as keyof typeof ModalText]}
      {children}
      <p className="text-danger text-center">
        <strong>{translate('GENERAL.ARE_YOU_SURE')}</strong>
      </p>
    </>
  );
};
