import { IFilterOptions } from '@hooks/useQueryState/utils/types';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';

export const getScheduleVersions = (
  monetaContractData: IOpsPortalMonetaContract | undefined,
) => {
  const uniqueScheduleVersions: number[] = [];
  const scheduleVersionsOptions: IFilterOptions[] = [];

  monetaContractData?.balances.forEach(balance => {
    const scheduleVersion = balance.schedule_version;
    if (!uniqueScheduleVersions.includes(scheduleVersion)) {
      const initialScheduleDate = balance.schedule_date;
      uniqueScheduleVersions.push(scheduleVersion);
      scheduleVersionsOptions.push({
        id: String(scheduleVersion),
        name: `V${scheduleVersion}${
          initialScheduleDate ? ` - ${initialScheduleDate}` : ''
        }`,
      });
    }
  });

  return scheduleVersionsOptions;
};
