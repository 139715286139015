import { Row } from '@tanstack/react-table';

interface IGetValueFromRowByKey<T> {
  key: keyof T;
  row: Row<T>;
}

export const getValueFromRowByKey = <T>({
  key,
  row,
}: IGetValueFromRowByKey<T>) => String(row.original[key]);
