interface IMerchantData {
  external_uuid: string;
  short_name: string;
}

interface IMerchantOptions {
  id: string;
  name: string;
}

export const getMerchantsOptions = (
  merchantsData: IMerchantData[] | undefined,
): IMerchantOptions[] => [
  { id: '', name: '—' },
  ...(merchantsData
    ? merchantsData.map((merchantRow: IMerchantData) => ({
        id: merchantRow.external_uuid,
        name: merchantRow.short_name,
      }))
    : []),
];
