interface IDownloadFileFromBlob {
  data: ArrayBuffer;
  filename: string;
  mimetype: string;
}

export const downloadFileFromBlob = ({
  data,
  filename,
  mimetype,
}: IDownloadFileFromBlob) => {
  const blob = new Blob([data], { type: mimetype });
  const url = URL.createObjectURL(blob);

  const downloadLink = document.createElement('a');
  downloadLink.style.display = 'none';
  downloadLink.href = url;
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();

  URL.revokeObjectURL(url);
};
