import { createColumnHelper } from '@tanstack/react-table';
import { ICardCTA, ReactTableColumns } from '@appTypes';
import { Translate } from '@hooks/useI18n';
import { AllocationDetailsButton } from './AllocationDetailsButton';
import {
  IAllocationDetails,
  IBankAccountReconciliationTableColumns,
} from './types';

interface IGetBankAccountReconciliationsColumnsProps {
  canReconcileBankAccount: boolean;
  ctaProps: ICardCTA | undefined;
  setAllocationDetails: (allocationDetails: IAllocationDetails) => void;
  translate: Translate;
}

export const getBankAccountReconciliationsColumns = ({
  canReconcileBankAccount,
  ctaProps,
  setAllocationDetails,
  translate,
}: IGetBankAccountReconciliationsColumnsProps): ReactTableColumns<IBankAccountReconciliationTableColumns> => {
  const columnHelper =
    createColumnHelper<IBankAccountReconciliationTableColumns>();

  const allocateTransactionColumn = columnHelper.display({
    cell: ({ row }) =>
      ctaProps ? (
        <AllocationDetailsButton
          allocationDetails={{
            amount: row.original.amount,
            reference: row.original.reference,
            totalAllocated: row.original.totalAllocated,
            uuid: row.original.uuid,
          }}
          ctaProps={ctaProps}
          setAllocationDetails={setAllocationDetails}
        />
      ) : null,
    id: 'ctaColumn',
  });

  const allColumns = [
    columnHelper.accessor('timestamp', {
      header: translate('TITLE.DATE'),
    }),
    columnHelper.accessor('amount', {
      header: translate('LABEL.AMOUNT'),
    }),
    columnHelper.accessor('reference', {
      header: translate('OPS_PORTAL.LABEL.REFERENCE'),
    }),
    columnHelper.accessor('name', {
      header: translate('OPS_PORTAL.LABEL.NAME'),
    }),
    columnHelper.accessor('address', {
      header: translate('LABEL.ADDRESS'),
    }),
    columnHelper.accessor('allocationDetails', {
      header: translate('OPS_PORTAL.LABEL.ALLOCATION_DETAILS'),
    }),
    columnHelper.accessor('allocationStatus', {
      header: translate('OPS_PORTAL.LABEL.ALLOCATION_STATUS'),
    }),
  ];

  if (canReconcileBankAccount) {
    return [...allColumns, allocateTransactionColumn];
  }

  return allColumns;
};
