import styled from 'styled-components';
import { HeaderGroup } from '@tanstack/react-table';
import { useI18n } from '@hooks/useI18n/useI18n';
import { getColSpan } from './getColSpan';

const CenteredTD = styled.td`
  text-align: center;
  vertical-align: middle;
`;

interface INoTableData<TData> {
  headerGroups: HeaderGroup<TData>[];
}

export const NoTableData = <TData,>({ headerGroups }: INoTableData<TData>) => {
  const { translate } = useI18n();
  const colSpan = getColSpan(headerGroups);

  return (
    <tr>
      <CenteredTD colSpan={colSpan}>
        {translate('STATUS.NO_DATA_AVAILABLE')}
      </CenteredTD>
    </tr>
  );
};
