import { IMerchantSettingGroup } from '@schemas/merchantPortal/types/accountSettings';
import { IAuthState, IUserProfile } from './types.d';

export const selectAgreedToPrivacyPolicyAt = ({ auth }: IAuthState) =>
  Boolean(auth?.agreedToPrivacyPolicyAt);

export const selectHasAuthToken = ({ auth }: IAuthState) => ({
  hasAuthToken: Boolean(auth.authToken),
});

export const selectIsAuthed = ({ auth }: IAuthState) => Boolean(auth.user);

export const selectOriginatorFromPath = ({ originator }: IAuthState) =>
  originator?.originatorFromPath;

export const selectOriginatorFromUser = ({ originator }: IAuthState) =>
  originator?.originatorFromUser;

export const selectOriginatorUuid = ({ auth, originator }: IAuthState) =>
  auth?.user.user_profiles.find(
    (profile: IUserProfile) =>
      profile.originator_name === originator.originatorFromUser,
  )?.originator_uuid;

export const selectUserProfiles = ({ originator }: IAuthState) =>
  originator?.userProfiles;

export const selectPathnamePrefix = ({ originator }: IAuthState) =>
  originator?.pathnamePrefix;

export const selectPermissions = ({ auth }: IAuthState) =>
  auth?.user?.user_permissions;

export const selectMerchantSettings = ({ auth }: IAuthState) =>
  auth?.user?.merchant_settings ?? [];

export const selectMerchantSettingGroup = ({ auth }: IAuthState) =>
  auth?.user?.merchant_setting_group as IMerchantSettingGroup | undefined;

export const selectPortal = ({ auth }: IAuthState) => auth?.user?.portal_name;

export { selectTimeToAuthExpiry } from './selectTimeToAuthExpiry';

export const selectUser = ({ auth }: IAuthState) => auth?.user;
