import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { originatorActions } from '@redux/originatorRedux';
import {
  selectOriginatorFromPath,
  selectOriginatorFromUser,
  selectPathnamePrefix,
  selectUserProfiles,
} from '@selectors/index';
import { Originators } from '@utils/constants';
import { getOriginatorByPath } from './utils/getOriginatorByPath';
import { getPathnamePrefixForOriginator } from './utils/getPathnamePrefixForOriginator';

export const useOriginator = () => {
  const dispatch = useDispatch();
  const setOriginator = useCallback(
    (value, fromPicker = false) =>
      fromPicker
        ? dispatch(originatorActions.setOriginatorFromPicker(value))
        : dispatch(originatorActions.setOriginatorFromPath(value)),
    [dispatch],
  );

  const originatorFromPath =
    useSelector(selectOriginatorFromPath) || getOriginatorByPath();
  const originatorFromUser = useSelector(selectOriginatorFromUser);
  // State should have an originator from user if authed, and an originator from path if
  // this isn't a first visit. Otherwise, grab the originator from the URL as a fallback.
  const originator = originatorFromUser || originatorFromPath;

  const userProfiles = useSelector(selectUserProfiles);

  const pathnamePrefix =
    useSelector(selectPathnamePrefix) ??
    getPathnamePrefixForOriginator(originatorFromPath);

  return {
    getPathnamePrefixForOriginator,
    isCompass: [Originators.PAGOLIGHT, Originators.PAGOLIGHTPRO].some(
      compassOriginator => compassOriginator === originator,
    ),
    isHeidiPay: Originators.HEIDIPAY === originator,
    isPagolight: Originators.PAGOLIGHT === originator,
    isPagolightPro: Originators.PAGOLIGHTPRO === originator,
    originator,
    originatorFromPath,
    pathnamePrefix,
    setOriginator,
    userProfiles,
  };
};
