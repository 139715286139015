import { Translate } from '@hooks/useI18n';

interface IGetContractDetailsFieldNames {
  translate: Translate;
}

export const getContractDetailsFieldNames = ({
  translate,
}: IGetContractDetailsFieldNames) => {
  const contractUUID = translate('OPS_PORTAL.LABEL.CONTRACT_UUID');
  const language = translate('LABEL.LANGUAGE');
  const monthlyPaymentAmount = translate(
    'OPS_PORTAL.LABEL.MONTHLY_PAYMENT_AMOUNT',
  );
  const email = translate('LABEL.EMAIL_ADDRESS');
  const numberOfInstallments = translate('LABEL.NUMBER_OF_INSTALMENTS');
  const originalNumberOfInstallments = translate(
    'OPS_PORTAL.LABEL.ORIGINAL_NUMBER_OF_INSTALMENTS',
  );
  const amountPaid = translate('OPS_PORTAL.LABEL.AMOUNT_PAID');

  const contractDetails = {
    originalAmount: translate('OPS_PORTAL.LABEL.ORIGINAL_AMOUNT'),
    amountRefunded: translate('OPS_PORTAL.LABEL.AMOUNT_REFUNDED'),
    amountReimbursedToCustomer: translate(
      'OPS_PORTAL.LABEL.AMOUNT_REIMBURSED_TO_CUSTOMER',
    ),
    currentAmount: translate('OPS_PORTAL.LABEL.CURRENT_AMOUNT'),
    amountPaid,
    writeOffAmount: translate('OPS_PORTAL.LABEL.AMOUNT_WRITTEN_OFF'),
  };

  const contractPerformance = {
    activityStatus: translate('OPS_PORTAL.LABEL.ACTIVITY_STATUS'),
    terminationDate: translate('OPS_PORTAL.LABEL.TERMINATION_DATE'),
    performanceStatus: translate('OPS_PORTAL.LABEL.PERFORMANCE_STATUS'),
    contractUUID,
    paymentDueDate: translate('OPS_PORTAL.LABEL.CURRENT_ARREARS_DAY_ZERO'),
    language,
    monthlyPaymentAmount,
    daysInArrears: translate('OPS_PORTAL.LABEL.DAYS_IN_ARREARS'),
    daysInArrearsByReconciliationDate: translate(
      'OPS_PORTAL.LABEL.DAYS_IN_ARREAR_BY_RECONCILIATION_DATE',
    ),
    paymentsDown: translate('OPS_PORTAL.LABEL.PAYMENTS_DOWN'),
    bucket: translate('OPS_PORTAL.LABEL.BUCKET'),
    arrearsAmount: translate('LABEL.ARREARS_AMOUNT'),
    email,
    numberOfInstallments,
    originalNumberOfInstallments,
    amountPaid,
  };

  const customerDetails = {
    customerUUID: translate('OPS_PORTAL.LABEL.CUSTOMER_UUID'),
    customerName: translate('TITLE.CUSTOMER_NAME'),
    customerContactAddress: translate(
      'OPS_PORTAL.LABEL.CUSTOMER_CONTACT_ADDRESS',
    ),
    dateOfBirth: translate('OPS_PORTAL.LABEL.DATE_OF_BIRTH'),
    email,
    customerAddress: translate('LABEL.ADDRESS'),
    phoneNumber: translate('LABEL.PHONE_NUMBER'),
    language,
  };

  return {
    contractDetails,
    contractPerformance,
    customerDetails,
  };
};
