import { IGetPaginationCountsProps } from '../types';

export const getPaginationCounts = ({
  pageCount,
  pageIndex,
  pageSize,
  rowCount,
  totalItems,
}: IGetPaginationCountsProps) => {
  const pageIndexNumber = Number(pageIndex);
  const pageNumber = pageIndexNumber + 1;
  const pageSizeNumber = Number(pageSize);
  const previousPagesCount = (pageCount - 1) * pageSizeNumber;
  let from;
  let to;
  let total;

  const thereAreNoRows = !rowCount;
  const thisIsTheOnlyPage = pageCount === 1;
  const thisIsTheLastPage = pageNumber === pageCount;

  if (thereAreNoRows) {
    from = 0;
    to = 0;
    total = 0;
  } else if (thisIsTheOnlyPage) {
    from = 1;
    to = totalItems;
    total = totalItems;
  } else if (thisIsTheLastPage) {
    from = previousPagesCount + 1;
    to = previousPagesCount + rowCount;
    total = totalItems;
  } else {
    from = pageIndexNumber * pageSizeNumber + 1;
    to = pageNumber * pageSizeNumber;
    total = totalItems;
  }

  return { from, to, total };
};
