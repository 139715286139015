import { Card } from '@components/Card';
import { NoDataAvailable } from '@components/NoDataAvailable';
import { NotificationBanner } from '@components/NotificationBanner';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { useApplicantBlocking } from '@hooks/useApplicantBlocking';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useEmailUserModal } from '@hooks/useModal/hooks/useEmailUserModal';
import { useModifyApplicantOverrideModal } from '@hooks/useModal/hooks/useModifyApplicantOverrideModal';
import { useOriginator } from '@hooks/useOriginator';
import { IQueryResults } from '@pages/ApplicationDetails/types';
import { useGetApplicantRiskOverrides } from '../hooks/queries/useGetApplicantRiskOverrides';
import { useApplicantRiskOverrideNotifications } from '../hooks/useApplicantRiskOverrideNotifications';
import { useDeleteRiskTestOverrides } from '../hooks/useDeleteRiskTestOverrides';
import { TabsComponent } from './TabsComponent';

export const DetailsCard = ({
  data,
  error,
  isLoading,
  nextPayment,
}: IQueryResults) => {
  const { translate } = useI18n();
  const { isCompass } = useOriginator();

  const hasUuid = Boolean(
    data?.order_details?.external_uuid || data?.external_contract_uuid,
  );

  const applicantUuid = data?.applicant_uuid;

  const { applicantRiskOverrideData } = useGetApplicantRiskOverrides({
    applicantUuid,
  });

  const applicationRiskOverrideNotifications =
    useApplicantRiskOverrideNotifications(applicantRiskOverrideData);

  const {
    applicantBlockModalCtaProps,
    applicantBlockModals,
    error: blockStatusError,
    isLoading: isBlockStatusLoading,
    notification,
  } = useApplicantBlocking({
    applicantUuid,
  });

  const emailUserModal = useEmailUserModal({
    contractData: data,
    dependencies: [hasUuid],
  });

  const modifyApplicantOverrideModal = useModifyApplicantOverrideModal({
    applicantUuid: String(applicantUuid),
    dependencies: [Boolean(applicantUuid)],
  });

  const { deleteRiskTestOverridesButton } = useDeleteRiskTestOverrides({
    applicantUuid,
    applicantRiskOverrideData,
  });

  const buttons = [
    ...applicantBlockModalCtaProps,
    ...(emailUserModal ? [emailUserModal?.ctaProps] : []),
    ...(modifyApplicantOverrideModal
      ? [modifyApplicantOverrideModal?.ctaProps]
      : []),
    ...deleteRiskTestOverridesButton,
  ];

  const cardTitle = translate('OPS_PORTAL.TITLE.APPLICATION_DETAILS');

  return (
    <>
      <Card buttons={buttons} title={cardTitle}>
        <NotificationBanner
          notifications={applicationRiskOverrideNotifications}
        />
        <QueryStateRouter
          error={error || blockStatusError}
          isLoading={isLoading || isBlockStatusLoading}
        >
          <>
            {notification}
            {data ? (
              <TabsComponent
                data={data}
                isCompass={isCompass}
                nextPayment={nextPayment}
              />
            ) : (
              <NoDataAvailable />
            )}
          </>
        </QueryStateRouter>
      </Card>
      {applicantBlockModals}
      {emailUserModal?.Modal ?? null}
      {modifyApplicantOverrideModal?.Modal ?? null}
    </>
  );
};
