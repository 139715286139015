import { IOpsPortalCollectionAgencies } from '@schemas/opsPortal/types/collectionAgencies';

export const updateAgencyToHeyLightRebrand = (
  data: IOpsPortalCollectionAgencies,
) =>
  data?.map(item => {
    if (item.key === 'heidipay') {
      return {
        ...item,
        value: 'HeyLight',
      };
    }
    return item;
  });
