export enum EventType {
  Communications = 'COMMUNICATIONS',
  FailedScheduledPayment = 'FAILED_SCHEDULED_PAYMENT',
  InitialPayment = 'INITIAL_PAYMENT',
  ManualPayment = 'MANUAL_PAYMENT',
  OtherPayment = 'OTHER_PAYMENT',
  ResolvedScheduledPayment = 'RESOLVED_SCHEDULED_PAYMENT',
  SuccessfulScheduledPayment = 'SUCCESSFUL_SCHEDULED_PAYMENT',
}

export enum CommunicationType {
  Email = 'email',
  Text = 'text_message',
}

export enum CommunicationDirection {
  Inbound = 'inbound',
  Outbound = 'outbound',
}

export enum CommunicationStatus {
  ApprovalRejected = 'approval rejected',
  ReadyToSend = 'ready to send',
  RequestApproval = 'request approval',
  Sent = 'sent',
}

export enum PaymentCaptureStatus {
  Complete = 'complete',
  Outstanding = 'outstanding',
  Partial = 'partial',
}

export enum PaymentProcessingStatus {
  Cancelled = 'cancelled',
  Complete = 'complete',
  Expired = 'expired',
  Failed = 'failed',
  Pending = 'pending',
  Processing = 'processing',
}

export enum EventColumnAccessor {
  CommunicationDirection = 'communicationDirection',
  CommunicationStatus = 'communicationStatus',
  CommunicationType = 'communicationType',
  CreatedDate = 'createdDate',
  Description = 'description',
  PaymentCaptureStatus = 'paymentCaptureStatus',
  PaymentProcessingStatus = 'paymentProcessingStatus',
  Title = 'title',
  Type = 'type',
}
