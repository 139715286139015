import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { Modal } from '@hooks/useModal/types';
import { usePortalError } from '@hooks/usePortalError';
import { IOpsPortalAllocationDetails } from '@schemas/opsPortal/types/allocationDetails';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { AllocationDetailsForm } from './AllocationDetailsForm';
import { AllocationGroup } from './AllocationGroup';
import { AllocationSectionHeader } from './Allocations.styles';
import { formatAllocationAmountTitle } from './formatAllocationAmountTitle';
import {
  IAllocationDetailsModal,
  IAllocationRequest,
  IArrearsManagedByRequest,
} from './types';

export const useAllocationDetailsModal = (
  props: IAllocationDetailsModal,
): Modal => {
  const {
    amount: allocationAmount,
    bankAccountReconciliationsQueryKey,
    reference,
    totalAllocated,
    uuid,
    variant,
  } = props;
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast(translate);

  const modal = useModal({
    sharedProps: props,
    modalName: 'allocationDetailsModal',
    permissions: ['ops_portal.can_reconcile_bank_account'],
    buttonTranslationKey:
      'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.ALLOCATE',
  });

  const queryClient = useQueryClient();

  const {
    data: allocationDetailsData,
    isFetching: isAllocationDetailsLoading,
  } = useQuery<IOpsPortalAllocationDetails, AxiosError>(
    [QueryKeys.ALLOCATION_DETAILS],
    () => fetchDataFromEndpoint(`${Endpoints.FetchAllocationDetails}/${uuid}`),
    { enabled: modal.isModalShowing },
  );

  const { isLoading: isUpdatingAllocation, mutate: allocationDetails } =
    useMutation<unknown, AxiosError<IError>, IAllocationRequest>(
      requestData => {
        return postDataToEndpoint({
          endpoint: Endpoints.PostAllocationTransaction,
          requestData,
        });
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            bankAccountReconciliationsQueryKey,
          ]);
          message.success(
            'OPS_PORTAL.STATUS.UPDATE_BANK_ACCOUNT_TRANSACTION_SUCCESSFUL',
          );
          modal.closeModal();
        },
        onError: error => {
          handleAxiosError(
            error,
            'OPS_PORTAL.STATUS.UNABLE_TO_UPDATE_BANK_ACCOUNT_TRANSACTION',
          );
        },
      },
    );

  const handleSubmit = useCallback(
    ({
      allocation_type: allocationType,
      amount,
      merchant_settlement_reference: merchantSettlementReference,
      merchant_uuid: merchantUuid,
      payment_plan_reference: paymentPlanReference,
      stripe_reference: stripeReference,
    }: IArrearsManagedByRequest) =>
      allocationDetailsData &&
      allocationDetails({
        statement_line_uuid: String(uuid),
        allocation_type: allocationType,
        payment_plan_reference: paymentPlanReference,
        merchant_uuid: merchantUuid,
        merchant_settlement_reference: merchantSettlementReference,
        stripe_reference: stripeReference,
        amount: {
          amount,
          currency: allocationDetailsData.amount.currency,
        },
      }),
    [allocationDetails, allocationDetailsData, uuid],
  );

  const formID = 'allocationTransactions';

  const isLoading = isUpdatingAllocation || isAllocationDetailsLoading;

  const modalContent = (
    <>
      <h3 className="mb-5">
        {formatAllocationAmountTitle(reference, allocationAmount)}
      </h3>
      {!isLoading && allocationDetailsData?.allocation_groups?.length ? (
        <>
          <AllocationSectionHeader>
            {formatAllocationAmountTitle(
              translate(
                'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.ALLOCATIONS',
              ),
              totalAllocated,
            )}
          </AllocationSectionHeader>
          {allocationDetailsData.allocation_groups.map(allocationGroup => (
            <AllocationGroup
              allocationGroup={allocationGroup}
              getLocaleCurrencyValue={getLocaleCurrencyValue}
              getLocaleDate={getLocaleDate}
              key={allocationGroup.uuid}
              translate={translate}
            />
          ))}
        </>
      ) : null}
      <AllocationDetailsForm
        amount={allocationDetailsData?.amount}
        formID={formID}
        handleSubmitForm={handleSubmit}
      />
    </>
  );

  const modalProps = {
    formID,
    isDangerousSubmit: true,
    isLoading,
    loadingOverlay: true,
    loadingOverlayText: isAllocationDetailsLoading
      ? 'STATUS.LOADING'
      : undefined,
    modalContent,
    showFormButtons: !isLoading,
    submitText: translate(
      'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.ALLOCATE',
    ),
    title: translate(
      'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.ALLOCATE_TRANSACTION',
    ),
    variant,
  };

  return getModalDetails({ modal, modalProps });
};
