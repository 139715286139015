export const getStoredLanguage = (languageKey?: string | null) => {
  if (!languageKey) {
    return null;
  }

  const stringifiedLanguage = localStorage.getItem(languageKey);
  const parsedLanguage = stringifiedLanguage
    ? JSON.parse(stringifiedLanguage)
    : null;

  return parsedLanguage?.language || null;
};
