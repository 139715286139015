import { forwardRef, useCallback, useEffect, useState } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Row,
  TableOptions,
  TableState,
  useReactTable,
} from '@tanstack/react-table';
import { NoTranslateWrapper } from '@components/Common.styles';
import { Filters } from '@components/Filters';
import { Pagination } from '@components/Pagination';
import { IFilter, IQueryState } from '@hooks/useQueryState/utils/types';
import { calculatePageCount } from '@utils/calculatePageCount';
import { TableBody, TableHeader } from './components';
import { Table, TableWrapper } from './Table.styles';
import { ITableRefObject } from './types';
import { handleFiltering } from './utils';

interface ICursorPagination {
  gotoNextPage: (() => void) | null;
  gotoPrevPage: (() => void) | null;
  pageSize: string;
  previous: string;
}

interface ILimitOffsetPagination {
  limit?: string;
  offset?: string;
}

type IPaginationProps = (ICursorPagination | ILimitOffsetPagination) & {
  totalItems?: number;
};

interface IServersideTableProps<TData, TCell> {
  className?: string;
  columns: ColumnDef<TData, TCell>[];
  data: TData[];
  enableMultiRowSelection?: boolean;
  enableRowSelection?: boolean;
  enableSorting?: boolean;
  filters?: IFilter[];
  handleMultiRowSelect?: (rows: Row<TData>[]) => void;
  handleRowSelect?: (row: Row<TData>) => void;
  isCursorPagination?: boolean;
  manageControlledState?: (newQueryState: object) => void;
  pagination: IPaginationProps;
  resetFilters: () => void;
  tableState: IQueryState;
}

const ServersideTableComponent = <TData, TCell>(
  {
    className,
    columns,
    data,
    enableMultiRowSelection = false,
    enableRowSelection = false,
    enableSorting = true,
    filters = [],
    handleMultiRowSelect,
    handleRowSelect,
    isCursorPagination = false,
    manageControlledState,
    pagination,
    resetFilters,
    tableState,
  }: IServersideTableProps<TData, TCell>,
  ref: React.ForwardedRef<ITableRefObject<TData>>,
) => {
  const [rowSelection, setRowSelection] = useState({});

  const limit = 'limit' in pagination && pagination.limit;
  const offset = 'offset' in pagination && pagination.offset;
  const pageSize = 'pageSize' in pagination && pagination.pageSize;

  const { totalItems } = pagination;
  const cursorLinkHandlers = {
    ...('gotoNextPage' in pagination
      ? { gotoNextPage: pagination.gotoNextPage }
      : {}),
    ...('gotoPrevPage' in pagination
      ? { gotoPrevPage: pagination.gotoPrevPage }
      : {}),
  };

  useEffect(() => {
    setRowSelection([]);
  }, [limit, offset]);

  const pageCount = calculatePageCount({
    totalItems,
    limit: Number(limit || pageSize),
  });

  const shouldEnableRowSelection =
    enableMultiRowSelection || enableRowSelection;

  const tableStateAfterRowSelectionCheck = { ...tableState };
  if (shouldEnableRowSelection) {
    tableStateAfterRowSelectionCheck.rowSelection = rowSelection;
  }

  const tableOptions: TableOptions<TData> = {
    autoResetPageIndex: false,
    columns,
    data,
    enableMultiSort: true,
    enableMultiRowSelection,
    enableRowSelection: shouldEnableRowSelection,
    enableSorting: enableSorting && data.length > 1,
    getCoreRowModel: getCoreRowModel(),
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onStateChange: manageControlledState,
    onRowSelectionChange: setRowSelection,
    pageCount,
    state: tableStateAfterRowSelectionCheck as Partial<TableState>,
  };

  const tableInstance = useReactTable(tableOptions);

  const {
    getAllColumns,
    getCanNextPage,
    getCanPreviousPage,
    getCoreRowModel: getCoreRows,
    getHeaderGroups,
    getPageCount,
    getPageOptions,
    getPaginationRowModel: getPageModel,
    getRowModel,
    getState,
    nextPage,
    previousPage,
    setPageIndex,
    setPageSize,
    setState,
  } = tableInstance;

  useEffect(() => {
    const selectedRows = tableInstance.getSelectedRowModel().rows;
    if (!enableMultiRowSelection) {
      // Since enableMultiRowSelection is false, we can use [0] instead of filtering by id.
      handleRowSelect?.(selectedRows[0]);
    } else {
      handleMultiRowSelect?.(selectedRows);
    }
  }, [
    rowSelection,
    tableInstance,
    enableMultiRowSelection,
    handleRowSelect,
    handleMultiRowSelect,
  ]);

  const filterHandler = useCallback(
    values => handleFiltering({ filters, setState, state: getState(), values }),
    [filters, getState, setState],
  );

  const headerGroups = getHeaderGroups();

  if (ref) {
    // eslint-disable-next-line no-param-reassign
    (ref as React.MutableRefObject<ITableRefObject<TData>>).current = {
      rows: getCoreRows().rows.map(row => row.original),
      columns: getAllColumns().map(column => column.columnDef),
    };
  }

  return (
    <>
      <Filters
        filterHandler={filterHandler}
        filters={filters}
        resetFilters={resetFilters}
      />

      <NoTranslateWrapper>
        <TableWrapper>
          <Table extraClassName={className}>
            <caption>
              <span className="sr-only">
                Column headers with buttons are sortable.
              </span>
            </caption>
            <TableHeader
              flexRender={flexRender}
              headerGroups={headerGroups}
              tableState={tableState}
            />
            <TableBody
              flexRender={flexRender}
              headerGroups={headerGroups}
              rows={getRowModel().rows}
            />
          </Table>
        </TableWrapper>

        <Pagination
          canNextPage={getCanNextPage()}
          canPreviousPage={getCanPreviousPage()}
          cursorLinkHandlers={cursorLinkHandlers}
          gotoPage={setPageIndex}
          isCursorPagination={isCursorPagination}
          nextPage={nextPage}
          pageCount={getPageCount()}
          pageIndex={getState().pagination.pageIndex}
          pageOptions={getPageOptions()}
          pageSize={getState().pagination.pageSize}
          previousPage={previousPage}
          rowCount={getPageModel().rows.length}
          setPageSize={setPageSize}
          totalItems={totalItems}
        />
      </NoTranslateWrapper>
    </>
  );
};

ServersideTableComponent.displayName = 'ServersideTable';

export const ServersideTable = forwardRef(ServersideTableComponent) as <
  TData,
  TCell,
>(
  // eslint-disable-next-line no-use-before-define
  props: IServersideTableProps<TData, TCell> &
    // eslint-disable-next-line no-use-before-define
    React.RefAttributes<ITableRefObject<TData>>,
) => React.ReactElement;
