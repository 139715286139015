import { AxiosError } from 'axios';
import { QueryKey, useQuery } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IMerchantPortalTracking } from '@schemas/merchantPortal/types/tracking';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseTrackingDataOptions {
  handleError?: () => void;
  handleSuccess?: (data: IMerchantPortalTracking) => void;
  isOpsEndpoint?: boolean;
  refetchInterval?: number;
}

export interface IUseTrackingDataProps {
  contractId?: string;
  languageOverride?: string;
  options: IUseTrackingDataOptions;
  queryKey: QueryKey;
}

export const useTrackingData = ({
  contractId,
  languageOverride,
  options,
  queryKey,
}: IUseTrackingDataProps) => {
  const {
    handleError,
    handleSuccess,
    isOpsEndpoint = false,
    refetchInterval,
  } = options;
  const { selectedLanguage } = useI18n();

  return useQuery<IMerchantPortalTracking, AxiosError>(
    queryKey,
    () =>
      fetchDataFromEndpoint(
        isOpsEndpoint ? Endpoints.TrackOrderOps : Endpoints.TrackOrder,
        {
          search: contractId,
          query: { lang: languageOverride ?? selectedLanguage },
        },
      ),
    {
      refetchInterval,
      enabled: Boolean(contractId),
      onError: handleError,
      onSuccess: handleSuccess,
    },
  );
};
