import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation } from '@tanstack/react-query';
import { Card } from '@components/Card';
import { Button } from '@components/Common.styles';
import { useI18n } from '@hooks/useI18n/useI18n';
import { selectOriginatorUuid } from '@selectors/index';
import { ButtonVariant, Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { redirectToExternalUrl } from '@utils/redirectToExternalUrl';

interface IInstoreInitRequest {
  locale: string;
  originatorUuid?: string;
}

interface IInstoreInitResponse {
  url: string;
}

export const InStore = () => {
  const { selectedLanguage, translate } = useI18n();
  const originatorUuid = useSelector(selectOriginatorUuid);

  const { isError, isLoading, mutate } = useMutation<
    IInstoreInitResponse,
    AxiosError<IError>,
    IInstoreInitRequest
  >(
    requestData =>
      postDataToEndpoint({
        endpoint: Endpoints.CreateNewOrder,
        requestData: {
          locale: requestData.locale,
          originator_uuid: requestData.originatorUuid,
        },
      }),
    {
      onSuccess: ({ url }) => {
        redirectToExternalUrl(url);
      },
    },
  );

  const redirect = useCallback(
    () => mutate({ locale: selectedLanguage, originatorUuid }),
    [mutate, originatorUuid, selectedLanguage],
  );

  useEffect(redirect, [redirect]);

  if (isLoading) {
    return <p>{translate('PAGE.INSTORE.REDIRECTING_TO_INSTORE')}</p>;
  }

  if (isError) {
    return (
      <Card title={translate('PAGE.INSTORE.INSTORE_REDIRECT_ERROR')}>
        <p>{translate('PAGE.INSTORE.UNABLE_TO_REDIRECT_TO_INSTORE')}</p>
        <Button onClick={redirect} variant={ButtonVariant.Primary}>
          {translate('BUTTON.TRY_AGAIN')}
        </Button>
      </Card>
    );
  }

  return (
    <Card title={translate('PAGE.INSTORE.IN_STORE')}>
      {translate('STATUS.LOADING')}
    </Card>
  );
};
