import { useCallback } from 'react';
import { ICardCTA } from '@appTypes';
import { CardCTA } from '@components/CardCTA';
import { IAllocationDetails } from './types';

interface IAllocationDetailsButtonProps {
  allocationDetails: IAllocationDetails;
  ctaProps: ICardCTA;
  setAllocationDetails: (allocationDetails: IAllocationDetails) => void;
}

export const AllocationDetailsButton = ({
  allocationDetails,
  ctaProps,
  setAllocationDetails,
}: IAllocationDetailsButtonProps) => {
  const clickHandler = useCallback(() => {
    setAllocationDetails(allocationDetails);
    ctaProps.clickHandler();
  }, [setAllocationDetails, allocationDetails, ctaProps]);

  return <CardCTA {...ctaProps} clickHandler={clickHandler} />;
};
