import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { originatorActions } from '@redux/originatorRedux';
import { ReduxPersistKeys } from '@utils/constants';
import { getUserByToken } from './authCrud';

export const actionTypes = Object.freeze({
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  passwordReset: '[passwordReset] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  SetAgreedToPrivacyPolicy: '[Set Agreed to privacy policy] Action',
});

const initialAuthState = {
  agreedToPrivacyPolicyAt: null,
  authToken: null,
  user: null,
};

const persistSettings = {
  storage,
  key: ReduxPersistKeys.AUTH,
  whitelist: ['authToken', 'agreedToPrivacyPolicyAt', 'user'],
};

export const reducer = persistReducer(
  persistSettings,
  (state = initialAuthState, action = {}) => {
    switch (action.type) {
      case actionTypes.Login:
      case actionTypes.passwordReset: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return {
          ...state,
          user,
          agreedToPrivacyPolicyAt: user?.agreed_to_privacy_policy_at,
        };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetAgreedToPrivacyPolicy: {
        const { agreedAt } = action.payload;
        return { ...state, agreedToPrivacyPolicyAt: agreedAt };
      }

      default:
        return state;
    }
  },
);

export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  passwordReset: authToken => ({
    type: actionTypes.passwordReset,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: user => ({ type: actionTypes.SetUser, payload: { user } }),
  setAgreedToPrivacyPolicy: agreedAt => ({
    type: actionTypes.SetAgreedToPrivacyPolicy,
    payload: { agreedAt },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.passwordReset, function* passwordResetSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    try {
      const { data: user } = yield getUserByToken();

      yield put(originatorActions.setOriginatorFromUser(user));
      yield put(originatorActions.SetUserProfiles(user));
      yield put(actions.fulfillUser(user));
    } catch (err) {
      yield put(actions.logout());
    }
  });
}
