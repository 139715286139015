import { IRiskData } from '@schemas/opsPortal/types/applicationDetails';

export const mapRiskTestsData = (data: IRiskData) => {
  const mappedTestBreakdowns = data.tests_breakdown?.map(test => {
    const {
      outcome: testOutcome,
      result: { result: rawTestResult },
      test_name: testName,
    } = test;

    const testResult = typeof rawTestResult !== 'object' ? rawTestResult : '';

    return { testName, testOutcome, testResult };
  });

  return mappedTestBreakdowns
    ? mappedTestBreakdowns.sort((test1, test2) =>
        test1.testName.localeCompare(test2.testName),
      )
    : [];
};
