import KTOffcanvas from '@metronic/_assets/js/components/offcanvas';
import { KTUtil } from '@metronic/_assets/js/components/util';

const KTLayoutQuickUser = (function ktLayoutQuickUser() {
  // Private properties
  let element;

  // Private functions
  const init = function init() {
    const header = KTUtil.find(element, '.offcanvas-header');
    const content = KTUtil.find(element, '.offcanvas-content');

    KTUtil.scrollInit(content, {
      disableForMobile: true,
      resetHeightOnDestroy: true,
      handleWindowResize: true,
      height() {
        let height = parseInt(KTUtil.getViewPort().height, 10);

        if (header) {
          height -= parseInt(KTUtil.actualHeight(header), 10);
          height -= parseInt(KTUtil.css(header, 'marginTop'), 10);
          height -= parseInt(KTUtil.css(header, 'marginBottom'), 10);
        }

        if (content) {
          height -= parseInt(KTUtil.css(content, 'marginTop'), 10);
          height -= parseInt(KTUtil.css(content, 'marginBottom'), 10);
        }

        height -= parseInt(KTUtil.css(element, 'paddingTop'), 10);
        height -= parseInt(KTUtil.css(element, 'paddingBottom'), 10);

        height -= 2;

        return height;
      },
    });

    return new KTOffcanvas(element, {
      overlay: true,
      baseClass: 'offcanvas',
      placement: 'right',
      closeBy: 'kt_quick_user_close',
      toggleBy: 'kt_quick_user_toggle',
    });
  };

  // Public methods
  return {
    init(id) {
      element = KTUtil.getById(id);

      if (!element) {
        return;
      }

      init();
    },

    getElement() {
      return element;
    },
  };
})();

export default KTLayoutQuickUser;
