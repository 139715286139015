import { IProgressDataItem } from '@schemas/merchantPortal/types/tracking';
import { Step } from './Step';
import { Steps } from './Tracking.styles';

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

interface IStepList {
  progressData: IProgressDataItem[];
}

export const StepList = ({ progressData }: IStepList) => (
  <Steps variants={variants}>
    {progressData.map(stepDetails => (
      <Step data={stepDetails} key={stepDetails.step_index} />
    ))}
  </Steps>
);
