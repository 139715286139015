import splashImage from '@assets/images/login-splash-compass.png';
import { resourcesBucketUrl } from '@utils/constants';
import { IImageLogo } from './types';

// This is for any configurations we want to specify
// across all portals and originators.
export const configShared = Object.freeze({
  // This is the maximum number of buttons in a card header
  // before a menu button is used.
  maxCardHeaderCTAs: 2,
  logo: {
    altTxt: 'HeyLight',
    url: `${resourcesBucketUrl}logos/heylight_<§:variant§>_standard.svg`,
  } as IImageLogo,
  theme: {
    bootstrapColors: {
      name: 'heylight',
    },
    colors: {
      primary: '#ea1c00',
    },
    components: {
      loginSidePanel: `
        background-position: 75% 10%;
        background-size: 65%;
        background-color: #E6E7E8;
        background-size: cover;
      `,
    },
    splashImage,
  },
});
