import { useEffect, useMemo, useRef } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Card } from '@components/Card';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ServersideTable } from '@components/Table/ServersideTable';
import { ITableRefObject } from '@components/Table/types';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useQueryState } from '@hooks/useQueryState';
import { IOpsPortalUnbalancedAllocations } from '@schemas/opsPortal/types/unbalancedAllocations';
import { QueryKeys, TableNames } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { getCursorPaginationCallbacks } from '@utils/getCursorPaginationCallbacks';
import { getUnbalancedAllocationsColumns } from './getUnbalancedAllocationsColumns';
import { mapUnbalancedAllocationsTableData } from './mapUnbalancedAllocationsTableData';
import { IUnbalancedAllocationsTableColumns } from './types';

export const UnbalancedAllocations = () => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const refToDataTable =
    useRef<ITableRefObject<IUnbalancedAllocationsTableColumns>>(null);

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(TableNames.UNBALANCED_ALLOCATIONS);

  const { data, error, isLoading } = useQuery<
    IOpsPortalUnbalancedAllocations,
    AxiosError
  >([QueryKeys.UNBALANCED_ALLOCATIONS, stringifiedQueryParams], () =>
    fetchDataFromEndpoint(Endpoints.FetchUnbalancedAllocations, {
      query: queryParams,
    }),
  );

  const columns = useMemo(
    () => getUnbalancedAllocationsColumns(translate),
    [translate],
  );

  const contractsTableData = mapUnbalancedAllocationsTableData({
    data: data?.results,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
  });

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  const filters = getFilters({});

  const { gotoNextPage, gotoPrevPage } = getCursorPaginationCallbacks({
    nextCursor: data?.next_cursor,
    prevCursor: data?.previous_cursor,
    queryState,
    updateQueryParams,
  });

  return (
    <QueryStateRouter error={error} isLoading={isLoading}>
      <Card title={translate('OPS_PORTAL.TITLE.UNBALANCED_ALLOCATIONS')}>
        <ServersideTable
          columns={columns}
          data={contractsTableData}
          filters={filters}
          isCursorPagination={true}
          manageControlledState={updateQueryParams}
          pagination={{
            gotoNextPage,
            gotoPrevPage,
            pageSize: queryParams.pageSize,
            totalItems: data?.count,
          }}
          ref={refToDataTable}
          resetFilters={resetFilters}
          tableState={queryState}
        />
      </Card>
    </QueryStateRouter>
  );
};
