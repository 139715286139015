import { useFormContext } from 'react-hook-form';
import { FormGroup, Label, Select } from '@components/Common.styles';
import { Tooltip } from '@components/Tooltip';
import { ITooltipContent } from '@components/types.d';
import { ValidationError } from '@components/ValidationError';

export interface IDropdownOptions {
  id: string;
  isPlaceholder?: boolean;
  name: string;
}

interface IDropdownProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  name: string;
  options: IDropdownOptions[];
  tooltip?: ITooltipContent;
}

export const DropdownRHF = ({
  label,
  name,
  options,
  tooltip,
  ...props
}: IDropdownProps) => {
  const { className: extraClassName, ...remainingProps } = props;
  const {
    formState: { errors },
    register,
  } = useFormContext();

  const error = errors[name]?.message;

  return (
    <FormGroup>
      <Label htmlFor={name}>{label}</Label>
      {tooltip ? <Tooltip tip={tooltip} /> : null}
      <Select
        extraClassName={extraClassName}
        id={name}
        {...register(name)}
        {...remainingProps}
      >
        {options.map(option => (
          <option
            disabled={option.isPlaceholder}
            key={option.id}
            value={option.id}
          >
            {option.name}
          </option>
        ))}
      </Select>
      {error ? <ValidationError error={error} /> : null}
    </FormGroup>
  );
};
