import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IOpsPortalRiskApplicationDetails } from '@schemas/opsPortal/types/riskApplicationDetails';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseGetContractRiskApplication {
  contractUuid?: string;
}

export const useGetContractRiskApplicationQuery = ({
  contractUuid,
}: IUseGetContractRiskApplication) => {
  const {
    data: riskApplicationData,
    error: riskApplicationError,
    isLoading: isRiskApplicationLoading,
  } = useQuery<IOpsPortalRiskApplicationDetails, AxiosError>(
    [QueryKeys.RISK_APPLICATION_DETAILS, contractUuid],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.RiskApplicationDetails}/${contractUuid}`,
      ),
    { enabled: Boolean(contractUuid) },
  );

  return {
    isRiskApplicationLoading,
    riskApplicationData,
    riskApplicationError,
  };
};
