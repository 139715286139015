interface IValidatePermissions {
  permissions: string[];
  scopes: string[];
}

export const validatePermissions = ({
  permissions,
  scopes,
}: IValidatePermissions) => {
  const scopesMap: Record<string, boolean> = {};
  scopes.forEach(scope => {
    scopesMap[scope] = true;
  });
  return permissions?.some(permission => scopesMap[permission]);
};
