import { Translate } from '@hooks/useI18n';
import { ISettlement } from '@schemas/opsPortal/types/monetaContract';
import { TransactionEntityTypes } from '@utils/constants';

export const getSettlementUuids = (
  contractSettlements: ISettlement[],
  getLocaleDate: (options: { date: string }) => void,
  translate: Translate,
) =>
  contractSettlements.map(settlement => {
    const date = getLocaleDate({
      date: settlement.settlement_date as string,
    });
    const type = translate(`TYPE.${settlement.type}`);
    const amount = `${settlement.amount?.currency} ${settlement.amount?.amount}`;
    const refund =
      settlement.payor.type === TransactionEntityTypes.HEIDI_PAY
        ? `, ${translate('TYPE.REFUND')}`
        : '';
    return {
      id: settlement.uuid,
      name: `${date} - ${type} (${amount}${refund})`,
    };
  });
