import { KTCookie } from '@metronic/_assets/js/components/cookie';
import KTToggle from '@metronic/_assets/js/components/toggle';
import { KTUtil } from '@metronic/_assets/js/components/util';
import KTLayoutAsideMenu from './aside-menu';
import KTLayoutHeaderMenu from './header-menu';
import KTLayoutStickyCard from './sticky-card';

const KTLayoutAsideToggle = (function ktLayoutAsideToggle() {
  // Private properties
  let body;
  let element;
  let toggleObject;

  // Initialize
  const init = function init() {
    toggleObject = new KTToggle(element, body, {
      targetState: 'aside-minimize',
      toggleState: 'active',
    });

    toggleObject.on('toggle', function onToggle(toggle) {
      // Update sticky card
      if (typeof KTLayoutStickyCard !== 'undefined') {
        KTLayoutStickyCard.update();
      }

      // Pause header menu dropdowns
      if (typeof KTLayoutHeaderMenu !== 'undefined') {
        KTLayoutHeaderMenu.pauseDropdownHover(800);
      }

      // Pause aside menu dropdowns
      if (typeof KTLayoutAsideMenu !== 'undefined') {
        KTLayoutAsideMenu.pauseDropdownHover(800);
      }

      // Remember state in cookie
      KTCookie.setCookie('kt_aside_toggle_state', toggle.getState());
      // to set default minimized left aside use this cookie value in your
      // server side code and add "kt-primary--minimize aside-minimize" classes to
      // the body tag in order to initialize the minimized left aside mode during page loading.
    });

    toggleObject.on('beforeToggle', function onBeforeToggle() {
      if (
        KTUtil.hasClass(body, 'aside-minimize') === false &&
        KTUtil.hasClass(body, 'aside-minimize-hover')
      ) {
        KTUtil.removeClass(body, 'aside-minimize-hover');
      }
    });
  };

  // Public methods
  return {
    init(id) {
      element = KTUtil.getById(id);
      body = KTUtil.getBody();

      if (!element) {
        return;
      }

      // Initialize
      init();
    },

    getElement() {
      return element;
    },

    getToggle() {
      return toggleObject;
    },

    onToggle(handler) {
      if (typeof toggleObject.element !== 'undefined') {
        toggleObject.on('toggle', handler);
      }
    },
  };
})();

export default KTLayoutAsideToggle;
