import { GetLocaleCurrencyValue, GetLocaleDate } from '@hooks/useI18n';
import { IReceivable } from '@schemas/consumerPortal/types/ordersDetails';

interface IScheduledPaymentsProps {
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  schedule?: IReceivable[];
}

export const getScheduledPayments = ({
  getLocaleCurrencyValue,
  getLocaleDate,
  schedule = [],
}: IScheduledPaymentsProps) =>
  schedule
    .filter(
      (scheduleItem: IReceivable) => !scheduleItem.is_paid && scheduleItem.date,
    )
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map(({ amount, date, outstanding_amount: outstandingAmount }) => ({
      dueDate: getLocaleDate({ date }),
      outstandingAmount: getLocaleCurrencyValue({
        currency: outstandingAmount?.currency,
        value: outstandingAmount?.amount,
      }) as string,
      paymentAmount: getLocaleCurrencyValue({
        currency: amount?.currency,
        value: amount?.amount,
      }) as string,
    }));
