import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components/index';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { useOriginator } from '@hooks/useOriginator/useOriginator';
import { IConsumerPortalAccountSettings } from '@schemas/consumerPortal/types/accountSettings';
import { IMerchantPortalAccountSettings } from '@schemas/merchantPortal/types/accountSettings';
import { IOpsPortalAccountSettings } from '@schemas/opsPortal/types/accountSettings';
import { QueryKeys } from '@utils/constants';
import { getErrorMessage } from '@utils/getErrorMessage/getErrorMessage';
import { postDataToEndpoint } from '@utils/postDataToEndpoint/postDataToEndpoint';
import { EditPhoneForm } from './EditPhoneForm';
import { VerificationForm } from './VerificationForm';

interface IUseEditPhoneModal extends ISharedModalProps {
  data?:
    | IConsumerPortalAccountSettings
    | IMerchantPortalAccountSettings
    | IOpsPortalAccountSettings;
}

interface IRequestData {
  phone_number: string;
}

export const useEditPhoneModal = (props: IUseEditPhoneModal): Modal => {
  const { data } = props;
  const { translate } = useI18n();
  const { originator } = useOriginator();

  const modal = useModal({
    sharedProps: props,
    modalName: 'editPhoneModal',
    permissions: [
      'merchant_portal.can_view_account_info',
      'consumer_portal.change_consumeruser',
      'ops_portal.view_merchant',
    ],
    buttonTranslationKey: 'PAGE.ACCOUNT_SETTINGS.EDIT_PHONE_NUMBER',
  });

  const [isVerification, setIsVerification] = useState(false);
  const queryClient = useQueryClient();

  const { isLoading: isEditLoading, mutate: editPhone } = useMutation<
    void,
    AxiosError<IError>,
    IRequestData
  >(
    requestData =>
      postDataToEndpoint({
        endpoint: 'user/edit-phone',
        requestData: { ...requestData, originator },
      }),
    {
      onSuccess: () => {
        setIsVerification(true);
      },
      onError: error => {
        modal.resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey:
              'PAGE.ACCOUNT_SETTINGS.UNABLE_TO_EDIT_PHONE_NUMBER',
            translate,
          }),
        );
      },
    },
  );

  const { isLoading: isVerifyLoading, mutate: verifyPhone } = useMutation<
    void,
    AxiosError<IError>,
    IRequestData
  >(
    requestData =>
      postDataToEndpoint({
        endpoint: 'user/verify-phone',
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.ACCOUNT_SETTINGS]);
        modal.resolveForm(
          translate('PAGE.ACCOUNT_SETTINGS.PHONE_NUMBER_SUCCESSFULLY_EDITED'),
        );
        setIsVerification(false);
      },
      onError: error => {
        modal.resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey:
              'PAGE.ACCOUNT_SETTINGS.UNABLE_TO_VERIFY_PHONE_NUMBER',
            translate,
          }),
        );
        setIsVerification(false);
      },
    },
  );

  const handleEdit = useCallback(
    values => {
      editPhone(values);
    },
    [editPhone],
  );

  const handleVerify = useCallback(
    values => {
      verifyPhone(values);
    },
    [verifyPhone],
  );

  const formID = isVerification ? 'verify-phone' : 'edit-phone';
  const isLoading = isEditLoading || isVerifyLoading;

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={modal.formStatusMessage}
      isLoading={isLoading}
    >
      {isVerification ? (
        <VerificationForm formID={formID} handleSubmit={handleVerify} />
      ) : (
        <EditPhoneForm data={data} formID={formID} handleSubmit={handleEdit} />
      )}
    </ModalStatusRouter>
  );

  const modalProps = {
    formID,
    isLoading,
    modalContent,
    title: translate('PAGE.ACCOUNT_SETTINGS.EDIT_PHONE_NUMBER'),
  };

  return getModalDetails({ modal, modalProps });
};
