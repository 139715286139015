import { Translate } from '@hooks/useI18n';
import { SettlementTypes } from '@utils/constants';

export const getSettlementTypes = (translate: Translate) => [
  {
    id: SettlementTypes.BANK_TRANSFER,
    name: translate('TYPE.BANK_TRANSFER'),
  },
  {
    id: SettlementTypes.CARD_PAYMENT,
    name: translate('TYPE.CARD_PAYMENT'),
  },
];
