import { useCallback, useEffect, useState } from 'react';
import { captureMessage, withScope } from '@sentry/react';
import { CardIframeContainer } from './CardIframeContainer';

interface IChangeCardDetailsModalContent {
  closeModal: () => void;
  data?: { redirect_url: string };
  externalUuid?: string;
}

export const ChangeCardDetailsModalContent = ({
  closeModal,
  data,
  externalUuid,
}: IChangeCardDetailsModalContent) => {
  const [eventDataLoaded, setEventDataLoaded] = useState(false);
  const handleMessageEvent = useCallback(
    event => {
      withScope(scope => {
        scope.setLevel('debug');
        scope.setExtra('card-change-iframe-event', JSON.stringify(event));
        captureMessage(`Card Change attempt for ${externalUuid}`, 'debug');
      });
      if (event.data === 'card_change_success' && !eventDataLoaded) {
        setEventDataLoaded(true);
        captureMessage(`Successful card change ${externalUuid}`, 'debug');
        closeModal();
      }
    },
    [closeModal, eventDataLoaded, externalUuid],
  );

  useEffect(() => {
    window.addEventListener('message', handleMessageEvent);

    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, [handleMessageEvent]);

  if (data) {
    return <CardIframeContainer url={data.redirect_url} />;
  }

  return null;
};
