import styled from 'styled-components';
import { RawStatus } from '@appTypes';
import { StatusPillClasses } from '@utils/constants';
import { getPillCategory } from './getPillCategory';

type StatusPillClass =
  (typeof StatusPillClasses)[keyof typeof StatusPillClasses];

interface IPill {
  category: StatusPillClass;
  isAlert: boolean;
}

const Pill = styled.span.attrs<IPill>(({ category, isAlert }) => ({
  className: `label label-lg label-light-${category} label-inline`,
  ...(isAlert ? { role: 'alert' } : {}),
}))<IPill>`
  white-space: nowrap;
  .card-header &[role='alert'] {
    font-size: 1.5rem;
    padding: 1.25rem 1rem;
  }
`;

interface IStatusPill {
  isAlert?: boolean;
  rawStatus?: RawStatus;
  status?: string;
}

export const StatusPill = ({
  isAlert = false,
  rawStatus = 'unknown',
  status = '',
}: IStatusPill) => {
  const category = getPillCategory(rawStatus);

  return (
    <Pill category={category} isAlert={isAlert}>
      {status.toUpperCase()}
    </Pill>
  );
};
