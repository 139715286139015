import { IQueryState } from './types';

interface INormaliseQueryUpdateStateProps {
  newQueryState: object;
  queryState: IQueryState;
}

export const normaliseQueryUpdateState = ({
  newQueryState,
  queryState,
}: INormaliseQueryUpdateStateProps) => {
  // React Table sometimes returns state as an updater function, sometimes as
  // a state object. It seems to have to do with pagination, but it's not clear.
  // In any case, we need this to normalise the state: if it's an updater, call it.
  // Also, in React Table, pageSize is typed as a number, but for us it's a string,
  // so we need to convert it.
  if (typeof newQueryState === 'function') {
    const resolvedState = newQueryState(queryState);

    return {
      ...resolvedState,
      pagination: {
        ...resolvedState.pagination,
        pageIndex: resolvedState.pagination.pageIndex,
        pageSize: resolvedState.pagination.pageSize,
      },
    };
  }

  return newQueryState;
};
