import KTCard from '@metronic/_assets/js/components/card';
import { KTUtil } from '@metronic/_assets/js/components/util';
import KTLayoutHeader from './header';

const KTLayoutStickyCard = (function ktLayoutStickyCard() {
  // Private properties
  let element;
  let object;

  // Private functions
  const init = function init() {
    let offset = 300;

    if (typeof KTLayoutHeader !== 'undefined') {
      offset = KTLayoutHeader.getHeight();
    }

    object = new KTCard(element, {
      sticky: {
        offset,
        zIndex: 90,
        position: {
          top() {
            let pos = 0;

            if (KTUtil.isBreakpointUp('lg')) {
              if (
                typeof KTLayoutHeader !== 'undefined' &&
                KTLayoutHeader.isFixed()
              ) {
                pos += KTLayoutHeader.getHeight();
              }
            } else if (
              typeof KTLayoutHeader !== 'undefined' &&
              KTLayoutHeader.isFixedForMobile()
            ) {
              pos += KTLayoutHeader.getHeightForMobile();
            }

            pos -= 1; // remove header border width

            return pos;
          },
          left() {
            return KTUtil.offset(element).left;
          },
          right() {
            const body = KTUtil.getBody();

            const cardWidth = parseInt(KTUtil.css(element, 'width'), 10);
            const bodyWidth = parseInt(KTUtil.css(body, 'width'), 10);
            const cardOffsetLeft = KTUtil.offset(element).left;

            return bodyWidth - cardWidth - cardOffsetLeft;
          },
        },
      },
    });

    object.initSticky();

    KTUtil.addResizeHandler(() => {
      object.updateSticky();
    });
  };

  // Public methods
  return {
    init(id) {
      element = KTUtil.getById(id);

      if (!element) {
        return;
      }

      // Initialize
      init();
    },

    update() {
      if (object) {
        object.updateSticky();
      }
    },
  };
})();

export default KTLayoutStickyCard;
