import { Navigate, useParams } from 'react-router-dom';

interface IAuthRedirect {
  country: string;
}

export const AuthRedirect = ({ country }: IAuthRedirect) => {
  const params = useParams();
  const paramValues = Object.values(params);
  const path = `/HeyLight/${country}/auth/${paramValues.join('/')}`;
  return <Navigate replace={true} to={path} />;
};
