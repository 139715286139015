import { createColumnHelper } from '@tanstack/react-table';
import { ReactTableColumns } from '@appTypes';
import { NoWrap } from '@components/Common.styles';
import { Translate } from '@hooks/useI18n';
import { PendingAllocation } from './PendingAllocation';
import { IPendingAllocationTableColumns } from './types';

export const getPendingAllocationsColumns = (
  canReconcileBankAccount: boolean,
  translate: Translate,
  queryKey: string[],
): ReactTableColumns<IPendingAllocationTableColumns> => {
  const columnHelper = createColumnHelper<IPendingAllocationTableColumns>();

  const allocateTransactionColumn = columnHelper.display({
    cell: ({ row }) => (
      <PendingAllocation
        allocationUuid={row.original.uuid}
        queryKey={queryKey}
      />
    ),
    id: 'ctaColumn',
  });

  const allColumns = [
    columnHelper.accessor('created', {
      header: translate('OPS_PORTAL.TITLE.ALLOCATED_ON'),
    }),
    columnHelper.accessor('statementLineDate', {
      header: translate('OPS_PORTAL.TITLE.RECEIVED_ON'),
    }),
    columnHelper.accessor('paymentPlanReference', {
      header: translate('OPS_PORTAL.LABEL.PAYMENT_PLAN_REFERENCE'),
    }),
    columnHelper.accessor('amount', {
      header: translate('LABEL.AMOUNT'),
      cell: ({ row }) => <NoWrap>{row.original.amount}</NoWrap>,
    }),
    columnHelper.accessor('statementLineReference', {
      header: translate('OPS_PORTAL.LABEL.REFERENCE'),
    }),
    columnHelper.accessor('allocationType', {
      header: translate('OPS_PORTAL.LABEL.ALLOCATION_DETAILS'),
    }),
  ];

  if (canReconcileBankAccount) {
    return [...allColumns, allocateTransactionColumn];
  }

  return allColumns;
};
