import { Card } from '@components/Card';
import { NoDataAvailable } from '@components/NoDataAvailable';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { useI18n } from '@hooks/useI18n/useI18n';
import { RisksCardContent } from './RisksCardContent';
import { IRiskTestsBreakdownProps } from './types';

export const RiskTestsBreakdown = ({
  data,
  error,
  isLoading,
}: IRiskTestsBreakdownProps) => {
  const { translate } = useI18n();

  return (
    <Card title={translate('OPS_PORTAL.TITLE.RISK_TESTS_BREAKDOWN')}>
      <QueryStateRouter error={error} isLoading={isLoading}>
        {data ? <RisksCardContent data={data} /> : <NoDataAvailable />}
      </QueryStateRouter>
    </Card>
  );
};
