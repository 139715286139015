import { Row, RowData } from '@tanstack/react-table';
import { extractFloatFromString } from '@utils/extractFloatFromString';
import { getValueFromRowByKey } from './getValueFromRowByKey';
import { sort } from './sort';

// This is a custom sort function to be used with react-table headers.
export const sortCurrency = <TData extends RowData>(
  rowA: Row<TData>,
  rowB: Row<TData>,
  key: keyof TData,
) => {
  const valA = extractFloatFromString(getValueFromRowByKey({ row: rowA, key }));
  const valB = extractFloatFromString(getValueFromRowByKey({ row: rowB, key }));

  return sort(valA, valB);
};
