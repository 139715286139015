import { useMemo } from 'react';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { ITabData } from '@components/Tabs/types';
import { useI18n } from '@hooks/useI18n/useI18n';
import { usePortalError } from '@hooks/usePortalError';
import { useQueryOnClick } from '@hooks/useQueryOnClick';
import { QueryKeys, TabEventKeys, TableNames } from '@utils/constants';
import { downloadFile } from '@utils/downloadFile';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { OpsPortalDocumentTypes } from './enums';
import { getDocumentsTableColumns } from './getDocumentTableColumns';
import { mapDocumentsTabTableData } from './mapDocumentsTabTableData';
import { IDocumentsTableColumn } from './types';
import { useGetContractDocumentsQuery } from './useGetContractDocumentsQuery';

interface IUseContractDocumentsTab {
  contractUuid: string;
  externalContractUUID?: string;
}

export const useContractDocumentsTab = ({
  contractUuid,
}: IUseContractDocumentsTab): ITabData => {
  const { getLocaleDate, translate } = useI18n();
  const { handleAxiosError } = usePortalError();

  const { data, error, isLoading } = useGetContractDocumentsQuery({
    contractUuid,
  });

  const useQueryCallbacks = useMemo(
    () => ({
      onSuccess: (url: string) => downloadFile(url),
      onError: (getDocumentsError: AxiosError<IError>) =>
        handleAxiosError(getDocumentsError),
    }),
    [handleAxiosError],
  );

  const { execute: executeOnfido } = useQueryOnClick<
    string,
    IDocumentsTableColumn
  >(
    [QueryKeys.DOCUMENT_ONFIDO],
    requestObj =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchContractDocumentOnfido}/${requestObj.uuid}`,
        {},
      ),
    useQueryCallbacks,
  );

  const { execute: executeIntrum } = useQueryOnClick<
    string,
    IDocumentsTableColumn
  >(
    [QueryKeys.DOCUMENT_INTRUM],
    requestObj =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchContractDocumentIntrum}/${requestObj.uuid}`,
        {},
      ),
    useQueryCallbacks,
  );

  const { execute: executeCustomerAgreement } = useQueryOnClick<
    string,
    IDocumentsTableColumn
  >(
    [QueryKeys.DOCUMENT_CUSTOMER_AGREEMENT],
    requestObj =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchContractDocumentCustomerAgremeent}/${requestObj.uuid}`,
        {},
      ),
    useQueryCallbacks,
  );

  const { execute: executeTermsAndConditions } = useQueryOnClick<
    string,
    IDocumentsTableColumn
  >(
    [QueryKeys.DOCUMENT_TERMS_AND_CONDITIONS],
    requestObj =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchContractDocumentTermsAndConditions}/${requestObj.uuid}`,
        {},
      ),
    useQueryCallbacks,
  );

  const contractDocumentsColumns = useMemo(
    () =>
      getDocumentsTableColumns({
        translate,
        setSelectedRow: newData => {
          const documentTypeExecutors = {
            [OpsPortalDocumentTypes.OnfidoFront]: executeOnfido,
            [OpsPortalDocumentTypes.OnfidoBack]: executeOnfido,
            [OpsPortalDocumentTypes.OnfidoSelfie]: executeOnfido,
            [OpsPortalDocumentTypes.OnfidoHealthCard]: executeOnfido,
            [OpsPortalDocumentTypes.IntrumIdentificationFiles]: executeIntrum,
            [OpsPortalDocumentTypes.IntrumSigningFiles]: executeIntrum,
            [OpsPortalDocumentTypes.CustomerAgreement]:
              executeCustomerAgreement,
            [OpsPortalDocumentTypes.TradeInOptionAgreement]:
              executeCustomerAgreement,
            [OpsPortalDocumentTypes.TermsAndConditionsFinalization]:
              executeTermsAndConditions,
            [OpsPortalDocumentTypes.TermsAndConditionsConclusion]:
              executeTermsAndConditions,
          };

          documentTypeExecutors[newData.documentType](newData);
        },
      }),
    [
      executeCustomerAgreement,
      executeIntrum,
      executeOnfido,
      executeTermsAndConditions,
      translate,
    ],
  );

  const contractDocumentsData = mapDocumentsTabTableData({
    data,
    getLocaleDate,
    translate,
  });

  return {
    columns: contractDocumentsColumns,
    data: contractDocumentsData,
    eventKey: TabEventKeys.CONTRACT_DOCUMENTS,
    error,
    isLoading,
    tableName: TableNames.DOCUMENTS,
    title: translate('OPS_PORTAL.TITLE.CONTRACT_DOCUMENTS'),
  };
};
