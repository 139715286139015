import { KTUtil } from '@metronic/_assets/js/components/util';
import KTLayoutFooter from './footer';
import KTLayoutHeader from './header';

const KTLayoutContent = (function ktLayoutContent() {
  // Private properties
  let element;

  // Private functions
  const getHeight = function getHeight() {
    let height;

    height = KTUtil.getViewPort().height;

    if (element) {
      height =
        height -
        parseInt(KTUtil.css(element, 'paddingTop'), 10) -
        parseInt(KTUtil.css(element, 'paddingBottom'), 10);
    }

    height -= KTLayoutHeader.getHeight();
    height -= KTLayoutFooter.getHeight();

    return height;
  };

  // Public methods
  return {
    init(id) {
      element = KTUtil.getById(id);
    },

    getHeight() {
      return getHeight();
    },

    getElement() {
      return element;
    },
  };
})();

export default KTLayoutContent;
