// The algorithms followed here are described at
// https://math.stackexchange.com/questions/1918217/the-mod97-operation-in-iban-validation.

const mod97 = (str: string): number | string => {
  const first9 = str.substring(0, 9);
  const remainingStr = str.substring(9);
  const remainder = Number(first9) % 97;
  const newString = remainder.toString() + remainingStr;

  if (newString.length > 2) {
    return mod97(newString);
  }

  return remainder;
};

export const validateIBAN = (iban: string) => {
  const reorderedString = iban.substring(4) + iban.substring(0, 4);
  const replacedString = reorderedString.replaceAll(/[a-z]/gi, match =>
    (match.toUpperCase().charCodeAt(0) - 55).toString(),
  );

  return mod97(replacedString) === 1;
};
