import { useEffect, useState } from 'react';
import { SortDirection } from '@utils/constants';
import { SortButton } from './SortButton';

export const ClientsideHeader = ({ flexRender, header }) => {
  const [sortState, setSortState] = useState(null);

  const sort = header.column.getIsSorted();

  useEffect(() => {
    setSortState(SortDirection[sort] || null);
  }, [sort]);

  return (
    <th aria-sort={sortState} key={header.id}>
      <SortButton
        sort={sortState}
        toggleSort={header.column.getToggleSortingHandler()}
      >
        {flexRender(header.column.columnDef.header, header.getContext())}
      </SortButton>
    </th>
  );
};
