import { ValueOf } from '@appTypes';
import { GetLocaleDate, Translate } from '@hooks/useI18n';
import { ISettlement } from '@schemas/opsPortal/types/monetaContract';
import { PaymentTypes } from '@utils/constants';
import { AdjustmentFormFields } from './AdjustmentFormFields';
import { ManualPaymentFormFields } from './ManualPaymentFormFields';
import { PrepaymentFormFields } from './PrepaymentFormFields';
import { RefundFormFields } from './RefundFormFields';
import { ReimbursementFormFields } from './ReimbursementFormFields';

interface IFormFieldsProps {
  contractSettlements?: ISettlement[];
  currency: string;
  getLocaleDate: GetLocaleDate;
  handleSettlementChanged: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  isFullRefund: boolean;
  paymentType: ValueOf<typeof PaymentTypes>;
  toggleRefundAmount: React.ChangeEventHandler<HTMLInputElement>;
  translate: Translate;
}

const formFieldComponents = {
  [PaymentTypes.ADJUSTMENTS]: AdjustmentFormFields,
  [PaymentTypes.MANUAL_PAYMENT]: ManualPaymentFormFields,
  [PaymentTypes.PREPAYMENT]: PrepaymentFormFields,
  [PaymentTypes.REFUND]: RefundFormFields,
  [PaymentTypes.REIMBURSEMENT]: ReimbursementFormFields,
};

export const FormFields = (props: IFormFieldsProps) => {
  const { paymentType } = props;
  const FormComponent = formFieldComponents[paymentType];

  return <FormComponent {...props} />;
};
