import { createColumnHelper } from '@tanstack/react-table';
import { Translate } from '@hooks/useI18n';
import { IMonetaTransactionsTableColumns } from './types';

export const getMonetaTransactionsColumns = (translate: Translate) => {
  const columnHelper = createColumnHelper<IMonetaTransactionsTableColumns>();

  return [
    columnHelper.accessor('monetaTransactionDate', {
      header: translate('LABEL.DATE'),
    }),
    columnHelper.accessor('monetaTransactionUuid', {
      header: translate('OPS_PORTAL.LABEL.TRANSACTION_UUID'),
    }),
    columnHelper.accessor('monetaTransactionType', {
      header: translate('TITLE.TYPE'),
    }),
    columnHelper.accessor('monetaTransactionAmount', {
      header: translate('LABEL.AMOUNT'),
    }),
  ];
};
