import { createColumnHelper } from '@tanstack/react-table';
import { ICardCTA, ReactTableColumns } from '@appTypes';
import { Translate } from '@hooks/useI18n';
import { MerchantStoreButton } from './MerchantStoreButton';
import { IMerchantStoreColumn } from './types';

interface IGetColumnsProps {
  ctaProps?: ICardCTA;
  setStoreUuid: (storeUuid: string) => void;
  translate: Translate;
}

export const getColumns = ({
  ctaProps,
  setStoreUuid,
  translate,
}: IGetColumnsProps): ReactTableColumns<IMerchantStoreColumn> => {
  const columnHelper = createColumnHelper<IMerchantStoreColumn>();

  return [
    columnHelper.accessor('storeName', {
      header: translate('TITLE.STORE_NAME'),
    }),
    columnHelper.accessor('storeNumber', {
      header: translate('TITLE.STORE_NUMBER'),
    }),
    columnHelper.display({
      id: 'ctaColumn',
      cell: ({ row }) => (
        <>
          {ctaProps ? (
            <MerchantStoreButton
              ctaProps={ctaProps}
              setStoreUuid={setStoreUuid}
              uuid={row.original.uuid}
            />
          ) : null}
        </>
      ),
    }),
  ];
};
