import { SortButton } from './SortButton';

export const ServersideHeader = ({ flexRender, header, tableState }) => {
  const colSort = tableState.sorting.find(col => col.id === header.id);
  const sortDirection = colSort?.desc ? 'descending' : 'ascending';
  const columnSortState = colSort ? sortDirection : null;

  return (
    <th aria-sort={columnSortState} key={header.id}>
      <SortButton
        sort={columnSortState}
        toggleSort={header.column.getToggleSortingHandler()}
      >
        {flexRender(header.column.columnDef.header, header.getContext())}
      </SortButton>
    </th>
  );
};
