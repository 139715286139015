import { Fragment, useEffect, useState } from 'react';
import { Dd, Dl, Dt } from '@components/Common.styles';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IMerchantSettingObservationViewConfigSetting } from '@schemas/merchantPortal/types/accountSettings';
import { IMerchantPortalTracking } from '@schemas/merchantPortal/types/tracking';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { missingValuePlaceholder } from '@utils/constants';
import { CheckoutLinkSentStatus } from './CheckoutLinkSentStatus';
import { ManualSigningSummary } from './ManualSigningSummary';
import {
  defaultSummaryFields,
  fieldTransform,
} from './transforms/fieldTransform';
import { IOrderSummary, KnownTransforms } from './types';

interface ITrackingSummary {
  config?: IMerchantSettingObservationViewConfigSetting;
  data: IMerchantPortalTracking;
}

export const TrackingSummary = ({ config, data }: ITrackingSummary) => {
  const { getLocaleCurrencyValue, translate } = useI18n();
  const [summaryValues, setSummaryValues] = useState<IOrderSummary[]>();

  useEffect(() => {
    const rowData = config?.summaryRows?.length
      ? config.summaryRows
      : defaultSummaryFields;

    const summaryRows = rowData.flatMap(({ key, options, value }) => {
      const val = fieldTransform(
        value as KnownTransforms,
        data,
        { getLocaleCurrencyValue },
        translate,
      );

      // hide blank values if options specify to
      if (
        options?.hideIfEmpty &&
        [missingValuePlaceholder, '', null, undefined].includes(val as string)
      ) {
        return [];
      }

      return addPlaceholderForEmptyValues<IOrderSummary>({
        label: key,
        value: val,
      });
    });

    setSummaryValues(summaryRows);
  }, [config?.summaryRows, data, getLocaleCurrencyValue, translate]);

  return (
    <Dl>
      {summaryValues?.map(({ label, value }) => (
        <Fragment key={label}>
          <Dt>{translate(label)}</Dt>
          <Dd>{value}</Dd>
        </Fragment>
      ))}
      <ManualSigningSummary
        manualAgreementSigningOverview={data.manualAgreementSigningOverview}
        translate={translate}
      />
      <CheckoutLinkSentStatus
        checkoutSmsLinkSent={data.checkoutSmsLinkSent}
        deliveryChannel={data.deliveryChannel}
        translate={translate}
      />
    </Dl>
  );
};
