import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import MUITooltip, {
  TooltipProps,
  tooltipClasses,
} from '@mui/material/Tooltip';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ITooltipContent } from './types.d';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.tooltip.light,
    boxShadow: '0px 0px 30px 0px rgba(82, 63, 105, 0.05)',
    color: theme.palette.tooltip.main,
    fontSize: theme.typography.pxToRem(theme.typography.htmlFontSize),
    border: '1px solid #e6e7e8',
  },
}));

export interface ITip {
  tip: ITooltipContent;
}

export const Tooltip = ({ tip }: ITip) => {
  const { translate } = useI18n();

  return (
    <StyledTooltip placement="right" title={tip.content}>
      <IconButton
        aria-label={translate('LABEL.TRIGGER_FOR_X_TOOLTIP', {
          label: tip.label,
        })}
      >
        <InfoIcon />
      </IconButton>
    </StyledTooltip>
  );
};
