import { DateRangePicker } from '@components/DateRangePicker/DateRangePicker.js';
import { Dropdown } from '@components/Dropdown/Dropdown.tsx';
import { TextField } from '@components/TextField/TextField.tsx';
import { FilterTypes } from '@utils/constants';

export const getFilterUI = filter => {
  if (filter.type === FilterTypes.SEARCH) {
    return (
      <TextField
        id={filter.id}
        label={filter.label}
        name={filter.id}
        type="search"
      />
    );
  }
  if (filter.type === FilterTypes.DATE_RANGE) {
    return (
      <DateRangePicker
        endId={filter.to.id}
        endLabel={filter.to.label}
        startId={filter.from.id}
        startLabel={filter.from.label}
      />
    );
  }
  if (
    [FilterTypes.DROPDOWN, FilterTypes.PRESET_DATE_RANGE].includes(filter.type)
  ) {
    return (
      <Dropdown
        id={filter.id}
        label={filter.label}
        name={filter.id}
        options={filter.options}
      />
    );
  }
  return null;
};
