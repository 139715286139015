import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useOriginator } from '@hooks/useOriginator';
import { originatorActions } from '@redux/originatorRedux';
import {
  AuthPageOuterWrapper,
  AuthPageInnerWrapper,
  Content,
  ContentOuterWrapper,
} from './Auth.styles';
import { AuthRoutes } from './AuthRoutes';
import { MobileFooter, SidePanel } from './components';
import '@metronic/_assets/sass/pages/login/classic/login-1.scss';

export const AuthPage = () => {
  const dispatch = useDispatch();
  const { originatorFromPath, pathnamePrefix, setOriginator } = useOriginator();

  useEffect(() => {
    // This ensures that, if originator was got from
    // pathname instead of state, the state is updated
    // before the path is changed so that redirection
    // to auth pages doesn't break.
    dispatch(originatorActions.setPathnamePrefix(pathnamePrefix));
    setOriginator(originatorFromPath);
  }, [dispatch, originatorFromPath, pathnamePrefix, setOriginator]);

  return (
    <AuthPageOuterWrapper className="heyLight">
      <AuthPageInnerWrapper>
        <SidePanel />
        <ContentOuterWrapper>
          <Content>
            <AuthRoutes />
          </Content>
          <MobileFooter />
        </ContentOuterWrapper>
      </AuthPageInnerWrapper>
    </AuthPageOuterWrapper>
  );
};
