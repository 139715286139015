import KTMenu from '@metronic/_assets/js/components/menu';
import { KTUtil } from '@metronic/_assets/js/components/util';
import KTLayoutAside from './aside';
import KTLayoutBrand from './brand';

const KTLayoutAsideMenu = (function ktLayoutAsideMenu() {
  // Private properties
  let body;
  let element;
  let menuObject;

  // Initialize
  const init = function init() {
    const menuDesktopMode =
      KTUtil.attr(element, 'data-menu-dropdown') === '1'
        ? 'dropdown'
        : 'accordion';
    let scroll;

    if (KTUtil.attr(element, 'data-menu-scroll') === '1') {
      scroll = {
        rememberPosition: true, // remember position on page reload
        height() {
          // calculate available scrollable area height
          let height = parseInt(KTUtil.getViewPort().height, 10);

          if (KTUtil.isBreakpointUp('lg')) {
            height -= KTLayoutBrand.getHeight();
          }

          height -=
            parseInt(KTUtil.css(element, 'marginBottom'), 10) +
            parseInt(KTUtil.css(element, 'marginTop'), 10);

          return height;
        },
      };
    }

    menuObject = new KTMenu(element, {
      // Vertical scroll
      scroll,

      // Submenu setup
      submenu: {
        desktop: menuDesktopMode,
        tablet: 'accordion', // menu set to accordion in tablet mode
        mobile: 'accordion', // menu set to accordion in mobile mode
      },

      // Accordion setup
      accordion: {
        expandAll: false, // allow having multiple expanded accordions in the menu
      },
    });
  };

  const initHover = function initHover() {
    // Handle Minimized Aside Hover
    if (
      KTUtil.hasClass(body, 'aside-fixed') &&
      KTUtil.hasClass(body, 'aside-minimize-hoverable')
    ) {
      let insideTm;
      let outsideTm;

      // Handle Aside Hover Mode
      KTUtil.addEvent(element, 'mouseenter', function onMousenter(e) {
        e.preventDefault();

        if (KTUtil.isBreakpointUp('lg') === false) {
          return;
        }

        if (outsideTm) {
          clearTimeout(outsideTm);
          outsideTm = null;
        }

        if (insideTm) {
          clearTimeout(insideTm);
          insideTm = null;
        }

        insideTm = setTimeout(function innerTimeout() {
          if (
            KTUtil.hasClass(body, 'aside-minimize') &&
            KTUtil.isBreakpointUp('lg')
          ) {
            // Hover class
            KTUtil.addClass(body, 'aside-minimize-hover');

            KTLayoutAsideMenu.getMenu().scrollUpdate();
            KTLayoutAsideMenu.getMenu().scrollTop();
          }
        }, 50);
      });

      KTUtil.addEvent(
        KTLayoutAside.getElement(),
        'mouseleave',
        function onMouseLeave(e) {
          e.preventDefault();

          if (KTUtil.isBreakpointUp('lg') === false) {
            return;
          }

          if (insideTm) {
            clearTimeout(insideTm);
            insideTm = null;
          }

          if (outsideTm) {
            clearTimeout(outsideTm);
            outsideTm = null;
          }

          outsideTm = setTimeout(function outerTimeout() {
            if (
              KTUtil.hasClass(body, 'aside-minimize-hover') &&
              KTUtil.isBreakpointUp('lg')
            ) {
              KTUtil.removeClass(body, 'aside-minimize-hover');

              // Hover class
              KTLayoutAsideMenu.getMenu().scrollUpdate();
              KTLayoutAsideMenu.getMenu().scrollTop();
            }
          }, 100);
        },
      );
    }
  };

  // Public methods
  return {
    init(id) {
      body = KTUtil.getBody();
      element = KTUtil.getById(id);

      if (!element) {
        return;
      }

      // Initialize menu
      init();
      initHover();
    },

    getElement() {
      return element;
    },

    getMenu() {
      return menuObject;
    },

    pauseDropdownHover(time) {
      if (menuObject) {
        menuObject.pauseDropdownHover(time);
      }
    },

    closeMobileOffcanvas() {
      if (menuObject && KTUtil.isMobileDevice()) {
        menuObject.hide();
      }
    },
  };
})();

export default KTLayoutAsideMenu;
