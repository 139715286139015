import { Formik, Form } from 'formik';
import { TextField } from '@components/TextField/TextField.tsx';
import { useI18n } from '@hooks/useI18n/useI18n.tsx';
import { AccountSettingsFormNames } from '@utils/constants';
import { getValidationSchema } from './getValidationSchema';

export const EditPhoneForm = ({ data, formID, handleSubmit }) => {
  const { selectedLanguage, translate } = useI18n();

  const { phone_number: phoneNumber } = data;

  // I realise this looks hideous, but it is a necessary evil, as
  // default values in destructuring only replace undefined, not null.
  const initialFormikValues = {
    phone_number: phoneNumber ?? '',
    language: selectedLanguage,
  };

  const form = AccountSettingsFormNames.PHONE;

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema({ form, translate })(
        initialFormikValues,
      )}
    >
      <Form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
      >
        <TextField
          id="phone_number"
          label={translate('LABEL.PHONE_NUMBER')}
          name="phone_number"
        />
      </Form>
    </Formik>
  );
};
