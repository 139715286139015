import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { usePortalError } from '@hooks/usePortalError';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';

interface IUseAddPDRQuery {
  closeModal?: () => void;
  contractDetailsQueryKey: QueryKey;
  contractUuid?: string;
}

export const useAddPDRQuery = ({
  closeModal,
  contractDetailsQueryKey,
  contractUuid,
}: IUseAddPDRQuery) => {
  const { translate } = useI18n();
  const { message } = useToast(translate);
  const { handleAxiosError } = usePortalError();
  const queryClient = useQueryClient();

  const { isLoading: isAddingPDR, mutate: addPDR } = useMutation<
    unknown,
    AxiosError<IError>
  >(
    requestData =>
      postDataToEndpoint({
        endpoint: `${Endpoints.SetAcknowledgementOfDebt}/${contractUuid}`,
        requestData,
      }),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(contractDetailsQueryKey);
        queryClient.invalidateQueries([
          QueryKeys.ACKNOWLEDGEMENT_OF_DEBT,
          contractUuid,
        ]);
        message.success('OPS_PORTAL.STATUS.SUCCESSFULLY_ADDED_PDR');
        closeModal?.();
      },
      onError: error => {
        handleAxiosError(error, 'OPS_PORTAL.STATUS.UNABLE_TO_ADD_PDR');
      },
    },
  );

  return {
    isAddingPDR,
    addPDR,
  };
};
