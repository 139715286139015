import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { Permissions, ValueOf } from '@appTypes';
import { TranslateKey } from '@hooks/useI18n';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { usePortal } from '@hooks/usePortal';
import { IOrderDetail as IConsumerOrderDetails } from '@schemas/consumerPortal/types/ordersDetails';
import { IOrderDetail as IMerchantOrderDetails } from '@schemas/merchantPortal/types/orderDetails';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { PaymentTypes, Portals } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { getErrorMessage } from '@utils/getErrorMessage';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { usePaymentModal } from '../usePaymentModal';

interface IOrderPaymentModalProps extends ISharedModalProps {
  data?: IConsumerOrderDetails | IMerchantOrderDetails;
  monetaData?: IOpsPortalMonetaContract;
  paymentType: ValueOf<typeof PaymentTypes>;
  queryKey: QueryKey;
  uuid: string;
}

interface IPaymentConfig {
  buttonTranslationKey: TranslateKey;
  errorMessage: TranslateKey;
  modalName: string;
  paymentEndpoint: Endpoints;
  permissions: Permissions;
  submitTextKey: TranslateKey;
  successMessage: TranslateKey;
  titleKey: TranslateKey;
}

const paymentConfig: Record<string, IPaymentConfig> = {
  [Portals.CONSUMER]: {
    paymentEndpoint: Endpoints.PrePayOrder,
    errorMessage: 'LABEL.UNABLE_TO_PROCESS_PREPAYMENT',
    successMessage: 'NOTIFICATION.PREPAYMENT_SUCCESSFULLY_ISSUED',
    buttonTranslationKey: 'BUTTON.PREPAY',
    modalName: 'orderPrepaymentModal',
    permissions: ['consumer_portal.execute_prepayment'],
    titleKey: 'TITLE.PREPAYMENT',
    submitTextKey: 'BUTTON.PREPAY_NOW',
  },
  [Portals.MERCHANT]: {
    paymentEndpoint: Endpoints.RefundOrder,
    errorMessage: 'PAGE.ORDERS.UNABLE_TO_PROCESS_REFUND',
    successMessage: 'NOTIFICATION.REFUND_SUCCESSFULLY_ISSUED',
    buttonTranslationKey: 'BUTTON.REFUND',
    modalName: 'orderRefundModal',
    permissions: ['merchant_portal.can_refund_payment_plans'],
    titleKey: 'BUTTON.REFUND',
    submitTextKey: 'BUTTON.REFUND_NOW',
  },
};

export const useOrderPaymentModal = (props: IOrderPaymentModalProps): Modal => {
  const { data, monetaData, paymentType, queryKey, uuid } = props;
  const { translate } = useI18n();
  const { portal } = usePortal();
  const queryClient = useQueryClient();

  const {
    buttonTranslationKey,
    errorMessage,
    modalName,
    paymentEndpoint,
    permissions,
    submitTextKey,
    successMessage,
    titleKey,
  } = paymentConfig[portal as keyof typeof paymentConfig];

  const modal = useModal({
    sharedProps: props,
    buttonTranslationKey,
    modalName,
    permissions,
  });
  const { resolveForm } = modal;

  const { isLoading, mutate } = useMutation(
    requestData =>
      postDataToEndpoint({
        endpoint: paymentEndpoint,
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey, uuid]);
        resolveForm(translate(successMessage));
      },
      onError: (error: AxiosError) => {
        resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey: errorMessage,
            translate,
          }),
        );
      },
    },
  );

  const handleSubmit = useCallback(
    values => {
      const { amount, max } = values;
      const adjustedValues = {
        ...values,
        ...(amount ? { amount } : { amount: max }),
      };
      delete adjustedValues.max;
      mutate(adjustedValues);
    },
    [mutate],
  );

  const submitText = translate(submitTextKey);

  const title = translate(titleKey);

  const modalProps = usePaymentModal({
    ...modal,
    data,
    handleSubmit,
    isPaymentLoading: isLoading,
    monetaData,
    paymentType,
    submitText,
    title,
  });

  return getModalDetails({ modal, modalProps });
};
