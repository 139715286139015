import { supportedLanguages } from '@utils/constants';
import { SupportedLanguage } from '../types';

interface IGetInitialLanguageAndLocale {
  initialLanguage: SupportedLanguage;
  locale: string;
}

const re = /^[a-z]{2,3}-?[a-z]{0,3}$/i;

const parseLanguage = (lang: string): IGetInitialLanguageAndLocale | null => {
  if (!re.test(lang)) {
    return null;
  }

  const parts = lang.split('-');
  const [initialLanguage, locale] = parts;

  return {
    initialLanguage: initialLanguage as SupportedLanguage,
    locale: locale ?? '',
  };
};

export const getInitialLanguageAndLocale = (
  browserLanguages: readonly string[],
): IGetInitialLanguageAndLocale => {
  const defaultLanguageAndLocale: IGetInitialLanguageAndLocale = {
    initialLanguage: 'en',
    locale: '',
  };

  const selectedLanguageAndLocale = browserLanguages
    .map(parseLanguage)
    .find(
      parsed => parsed && supportedLanguages.includes(parsed.initialLanguage),
    );

  return selectedLanguageAndLocale ?? defaultLanguageAndLocale;
};
