import { useEffect, useMemo, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Card } from '@components/Card';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ServersideTable } from '@components/Table/ServersideTable';
import { ITableRefObject } from '@components/Table/types';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useAllocationDetailsModal } from '@hooks/useModal/hooks/useAllocationDetailsModal';
import { useQueryState } from '@hooks/useQueryState';
import { IOpsPortalBankAccountReconciliations } from '@schemas/opsPortal/types/bankAccountReconciliations';
import { QueryKeys, TableNames } from '@utils/constants';
import {
  BankAccountReconciliationView,
  ButtonVariant,
  Endpoints,
} from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { getCursorPaginationCallbacks } from '@utils/getCursorPaginationCallbacks';
import { BankAccountReconciliationsTable } from './bankAccountReconciliationsStyles';
import { getBankAccountReconciliationsColumns } from './getBankAccountReconciliationsColumns';
import { mapBankAccountReconciliationsTableData } from './mapBankAccountReconciliationsTableData';
import { IBankAccountReconciliationTableColumns, IViewConfig } from './types';

interface IBankAccountReconciliations {
  view: BankAccountReconciliationView;
}

const viewConfig: Record<BankAccountReconciliationView, IViewConfig> = {
  [BankAccountReconciliationView.BankAccountReconciliations]: {
    title: 'OPS_PORTAL.TITLE.BANK_ACCOUNT_RECONCILIATIONS',
    queryParam: {},
    tableName: TableNames.BANK_ACCOUNT_RECONCILIATIONS,
  },
  [BankAccountReconciliationView.Suspense]: {
    title: 'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.SUSPENSE',
    queryParam: { allocationType: 'SUSPENSE' },
    tableName: TableNames.SUSPENSE,
  },
};

export const BankAccountReconciliations = ({
  view,
}: IBankAccountReconciliations) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();
  const canReconcileBankAccount = useHasPermission([
    'ops_portal.can_reconcile_bank_account',
  ]);

  const refToDataTable =
    useRef<ITableRefObject<IBankAccountReconciliationTableColumns>>(null);

  const { queryParam, tableName, title } = viewConfig[view];

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(tableName);

  const {
    data: contractsData,
    error: contractsError,
    isLoading: isContractsLoading,
  } = useQuery<IOpsPortalBankAccountReconciliations, AxiosError>(
    [QueryKeys.BANK_ACCOUNT_TRANSACTIONS, stringifiedQueryParams, queryParam],
    () =>
      fetchDataFromEndpoint(Endpoints.FetchBankAccountTransactions, {
        query: { ...queryParams, ...queryParam },
      }),
  );

  const [allocationDetails, setAllocationDetails] = useState({});

  const allocationDetailsModal = useAllocationDetailsModal({
    ...allocationDetails,
    bankAccountReconciliationsQueryKey: QueryKeys.BANK_ACCOUNT_TRANSACTIONS,
    variant: [ButtonVariant.Primary, ButtonVariant.Sm],
  });

  const columns = useMemo(
    () =>
      getBankAccountReconciliationsColumns({
        canReconcileBankAccount,
        ctaProps: allocationDetailsModal?.ctaProps,
        setAllocationDetails,
        translate,
      }),
    [allocationDetailsModal?.ctaProps, canReconcileBankAccount, translate],
  );

  const contractsTableData = mapBankAccountReconciliationsTableData({
    data: contractsData?.results,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
  });

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  const filters = getFilters({});

  const { gotoNextPage, gotoPrevPage } = getCursorPaginationCallbacks({
    nextCursor: contractsData?.next_cursor,
    prevCursor: contractsData?.previous_cursor,
    queryState,
    updateQueryParams,
  });

  return (
    <QueryStateRouter error={contractsError} isLoading={isContractsLoading}>
      <Card title={translate(title)}>
        <BankAccountReconciliationsTable>
          <ServersideTable
            columns={columns}
            data={contractsTableData}
            filters={filters}
            isCursorPagination={true}
            manageControlledState={updateQueryParams}
            pagination={{
              gotoNextPage,
              gotoPrevPage,
              pageSize: queryParams.pageSize,
              totalItems: contractsData?.count,
            }}
            ref={refToDataTable}
            resetFilters={resetFilters}
            tableState={queryState}
          />
        </BankAccountReconciliationsTable>
        {allocationDetailsModal?.Modal}
      </Card>
    </QueryStateRouter>
  );
};
