import { useForm, FormProvider } from 'react-hook-form';
import { AxiosError } from 'axios';
import { v4 as getUuid } from 'uuid';
import * as Yup from 'yup';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@components/Button';
import { CardletTitle, MuiButtonSpinner } from '@components/Common.styles';
import { TextFieldRHF } from '@components/TextField';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IMerchantPortalAdjustContractPreview } from '@schemas/merchantPortal/types/adjustContractPreview';
import { ButtonVariant, Endpoints, ValidationErrorKeys } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { AdjustContractValueWarning } from '../AdjustContractValueWarning';
import { Values } from '../types';
import { PaymentPlan } from './components/PaymentPlan';
import { getPaymentPlanFields } from './getPaymentPlanFields';
import { getPaymentPlanScheduleFields } from './getPaymentPlanScheduleFields';

interface IAdjustContractValueFormV2 {
  contractAmount: string;
  contractUuid: string;
  currency: string;
  formID: string;
  handleSubmit: (values: Record<Values, string>) => void;
  resolveForm: (message: string) => void;
}

export const AdjustContractValueFormV2 = ({
  contractAmount,
  contractUuid,
  currency,
  formID,
  handleSubmit,
  resolveForm,
}: IAdjustContractValueFormV2) => {
  const { getLocaleCurrencyValue, translate } = useI18n();

  const {
    data: simulationData,
    isLoading,
    mutate,
  } = useMutation<
    IMerchantPortalAdjustContractPreview,
    AxiosError<IError>,
    string
  >(
    adjustmentAmount =>
      postDataToEndpoint({
        endpoint: `${Endpoints.AdjustContractPreview}/${contractUuid}`,
        requestData: {
          adjustment_amount: { amount: adjustmentAmount, currency },
        },
      }),
    {
      onError: () => {
        resolveForm(
          translate('PAGE.ORDERS.UNABLE_TO_RUN_CONTRACT_ADJUSTMENT_SIMULATION'),
        );
      },
    },
  );

  const validationSchema = Yup.object({
    contract_value: Yup.number()
      .typeError(translate(ValidationErrorKeys.AmountMustBeNumber))
      .required(translate(ValidationErrorKeys.Required))
      .lessThan(
        Number(contractAmount),
        translate(ValidationErrorKeys.AmountCannotExceedOrderValue),
      )
      .min(0, translate(ValidationErrorKeys.MinNum0)),
  });
  const methods = useForm({
    defaultValues: {
      contract_value: '',
      total: contractAmount,
    },
    resolver: yupResolver(validationSchema),
  });

  const handleSubmitAdjustmentSimulation = methods.handleSubmit(data =>
    mutate(data.contract_value),
  );

  const handleSubmitAdjustment = methods.handleSubmit(() => {
    if (simulationData?.total.amount) {
      const formValues: Record<Values, string> = {
        contract_value: simulationData.total.amount,
        currency: simulationData.total.currency,
        intervention_uuid: getUuid(),
      };
      handleSubmit(formValues);
    }
  });

  const schedule = simulationData?.schedule ?? [];
  const scheduleRows = simulationData
    ? getPaymentPlanScheduleFields({
        getLocaleCurrencyValue,
        schedule,
      })
    : [];

  const paymentPlanRows = simulationData
    ? getPaymentPlanFields({
        getLocaleCurrencyValue,
        paymentSimulation: {
          numberOfInstallments: schedule.length,
          payMonthly: simulationData.monthly_payment_amount,
          payNow: simulationData.initial_payment,
          residualAmount: simulationData.residual_amount,
          residualPercentage: simulationData.residual_percentage,
          schedule: simulationData.schedule,
          total: simulationData.total,
        },
        translate,
      })
    : [];

  return (
    <FormProvider {...methods}>
      <form id={formID} onSubmit={handleSubmitAdjustment}></form>
      <TextFieldRHF
        disabled={true}
        id="total"
        label={translate('LABEL.ORIGINAL_AMOUNT_WITH_CURRENCY', { currency })}
        name="total"
      />
      <Grid container={true} mb="12px">
        <Grid item={true} sm={7}>
          <TextFieldRHF
            id="contract_value"
            label={translate('LABEL.REDUCE_AMOUNT_BY', { currency })}
            name="contract_value"
          />
        </Grid>
        <Grid
          alignItems="start"
          display="flex"
          item={true}
          justifyContent="end"
          pt="27px"
          sm={5}
        >
          <Button
            className="no-margin"
            onClick={handleSubmitAdjustmentSimulation}
            type="submit"
            variant={ButtonVariant.Primary}
          >
            {translate('BUTTON.PREVIEW')}
            {isLoading ? <MuiButtonSpinner /> : null}
          </Button>
        </Grid>
      </Grid>
      {simulationData ? (
        <>
          <Divider sx={{ mb: '1.2rem' }} />
          <CardletTitle>
            <b>{translate('TITLE.ORDER_SUMMARY_PREVIEW')}</b>
          </CardletTitle>
          <PaymentPlan rows={paymentPlanRows} scheduleRows={scheduleRows} />
          <Divider sx={{ mb: '1.4rem', mt: '1.2rem' }} />
          <Box mb="-10px" mt="2rem" textAlign="center">
            <p>{translate('PAGE.ORDERS.SUBMIT_CONTRACT_VALUE_ADJUSTMENT')}</p>
            <AdjustContractValueWarning />
          </Box>
        </>
      ) : null}
    </FormProvider>
  );
};
