import { Header, HeaderGroup, Renderable } from '@tanstack/react-table';
import { ClientsideHeader } from './ClientsideHeader';
import { EmptyHeader } from './EmptyHeader';
import { NonSortingHeader } from './NonSortingHeader';
import { ServersideHeader } from './ServersideHeader';

interface ITableHeaderProps<T> {
  flexRender?: <TProps extends object>(
    Comp: Renderable<TProps>,
    props: TProps,
  ) => React.ReactNode | JSX.Element;
  headerGroups: HeaderGroup<T>[];
  tableState?: object;
}

export const TableHeader = <T,>({
  flexRender,
  headerGroups,
  tableState,
}: ITableHeaderProps<T>) => (
  <thead>
    {headerGroups.map(headerGroup => (
      <tr key={headerGroup.id}>
        {headerGroup.headers.map((header: Header<T, unknown>) => {
          // This is for cases where the column holds a CTA or other unsortable non-data.
          if (!header.column.accessorFn) {
            return <EmptyHeader id={header.id} key={header.id} />;
          }

          if (!header.column.getCanSort()) {
            return (
              <NonSortingHeader
                flexRender={flexRender}
                header={header}
                key={header.id}
              />
            );
          }

          if (tableState) {
            return (
              <ServersideHeader
                flexRender={flexRender}
                header={header}
                key={header.id}
                tableState={tableState}
              />
            );
          }

          return (
            <ClientsideHeader
              flexRender={flexRender}
              header={header}
              key={header.id}
            />
          );
        })}
      </tr>
    ))}
  </thead>
);
