import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { Card } from '@components/Card/Card.tsx';
import { CSVLink } from '@components/Common.styles.tsx';
import { Loading } from '@components/Loading/Loading.tsx';
import { HybridTable } from '@components/Table/HybridTable.tsx';
import { useI18n } from '@hooks/useI18n/useI18n.tsx';
import { useQueryState } from '@hooks/useQueryState/useQueryState.ts';
import { selectTimeToAuthExpiry } from '@selectors/selectTimeToAuthExpiry';
import { QueryKeys, TableNames } from '@utils/constants';
import { downloadCSV } from '@utils/downloadCSV';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint/fetchDataFromEndpoint.ts';
import { getErrorMessage } from '@utils/getErrorMessage/getErrorMessage.ts';
import { getMediobancaReconciliationsTableColumns } from './getMediobancaReconciliationsTableColumns';
import { prepareReconciliationsValues } from './prepareReconciliationsValues';

export const MediobancaReconciliations = () => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(TableNames.MEDIOBANCA_RECONCILIATIONS);

  const fetchData = useCallback(
    (asCsv = false) =>
      fetchDataFromEndpoint('mediobancaSettlements/full-details', {
        query: {
          ...queryParams,
          asCsv,
        },
      }),
    [queryParams],
  );

  const msUntilTokenExpiry = useSelector(selectTimeToAuthExpiry);

  const { data, error, isLoading } = useQuery(
    [QueryKeys.MEDIOBANCA_SETTLEMENTS, stringifiedQueryParams],
    () => fetchData(false),
    { cacheTime: msUntilTokenExpiry, staleTime: msUntilTokenExpiry },
  );

  const filters = getFilters();

  const reconciliationRows =
    data &&
    prepareReconciliationsValues({
      getLocaleCurrencyValue,
      getLocaleDate,
      reconciliations: data,
      translate,
    });

  const columns = useMemo(
    () => getMediobancaReconciliationsTableColumns(translate),
    [translate],
  );

  const getCsvFileName = () =>
    `${translate('TITLE.TRANSACTIONS')}-${queryParams.fromDate}/${
      queryParams.toDate
    }`;

  const refToCSVLink = useRef();

  const onDownloadCsvClick = useCallback(() => {
    fetchData(true).then(csv => {
      downloadCSV({ csv, refToCSVLink });
    });
  }, [fetchData]);

  const buttons = [
    {
      clickHandler: onDownloadCsvClick,
      dependencies: [Boolean(reconciliationRows?.length)],
      key: 'downloadCSV',
      text: translate('BUTTON.DOWNLOAD_TRANSACTIONS'),
    },
  ];

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  if (error) {
    return <p>{getErrorMessage({ error, translate })}</p>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Card buttons={buttons} title={translate('TITLE.TRANSACTIONS')}>
      <CSVLink download={getCsvFileName()} ref={refToCSVLink} />
      <HybridTable
        columns={columns}
        data={reconciliationRows}
        filters={filters}
        manageControlledState={updateQueryParams}
        resetFilters={resetFilters}
        tableState={queryState}
      />
    </Card>
  );
};
