import { GetLocaleCurrencyValue } from '@hooks/useI18n';
import { IProduct } from '@schemas/opsPortal/types/applicationDetails';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { extractFloatFromString } from '@utils/extractFloatFromString';
import { IUnitPrice } from './types';

interface IGetUnitPriceProps {
  currency: string;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  item: IProduct;
}

export const getUnitPrice = ({
  currency,
  getLocaleCurrencyValue,
  item,
}: IGetUnitPriceProps): IUnitPrice => {
  const { name, price, quantity, sku } = item;

  if (!price) {
    return addPlaceholderForEmptyValues({
      item: name,
      sku,
      quantity,
      unitPrice: '',
      linePrice: '',
    });
  }

  const lineAmount = Number(
    typeof price === 'string' ? extractFloatFromString(price) : price.amount,
  );

  const unitAmount = Number(lineAmount) / Number(quantity);

  const unitPrice = getLocaleCurrencyValue({
    currency,
    value: unitAmount.toFixed(2),
  });

  const linePrice = getLocaleCurrencyValue({
    currency,
    value: lineAmount.toFixed(2),
  });

  return addPlaceholderForEmptyValues({
    item: name,
    linePrice,
    quantity,
    sku,
    unitPrice,
  });
};
