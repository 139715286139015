import { CursorPaginationButtons } from './CursorPaginationButtons';
import { PageSizeSetter } from './PageSizeSetter';
import { PaginationWrapper } from './Pagination.styled';
import { PaginationButtons } from './PaginationButtons';
import { PaginationCounts } from './PaginationCounts';
import { ILinkHandlers, IPaginationProps } from './types';

export const Pagination = ({
  canNextPage,
  canPreviousPage,
  cursorLinkHandlers,
  gotoPage,
  isCursorPagination,
  nextPage,
  pageCount,
  pageIndex,
  pageOptions,
  pageSize,
  previousPage,
  rowCount,
  setPageSize,
  totalItems,
}: IPaginationProps) => (
  <PaginationWrapper>
    <PageSizeSetter pageSize={pageSize} setPageSize={setPageSize} />
    <PaginationCounts
      isCursorPagination={isCursorPagination}
      pageCount={pageCount}
      pageIndex={pageIndex}
      pageSize={pageSize}
      rowCount={rowCount}
      totalItems={totalItems}
    />
    {isCursorPagination ? (
      <CursorPaginationButtons
        paginationHandlers={cursorLinkHandlers as ILinkHandlers}
      />
    ) : (
      <PaginationButtons
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        previousPage={previousPage}
      />
    )}
  </PaginationWrapper>
);
