import * as Yup from 'yup';
import { Translate } from '@hooks/useI18n';
import { ValidationErrorKeys } from '@utils/enums';

interface IPhoneProps {
  phone_number: string;
}

interface ISchema {
  code: () => Yup.AnyObjectSchema;
  phone: (props: IPhoneProps) => Yup.AnyObjectSchema;
}

interface IGetValidationSchemaProps {
  form: keyof ISchema;
  translate: Translate;
}

export const getValidationSchema = ({
  form,
  translate,
}: IGetValidationSchemaProps) => {
  const schemata = {
    code: () =>
      Yup.object({
        code: Yup.string().required(translate(ValidationErrorKeys.Required)),
      }),

    phone: ({ phone_number: phoneNumber }: IPhoneProps) =>
      Yup.object({
        phone_number: Yup.string()
          .required(translate(ValidationErrorKeys.Required))
          .test(
            'not-duplicate',
            translate(ValidationErrorKeys.PhoneNumberHasNotChanged),
            value => value !== phoneNumber,
          ),
      }),
  };

  return schemata[form];
};
