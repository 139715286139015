import axios from 'axios';

interface IPutDataToEndpointProps {
  endpoint: string;
  options?: { search: string };
  requestData: object;
}

export const putDataToEndpoint = async ({
  endpoint,
  options,
  requestData,
}: IPutDataToEndpointProps) => {
  const { data } = await axios.put(
    `/api/${endpoint}/${options?.search ?? ''}`,
    requestData,
  );
  return data;
};
