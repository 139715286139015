interface IObjectWithName {
  [key: string]: unknown;
  name: string;
}

export const sortByName = (
  a: IObjectWithName,
  b: IObjectWithName,
  locale: string,
) => a.name.localeCompare(b.name, locale, { sensitivity: 'base' });
