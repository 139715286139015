import { IOrderDetail as IMerchantOrderDetails } from '@schemas/merchantPortal/types/orderDetails';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { getFullName } from '@utils/getFullName';
import { getStoreInformation } from '@utils/getStoreInformation';
import { IGetOrderSummaryProps, IOrderSummaryProps } from './types';

export interface IGetMerchantOrderSummaryProps extends IGetOrderSummaryProps {
  isPagolightPro: boolean;
  orderDetailsData: IMerchantOrderDetails;
  showCompassOrders: boolean;
}

export const getMerchantOrderSummaryProps = ({
  getLocaleCurrencyValue,
  getLocaleDate,
  isPagolightPro,
  orderDetailsData,
  showCompassOrders,
}: IGetMerchantOrderSummaryProps): IOrderSummaryProps => {
  const {
    amount: { amount, currency },
    annualised_interest_rate: annualisedInterestRate,
    consumer,
    created_date: date,
    fee_amount: fee,
    final_payment: finalPayment,
    mediobanca_external_id: mediobancaExternalId,
    merchant_contract_status: status,
    monthly_payment_amount: monthlyPaymentAmount,
    number_of_installments: numberOfInstalments,
    order_details: {
      order_description: orderDescription,
      order_reference: merchantOrderId,
    },
    payment_plan_reference: paymentPlanId,
    refunded,
  } = orderDetailsData;

  const orderValue = getLocaleCurrencyValue({
    currency,
    value: amount,
  });

  const { first_name: firstName, last_name: lastName } = consumer;
  const fullName = getFullName({ firstName, lastName });

  const netOrderAmount = getLocaleCurrencyValue({
    currency,
    value: (parseFloat(amount) - parseFloat(fee?.amount ?? '0')).toFixed(2),
  });
  const totalRefund = getLocaleCurrencyValue({
    currency,
    value: refunded.amount,
  });

  if (showCompassOrders) {
    // Do not alphabetise these object properties, as the order
    // of the Object.keys determines display order.
    return addPlaceholderForEmptyValues({
      date: date ? getLocaleDate({ date, includeTime: true }) : null,
      mediobancaExternalId,
      merchantOrderId,
      customerName: fullName,
      numberOfInstalments,
      orderValue,
      ...(isPagolightPro
        ? {
            amountRefunded: getLocaleCurrencyValue({
              currency,
              value: refunded.amount,
            }),
            adjustedOrderValue: getLocaleCurrencyValue({
              currency,
              value: (parseFloat(amount) - parseFloat(refunded.amount)).toFixed(
                2,
              ),
            }),
          }
        : {}),
      status,
    });
  }

  // Do not alphabetise these object properties, as the order
  // of the Object.keys determines display order.
  return addPlaceholderForEmptyValues({
    date: date ? getLocaleDate({ date, includeTime: true }) : null,
    paymentPlanId,
    merchantOrderId,
    customerName: fullName,
    numberOfInstalments,
    orderValue,
    feeAmount:
      fee &&
      getLocaleCurrencyValue({
        currency,
        value: fee.amount,
      }),
    netOrderAmount,
    totalRefund,
    monthlyPaymentAmount:
      monthlyPaymentAmount &&
      getLocaleCurrencyValue({
        currency,
        value: monthlyPaymentAmount.amount,
      }),
    ...(finalPayment
      ? {
          finalPayment: getLocaleCurrencyValue({
            currency,
            value: finalPayment.amount,
          }),
        }
      : {}),
    annualisedInterestRate: `${(annualisedInterestRate ?? 0) * 100}%`,
    orderDescription,
    status,
    ...getStoreInformation(orderDetailsData),
  });
};
