import styled from 'styled-components';
import { GetLocaleCurrencyValue, Translate } from '@hooks/useI18n';
import { ICurrency } from '@schemas/opsPortal/types/contracts';

interface IDeleteFeesNotificationMessageProps {
  currency?: ICurrency;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  paidFeesSelected: number;
  totalFeesSelected: number;
  translate: Translate;
}

const MessageDiv = styled.div`
  text-align: center;
  padding-inline: 10px;
`;

export const DeleteFeesNotificationMessage = ({
  currency,
  getLocaleCurrencyValue,
  paidFeesSelected,
  totalFeesSelected,
  translate,
}: IDeleteFeesNotificationMessageProps) => (
  <MessageDiv>
    <div>
      {translate('OPS_PORTAL.DELETE_LATE_FEES', {
        amountString: getLocaleCurrencyValue({
          currency,
          value: totalFeesSelected.toString(),
        }),
      })}
    </div>
    {paidFeesSelected > 0 && (
      <div>
        {translate('OPS_PORTAL.DELETE_LATE_FEE_RETURN_TO_CUSTOMER', {
          amountString: getLocaleCurrencyValue({
            currency,
            value: paidFeesSelected.toString(),
          }),
        })}
      </div>
    )}
  </MessageDiv>
);
