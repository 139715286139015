import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastProvider } from '@heidi-pay/heidi-common-fe/hooks';
import { EnhancedStore } from '@reduxjs/toolkit';
import { AuthInit } from '@auth';
import { PortalsToast } from '@components/AlertToast/PortalsToast';
import { AppLanguageController } from '@components/AppLanguageController';
import { BrowserTabThemeController } from '@components/BrowserTabThemeController';
import { HotjarFeedbackController } from '@components/HotjarFeedbackController';
import { ConfirmDialogProvider } from '@hooks/useConfirm';
import { I18nProvider } from '@hooks/useI18n';
import { LayoutSplashScreen, MaterialThemeProvider } from '@metronic/layout';
import { PortalRoutes } from '@routes';
import { StyledComponentsTheme } from '@utils/StyledComponentsTheme';

import '@heidi-pay/heidi-component-library/styles/index.css';

interface IApp {
  basename?: string;
  persistor: Persistor;
  store: EnhancedStore<unknown>;
}

export default function App({ basename, persistor, store }: Readonly<IApp>) {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      <BrowserTabThemeController />
      <HotjarFeedbackController />
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate loading={<LayoutSplashScreen />} persistor={persistor}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/* This library only returns the location that has been active
              before the recent location change in the current window lifetime. */}
            <MaterialThemeProvider>
              <StyledComponentsTheme>
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nProvider>
                  <ConfirmDialogProvider>
                    <AppLanguageController />
                    {/* Render routes with provided `Layout`. */}
                    <AuthInit>
                      <ToastProvider
                        containerClassName="toastContainer"
                        toastComponent={PortalsToast}
                      >
                        <PortalRoutes />
                      </ToastProvider>
                    </AuthInit>
                  </ConfirmDialogProvider>
                </I18nProvider>
              </StyledComponentsTheme>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
