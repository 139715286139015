import styled from 'styled-components';
import { AriaRoles } from '@heidi-pay/heidi-common-fe/enums';
import { Translate } from '@hooks/useI18n';
import {
  DateFeeLabelDiv,
  DateLabelSpan,
  AmountLabelSpan,
} from './DateFeeLabel';

interface IDateFeeHeaders {
  translate: Translate;
}

export const DateFeeHeaders = ({ translate }: IDateFeeHeaders) => {
  const AmountHeaderSpan = styled(AmountLabelSpan).attrs({
    role: AriaRoles.Heading,
  })`
    text-decoration: underline;
  `;

  const DateFeeHeadersDiv = styled(DateFeeLabelDiv)`
    margin-left: -7px;
    margin-bottom: -10px;
  `;

  return (
    <DateFeeHeadersDiv>
      <DateLabelSpan />
      <AmountHeaderSpan>{translate('OPS_PORTAL.LABEL.DUE')}</AmountHeaderSpan>
      <AmountHeaderSpan>{translate('TITLE.PAID')}</AmountHeaderSpan>
    </DateFeeHeadersDiv>
  );
};
