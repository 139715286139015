import { useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileUpload } from '@components/FileUpload';
import { useFileUploadQuery } from '@hooks/useFileUploadQuery';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { ValidationErrorKeys } from '@utils/enums';

export interface IUseFileUploadModalProps extends ISharedModalProps {
  acceptedFiles: Array<
    'text/csv' | '.xml' | '.pdf' | '.jpeg' | '.jpg' | '.png'
  >;
  description?: string;
  endpoint: string;
  title?: string;
}

export interface IModalContentProps extends IUseFileUploadModalProps {
  closeModal: () => void;
  formId: string;
}

const ModalContent = ({
  acceptedFiles,
  closeModal,
  description,
  endpoint,
  formId,
}: IModalContentProps) => {
  const { translate } = useI18n();
  const { message } = useToast(translate);

  const validationSchema = Yup.object({
    file: Yup.mixed<File[]>()
      .test('fileLength', translate(ValidationErrorKeys.Required), value =>
        Boolean(value && value.length > 0),
      )
      .required(translate(ValidationErrorKeys.Required)),
  });

  const methods = useForm({
    defaultValues: {
      file: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const { mutate } = useFileUploadQuery({
    endpoint,
    onError: () => message.error('ERROR.FILE_UPLOAD', { timeout: 10000 }),
    onSuccess: () => {
      message.success('GENERAL.FILE_UPLOAD_SUCCESS', { noTimeout: true });
      closeModal();
    },
  });

  const handleFileUpload = useCallback(data => mutate(data.file), [mutate]);
  const acceptedFilesString = acceptedFiles.join(', ');
  const fileSelectorLabel = `${translate(
    'LABEL.CHOOSE_FILE',
  )} (${acceptedFilesString})`;

  return (
    <FormProvider {...methods}>
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formId}
        onSubmit={methods.handleSubmit(handleFileUpload)}
      >
        {description ? (
          <p className="text-danger">
            <strong>{description}</strong>
          </p>
        ) : null}

        <FileUpload
          accept={acceptedFilesString}
          id="file"
          label={fileSelectorLabel}
          maximumFileSizeMb={40}
          multiple={false}
          name="file"
        />
      </form>
    </FormProvider>
  );
};

export const useFileUploadModal = (props: IUseFileUploadModalProps): Modal => {
  const {
    acceptedFiles,
    additionalButtonContent,
    description,
    endpoint,
    title,
  } = props;
  const { translate } = useI18n();
  const formID = 'file-upload-form';

  const modal = useModal({
    sharedProps: props,
    modalName: 'fileUploadModal',
    permissions: ['merchant_portal.can_upload_signed_agreement'],
    buttonTranslationKey: 'BUTTON.UPLOAD_CUSTOMER_AGREEMENT',
  });

  const modalProps = {
    additionalButtonContent,
    cancelText: translate('BUTTON.CLOSE'),
    formID,
    isDangerousSubmit: true,
    modalContent: (
      <ModalContent
        acceptedFiles={acceptedFiles}
        closeModal={modal.closeModal}
        description={description}
        endpoint={endpoint}
        formId={formID}
      />
    ),
    showFormButtons: true,
    submitText: translate('BUTTON.UPLOAD'),
    title,
  };

  return getModalDetails({ modal, modalProps });
};
