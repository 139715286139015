import KTOffcanvas from '@metronic/_assets/js/components/offcanvas';
import { KTUtil } from '@metronic/_assets/js/components/util';

const KTLayoutQuickPanel = (function ktLayoutQuickPanel() {
  // Private properties
  let element;
  let notificationsElement;
  let logsElement;
  let settingsElement;

  // Private functions
  const getContentHeight = function getContentHeight() {
    const header = KTUtil.find(element, '.offcanvas-header');
    const content = KTUtil.find(element, '.offcanvas-content');

    let height = parseInt(KTUtil.getViewPort().height, 10);

    if (header) {
      height -= parseInt(KTUtil.actualHeight(header), 10);
      height -= parseInt(KTUtil.css(header, 'marginTop'), 10);
      height -= parseInt(KTUtil.css(header, 'marginBottom'), 10);
    }

    if (content) {
      height -= parseInt(KTUtil.css(content, 'marginTop'), 10);
      height -= parseInt(KTUtil.css(content, 'marginBottom'), 10);
    }

    height -= parseInt(KTUtil.css(element, 'paddingTop'), 10);
    height -= parseInt(KTUtil.css(element, 'paddingBottom'), 10);

    height -= 2;

    return height;
  };

  const init = function init() {
    return new KTOffcanvas(element, {
      overlay: true,
      baseClass: 'offcanvas',
      placement: 'right',
      closeBy: 'kt_quick_panel_close',
      toggleBy: 'kt_quick_panel_toggle',
    });
  };

  const initNotifications = function initNotifications() {
    KTUtil.scrollInit(notificationsElement, {
      mobileNativeScroll: true,
      resetHeightOnDestroy: true,
      handleWindowResize: true,
      height() {
        return getContentHeight();
      },
    });
  };

  const initLogs = function initLogs() {
    KTUtil.scrollInit(logsElement, {
      mobileNativeScroll: true,
      resetHeightOnDestroy: true,
      handleWindowResize: true,
      height() {
        return getContentHeight();
      },
    });
  };

  const initSettings = function initSettings() {
    KTUtil.scrollInit(settingsElement, {
      mobileNativeScroll: true,
      resetHeightOnDestroy: true,
      handleWindowResize: true,
      height() {
        return getContentHeight();
      },
    });
  };

  // Public methods
  return {
    init(id) {
      element = KTUtil.getById(id);
      notificationsElement = KTUtil.getById('kt_quick_panel_notifications');
      logsElement = KTUtil.getById('kt_quick_panel_logs');
      settingsElement = KTUtil.getById('kt_quick_panel_settings');

      init();
      initNotifications();
      initLogs();
      initSettings();
    },
  };
})();

export default KTLayoutQuickPanel;
