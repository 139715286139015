import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

interface IValidationError {
  error: string | FieldError | Merge<FieldError, FieldErrorsImpl>;
}

export const ValidationError = ({ error }: IValidationError) => (
  <div className="fv-plugins-message-container">
    <p className="fv-help-block">{error}</p>
  </div>
);
