import styled from 'styled-components';
import { useI18n } from '@hooks/useI18n/useI18n';

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255, 0.7);
`;

interface IModalFormButtonsProps {
  isLoading?: boolean;
  loadingText?: string;
}

export const ModalLoadingOverlay = ({
  isLoading,
  loadingText = 'STATUS.FORM_SUBMITTING',
}: IModalFormButtonsProps) => {
  const { translate } = useI18n();
  return isLoading ? <Overlay>{translate(loadingText)}</Overlay> : null;
};
