import { ValueOf } from '@appTypes';
import { SupportedLanguages } from '@utils/constants';

type Language = ValueOf<typeof SupportedLanguages>;
const baseUrl = 'https://storage.googleapis.com/';

const instoreGuides = {
  de: 'Ha%CC%88ndler%20Guide',
  en: 'Merchant%20guide',
  fr: 'Guide%20vendeur',
  it: 'Guida%20venditore',
};

export const createGuides = (createUrl: (lang: Language) => string) =>
  Object.values(SupportedLanguages).map(lang => ({
    lang,
    url: createUrl(lang),
  }));

export const createInstoreUrl = (lang: Language) =>
  `${baseUrl}heidi-media-prod/${instoreGuides[lang]}.pdf`;
export const createCardChangeUrl = (lang: Language) =>
  `${baseUrl}heidi-consumer-portal-guides/Card%20change%20${lang.toUpperCase()}.pdf`;

export const guides = {
  INSTORE_SALES: createGuides(createInstoreUrl),
  CARD_CHANGE: createGuides(createCardChangeUrl),
};
