import { ChangeEvent, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, FormGroup, Label } from '@components/Common.styles';
import { ValidationError } from '@components/ValidationError';

export interface ICheckboxGroupOptionsRHF {
  disabled?: boolean;
  id: string;
  label: string | JSX.Element;
}

interface ICheckboxGroupProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  handleChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  label?: string | JSX.Element;
  name: string;
  options: ICheckboxGroupOptionsRHF[];
}

export const CheckboxGroupRHF = ({
  label,
  name,
  options,
}: ICheckboxGroupProps) => {
  const {
    formState: { errors },
    getValues,
    register,
    setValue,
  } = useFormContext();

  const error = errors[name]?.message;

  const handleChange = useCallback(
    (clickedOptionId: string, isChecked: boolean) => {
      const currentValues = getValues(name) || [];
      const newValue = isChecked
        ? [...currentValues, clickedOptionId]
        : currentValues.filter((id: string) => id !== clickedOptionId);
      setValue(name, newValue);
    },
    [getValues, name, setValue],
  );

  const handleCheckboxClick = useCallback(
    (optionId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(optionId, e.target.checked);
    },
    [handleChange],
  );

  const checkedBoxes = getValues(name) || [];

  return (
    <FormGroup>
      {label ? <Label htmlFor={name}>{label}</Label> : null}
      {options.map(({ id: optionId, label: checkBoxLabel }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...register(`${name}-${optionId}`)}
              checked={checkedBoxes.includes(optionId)}
              onChange={handleCheckboxClick(optionId)}
              value={optionId}
            />
          }
          key={optionId}
          label={checkBoxLabel}
        />
      ))}
      {error ? <ValidationError error={error} /> : null}
    </FormGroup>
  );
};
