import { GetLocaleDate } from '@hooks/useI18n';
import { IOpsPortalActionLogs } from '@schemas/opsPortal/types/actionLogs';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { replaceUnderscoresAndCapitalise } from '@utils/replaceUnderscoresAndCapitalise';
import { IActionLogsTable } from './getActionLogsColumns';

interface IMapActionLogsDataProps {
  data: IOpsPortalActionLogs | undefined;
  getLocaleDate: GetLocaleDate;
}

export const mapActionLogsData = ({
  data,
  getLocaleDate,
}: IMapActionLogsDataProps) =>
  data?.map(dataRow => {
    const {
      action_name: actionName,
      action_source: actionSource,
      created,
      log_type: logType,
      phase,
      phase_priority: phasePriority,
      progress_step: progressStep,
    } = dataRow;

    return addPlaceholderForEmptyValues<IActionLogsTable>({
      actionName: replaceUnderscoresAndCapitalise(actionName),
      actionSource: replaceUnderscoresAndCapitalise(actionSource),
      logType: replaceUnderscoresAndCapitalise(logType),
      phase,
      phasePriority,
      progressStep,
      createdDate: created
        ? getLocaleDate({ date: created, includeTime: true })
        : null,
    });
  }) ?? [];
