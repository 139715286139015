import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components/index';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { Modal } from '@hooks/useModal/types';
import { getErrorMessage } from '@utils/getErrorMessage/getErrorMessage';
import { putDataToEndpoint } from '@utils/putDataToEndpoint/putDataToEndpoint';
import { PasswordForm } from './PasswordForm';

interface IRequestData {
  new_password: string;
  new_password_confirm: string;
  old_password: string;
}

export const usePasswordModal = (): Modal => {
  const { translate } = useI18n();

  const modal = useModal({
    sharedProps: {},
    modalName: 'passwordModal',
    buttonTranslationKey: 'ACCOUNT.CHANGE_PASSWORD',
    permissions: [
      'merchant_portal.can_view_account_info',
      'consumer_portal.change_consumeruser',
      'ops_portal.view_merchant',
    ],
  });

  const { isLoading, mutate } = useMutation<
    void,
    AxiosError<IError>,
    IRequestData
  >(
    requestData =>
      putDataToEndpoint({
        endpoint: 'user/settings/password-change',
        requestData,
      }),
    {
      onSuccess: () => {
        modal.resolveForm(translate('ACCOUNT.PASSWORD_SUCCESSFULLY_CHANGED'));
      },
      onError: error => {
        modal.resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey: 'ACCOUNT.UNABLE_TO_CHANGE_PASSWORD',
            translate,
          }),
        );
      },
    },
  );

  const handleSubmit = useCallback(
    values => {
      mutate(values);
    },
    [mutate],
  );

  const formID = 'changePassword';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={modal.formStatusMessage}
      isLoading={isLoading}
    >
      <PasswordForm formID={formID} handleSubmit={handleSubmit} />
    </ModalStatusRouter>
  );

  const modalProps = {
    formID,
    isLoading,
    modalContent,
    title: translate('ACCOUNT.CHANGE_PASSWORD'),
  };

  return getModalDetails({ modal, modalProps });
};
