import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { IBlockedApplicantsTableColumns, IMapDataProps } from './types';

export const mapData = ({
  data,
  getLocaleDate,
}: IMapDataProps): IBlockedApplicantsTableColumns[] =>
  data.map(dataRow => {
    const {
      applicant_first_name: firstName,
      applicant_last_name: lastName,
      applicant_uuid: applicantUuid,
      block_reason: blockReason,
      blocked_by: blockedBy,
      created: dateBlocked,
      date_to_unblock: unblockDate,
    } = dataRow;

    return addPlaceholderForEmptyValues({
      applicantUuid,
      applicantName:
        firstName || lastName ? `${lastName}, ${firstName}`.trim() : '',
      blockedBy,
      blockReason,
      dateBlocked: getLocaleDate({ date: dateBlocked }),
      unblockDate: unblockDate && getLocaleDate({ date: unblockDate }),
    });
  });
