import { ControllerRenderProps } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Translate } from '@hooks/useI18n';
import { IFormData } from '../types';
import { getMinDateTime } from '../utils/dateUtils';

interface IDatePickerProps {
  errorMessage?: string;
  field: ControllerRenderProps<IFormData, 'expiryTime'>;
  onError: (error: string | null) => void;
  translate: Translate;
}

export const ExpiryDatePicker = ({
  errorMessage,
  field,
  onError,
  translate,
}: IDatePickerProps) => (
  <DateTimePicker
    {...field}
    ampm={false}
    format="dd/MM/yyyy HH:mm"
    label={translate('OPS_PORTAL.EXPIRY_DATE_TIME_LABEL')}
    minDateTime={getMinDateTime()}
    onError={onError}
    slotProps={{
      textField: {
        fullWidth: true,
        error: !!errorMessage,
        helperText: errorMessage ?? '',
      },
    }}
    views={['year', 'month', 'day', 'hours', 'minutes']}
  />
);
