import { shallowEqual, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthPage } from '@auth';
import { selectIsAuthed } from '@selectors';
import { getCached } from '@utils/getCached';
import { removeCached } from '@utils/removeCached';

export const AuthRoute = () => {
  const isAuthed = useSelector(selectIsAuthed, shallowEqual);

  if (isAuthed) {
    const cacheProps = {
      cache: sessionStorage,
      label: 'portalCachedPath',
    };

    const cachedPath = getCached(cacheProps);
    removeCached(cacheProps);

    return <Navigate replace={true} to={cachedPath || '/'} />;
  }

  return <AuthPage />;
};
