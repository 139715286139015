import { useCallback } from 'react';
import { ICardCTA } from '@appTypes';
import { CardCTA } from '@components/CardCTA';

interface IMerchantStoreButtonProps {
  ctaProps: ICardCTA;
  setStoreUuid: (uuid: string) => void;
  uuid: string;
}

export const MerchantStoreButton = ({
  ctaProps,
  setStoreUuid,
  uuid,
}: IMerchantStoreButtonProps) => {
  const clickHandler = useCallback(() => {
    setStoreUuid(uuid);
    ctaProps?.clickHandler();
  }, [ctaProps, setStoreUuid, uuid]);

  const updatedCTAProps = { ...ctaProps, clickHandler };

  return <CardCTA {...updatedCTAProps} />;
};
