import { getFilterValue } from './getFilterValue';
import { IGetQueryStateValuesProps } from './types';

export const getQueryStateDateRangeValues = (
  queryStateProps: IGetQueryStateValuesProps,
): [string, string] => {
  const filterValue = getFilterValue<[string, string]>(queryStateProps) ?? [
    '',
    '',
  ];
  return [filterValue[0] ?? '', filterValue[1] ?? ''];
};
