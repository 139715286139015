import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
} from '@metronic/layout';
import * as _redux from '@redux';
import store, { persistor } from '@redux/store';
import { initSentry } from '@utils/initSentry';
import App from './app/App';

import './index.scss';
import '@metronic/_assets/plugins/keenthemes-icons/font/ki.css';
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@metronic/_assets/plugins/flaticon/flaticon.css';
import '@metronic/_assets/plugins/flaticon2/flaticon.css';
import 'react-datepicker/dist/react-datepicker.css';

initSentry();

// UNCOMMENT THIS CODE TO ENABLE IN BROWSER MOCKING
// to enable in browser mocking run 'npm run enable:mocking'
// TODO - Investigate why this breaks build when uncommented https://heidi-pay.atlassian.net/browse/HC-8930
// eslint-disable-next-line import/first, import/order
// import { mockPortals } from '@mocking/msw';

// if (process.env.NODE_ENV === 'development') {
//   mockPortals();
// }

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// Expose store when app is run in Cypress.
if (window.Cypress) {
  window.store = store;
}

ReactDOM.render(
  <MetronicLayoutProvider>
    <MetronicSplashScreenProvider>
      <QueryClientProvider client={queryClient}>
        <App basename={PUBLIC_URL} persistor={persistor} store={store} />
      </QueryClientProvider>
    </MetronicSplashScreenProvider>
  </MetronicLayoutProvider>,
  document.getElementById('root'),
);
