import { useCallback } from 'react';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { Translate } from '@hooks/useI18n';
import { IFormData, IRiskOverride } from '../types';

interface ICheckboxListProps {
  field: ControllerRenderProps<IFormData, 'overrideLists'>;
  isLoading: boolean;
  options: IRiskOverride[];
  translate: Translate;
}

export const RiskOverrideCheckboxList = ({
  field: { onChange, value = [], ...fieldProps },
  isLoading,
  options,
}: ICheckboxListProps) => {
  const handleChange = useCallback(
    (uuid: string) => () => {
      onChange(
        value.includes(uuid)
          ? value.filter(id => id !== uuid)
          : [...value, uuid],
      );
    },
    [onChange, value],
  );

  return (
    <FormGroup {...fieldProps}>
      {options.map(({ name, uuid }) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={value.includes(uuid)}
              disabled={isLoading}
              onChange={handleChange(uuid)}
            />
          }
          key={uuid}
          label={name}
        />
      ))}
    </FormGroup>
  );
};
