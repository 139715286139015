import { useCallback, useState, useMemo, Dispatch } from 'react';
import { useCombobox } from 'downshift';
import countries from 'i18n-iso-countries';
import { FormGroup, InputText, Label } from '@components/Common.styles';
import { useI18n } from '@hooks/useI18n/useI18n';
import { menuStyles } from './menuStyles';
import deCountries from 'i18n-iso-countries/langs/de.json';
import enCountries from 'i18n-iso-countries/langs/en.json';
import frCountries from 'i18n-iso-countries/langs/fr.json';
import itCountries from 'i18n-iso-countries/langs/it.json';

countries.registerLocale(deCountries);
countries.registerLocale(enCountries);
countries.registerLocale(frCountries);
countries.registerLocale(itCountries);

interface ICountrySelectorProps {
  countryAlpha2: string;
  updateCountry: Dispatch<string>;
}

export const CountrySelector = ({
  countryAlpha2,
  updateCountry,
}: ICountrySelectorProps) => {
  const { selectedLanguage, translate } = useI18n();

  const translatedCountryNames = countries.getNames(selectedLanguage, {
    select: 'official',
  });

  const sortedCountriesArray = useMemo(
    () =>
      Object.keys(translatedCountryNames)
        .sort((a, b) =>
          translatedCountryNames[a] > translatedCountryNames[b] ? 1 : -1,
        )
        .map(key => ({ id: key, name: translatedCountryNames[key] })),
    [translatedCountryNames],
  );

  const sortedCountryNames = useMemo(
    () => sortedCountriesArray.map(country => country.name),
    [sortedCountriesArray],
  );

  const findCountryIdByName = useCallback(
    name =>
      sortedCountriesArray.find(country => name === country.name)?.id ?? '',
    [sortedCountriesArray],
  );

  const handleCountryChange = useCallback(
    e => updateCountry(findCountryIdByName(e.selectedItem)),
    [findCountryIdByName, updateCountry],
  );

  const findCountryNameById = (id: string) =>
    sortedCountriesArray.find(country => id === country.id)?.name ?? '';

  const [countryList, setCountryList] = useState(sortedCountryNames);

  const {
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    isOpen,
  } = useCombobox({
    items: countryList,
    selectedItem: findCountryNameById(countryAlpha2),
    onSelectedItemChange: handleCountryChange,
    onInputValueChange: ({ inputValue = '' }) => {
      setCountryList(
        sortedCountryNames.filter(item =>
          item.toLowerCase().match(inputValue.toLowerCase()),
        ),
      );
    },
  });

  return (
    <div>
      <FormGroup>
        <Label {...getLabelProps()}>{translate('LABEL.SELECT_COUNTRY')}</Label>
        <InputText {...getInputProps()} type="search" />
      </FormGroup>
      <ul
        {...getMenuProps()}
        style={{ ...menuStyles, display: isOpen ? 'block' : 'none' }}
      >
        {isOpen &&
          countryList.map((country, index) => (
            <li
              key={country}
              style={
                highlightedIndex === index ? { backgroundColor: '#bde4ff' } : {}
              }
              {...getItemProps({ item: country, index })}
            >
              {country}
            </li>
          ))}
      </ul>
    </div>
  );
};
