import { IMapShoppingCartDataProps } from './types';

export const mapShoppingCartData = ({
  getLocaleCurrencyValue,
  orderDetails,
}: IMapShoppingCartDataProps) => {
  const {
    amount: { amount, currency },
    products,
  } = orderDetails;

  const total = getLocaleCurrencyValue({
    currency,
    value: amount,
  });

  return { currency, products, total };
};
