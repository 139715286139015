import { useFormContext } from 'react-hook-form';
import { FormGroup, InputText, Label } from '@components/Common.styles';
import { ValidationError } from '@components/ValidationError';
import { useI18n } from '@hooks/useI18n/useI18n';

interface ITextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string;
  label: string;
  name: string;
  readOnly?: boolean;
}

export const TextFieldRHF = ({
  containerClassName,
  label,
  name,
  readOnly,
  ...props
}: ITextFieldProps) => {
  const { translate } = useI18n();
  const {
    formState: { errors },
    register,
  } = useFormContext();

  const { className: propsClassName, ...remainingProps } = props;

  const error = errors[name]?.message;

  return (
    <FormGroup extraClassName={containerClassName}>
      <Label htmlFor={name}>
        {label + (readOnly ? ` (${translate('LABEL.CANNOT_BE_EDITED')})` : '')}
      </Label>
      <InputText
        extraClassName={propsClassName}
        id={name}
        readOnly={readOnly}
        {...register(name)}
        {...remainingProps}
      />
      {error ? <ValidationError error={error} /> : null}
    </FormGroup>
  );
};
