import { IGetBlockedDetailsLabel, INotificationProps } from './types';

export const getNotificationMessage = ({
  associations,
  blockDetails,
  getLocaleDate,
  isBlocked,
  isPrimaryBlock,
  translate,
}: INotificationProps) => {
  if (!isBlocked) {
    return '';
  }

  if (!isPrimaryBlock) {
    return translate('OPS_PORTAL.STATUS.CONSUMER_HAS_SECONDARY_BLOCK', {
      associations,
    });
  }

  const blockMessage = translate(
    'OPS_PORTAL.STATUS.CONSUMER_HAS_PRIMARY_BLOCK',
    {
      associations,
    },
  );

  const getBlockedDetailsLabel = ({
    isDate,
    key,
    value,
  }: IGetBlockedDetailsLabel) => {
    if (value) {
      const keyTranslated = translate(`OPS_PORTAL.TITLE.${key}`);
      const valueFormatted = isDate ? getLocaleDate({ date: value }) : value;
      return ` ${keyTranslated}: ${valueFormatted}.`;
    }
    return '';
  };

  const blockCreated = getBlockedDetailsLabel({
    key: 'DATE_BLOCKED',
    value: blockDetails?.created,
    isDate: true,
  });
  const blockedUntil = getBlockedDetailsLabel({
    key: 'DATE_TO_UNBLOCK',
    value: blockDetails?.date_to_unblock,
    isDate: true,
  });
  const blockedBy = getBlockedDetailsLabel({
    key: 'BLOCKED_BY',
    value: blockDetails?.blocked_by,
  });
  const blockReason = getBlockedDetailsLabel({
    key: 'BLOCK_REASON',
    value: blockDetails?.block_reason,
  });

  return blockMessage + blockCreated + blockedUntil + blockedBy + blockReason;
};
