export const getDateLimits = (dates: string[]) =>
  dates.map((_, i) => {
    let prevDate: Date | undefined;
    let nextDate: Date | undefined;

    if (i !== 0) {
      const date = new Date(`${dates[i - 1]}`);
      date.setUTCDate(date.getUTCDate() + 1);

      const now = new Date(Date.now());
      const currentDate = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1),
      );

      prevDate = date < currentDate ? currentDate : date;
    }

    if (i !== dates.length - 1) {
      const date = new Date(`${dates[i + 1]}`);
      nextDate = new Date(date.setUTCDate(date.getUTCDate() - 1));
    }

    return [prevDate, nextDate];
  });
