import { TextField } from '@components/TextField';
import { Translate } from '@hooks/useI18n';

interface IPrepaymentFormFieldsProps {
  currency: string;
  translate: Translate;
}

export const PrepaymentFormFields = ({
  currency,
  translate,
}: IPrepaymentFormFieldsProps) => {
  return (
    <>
      <TextField
        id="payment_plan_reference"
        label={translate('TITLE.PAYMENT_PLAN_ID')}
        name="payment_plan_reference"
      />
      <TextField
        id="amount"
        label={`${translate('PAGE.ORDERS.AMOUNT_TO_PREPAY')} (${currency})`}
        name="amount"
      />
    </>
  );
};
