import { ToastVariant } from '@heidi-pay/heidi-common-fe/enums';
import { IToastComponent } from '@heidi-pay/heidi-common-fe/types';
import { AlertVariant } from '@utils/constants';
import { AlertToast } from './AlertToast';

const ToastAlertMap = {
  [ToastVariant.SUCCESS]: AlertVariant.SUCCESS,
  [ToastVariant.ERROR]: AlertVariant.ERROR,
  [ToastVariant.WARNING]: AlertVariant.WARNING,
  [ToastVariant.INFO]: AlertVariant.INFO,
};

export const PortalsToast = ({
  message,
  onClose,
  timeout,
  type,
}: IToastComponent) => (
  <AlertToast
    autoHideDuration={timeout}
    message={message}
    open={true}
    setOpen={onClose}
    snackbarProps={{
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
    }}
    variant={ToastAlertMap[type]}
  />
);
