import { IMerchantStore } from '@schemas/merchantPortal/types/merchantStores';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { IMapDataProps, IMerchantStoreColumn } from './types';

export const mapData = ({ data }: IMapDataProps): IMerchantStoreColumn[] =>
  data?.map((dataRow: IMerchantStore) => {
    const { store_name: storeName, store_number: storeNumber, uuid } = dataRow;

    return addPlaceholderForEmptyValues({
      storeName,
      storeNumber,
      uuid,
    });
  }) ?? [];
