import { Days } from '@appTypes';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';

export const getMonthlyPaymentDate = (
  monetaData?: IOpsPortalMonetaContract,
) => {
  if (monetaData?.balances?.length) {
    const dueDates = monetaData.balances.filter(
      balance =>
        Number(balance.amount_due) > 0 &&
        balance.payment_type === 'Instalment' &&
        balance.latest_schedule,
    );
    if (dueDates.length) {
      // We want to get the second payment as this is often representative to the payment taken on a schedule
      // rather than the initial payment date
      const index = dueDates.length > 1 ? 1 : 0;
      return new Date(dueDates[index].payment_date).getDate() as Days;
    }
  }

  return null;
};
