import { Dd, Dt } from '@components/Common.styles';
import { Translate } from '@hooks/useI18n';
import { IDeliveryChannel } from '@schemas/merchantPortal/types/tracking';
import { CompeteIcon } from './CompeteIcon';

interface ICheckoutLinkSentStatus {
  checkoutSmsLinkSent: boolean;
  deliveryChannel: IDeliveryChannel;
  translate: Translate;
}

export const CheckoutLinkSentStatus = ({
  checkoutSmsLinkSent,
  deliveryChannel,
  translate,
}: ICheckoutLinkSentStatus) => {
  const showSmsSentStatus =
    deliveryChannel !== null &&
    ['SMS', 'DELAYED_SMS'].includes(deliveryChannel);

  return showSmsSentStatus ? (
    <>
      <Dt>{translate('LABEL.HAS_SENT_CHECKOUT_LINK')}</Dt>
      <Dd>
        <CompeteIcon
          complete={deliveryChannel === 'SMS' || checkoutSmsLinkSent}
          translate={translate}
        />
      </Dd>
    </>
  ) : null;
};
