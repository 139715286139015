import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { usePortalError } from '@hooks/usePortalError';
import { ICurrency } from '@schemas/opsPortal/types/contractDetails';
import { IUuid } from '@schemas/opsPortal/types/contracts';
import { ILateFees } from '@schemas/opsPortal/types/monetaContract';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { DeleteFeeForm } from './DeleteFeeForm';

interface IUseDeleteFeesModalProps extends ISharedModalProps {
  currency?: ICurrency;
  externalContractUUID?: IUuid;
  invalidateMonetaData?: () => void;
  lateFees?: ILateFees;
}

export const useDeleteFeesModal = (props: IUseDeleteFeesModalProps): Modal => {
  const {
    currency,
    externalContractUUID,
    invalidateMonetaData,
    lateFees = [],
  } = props;
  const { translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast(translate);

  const modal = useModal({
    sharedProps: props,
    modalName: 'deleteFeesModal',
    buttonTranslationKey: 'OPS_PORTAL.TITLE.DELETE_FEES',
    permissions: ['ops_portal.can_remove_late_fees'],
  });
  const { closeModal } = modal;

  const [isFeeSelected, setIsFeeSelected] = useState(false);

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<
    unknown,
    AxiosError<IError>,
    unknown
  >(
    receivableUuids =>
      postDataToEndpoint({
        endpoint: `${Endpoints.DeleteFees}/${externalContractUUID}`,
        requestData: { receivable_uuids: receivableUuids },
      }),
    {
      onSuccess: () => {
        message.success('OPS_PORTAL.STATUS.SUCCESSFULLY_DELETED_FEES');
        queryClient.invalidateQueries([
          QueryKeys.DELETE_FEES,
          externalContractUUID,
        ]);

        closeModal();
        invalidateMonetaData?.();
      },
      onError: error => {
        handleAxiosError(error, 'OPS_PORTAL.STATUS.UNABLE_TO_DELETE_FEES');
        closeModal();
      },
    },
  );

  const handleSubmit = useCallback(
    values => {
      mutate(values.feesSelectedForDeletion ?? []);
    },
    [mutate],
  );

  const formID = 'delete-fees';

  const modalContent = (
    <DeleteFeeForm
      currency={currency}
      formID={formID}
      handleIsFeeSelected={setIsFeeSelected}
      handleSubmit={handleSubmit}
      lateFees={lateFees}
    />
  );

  const modalProps = {
    formID,
    isLoading,
    modalContent,
    submitDisabled: !isFeeSelected || isLoading,
    submitText: translate('OPS_PORTAL.BUTTON.DELETE'),
    title: translate('OPS_PORTAL.TITLE.DELETE_FEES'),
  };

  return getModalDetails({ modal, modalProps });
};
