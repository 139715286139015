import { getValueFromRowByKey } from './getValueFromRowByKey';
import { sort } from './sort';

// This is a custom sort function to be used with react-table headers.
export const sortNumeric = (rowA, rowB, key) => {
  const valA = getValueFromRowByKey({ row: rowA, key }).replace(/[^\d]+/g, '');
  const valB = getValueFromRowByKey({ row: rowB, key }).replace(/[^\d]+/g, '');

  return sort(valA, valB);
};
