import { createColumnHelper } from '@tanstack/react-table';
import { SortingFunctions } from '@utils/constants';

export const getMediobancaReconciliationsTableColumns = translate => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('paymentPlanId', {
      header: translate('TITLE.PAYMENT_PLAN_ID'),
    }),
    columnHelper.accessor('invoiceNumber', {
      header: translate('TITLE.YOUR_INVOICE_NUMBER'),
    }),
    columnHelper.accessor('mediobancaContractId', {
      header: translate('PAGE.MEDIOBANCA_RECONCILIATIONS.TRANSACTION_ID'),
    }),
    columnHelper.accessor('settlementType', {
      header: translate('PAGE.SETTLEMENTS.SETTLEMENT_TYPE'),
    }),
    columnHelper.accessor('consumerName', {
      header: translate('TITLE.CUSTOMER_NAME'),
    }),
    columnHelper.accessor('settlementDate', {
      header: translate('PAGE.MEDIOBANCA_RECONCILIATIONS.ORDER_DATE'),
      sortingFn: SortingFunctions.DATETIME,
    }),
    columnHelper.accessor('installments', {
      header: translate('LABEL.NUMBER_OF_INSTALMENTS'),
    }),
    columnHelper.accessor('grossAmount', {
      header: translate('PAGE.MEDIOBANCA_RECONCILIATIONS.ORDER_AMOUNT'),
      sortingFn: SortingFunctions.CURRENCY,
    }),
    columnHelper.accessor('netAmount', {
      header: translate('PAGE.MEDIOBANCA_RECONCILIATIONS.NET_AMOUNT'),
      sortingFn: SortingFunctions.CURRENCY,
    }),
    columnHelper.accessor('interest', {
      header: translate('LABEL.INTEREST'),
      sortingFn: SortingFunctions.CURRENCY,
    }),
    columnHelper.accessor('netFees', {
      header: translate('PAGE.MEDIOBANCA_RECONCILIATIONS.FEES_INCLUDING_TAX'),
      sortingFn: SortingFunctions.CURRENCY,
    }),
  ];
};
