import { IQueryState } from './types';

interface IGetColumnParamsProps {
  columnFilters: IQueryState['columnFilters'];
  defaults: IQueryState['columnFilters'];
}

interface IGetPaginationParamsProps {
  defaults: IQueryState['pagination'];
  pagination: IQueryState['pagination'];
}

interface IGetSearchParamProps {
  defaultFilter: IQueryState['globalFilter'];
  globalFilter: IQueryState['globalFilter'];
}

interface IGetUpdatedQueryParamsProps {
  defaultState: IQueryState;
  queryState: IQueryState;
}

const getPaginationParams = ({
  defaults,
  pagination,
}: IGetPaginationParamsProps) => ({
  ...(pagination.pageSize === defaults.pageSize
    ? {}
    : { limit: pagination.pageSize.toString() }),
  ...(pagination.pageIndex === defaults.pageIndex
    ? {}
    : { offset: pagination.pageIndex.toString() }),
});

const getCursorParam = (cursor: IQueryState['cursor']) => ({
  ...(cursor ? { cursor } : {}),
});

const getSearchParam = ({
  defaultFilter,
  globalFilter,
}: IGetSearchParamProps) => ({
  ...(globalFilter && globalFilter !== defaultFilter
    ? { search: globalFilter }
    : {}),
});

const getSortingParam = (sorting: IQueryState['sorting']) => ({
  ...(sorting?.length
    ? {
        sorting: sorting
          .map(col => `${col.desc ? '-' : ''}${col.id}`)
          .join(','),
      }
    : {}),
});

const getColumnParams = ({ columnFilters, defaults }: IGetColumnParamsProps) =>
  columnFilters?.reduce((obj, current) => {
    const currentDefault = defaults?.find(d => d.id === current.id);

    if (Array.isArray(current.value)) {
      if (!current.value.length) {
        return obj;
      }

      return {
        ...obj,
        ...(current.value[0] && current.value[0] !== currentDefault?.value?.[0]
          ? { [`${current.id}From`]: current.value[0] }
          : {}),
        ...(current.value[1] && current.value[1] !== currentDefault?.value?.[1]
          ? { [`${current.id}To`]: current.value[1] }
          : {}),
      };
    }

    return {
      ...(current.id && current.value && current.value !== currentDefault?.value
        ? { ...obj, [current.id]: current.value }
        : obj),
    };
  }, {});

export const getUpdatedQueryParams = ({
  defaultState,
  queryState,
}: IGetUpdatedQueryParamsProps) => {
  const columnParams = getColumnParams({
    columnFilters: queryState.columnFilters,
    defaults: defaultState.columnFilters,
  }) as Record<string, string>;

  const cursorParam = getCursorParam(queryState.cursor);

  const searchParam = getSearchParam({
    defaultFilter: defaultState.globalFilter,
    globalFilter: queryState.globalFilter,
  });
  const paginationParams = getPaginationParams({
    defaults: defaultState.pagination,
    pagination: queryState.pagination,
  });

  const sortingParam = getSortingParam(queryState.sorting);

  return new URLSearchParams({
    ...columnParams,
    ...cursorParam,
    ...paginationParams,
    ...searchParam,
    ...sortingParam,
  }).toString();
};
