import { FieldValues } from 'react-hook-form';
import * as Yup from 'yup';
import { Translate } from '@hooks/useI18n';
import { ITemplateListItemTriggerId } from '@schemas/opsPortal/types/templateList.d';
import { ValidationErrorKeys } from '@utils/enums';
import { CheckboxAmount } from './CheckboxAmount';
import { FieldComponent, FieldComponentProps } from './types';

interface IGetTemplateFieldsProps {
  currency: string;
  isInArrears: boolean;
  translate: Translate;
  triggerId: ITemplateListItemTriggerId;
}

export const getTemplateFields = ({
  currency,
  isInArrears,
  translate,
  triggerId,
}: IGetTemplateFieldsProps): FieldComponent | undefined => {
  const componentMappings: Partial<
    Record<ITemplateListItemTriggerId, FieldComponent>
  > = {
    FAILED_PAYMENTS_AND_QR_CODES_IN_PORTAL: ({
      methods,
    }: FieldComponentProps) => (
      <CheckboxAmount
        amountLabel={`${translate('LABEL.AMOUNT')} (${currency})`}
        checkboxLabel={translate(
          'OPS_PORTAL.LABEL.GENERATE_QR_FOR_ALL_ACTIVE_PAYMENTS',
        )}
        isInArrears={isInArrears}
        methods={methods}
      />
    ),
  };
  return componentMappings[triggerId];
};

const getTemplateFieldValidation = (
  triggerId: ITemplateListItemTriggerId,
  translate: Translate,
): object => {
  const validationMappings: Partial<
    Record<ITemplateListItemTriggerId, object>
  > = {
    FAILED_PAYMENTS_AND_QR_CODES_IN_PORTAL: {
      amount: Yup.string().when('full_amount', {
        is: false,
        then: schema =>
          schema.required(translate(ValidationErrorKeys.Required)),
      }),
    },
  };
  return validationMappings[triggerId] ?? {};
};

interface IGetTemplateFieldDefaultValuesProps {
  isInArrears: boolean;
  triggerId: ITemplateListItemTriggerId;
}

const getTemplateFieldDefaultValues = ({
  isInArrears,
  triggerId,
}: IGetTemplateFieldDefaultValuesProps) => {
  const defaultValueMappings: Partial<
    Record<ITemplateListItemTriggerId, FieldValues>
  > = {
    FAILED_PAYMENTS_AND_QR_CODES_IN_PORTAL: {
      full_amount: isInArrears,
    },
  };
  return defaultValueMappings[triggerId] ?? {};
};

export const getTemplateFormConfig = (
  isInArrears: boolean,
  translate: Translate,
  triggerId: ITemplateListItemTriggerId,
) => {
  const validation = getTemplateFieldValidation(triggerId, translate);
  const templateFieldDefaultValues = getTemplateFieldDefaultValues({
    isInArrears,
    triggerId,
  });
  return { templateFieldDefaultValues, validation };
};
