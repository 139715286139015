import { useMemo } from 'react';
import { Tabs } from '@components/Tabs';
import { useContractDocumentsTab } from '@hooks/useContractsDocumentsTab';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IOpsPortalBlockExternalCollectionsReferral } from '@schemas/opsPortal/types/blockExternalCollectionsReferral';
import { IOpsPortalCollectionsDetails } from '@schemas/opsPortal/types/collectionsDetails';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { IOpsPortalPauseCommunications } from '@schemas/opsPortal/types/pauseCommunications';
import { TabEventKeys } from '@utils/constants';
import {
  useAssignmentHistoryTab,
  useCertificatesOfInterestTab,
  useContractOverviewTab,
} from '../hooks';
import { useCardAttemptsTab } from '../hooks/useCardAttemptsTab';
import { useContractPaymentScheduleHistoryTab } from '../hooks/useContractPaymentScheduleHistoryTab';
import { generateTabData } from '../utils/generateTabData';
import { getPaymentScheduleTableColumns } from '../utils/getPaymentScheduleTableColumns';
import { getPaymentsTableColumns } from '../utils/getPaymentsTableColumns';
import { mapContractDetailsData } from '../utils/mapContractDetailsData';
import { mapContractPerformanceData } from '../utils/mapContractPerformanceData';
import { mapCustomerDetailsData } from '../utils/mapCustomerDetailsData';
import { mapPaymentScheduleData } from '../utils/mapPaymentScheduleData';
import { mapPaymentsData } from '../utils/mapPaymentsData';
import { ContractPaymentScheduleColumns } from '../utils/types';

interface ITabsComponentProps {
  collectionsBlockAutoReferralData?: IOpsPortalBlockExternalCollectionsReferral;
  collectionsData?: IOpsPortalCollectionsDetails;
  contractData: IContractDetails;
  contractUuid?: string;
  externalContractUUID?: string;
  isArrearsToBeManagedByHeidi: boolean;
  isCollectionsDataError: boolean;
  isCompass: boolean;
  isInternalOpsUser: boolean;
  monetaContractData?: IOpsPortalMonetaContract;
  pauseCommunicationsData?: IOpsPortalPauseCommunications;
}

export const TabsComponent = ({
  collectionsBlockAutoReferralData,
  collectionsData,
  contractData,
  contractUuid,
  externalContractUUID,
  isArrearsToBeManagedByHeidi,
  isCollectionsDataError,
  isCompass,
  isInternalOpsUser,
  monetaContractData,
  pauseCommunicationsData,
}: ITabsComponentProps) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const contractActivePaymentScheduleColumns = useMemo(
    () =>
      getPaymentScheduleTableColumns<ContractPaymentScheduleColumns>(translate),
    [translate],
  );

  const contractPaymentsColumns = useMemo(
    () => getPaymentsTableColumns(translate),
    [translate],
  );

  const contractDetailsData = mapContractDetailsData({
    data: contractData,
    getLocaleCurrencyValue,
  });
  const contractPerformanceData = mapContractPerformanceData({
    collectionsData,
    contractData,
    getLocaleCurrencyValue,
    getLocaleDate,
    isCollectionsDataError,
    translate,
  });
  const customerDetailsData = mapCustomerDetailsData({
    data: contractData,
    getLocaleDate,
  });

  const contractPaymentScheduleData = mapPaymentScheduleData({
    data: monetaContractData,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
  });

  const contractPaymentsData = mapPaymentsData({
    data: monetaContractData,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
  });

  const tabs = generateTabData({
    collectionsBlockAutoReferralData,
    contractActivePaymentScheduleColumns,
    contractDetailsData,
    contractPaymentsColumns,
    contractPaymentScheduleData,
    contractPaymentsData,
    contractPerformanceData,
    customerDetailsData,
    isArrearsToBeManagedByHeidi,
    isCompass,
    pauseCommunicationsData,
    translate,
  });

  const opsCanViewContractDocumentsTab = useHasPermission([
    'ops_portal.can_view_customer_agreements',
  ]);

  const opsCanViewCertificateOfInterestTab = useHasPermission([
    'ops_portal.list_certificate_of_interests',
  ]);

  const contractDocumentsTab = useContractDocumentsTab({
    contractUuid: contractData.contract_uuid,
    externalContractUUID,
  });

  const certificatesOfInterestTab = useCertificatesOfInterestTab({
    externalContractUUID,
  });

  const contractOverviewTab = useContractOverviewTab({
    collectionsBlockAutoReferralData,
    contractData,
    contractUuid,
    isArrearsToBeManagedByHeidi,
    isCompass,
    isInternalOpsUser,
    pauseCommunicationsData,
  });

  const assignmentHistoryTab = useAssignmentHistoryTab(externalContractUUID);

  const contractPaymentScheduleHistoryTab =
    useContractPaymentScheduleHistoryTab(monetaContractData);

  const cardAttemptTab = useCardAttemptsTab(externalContractUUID);

  const tabDataArray = [
    contractOverviewTab,
    ...tabs,
    assignmentHistoryTab,
    contractPaymentScheduleHistoryTab,
    ...(opsCanViewContractDocumentsTab ? [contractDocumentsTab] : []),
    ...(opsCanViewCertificateOfInterestTab ? [certificatesOfInterestTab] : []),
    cardAttemptTab,
  ];

  return (
    <Tabs
      initialTabKey={TabEventKeys.CONTRACT_OVERVIEW}
      tabsData={tabDataArray}
      tabsId="contract-drilldown-tabs"
    />
  );
};
