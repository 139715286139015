import { Nullable } from '@heidi-pay/heidi-common-fe/types';

interface IStore {
  store_id?: Nullable<string>;
  store_name?: Nullable<string>;
  store_number?: Nullable<string>;
}

export const getStoreInformation = ({
  store_id: storeId,
  store_name: storeName,
  store_number: storeNumber,
}: Partial<IStore>) => {
  if (storeId === 'ecommerce') {
    return { storeId };
  }

  return Object.fromEntries(
    Object.entries({ storeId, storeNumber, storeName }).filter(entry =>
      Boolean(entry[1]),
    ),
  );
};
