export const NavSectionHeadingKeys = Object.freeze({
  ACCOUNT_MANAGEMENT: 'TITLE.ACCOUNT_MANAGEMENT',
  APPLICATIONS: 'TITLE.APPLICATIONS',
  BANKING: 'OPS_PORTAL.TITLE.BANKING',
  HELP: 'LINK.HELP',
  CONTRACTS: 'OPS_PORTAL.TITLE.CONTRACTS',
  CUSTOMERS: 'TITLE.CUSTOMERS',
  ORGANISATION: 'TITLE.ORGANISATION',
  VIEWS: 'OPS_PORTAL.TITLE.VIEWS',
});
