import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { reduxBatch } from '@manaflair/redux-batch';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { getEnv } from '@utils/getEnv/getEnv.ts';
import { rootReducer, rootSaga } from './rootReducer';

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }),
  sagaMiddleware,
];

export const createStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware,
    devTools: !getEnv().isProd,
    enhancers: [reduxBatch],
  });

const store = createStore();

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export default store;
