import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@components/Button';
import { ButtonVariant } from '@utils/enums';
import { IConfirmProps } from './types';

export const ConfirmDialog = ({
  cancelButtonText,
  confirmation,
  dismiss,
  isOpen,
  okButtonText,
  proceed,
  title,
}: IConfirmProps) => (
  <Dialog open={isOpen}>
    {title ? <DialogTitle>{title}</DialogTitle> : null}
    <DialogContent>
      <DialogContentText>{confirmation}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={dismiss}
        variant={[ButtonVariant.Secondary, ButtonVariant.Sm]}
      >
        {cancelButtonText}
      </Button>
      <Button
        onClick={proceed}
        variant={[ButtonVariant.Danger, ButtonVariant.Sm]}
      >
        {okButtonText}
      </Button>
    </DialogActions>
  </Dialog>
);
