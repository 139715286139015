import { INotification } from '@components/NotificationBanner/types';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IApplicantRiskOverrideItem } from '@schemas/opsPortal/types/applicantRiskOverrides';
import { AlertSeverity } from '@utils/enums';

export const useApplicantRiskOverrideNotifications = (
  applicantRiskOverrideData?: IApplicantRiskOverrideItem[],
): INotification[] => {
  const { translate } = useI18n();

  if (!applicantRiskOverrideData?.length) {
    return [];
  }

  return applicantRiskOverrideData
    .filter(override => !override.is_expired)
    .map(override => ({
      severity: AlertSeverity.Info,
      title: translate('OPS_PORTAL.LABEL.RISK_TEST_OVERRIDES_ACTIVE'),
      message: translate('OPS_PORTAL.LABEL.RISK_OVERRIDE_ACTIVE', {
        expiryTime: new Date(override.expiry_time).toLocaleString(),
        overrideTypes: override.override_lists
          .map(({ name }) => name)
          .join(', '),
      }),
      id: 'risk-override-notification',
    }));
};
