import { useMemo } from 'react';
import { Row } from '@tanstack/react-table';
import { ServersideTable } from '@components/Table/ServersideTable';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IFilter, IQueryState } from '@hooks/useQueryState/utils/types';
import { ICommunicationsTableData } from '@pages/ConsumerDetails/types';
import {
  getCommunicationsTableColumns,
  prepareCommunicationsTableData,
} from '@pages/ConsumerDetails/utils';
import {
  ICommunicationsChannel,
  IOpsPortalCommunications,
} from '@schemas/opsPortal/types/communications';
import { CommunicationsTypes } from '@utils/constants';
import { Viewer, ViewerSelector, ViewerWrapper } from './Communication.styles';
import { EventView } from './EventView';

interface ICardDisplayProps {
  filters: IFilter[];
  limit?: string;
  listData: IOpsPortalCommunications;
  manageControlledState: (newQueryState: object) => void;
  queryState: IQueryState;
  resetFilters: () => void;
  selectedCommunicationType: ICommunicationsChannel | '';
  selectedEventUUID: string;
  selectorChangeHandler: (row: Row<ICommunicationsTableData>) => void;
}

export const CardDisplay = ({
  filters,
  limit,
  listData,
  manageControlledState,
  queryState,
  resetFilters,
  selectedCommunicationType,
  selectedEventUUID,
  selectorChangeHandler,
}: ICardDisplayProps) => {
  const { getLocaleDate, translate } = useI18n();

  const columns = useMemo(
    () => getCommunicationsTableColumns(translate),
    [translate],
  );

  const communicationsTableData = prepareCommunicationsTableData({
    data: listData.results,
    getLocaleDate,
    translate,
  });

  return (
    <ViewerWrapper>
      <ViewerSelector>
        <ServersideTable
          columns={columns}
          data={communicationsTableData}
          enableRowSelection={true}
          filters={filters}
          handleRowSelect={selectorChangeHandler}
          manageControlledState={manageControlledState}
          pagination={{
            limit,
            totalItems: listData.count,
          }}
          resetFilters={resetFilters}
          tableState={queryState}
        />
      </ViewerSelector>
      <Viewer isEmail={selectedCommunicationType === CommunicationsTypes.EMAIL}>
        <EventView selectedEventUUID={selectedEventUUID} />
      </Viewer>
    </ViewerWrapper>
  );
};
