import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { ValueOf } from '@appTypes';
import { CommunicationIframe } from '@components/Common.styles';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { useModal } from '@hooks/useModal';
import { ModalSize } from '@hooks/useModal/components/enums';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { IOpsPortalCommunicationsEvent } from '@schemas/opsPortal/types/communicationsEvent';
import { CommunicationsTypes, QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { getDisplayData } from '@utils/getDisplayData';
import { useDownloadAttachmentQuery } from './useDownloadAttachmentQuery';

interface IViewCommunicationModalProps extends ISharedModalProps {
  title?: string;
  type?: ValueOf<typeof CommunicationsTypes>;
  uuid?: string;
}

export const useViewCommunicationModal = (
  props: IViewCommunicationModalProps,
): Modal => {
  const { title, type, uuid } = props;

  const modal = useModal({
    sharedProps: props,
    buttonTranslationKey: 'OPS_PORTAL.BUTTON.VIEW_COMMUNICATION',
    modalName: 'viewCommunicationModal',
    permissions: ['ops_portal.can_view_communication_events'],
  });
  const { isModalShowing } = modal;

  const {
    data: communicationData,
    error: communicationError,
    isLoading: isCommunicationLoading,
  } = useQuery<IOpsPortalCommunicationsEvent, AxiosError>(
    [QueryKeys.COMMUNICATIONS_EVENT, type, uuid],
    () =>
      fetchDataFromEndpoint(Endpoints.FetchCommunicationsEvent, {
        search: uuid,
      }),
    { enabled: isModalShowing },
  );

  const handleAttachmentDownload = useDownloadAttachmentQuery(uuid);

  const displayData = communicationData
    ? getDisplayData(communicationData)
    : '';

  const isEmail = type === CommunicationsTypes.EMAIL;

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      if (event.data.message === 'attachment-download') {
        handleAttachmentDownload(event.data);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, [handleAttachmentDownload]);

  const modalContent = (
    <QueryStateRouter
      error={communicationError}
      isLoading={isCommunicationLoading}
    >
      <CommunicationIframe src={displayData} />
    </QueryStateRouter>
  );

  const modalProps = {
    extratall: isEmail,
    width: isEmail ? ModalSize.Sm : undefined,
    isLoading: isCommunicationLoading,
    modalContent,
    showFormButtons: false,
    title,
  };

  return getModalDetails({ modal, modalProps });
};
