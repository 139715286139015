import KTOffcanvas from '@metronic/_assets/js/components/offcanvas';
import { KTUtil } from '@metronic/_assets/js/components/util';

const KTLayoutAside = (function ktLayoutAside() {
  // Private properties
  let body;
  let element;
  let offcanvasObject;

  const closeAside = () => offcanvasObject?.hide();

  const setupLinkCloseEvent = () => {
    const links = element.querySelectorAll('.aside-menu a');
    links.forEach(link => {
      link.addEventListener('click', closeAside);
    });
  };

  // Private functions
  // Initialize
  const init = function init() {
    const offcanvasClass = KTUtil.hasClass(element, 'aside-offcanvas-default')
      ? 'aside-offcanvas-default'
      : 'aside';

    // Initialize mobile aside offcanvas
    offcanvasObject = new KTOffcanvas(element, {
      baseClass: offcanvasClass,
      overlay: true,
      closeBy: 'kt_aside_close_btn',
      toggleBy: {
        target: 'kt_aside_mobile_toggle',
        state: 'mobile-toggle-active',
      },
    });

    setupLinkCloseEvent();
  };

  // Public methods
  return {
    init(id) {
      element = KTUtil.getById(id);
      body = KTUtil.getBody();

      if (!element) {
        return;
      }

      // Initialize
      init();
    },

    getElement() {
      return element;
    },

    getOffcanvas() {
      return offcanvasObject;
    },

    isFixed() {
      return KTUtil.hasClass(body, 'aside-fixed');
    },

    isMinimized() {
      return (
        KTUtil.hasClass(body, 'aside-fixed') &&
        KTUtil.hasClass(body, 'aside-minimize')
      );
    },

    isHoverable() {
      return (
        KTUtil.hasClass(body, 'aside-fixed') &&
        KTUtil.hasClass(body, 'aside-minimize-hoverable')
      );
    },
  };
})();

export default KTLayoutAside;
