import { useCallback, useEffect, useState } from 'react';
import { Permissions } from '@appTypes';
import { useHasPermission } from '@hooks/useHasPermission';
import { TranslateKey } from '@hooks/useI18n/types';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ISharedModalProps, IUseModalResponse } from './types';

interface IUseModal {
  buttonTranslationKey: TranslateKey;
  modalName: string;
  permissions: Permissions;
  sharedProps: ISharedModalProps;
}

export const useModal = ({
  buttonTranslationKey,
  modalName,
  permissions,
  sharedProps,
}: IUseModal): IUseModalResponse => {
  const { translate } = useI18n();
  const { dependencies, onClose } = sharedProps;
  const hasPermission = useHasPermission(permissions);
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [formStatusMessage, setFormStatusMessage] = useState('');

  const openModal = useCallback(() => setIsModalShowing(true), []);

  const closeModal = useCallback(() => {
    setIsModalShowing(false);
    onClose?.();
  }, [onClose]);

  useEffect(() => setFormStatusMessage(''), [isModalShowing]);

  const resolveForm = useCallback(
    message => setFormStatusMessage(message),
    [setFormStatusMessage],
  );

  const dependenciesFailed =
    dependencies?.length && !dependencies.every(Boolean);

  const canUseModal = hasPermission && !dependenciesFailed;
  const ctaText = translate(buttonTranslationKey);

  return {
    canUseModal,
    closeModal,
    ctaText,
    formStatusMessage,
    isModalShowing,
    modalName,
    openModal,
    resolveForm,
  };
};
