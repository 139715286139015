import { GetLocaleCurrencyValue } from '@hooks/useI18n';
import { IMerchantPortalTracking } from '@schemas/merchantPortal/types/tracking';

export interface IOrderSummary {
  label: string;
  value?: string | null;
}

export type MediamarktTransformKey =
  | 'mediamarktShoppingCardNumber'
  | 'mediamarktArticleNumber';

export type MobilezoneTransformKey =
  | 'mobilezoneProductName'
  | 'mobilezoneProductPrice'
  | 'mobilezoneProductImei'
  | 'mobilezoneProductEan';

export type CustomTransformKey =
  | 'address'
  | 'amount'
  | 'blank'
  | 'dob'
  | 'fullName'
  | 'products'
  | 'statusLabel'
  | MobilezoneTransformKey
  | MediamarktTransformKey
  | 'merchantStoreId'
  | 'merchantStoreName'
  | 'merchantStoreNumber'
  | 'merchantUserFullName';

export type OrderTrackingKey = keyof Partial<IMerchantPortalTracking>;

export type KnownTransforms = CustomTransformKey | OrderTrackingKey;

interface ISummaryFieldOptions {
  hideIfEmpty: boolean;
}

export interface ISummaryField {
  key: string;
  options?: ISummaryFieldOptions;
  value: KnownTransforms;
}

export type QrCodeType = 'mobilezone';

export interface IFormatters {
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
}

export interface ICustomTransform {
  data: IMerchantPortalTracking;
  formatters: IFormatters;
  translate?: Translate;
}
