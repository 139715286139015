import { useEffect } from 'react';
import { useI18n } from '@hooks/useI18n/useI18n';
import { redirectToExternalUrl } from '@utils/redirectToExternalUrl';

export const MerchantGuide = () => {
  const { translate } = useI18n();

  useEffect(() => {
    redirectToExternalUrl('https://docs.heidipay.com/docs');
  }, []);

  return (
    <p>{translate('PAGE.MERCHANT_GUIDE.REDIRECTING_TO_MERCHANT_GUIDE')}</p>
  );
};
