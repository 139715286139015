/* eslint-disable max-lines */
import { KTUtil } from './util';

// Component Definition
const KTToggle = function ktToggle(toggleElement, targetElement, options) {
  // Main object
  let the = this;

  // Get element object
  const element = toggleElement;
  const target = targetElement;

  if (!element) {
    return;
  }

  // Default options
  let defaultOptions = {
    targetToggleMode: 'class', // class|attribute
  };

  /// /////////////////////////
  // ** Private Methods  ** //
  /// /////////////////////////

  const Plugin = {
    /**
     * Construct
     */

    construct(opts) {
      if (KTUtil.data(element).has('toggle')) {
        the = KTUtil.data(element).get('toggle');
      } else {
        // reset menu
        Plugin.init(opts);

        // build menu
        Plugin.build();

        KTUtil.data(element).set('toggle', the);
      }

      return the;
    },

    /**
     * Handles subtoggle click toggle
     */
    init(opts) {
      the.element = element;
      the.events = [];

      // Merge default and user defined options
      the.options = KTUtil.deepExtend({}, defaultOptions, opts);

      the.target = target;

      the.targetState = the.options.targetState;
      the.toggleState = the.options.toggleState;

      if (the.options.targetToggleMode === 'class') {
        the.state = KTUtil.hasClasses(the.target, the.targetState)
          ? 'on'
          : 'off';
      } else {
        the.state = KTUtil.hasAttr(the.target, `data-${the.targetState}`)
          ? KTUtil.attr(the.target, `data-${the.targetState}`)
          : 'off';
      }
    },

    /**
     * Setup toggle
     */
    build() {
      KTUtil.addEvent(element, 'mouseup', Plugin.toggle);
    },

    /**
     * Handles offcanvas click toggle
     */
    toggle(e) {
      Plugin.eventTrigger('beforeToggle');

      if (the.state === 'off') {
        Plugin.toggleOn();
      } else {
        Plugin.toggleOff();
      }

      Plugin.eventTrigger('afterToggle');

      e.preventDefault();

      return the;
    },

    /**
     * Handles toggle click toggle
     */
    toggleOn() {
      Plugin.eventTrigger('beforeOn');

      if (the.options.targetToggleMode === 'class') {
        KTUtil.addClass(the.target, the.targetState);
      } else {
        KTUtil.attr(the.target, `data-${the.targetState}`, 'on');
      }

      if (the.toggleState) {
        KTUtil.addClass(element, the.toggleState);
      }

      the.state = 'on';

      Plugin.eventTrigger('afterOn');

      Plugin.eventTrigger('toggle');

      return the;
    },

    /**
     * Handles toggle click toggle
     */
    toggleOff() {
      Plugin.eventTrigger('beforeOff');

      if (the.options.targetToggleMode === 'class') {
        KTUtil.removeClass(the.target, the.targetState);
      } else {
        KTUtil.removeAttr(the.target, `data-${the.targetState}`);
      }

      if (the.toggleState) {
        KTUtil.removeClass(element, the.toggleState);
      }

      the.state = 'off';

      Plugin.eventTrigger('afterOff');

      Plugin.eventTrigger('toggle');

      return the;
    },

    eventTrigger(name) {
      // eslint-disable-next-line consistent-return
      the.events.forEach((event, i) => {
        if (event.name === name) {
          if (event.one) {
            if (!event.fired) {
              the.events[i].fired = true;
              return event.handler.call(this, the);
            }
          } else {
            return event.handler.call(this, the);
          }
        }
      });
    },

    addEvent(name, handler, one) {
      the.events.push({
        name,
        handler,
        one,
        fired: false,
      });

      return the;
    },
  };

  /// ///////////////////////
  // ** Public Methods ** //
  /// ///////////////////////

  /**
   * Set default options
   */
  the.setDefaults = function setDefaults(opts) {
    defaultOptions = opts;
  };

  /**
   * Get toggle state
   */
  the.getState = () => the.state;

  /**
   * Toggle
   */
  the.toggle = () => Plugin.toggle();

  /**
   * Toggle on
   */
  the.toggleOn = () => Plugin.toggleOn();

  /**
   * Toggle off
   */
  the.toggleOff = () => Plugin.toggleOff();

  /**
   * Attach event
   * @returns {KTToggle}
   */
  the.on = (name, handler) => Plugin.addEvent(name, handler);

  /**
   * Attach event that will be fired once
   * @returns {KTToggle}
   */
  the.one = (name, handler) => Plugin.addEvent(name, handler, true);

  // Construct plugin
  Plugin.construct.apply(the, [options]);
};

export default KTToggle;
