import { Translate } from '@hooks/useI18n';
import { TableNames } from '@utils/constants';
import { IFilter, IRootFilter, ITableNames } from './types';

interface IFilterProp {
  accessorKey: string;
  fromId: string;
  toId: string;
}

const filterProps: Partial<Record<ITableNames, IFilterProp>> = {
  [TableNames.API_CALLS]: {
    accessorKey: 'timestamp',
    fromId: 'timestampFrom',
    toId: 'timestampTo',
  },
  [TableNames.BANK_ACCOUNT_RECONCILIATIONS]: {
    accessorKey: 'transactionDate',
    fromId: 'transactionDateFrom',
    toId: 'transactionDateTo',
  },
  [TableNames.COMMUNICATIONS]: {
    accessorKey: 'sentAt',
    fromId: 'sentDateFrom',
    toId: 'sentDateTo',
  },
  [TableNames.BLOCKED_APPLICANTS]: {
    accessorKey: 'unblockDate',
    fromId: 'unblockDateFrom',
    toId: 'unblockDateTo',
  },
  [TableNames.MEDIOBANCA_RECONCILIATIONS]: {
    accessorKey: 'settlementDate',
    fromId: 'fromDate',
    toId: 'toDate',
  },
  [TableNames.ORDERS]: {
    accessorKey: 'dateConfirmed',
    fromId: 'dateFrom',
    toId: 'dateTo',
  },
  [TableNames.SETTLEMENTS]: {
    accessorKey: 'settlementDate',
    fromId: 'fromDate',
    toId: 'toDate',
  },
  [TableNames.SUSPENSE]: {
    accessorKey: 'transactionDate',
    fromId: 'transactionDateFrom',
    toId: 'transactionDateTo',
  },
  [TableNames.PENDING_ALLOCATIONS]: {
    accessorKey: 'allocationDate',
    fromId: 'allocationDateFrom',
    toId: 'allocationDateTo',
  },
  [TableNames.USERS]: {
    accessorKey: 'lastLogin',
    fromId: 'lastLoginFrom',
    toId: 'lastLoginTo',
  },
};

interface IGetDateFilter {
  queryParams: Record<string, string>;
  tableName: ITableNames;
  translate: Translate;
}

export const getDateFilter = ({
  queryParams,
  tableName,
  translate,
}: IGetDateFilter): IFilter => {
  const { accessorKey, fromId, toId } = filterProps[tableName] ?? {
    accessorKey: 'createdDate',
    fromId: 'createdDateFrom',
    toId: 'createdDateTo',
  };

  return {
    accessorKey,
    from: {
      id: fromId,
      label: translate('LABEL.START_DATE'),
      value: queryParams?.[fromId],
    } as IRootFilter,
    id: '',
    to: {
      id: toId,
      label: translate('LABEL.END_DATE'),
      value: queryParams?.[toId],
    } as IRootFilter,
    type: 'daterange',
  };
};
