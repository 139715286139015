interface IGetLocaleCurrencyValue {
  currency?: string;
  languageCode: string;
  value?: string;
}

export const getLocaleCurrencyValue = ({
  currency,
  languageCode,
  value,
}: IGetLocaleCurrencyValue) => {
  if (Number.isNaN(Number(value))) {
    return '';
  }

  if (!currency) {
    return value;
  }

  const valueAsNumber = Number(value);

  return valueAsNumber.toLocaleString(languageCode, {
    currency,
    style: 'currency',
  });
};
