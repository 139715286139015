import { useMemo } from 'react';
import { Tabs } from '@components/Tabs';
import { useContractDocumentsTab } from '@hooks/useContractsDocumentsTab';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ITabsComponentProps } from '@pages/ApplicationDetails/types';
import {
  generateTabData,
  mapApplicationData,
  mapApplicantData,
} from '@pages/ApplicationDetails/utils';
import { TabEventKeys } from '@utils/constants';

export const TabsComponent = ({
  data,
  isCompass,
  nextPayment,
}: ITabsComponentProps) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const opsCanViewContractDocumentsTab = useHasPermission([
    'ops_portal.can_view_customer_agreements',
  ]);

  const overviewData = useMemo(
    () =>
      mapApplicationData({
        data,
        getLocaleCurrencyValue,
        getLocaleDate,
        isCompass,
        nextPayment,
        translate,
      }),
    [
      data,
      getLocaleCurrencyValue,
      getLocaleDate,
      isCompass,
      nextPayment,
      translate,
    ],
  );

  const applicantData = useMemo(
    () =>
      mapApplicantData({
        data,
        getLocaleDate,
        translate,
      }),
    [data, getLocaleDate, translate],
  );

  const staticListData = useMemo(
    () =>
      generateTabData({
        applicantData,
        isCompass,
        overviewData,
        translate,
      }),
    [applicantData, isCompass, overviewData, translate],
  );

  const documentData = useContractDocumentsTab({
    contractUuid: overviewData?.contractUUID,
  });

  const tabDataArray = [
    ...staticListData,
    ...(opsCanViewContractDocumentsTab ? [documentData] : []),
  ];

  return (
    <Tabs
      initialTabKey={TabEventKeys.APPLICATION_OVERVIEW}
      tabsData={tabDataArray}
      tabsId="application-drilldown-tabs"
    />
  );
};
