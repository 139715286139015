import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { ISettlementTransactions } from '@schemas/merchantPortal/types/settlementTransactions';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { RawStatuses } from '@utils/constants';
import { getTranslatedData } from '@utils/getTranslatedData';
import { getReceivableValue } from './getReceivableValue';
import { TransactionsTableData } from './types';

interface IPrepareTransactionsTableData {
  data: ISettlementTransactions;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const prepareTransactionsTableData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  translate,
}: IPrepareTransactionsTableData): TransactionsTableData =>
  data.map(dataRow => {
    const {
      amount: { amount, currency },
      merchant_order_id: merchantOrderId,
      payment_plan_id: paymentPlanID,
      payor: { type: payorType },
      receivable: { type: receivableType },
      transaction_date: date,
      uuid: transactionID,
    } = dataRow;

    const signedAmount = getReceivableValue({
      amount,
      payorType,
    });

    const rawStatus = receivableType.toLowerCase() || RawStatuses.UNKNOWN;

    const status = getTranslatedData({ term: rawStatus, translate });

    return addPlaceholderForEmptyValues({
      amount: currency && getLocaleCurrencyValue({ currency, value: amount }),
      currency,
      date: date && getLocaleDate({ date }),
      merchantOrderId,
      paymentPlanID,
      rawStatus,
      signedAmount,
      status,
      transactionID,
      type: status,
    });
  });
