import { useCallback } from 'react';
import { useI18n } from '@hooks/useI18n/useI18n';
import {
  PaginationCurrentPage,
  PaginationGotoButton,
  PaginationPlaceholder,
  PaginationStepButton,
} from './Pagination.styled';
import { IPaginationButtons } from './types';
import { getPagesToDisplay } from './utils';

export const PaginationButtons = ({
  canNextPage,
  canPreviousPage,
  gotoPage,
  nextPage,
  pageIndex,
  pageOptions,
  previousPage,
}: IPaginationButtons) => {
  const gotoOptionPage = useCallback(
    e => gotoPage(Number(e.target.dataset.option)),
    [gotoPage],
  );

  const { translate } = useI18n();

  const pagesToDisplay = getPagesToDisplay({
    pages: pageOptions,
    currentPageIndex: pageIndex,
  });

  return (
    <div>
      <PaginationStepButton disabled={!canPreviousPage} onClick={previousPage}>
        {translate('BUTTON.PREVIOUS')}
      </PaginationStepButton>
      {pagesToDisplay.length ? (
        pagesToDisplay.map(page => {
          if (typeof page.idx === 'undefined') {
            return (
              <PaginationPlaceholder key={page.id}>
                {page.label}
              </PaginationPlaceholder>
            );
          }

          if (Number(pageIndex) === page.idx) {
            return (
              <PaginationCurrentPage key={page.id}>
                {page.label}
              </PaginationCurrentPage>
            );
          }

          return (
            <PaginationGotoButton
              data-option={page.idx}
              key={page.id}
              onClick={gotoOptionPage}
            >
              {page.label}
            </PaginationGotoButton>
          );
        })
      ) : (
        <PaginationCurrentPage key="current">1</PaginationCurrentPage>
      )}
      <PaginationStepButton disabled={!canNextPage} onClick={nextPage}>
        {translate('BUTTON.NEXT')}
      </PaginationStepButton>
    </div>
  );
};
