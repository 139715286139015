import { createColumnHelper } from '@tanstack/react-table';
import { StaticTable } from '@components/Table/StaticTable';
import { useI18n } from '@hooks/useI18n/useI18n';

export interface IScheduledPaymentsColumns {
  dueDate: string;
  paymentAmount: string;
}

interface IScheduledPaymentsProps {
  payments: IScheduledPaymentsColumns[];
}

export const ScheduledPayments = ({ payments }: IScheduledPaymentsProps) => {
  const { translate } = useI18n();

  const columnHelper = createColumnHelper<IScheduledPaymentsColumns>();

  const columns = [
    columnHelper.accessor('dueDate', { header: translate('TITLE.DUE_DATE') }),
    columnHelper.accessor('paymentAmount', {
      header: translate('TITLE.PAYMENT_AMOUNT'),
    }),
  ];

  return <StaticTable columns={columns} data={payments} />;
};
