import { useField } from 'formik';
import { FormGroup, Label, Select } from '@components/Common.styles';
import { Tooltip } from '@components/Tooltip';
import { ITooltipContent } from '@components/types.d';
import { ValidationError } from '@components/ValidationError';

interface IDropdownOptions {
  id: string;
  isPlaceholder?: boolean;
  name: string;
}

interface IDropdownProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  name: string;
  options: IDropdownOptions[];
  tooltip?: ITooltipContent;
}

export const Dropdown = ({
  label,
  options,
  tooltip,
  ...props
}: IDropdownProps) => {
  const { className: extraClassName, ...remainingProps } = props;
  const [field, meta] = useField(remainingProps);

  return (
    <FormGroup>
      <Label htmlFor={props.name}>{label}</Label>
      {tooltip ? <Tooltip tip={tooltip} /> : null}
      <Select extraClassName={extraClassName} {...field} {...remainingProps}>
        {options.map(option => (
          <option
            disabled={option.isPlaceholder}
            key={option.id}
            value={option.id}
          >
            {option.name}
          </option>
        ))}
      </Select>
      {meta.touched && meta.error ? (
        <ValidationError error={meta.error} />
      ) : null}
    </FormGroup>
  );
};
