import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Card } from '@components/Card';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useMerchantSettingGroup } from '@hooks/useMerchantSettingGroup/useMerchantSettingGroup';
import { IMerchantPortalTracking } from '@schemas/merchantPortal/types/tracking';
import { QueryKeys } from '@utils/constants';
import { FinancingRequestApproved } from './FinancingRequestApproved';
import { useGetTrackingButtons } from './hooks/useGetTrackingButtons';
import { useTrackingData } from './hooks/useTrackingData';
import { StepList } from './StepList';
import { TrackingGridContainer, TrackingGridItem } from './Tracking.styles';
import { TrackingQrCode } from './TrackingQrCode';
import { TrackingSummary } from './TrackingSummary';
import { getIsApplicationSuccessful } from './utils/getIsApplicationSuccessful';

export const Tracking = () => {
  const { getLocaleCurrencyValue, selectedLanguage, translate } = useI18n();
  const [refetchInterval, setRefetchInterval] = useState(1000);

  const { getMerchantSetting } = useMerchantSettingGroup();
  const config = getMerchantSetting('ObservationViewConfig');
  const { contractId } = useParams();

  const queryClient = useQueryClient();
  const queryKey = [QueryKeys.ORDER_TRACKING, contractId, selectedLanguage];

  const handleSuccess = useCallback(data => {
    if (getIsApplicationSuccessful(data?.statusLabel)) {
      setRefetchInterval(0);
    }
  }, []);

  const { data, error, isLoading } = useTrackingData({
    contractId,
    options: {
      refetchInterval,
      handleError: () => setRefetchInterval(0),
      handleSuccess,
    },
    queryKey,
  });

  const { buttons, fileDownloadModal, fileUploadModal } = useGetTrackingButtons(
    {
      queryClient,
      queryKey,
      contractId,
      data,
      config,
    },
  );

  return (
    <QueryStateRouter error={error} isLoading={isLoading}>
      {data ? (
        <Card
          buttons={buttons}
          title={translate('PAGE.TRACKING.TRACK_CHECKOUT')}
        >
          <TrackingSummary config={config} data={data} />
          <FinancingRequestApproved data={data} translate={translate} />
          <TrackingGridContainer container={true}>
            <TrackingGridItem item={true} md={6}>
              <StepList
                progressData={
                  data?.progressData as IMerchantPortalTracking['progressData']
                }
              />
            </TrackingGridItem>
            {config?.qrCode ? (
              <TrackingQrCode
                config={config}
                data={data}
                getLocaleCurrencyValue={getLocaleCurrencyValue}
                translate={translate}
              />
            ) : null}
          </TrackingGridContainer>
          {fileDownloadModal?.Modal}
          {fileUploadModal?.Modal}
        </Card>
      ) : null}
    </QueryStateRouter>
  );
};
