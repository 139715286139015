import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { actions } from '@auth/_redux';
import { logoutChannel } from '@auth/hooks/useCheckForLogOut';
import { useOriginator } from '@hooks/useOriginator';
import { LayoutSplashScreen } from '@metronic/layout';
import { originatorActions } from '@redux/originatorRedux';
import { selectHasAuthToken } from '@selectors';
import { AuthPathnames } from '@utils/constants';

export const Logout = () => {
  const queryClient = useQueryClient();
  queryClient.clear();
  queryClient.invalidateQueries({ refetchType: 'none' });
  queryClient.removeQueries();

  const { hasAuthToken } = useSelector(selectHasAuthToken);
  const { pathnamePrefix } = useOriginator();
  const path = pathnamePrefix + AuthPathnames.LOGIN;

  const dispatch = useDispatch();

  useEffect(() => {
    logoutChannel.postMessage('Logout');
    dispatch(actions.logout());
    dispatch(originatorActions.logout());
  });

  return hasAuthToken ? (
    <LayoutSplashScreen />
  ) : (
    <Navigate replace={true} to={path} />
  );
};
