import { useEffect, useRef } from 'react';
import { InputCheckbox } from '@components/Common.styles';
import { IUuid } from '@schemas/opsPortal/types/consumerDetails';

interface ISelectorProps {
  checked: boolean;
  disabled?: boolean;
  onChange: (event: unknown) => void;
  value: IUuid;
}

export const Selector = (props: ISelectorProps) => {
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = false;
    }
  }, [ref]);

  return <InputCheckbox name="eventSelector" ref={ref} {...props} />;
};
