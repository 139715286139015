import { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card } from '@components/Card/Card.tsx';
import { Loading } from '@components/Loading/Loading.tsx';
import { ServersideTable } from '@components/Table/ServersideTable.tsx';
import { useI18n } from '@hooks/useI18n/useI18n.tsx';
import { useOriginator } from '@hooks/useOriginator/useOriginator.ts';
import { useQueryState } from '@hooks/useQueryState/useQueryState.ts';
import { QueryKeys, TableNames } from '@utils/constants';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint/fetchDataFromEndpoint.ts';
import { getErrorMessage } from '@utils/getErrorMessage/getErrorMessage.ts';
import { getConsumerTableColumns } from './getConsumerTableColumns';
import { prepareConsumerTableData } from './prepareConsumerTableData';

export const Consumers = () => {
  const { getLocaleDate, translate } = useI18n();
  const { originator } = useOriginator();

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(TableNames.CONSUMERS);

  const { data, error, isLoading } = useQuery(
    [QueryKeys.CONSUMER_TABLE_DATA, stringifiedQueryParams, originator],
    () =>
      fetchDataFromEndpoint('consumers', {
        query: { ...queryParams, originator },
      }),
  );

  const columns = useMemo(
    () => getConsumerTableColumns(translate),
    [translate],
  );

  const filters = getFilters();

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <p>{getErrorMessage({ error, translate })}</p>;
  }

  const consumerTableData = prepareConsumerTableData({
    data: data.results,
    getLocaleDate,
  });

  return (
    <Card title={translate('OPS_PORTAL.TITLE.CONSUMERS')}>
      <ServersideTable
        columns={columns}
        data={consumerTableData}
        filters={filters}
        manageControlledState={updateQueryParams}
        pagination={{
          limit: queryParams.limit,
          totalItems: data?.count,
        }}
        resetFilters={resetFilters}
        tableState={queryState}
      />
    </Card>
  );
};
