import { DataDisplay } from '@components/DataDisplay';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useOriginator } from '@hooks/useOriginator';
import { usePortal } from '@hooks/usePortal';
import { getDataAndLabels } from '@pages/OrderDetails/utils';
import { getDataList } from '@utils/getDataList';

export const OrderSummary = (props: Record<string, unknown>) => {
  const { translate } = useI18n();
  const { isPagolightPro } = useOriginator();
  const { isMerchantPortal } = usePortal();

  const { data, labels } = getDataAndLabels({
    isMerchantPortal,
    isPagolightPro,
    props,
    translate,
  });

  const dataList = getDataList({ data, labels });
  return <DataDisplay dataList={dataList} />;
};
