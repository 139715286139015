import { GetLocaleDate, Translate } from '@hooks/useI18n';
import {
  ICommunications,
  IOpsPortalCommunicationsEvent,
} from '@schemas/opsPortal/types/communications';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { ICommunicationsTableData } from '../types';

interface IPrepareCommunicationsTableDataProps {
  data: ICommunications;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

const dict = {
  email: 'OPS_PORTAL.PAGE.COMMUNICATIONS.EMAIL',
  text_message: 'OPS_PORTAL.PAGE.COMMUNICATIONS.TEXT_MESSAGE',
};

export const prepareCommunicationsTableData = ({
  data,
  getLocaleDate,
  translate,
}: IPrepareCommunicationsTableDataProps): ICommunicationsTableData[] =>
  data.map((dataRow: IOpsPortalCommunicationsEvent) => {
    const {
      channel: communicationType,
      sent_at: sentDate,
      uuid: eventUUID,
    } = dataRow;

    return addPlaceholderForEmptyValues({
      communicationType,
      communicationTypeLabel:
        communicationType &&
        translate(
          dict[communicationType as keyof typeof dict] || communicationType,
        ),
      eventUUID,
      sentDate:
        sentDate && getLocaleDate({ date: sentDate, includeTime: true }),
    });
  });
