import KTToggle from '@metronic/_assets/js/components/toggle';
import { KTUtil } from '@metronic/_assets/js/components/util';

const KTLayoutHeaderTopbar = (function ktLayoutHeaderTopbar() {
  // Private properties
  let toggleElement;

  // Private functions
  const init = () =>
    new KTToggle(toggleElement, KTUtil.getBody(), {
      targetState: 'topbar-mobile-on',
      toggleState: 'active',
    });

  // Public methods
  return {
    init(id) {
      toggleElement = KTUtil.getById(id);

      // Initialize
      init();
    },

    getToggleElement() {
      return toggleElement;
    },
  };
})();

export default KTLayoutHeaderTopbar;
