import { useMutation } from '@tanstack/react-query';
import { ITableParams } from '@hooks/useQueryState/utils/types';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpointCsv } from '@utils/fetchDataFromEndpoint';
import { ICsvColumnConfig } from '../types';

interface IUseGetApplicationCsvQuery {
  columnConfig: ICsvColumnConfig[];
  queryParams: ITableParams;
}

export const useGetApplicationsCsvQuery = ({
  columnConfig,
  queryParams,
}: IUseGetApplicationCsvQuery) => {
  const { isLoading: isLoadingCsv, mutate: downloadCsv } = useMutation(() =>
    fetchDataFromEndpointCsv(
      Endpoints.FetchApplicationsCsv,
      {
        query: queryParams,
      },
      'applications',
      true,
      columnConfig,
    ),
  );

  return {
    isLoadingCsv,
    downloadCsv,
  };
};
