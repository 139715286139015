import { dictionary } from '@utils/dictionary';
import { ISortColumns, ISortListProps } from './types';

export const getSortList = ({ queryState, table }: ISortListProps) =>
  queryState.sorting
    .map(
      sortObj =>
        `${sortObj.desc ? '-' : ''}${
          (dictionary.sort_columns as ISortColumns)[table]?.[sortObj.id]
        }`,
    )
    .join(',');
