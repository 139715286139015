import { IGenerateTabDataProps } from '@pages/ApplicationDetails/types';
import { TabEventKeys } from '@utils/constants';
import { getDataList } from '@utils/getDataList';
import { getFieldNames } from './getFieldNames';

export const generateTabData = ({
  applicantData,
  isCompass,
  overviewData,
  translate,
}: IGenerateTabDataProps) => {
  const fieldNames = getFieldNames({
    isCompass,
    translate,
  });

  const overview = {
    eventKey: TabEventKeys.APPLICATION_OVERVIEW,
    title: translate('OPS_PORTAL.TITLE.OVERVIEW'),
    dataList: getDataList({
      data: overviewData,
      labels: fieldNames.overview,
    }),
  };

  const applicantDetails = {
    eventKey: TabEventKeys.APPLICANT_DETAILS,
    title: translate('OPS_PORTAL.TITLE.APPLICANT_DETAILS'),
    dataList: getDataList({
      data: applicantData,
      labels: fieldNames.applicantDetails,
    }),
  };

  return [overview, applicantDetails];
};
