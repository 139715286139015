import styled from 'styled-components';
import { Button } from '@components/Common.styles';

interface IStyledComponentWithClassName {
  extraClassName?: string;
}

export const SortingButton = styled(Button)`
  margin: 0 0 0 -1rem;
  font-size: inherit;
  font-weight: inherit;
  white-space: nowrap;

  span {
    display: inline-block;
    width: 1rem;
    margin-inline-start: 0.5rem;
  }
`;

export const Table = styled.table.attrs(
  ({ extraClassName = '' }: IStyledComponentWithClassName) => ({
    className: `table table-borderless table-vertical-center ${extraClassName}`,
  }),
)<IStyledComponentWithClassName>`
  .row & th {
    padding-block-start: 0;
  }
  th:has(button) {
    padding-block-end: 0.75rem;
  }
  tr.row-selected {
    background-color: #f3f6f9;
  }
`;

export const TableWrapper = styled.div.attrs({
  className: 'table-responsive',
})``;

export const TFoot = styled.tfoot`
  font-weight: 600;
  th {
    text-align: right;
  }
`;
