import { createColumnHelper } from '@tanstack/react-table';
import { Link } from '@components/Common.styles';
import { Translate } from '@hooks/useI18n';
import { IPausedContractTableColumns } from './types';

export const getPausedContractsTableColumns = (translate: Translate) => {
  const columnHelper = createColumnHelper<IPausedContractTableColumns>();

  return [
    columnHelper.accessor('externalUuid', {
      header: translate('OPS_PORTAL.LABEL.CONTRACT_UUID'),
      cell: ({ row }) => (
        <Link to={`/paused_contracts/${row.original.externalUuid}`}>
          {row.original.externalUuid}
        </Link>
      ),
    }),
    columnHelper.accessor('customerName', {
      header: translate('TITLE.CUSTOMER_NAME'),
      enableSorting: false,
    }),
    columnHelper.accessor('pausedTill', {
      header: translate('OPS_PORTAL.LABEL.PAUSED_UNTIL'),
    }),
  ];
};
