export enum OpsPortalDocumentTypes {
  CustomerAgreement = 'Customer agreement',
  IntrumIdentificationFiles = 'Intrum identification files',
  IntrumSigningFiles = 'Intrum signing files',
  OnfidoBack = 'Onfido back',
  OnfidoFront = 'Onfido front',
  OnfidoHealthCard = 'Onfido health card',
  OnfidoSelfie = 'Onfido selfie',
  TermsAndConditionsConclusion = 'Terms and conditions (Conclusion)',
  TermsAndConditionsFinalization = 'Terms and conditions (Finalization)',
  TradeInOptionAgreement = 'Trade in option agreement',
}
