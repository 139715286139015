// This hook is based on the hook developed by John Reilly at
// https://blog.logrocket.com/use-state-url-persist-state-usesearchparams/.
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSearchParamsState = (
  searchParamName: string,
): [string | null, (newState: string) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamsState = searchParams.get(searchParamName);

  const setSearchParamsState = useCallback(
    (newState: string) => {
      const currentParams: Record<string, string> = [
        ...searchParams.entries(),
      ].reduce(
        (obj: Record<string, string>, [key, value]) => ({
          ...obj,
          [key]: value,
        }),
        {},
      );

      if (newState) {
        setSearchParams({
          ...currentParams,
          [searchParamName]: newState,
        });
      } else {
        delete currentParams[searchParamName];
        setSearchParams(currentParams);
      }
    },
    [searchParamName, searchParams, setSearchParams],
  );

  return [searchParamsState, setSearchParamsState];
};
