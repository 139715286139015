import { getNavData } from '@hooks/useConfig/utils';
import { ConfigFunc } from './types';

const { NavItems, NavSectionHeadingKeys } = getNavData();

export const configConsumerPagolightpro: ConfigFunc = () => {
  return Object.freeze({
    canChangePassword: true,
    canEditAddress: false,
    canEditName: false,
    canEditPhone: false,
    navigation: [
      {
        heading: undefined,
        items: [NavItems.ORDERS_CONSUMER_PAGO],
      },
      {
        heading: NavSectionHeadingKeys.HELP,
        items: [NavItems.CARD_CHANGE_GUIDE],
      },
      {
        heading: NavSectionHeadingKeys.ACCOUNT_MANAGEMENT,
        items: [NavItems.ACCOUNT_SETTINGS, NavItems.LOGOUT],
      },
    ],
    showCompassOrders: true,
    showFooter: false,
    showFooterPrivacyLink: false,
    showInvoiceDetails: false,
    showLanguageDropdown: false,
    showProfilePicker: true,
  });
};
