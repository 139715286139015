/* eslint-disable max-lines */
import { KTUtil } from './util';

const attrDataHorDirection = 'data-hor-direction';
const attrDataHover = 'data-hover';
const attrDataTimeout = 'data-timeout';
const attrDataMenuSubmenuMode = 'data-menu-submenu-mode';
const attrDataMenuToggleClass = 'data-menu-toggle-class';
const classMenuItem = '.menu-item';
const classMenuItemHover = 'menu-item-hover';
const classMenuItemOpen = 'menu-item-open';
const classMenuItemOpenDropdown = 'menu-item-open-dropdown';
const classMenuItemSubmenu = '.menu-item-submenu';
const classMenuSubmenu = 'menu-submenu';
const classMenuText = '.menu-text';

// Component Definition
const KTMenu = function ktMenu(elementId, options) {
  // Main object
  let the = this;
  let init = false;

  // Get element object
  const element = KTUtil.getById(elementId);
  const body = KTUtil.getBody();

  if (!element) {
    return;
  }

  // Default options
  let defaultOptions = {
    // scrollable area with Perfect Scroll
    scroll: {
      rememberPosition: false,
    },

    // accordion submenu mode
    accordion: {
      slideSpeed: 200, // accordion toggle slide speed in milliseconds
      autoScroll: false, // enable auto scrolling(focus) to the clicked menu item
      autoScrollSpeed: 1200,
      expandAll: true, // allow having multiple expanded accordions in the menu
    },

    // dropdown submenu mode
    dropdown: {
      timeout: 500, // timeout in milliseconds to show and hide the hoverable submenu dropdown
    },
  };

  /// /////////////////////////
  // ** Private Methods  ** //
  /// /////////////////////////

  const Plugin = {
    /**
     * Run plugin
     * @returns {KTMenu}
     */
    construct(opts) {
      if (KTUtil.data(element).has('menu')) {
        the = KTUtil.data(element).get('menu');
      } else {
        // reset menu
        Plugin.init(opts);

        // reset menu
        Plugin.reset();

        // build menu
        Plugin.build();

        KTUtil.data(element).set('menu', the);
      }

      return the;
    },

    /**
     * Handles submenu click toggle
     * @returns {KTMenu}
     */
    init(opts) {
      the.events = [];

      the.eventHandlers = {};

      // merge default and user defined options
      the.options = KTUtil.deepExtend({}, defaultOptions, opts);

      // pause menu
      the.pauseDropdownHoverTime = 0;

      the.uid = KTUtil.getUniqueID();
    },

    update(opts) {
      // merge default and user defined options
      the.options = KTUtil.deepExtend({}, defaultOptions, opts);

      // pause menu
      the.pauseDropdownHoverTime = 0;

      // reset menu
      Plugin.reset();

      the.eventHandlers = {};

      // build menu
      Plugin.build();

      KTUtil.data(element).set('menu', the);
    },

    reload() {
      // reset menu
      Plugin.reset();

      // build menu
      Plugin.build();

      // reset submenu props
      Plugin.resetSubmenuProps();
    },

    /**
     * Reset menu
     * @returns {KTMenu}
     */
    build() {
      // General accordion submenu toggle
      the.eventHandlers.event_1 = KTUtil.on(
        element,
        '.menu-toggle',
        'click',
        Plugin.handleSubmenuAccordion,
      );

      // Dropdown mode(hoverable)
      if (Plugin.isConditionalSubmenuDropdown()) {
        // dropdown submenu - hover toggle
        the.eventHandlers.event_2 = KTUtil.on(
          element,
          '[data-menu-toggle="hover"]',
          'mouseover',
          Plugin.handleSubmenuDrodownHoverEnter,
        );
        the.eventHandlers.event_3 = KTUtil.on(
          element,
          '[data-menu-toggle="hover"]',
          'mouseout',
          Plugin.handleSubmenuDrodownHoverExit,
        );

        // dropdown submenu - click toggle
        the.eventHandlers.event_4 = KTUtil.on(
          element,
          '[data-menu-toggle="click"] > .menu-toggle, [data-menu-toggle="click"] > .menu-link .menu-toggle',
          'click',
          Plugin.handleSubmenuDropdownClick,
        );
        the.eventHandlers.event_5 = KTUtil.on(
          element,
          '[data-menu-toggle="tab"] > .menu-toggle, [data-menu-toggle="tab"] > .menu-link .menu-toggle',
          'click',
          Plugin.handleSubmenuDropdownTabClick,
        );
      }

      // Handle general link click
      the.eventHandlers.event_6 = KTUtil.on(
        element,
        '.menu-item > .menu-link:not(.menu-toggle):not(.menu-link-toggle-skip)',
        'click',
        Plugin.handleLinkClick,
      );

      // Init scrollable menu
      if (the.options.scroll?.height) {
        Plugin.scrollInit();
      }
    },

    /**
     * Reset menu
     * @returns {KTMenu}
     */
    reset() {
      KTUtil.off(element, 'click', the.eventHandlers.event_1);

      // dropdown submenu - hover toggle
      KTUtil.off(element, 'mouseover', the.eventHandlers.event_2);
      KTUtil.off(element, 'mouseout', the.eventHandlers.event_3);

      // dropdown submenu - click toggle
      KTUtil.off(element, 'click', the.eventHandlers.event_4);
      KTUtil.off(element, 'click', the.eventHandlers.event_5);

      // handle link click
      KTUtil.off(element, 'click', the.eventHandlers.event_6);
    },

    /**
     * Init scroll menu
     *
     */
    scrollInit() {
      if (the.options.scroll?.height) {
        KTUtil.scrollDestroy(element, true);
        KTUtil.scrollInit(element, {
          mobileNativeScroll: true,
          windowScroll: false,
          resetHeightOnDestroy: true,
          handleWindowResize: true,
          height: the.options.scroll.height,
          rememberPosition: the.options.scroll.rememberPosition,
        });
      } else {
        KTUtil.scrollDestroy(element, true);
      }
    },

    /**
     * Update scroll menu
     */
    scrollUpdate() {
      if (the.options.scroll?.height) {
        KTUtil.scrollUpdate(element);
      }
    },

    /**
     * Scroll top
     */
    scrollTop() {
      if (the.options.scroll?.height) {
        KTUtil.scrollTop(element);
      }
    },

    /**
     * Get submenu mode for current breakpoint and menu state
     * @returns {KTMenu}
     */
    // eslint-disable-next-line consistent-return
    getSubmenuMode(el) {
      if (KTUtil.isBreakpointUp('lg')) {
        if (
          el &&
          KTUtil.hasAttr(el, 'data-menu-toggle') &&
          KTUtil.attr(el, 'data-menu-toggle') === 'hover'
        ) {
          return 'dropdown';
        }

        if (KTUtil.isset(the.options.submenu, 'desktop.state.body')) {
          if (KTUtil.hasClasses(body, the.options.submenu.desktop.state.body)) {
            return the.options.submenu.desktop.state.mode;
          }
          return the.options.submenu.desktop.default;
        }
        if (KTUtil.isset(the.options.submenu, 'desktop')) {
          return the.options.submenu.desktop;
        }
      } else if (
        KTUtil.isBreakpointUp('md') &&
        KTUtil.isBreakpointDown('lg') &&
        KTUtil.isset(the.options.submenu, 'tablet')
      ) {
        return the.options.submenu.tablet;
      } else if (
        KTUtil.isBreakpointDown('md') &&
        KTUtil.isset(the.options.submenu, 'mobile')
      ) {
        return the.options.submenu.mobile;
      } else {
        return false;
      }
    },

    /**
     * Get submenu mode for current breakpoint and menu state
     * @returns {KTMenu}
     */
    isConditionalSubmenuDropdown() {
      return (
        KTUtil.isBreakpointUp('lg') &&
        KTUtil.isset(the.options.submenu, 'desktop.state.body')
      );
    },

    /**
     * Reset submenu attributes
     * @returns {KTMenu}
     */
    resetSubmenuProps() {
      const submenus = KTUtil.findAll(element, `.${classMenuSubmenu}`);
      if (submenus) {
        for (let i = 0, len = submenus.length; i < len; i += 1) {
          const submenu = submenus[0];

          KTUtil.css(submenu, 'display', '');
          KTUtil.css(submenu, 'overflow', '');

          if (submenu.hasAttribute(attrDataHorDirection)) {
            KTUtil.removeClass(submenu, `${classMenuSubmenu}-left`);
            KTUtil.removeClass(submenu, `${classMenuSubmenu}-right`);
            KTUtil.addClass(
              submenu,
              submenu.getAttribute(attrDataHorDirection),
            );
          }
        }
      }
    },

    /**
     * Handles submenu hover toggle
     * @returns {KTMenu}
     */
    handleSubmenuDrodownHoverEnter() {
      if (!the.resumeDropdownHover()) {
        return;
      }

      const item = this;

      if (item.getAttribute(attrDataHover) === '1') {
        item.removeAttribute(attrDataHover);
        clearTimeout(item.getAttribute(attrDataTimeout));
        item.removeAttribute(attrDataTimeout);
      }

      Plugin.showSubmenuDropdown(item);
    },

    /**
     * Handles submenu hover toggle
     * @returns {KTMenu}
     */
    handleSubmenuDrodownHoverExit() {
      if (!the.resumeDropdownHover()) {
        return;
      }

      const item = this;
      const time = the.options.dropdown.timeout;

      const timeout = setTimeout(() => {
        if (item.getAttribute(attrDataHover) === '1') {
          Plugin.hideSubmenuDropdown(item, true);
        }
      }, time);

      item.setAttribute(attrDataHover, '1');
      item.setAttribute(attrDataTimeout, timeout);
    },

    /**
     * Handles submenu click toggle
     * @returns {KTMenu}
     */
    handleSubmenuDropdownClick(e) {
      const item = this.closest(classMenuItem);

      // Trigger click event handlers
      const result = Plugin.eventTrigger('submenuToggle', this, e);
      if (!result) {
        return;
      }

      if (item.getAttribute(attrDataMenuSubmenuMode) === 'accordion') {
        return;
      }

      if (!KTUtil.hasClass(item, classMenuItemHover)) {
        KTUtil.addClass(item, classMenuItemOpenDropdown);
        Plugin.showSubmenuDropdown(item);
      } else {
        KTUtil.removeClass(item, classMenuItemOpenDropdown);
        Plugin.hideSubmenuDropdown(item, true);
      }

      e.preventDefault();
    },

    /**
     * Handles tab click toggle
     * @returns {KTMenu}
     */
    handleSubmenuDropdownTabClick(e) {
      const item = this.closest(classMenuItem);

      // Trigger click event handlers
      const result = Plugin.eventTrigger('submenuToggle', this, e);
      if (!result) {
        return;
      }

      if (item.getAttribute(attrDataMenuSubmenuMode) === 'accordion') {
        return;
      }

      if (!KTUtil.hasClass(item, classMenuItemHover)) {
        KTUtil.addClass(item, classMenuItemOpenDropdown);
        Plugin.showSubmenuDropdown(item);
      }

      e.preventDefault();
    },

    /**
     * Handles link click
     * @returns {KTMenu}
     */
    handleLinkClick(e) {
      const submenu = this.closest('.menu-item.menu-item-submenu');

      // Trigger click event handlers
      const result = Plugin.eventTrigger('linkClick', this, e);
      if (!result) {
        return;
      }

      if (submenu && Plugin.getSubmenuMode(submenu) === 'dropdown') {
        Plugin.hideSubmenuDropdowns();
      }
    },

    /**
     * Handles submenu dropdown close on link click
     * @returns {KTMenu}
     */
    handleSubmenuDropdownClose(_, el) {
      const shown = element.querySelectorAll(
        '.menu-item.menu-item-submenu.menu-item-hover:not(.menu-item-tabs)',
      );

      // check if currently clicked link's parent item ha
      if (
        shown.length > 0 &&
        !KTUtil.hasClass(el, 'menu-toggle') &&
        !el.querySelectorAll('.menu-toggle').length
      ) {
        // close opened dropdown menus
        for (let i = 0, len = shown.length; i < len; i += 1) {
          Plugin.hideSubmenuDropdown(shown[0], true);
        }
      }
    },

    /**
     * helper functions
     * @returns {KTMenu}
     */
    handleSubmenuAccordion(e, el = this) {
      // Trigger click event handlers
      const result = Plugin.eventTrigger('submenuToggle', this, e);
      if (!result) {
        return;
      }

      const li = el.closest(classMenuItem);

      if (
        Plugin.getSubmenuMode(el).toString() === 'dropdown' &&
        li.length &&
        li.getAttribute(attrDataMenuSubmenuMode) !== 'accordion'
      ) {
        e.preventDefault();
        return;
      }

      const submenu = KTUtil.child(li, `.${classMenuSubmenu}`, '.menu-inner');

      if (KTUtil.hasClass(li, 'menu-item-open-always')) {
        return;
      }

      if (li && submenu) {
        e.preventDefault();
        const speed = the.options.accordion.slideSpeed;

        const slideHandler = () => {
          Plugin.scrollToItem(el);
          Plugin.scrollUpdate();
          Plugin.eventTrigger('submenuToggle', submenu, e);
        };

        if (!KTUtil.hasClass(li, classMenuItemOpen)) {
          // hide other accordions
          if (!the.options.accordion.expandAll) {
            const subnav = el.closest('.menu-nav, .menu-subnav');
            const closables = KTUtil.children(
              subnav,
              '.menu-item.menu-item-open.menu-item-submenu:not(.menu-item-here):not(.menu-item-open-always)',
            );

            // eslint-disable-next-line max-depth
            if (subnav && closables) {
              closables.forEach(closable => {
                const subMenu = KTUtil.child(closable, `.${classMenuSubmenu}`);
                if (subMenu) {
                  KTUtil.slideUp(subMenu, speed, () => {
                    Plugin.scrollUpdate();
                    KTUtil.removeClass(closable, classMenuItemOpen);
                  });
                }
              });
            }
          }

          KTUtil.slideDown(submenu, speed, slideHandler);

          KTUtil.addClass(li, classMenuItemOpen);
        } else {
          KTUtil.slideUp(submenu, speed, slideHandler);

          KTUtil.removeClass(li, classMenuItemOpen);
        }
      }
    },

    /**
     * scroll to item function
     * @returns {KTMenu}
     */
    scrollToItem(item) {
      // handle auto scroll for accordion submenus
      if (
        KTUtil.isBreakpointUp('lg') &&
        the.options.accordion.autoScroll &&
        element.getAttribute('data-menu-scroll') !== '1'
      ) {
        KTUtil.scrollTo(item, the.options.accordion.autoScrollSpeed);
      }
    },

    /**
     * Hide submenu dropdown
     * @returns {KTMenu}
     */
    hideSubmenuDropdown(item, classAlso) {
      // remove submenu activation class
      if (classAlso) {
        KTUtil.removeClass(item, classMenuItemHover);
        KTUtil.removeClass(item, 'menu-item-active-tab');
      }

      // clear timeout
      item.removeAttribute(attrDataHover);

      if (item.getAttribute(attrDataMenuToggleClass)) {
        KTUtil.removeClass(body, item.getAttribute(attrDataMenuToggleClass));
      }

      const timeout = item.getAttribute(attrDataTimeout);
      item.removeAttribute(attrDataTimeout);
      clearTimeout(timeout);
    },

    /**
     * Hide submenu dropdowns
     * @returns {KTMenu}
     */
    hideSubmenuDropdowns() {
      const items = element.querySelectorAll(
        '.menu-item-submenu.menu-item-hover:not(.menu-item-tabs):not([data-menu-toggle="tab"])',
      );

      if (items.length) {
        items.forEach(item => {
          Plugin.hideSubmenuDropdown(item, true);
        });
      }
    },

    /**
     * helper functions
     * @returns {KTMenu}
     */
    showSubmenuDropdown(item) {
      // close active submenus
      const list = element.querySelectorAll(
        '.menu-item-submenu.menu-item-hover, .menu-item-submenu.menu-item-active-tab',
      );

      if (list.length) {
        list.forEach(el => {
          if (item !== el && !el.contains(item) && !item.contains(el)) {
            Plugin.hideSubmenuDropdown(el, true);
          }
        });
      }

      // add submenu activation class
      KTUtil.addClass(item, classMenuItemHover);

      // Change the alignment of submenu is offscreen.
      const submenu = KTUtil.find(item, `.${classMenuSubmenu}`);

      if (submenu && !submenu.hasAttribute(attrDataHorDirection)) {
        if (KTUtil.hasClass(submenu, `${classMenuSubmenu}-left`)) {
          submenu.setAttribute(
            attrDataHorDirection,
            `${classMenuSubmenu}-left`,
          );
        } else if (KTUtil.hasClass(submenu, `${classMenuSubmenu}-right`)) {
          submenu.setAttribute(
            attrDataHorDirection,
            `${classMenuSubmenu}-right`,
          );
        }
      }

      if (submenu && KTUtil.isOffscreen(submenu, 'left', 15) === true) {
        KTUtil.removeClass(submenu, `${classMenuSubmenu}-left`);
        KTUtil.addClass(submenu, `${classMenuSubmenu}-right`);
      } else if (submenu && KTUtil.isOffscreen(submenu, 'right', 15) === true) {
        KTUtil.removeClass(submenu, `${classMenuSubmenu}-right`);
        KTUtil.addClass(submenu, `${classMenuSubmenu}-left`);
      }

      if (item.getAttribute(attrDataMenuToggleClass)) {
        KTUtil.addClass(body, item.getAttribute(attrDataMenuToggleClass));
      }
    },

    /**
     * Handles submenu slide toggle
     * @returns {KTMenu}
     */
    createSubmenuDropdownClickDropoff(el) {
      const query = KTUtil.child(el, `.${classMenuSubmenu}`);
      const zIndex = (query ? KTUtil.css(query, 'z-index') : 0) - 1;

      const dropoff = document.createElement(
        // eslint-disable-next-line max-len
        `<div class="menu-dropoff" style="background: transparent; position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: ${zIndex}"></div>`,
      );

      body.appendChild(dropoff);

      KTUtil.addEvent(dropoff, 'click', e => {
        e.stopPropagation();
        e.preventDefault();
        KTUtil.remove(this);
        Plugin.hideSubmenuDropdown(el, true);
      });
    },

    /**
     * Handles submenu hover toggle
     * @returns {KTMenu}
     */
    pauseDropdownHover(time) {
      const date = new Date();

      the.pauseDropdownHoverTime = date.getTime() + time;
    },

    /**
     * Handles submenu hover toggle
     * @returns {KTMenu}
     */
    resumeDropdownHover() {
      const date = new Date();

      return date.getTime() > the.pauseDropdownHoverTime;
    },

    /**
     * Reset menu's current active item
     * @returns {KTMenu}
     */
    resetActiveItem() {
      let parents;

      const listActive = element.querySelectorAll('.menu-item-active');

      listActive.forEach(el => {
        KTUtil.removeClass(el, 'menu-item-active');
        KTUtil.hide(KTUtil.child(el, `.${classMenuSubmenu}`));
        parents = KTUtil.parents(el, classMenuItemSubmenu) || [];

        parents.forEach(parent => {
          KTUtil.removeClass(parent, classMenuItemOpen);
          KTUtil.hide(KTUtil.child(parent, `.${classMenuSubmenu}`));
        });
      });

      // close open submenus
      if (!the.options.accordion.expandAll) {
        const listOpen = element.querySelectorAll('.menu-item-open');
        listOpen.forEach(() => {
          KTUtil.removeClass(parents[0], classMenuItemOpen);
        });
      }
    },

    /**
     * Sets menu's active item
     * @returns {KTMenu}
     */
    setActiveItem(item) {
      // reset current active item
      Plugin.resetActiveItem();

      const parents = KTUtil.parents(item, classMenuItemSubmenu) || [];
      for (let i = 0, len = parents.length; i < len; i += 1) {
        KTUtil.addClass(parents[i], classMenuItemOpen);
      }

      KTUtil.addClass(item, 'menu-item-active');
    },

    /**
     * Returns page breadcrumbs for the menu's active item
     * @returns {KTMenu}
     */
    getBreadcrumbs(item) {
      const breadcrumbs = [];
      const link = KTUtil.child(item, '.menu-link');

      breadcrumbs.push({
        text: KTUtil.child(link, classMenuText)?.innerHTML || '',
        title: link.getAttribute('title'),
        href: link.getAttribute('href'),
      });

      const parents = KTUtil.parents(item, classMenuItemSubmenu);
      for (let i = 0, len = parents.length; i < len; i += 1) {
        const submenuLink = KTUtil.child(parents[i], '.menu-link');

        breadcrumbs.push({
          text: KTUtil.child(submenuLink, classMenuText)?.innerHTML || '',
          title: submenuLink.getAttribute('title'),
          href: submenuLink.getAttribute('href'),
        });
      }

      return breadcrumbs.reverse();
    },

    /**
     * Returns page title for the menu's active item
     * @returns {KTMenu}
     */
    getPageTitle(item) {
      return KTUtil.child(item, classMenuText)?.innerHTML || '';
    },

    /**
     * Trigger events
     */
    eventTrigger(name, target, e) {
      // eslint-disable-next-line consistent-return
      the.events.forEach((event, i) => {
        if (event.name === name) {
          if (event.one === true) {
            if (!event.fired) {
              the.events[i].fired = true;
              return event.handler.call(this, target, e);
            }
          } else {
            return event.handler.call(this, target, e);
          }
        }
      });
    },

    addEvent(name, handler, one) {
      the.events.push({
        name,
        handler,
        one,
        fired: false,
      });
    },

    removeEvent(name) {
      if (the.events[name]) {
        delete the.events[name];
      }
    },
  };

  /// ///////////////////////
  // ** Public Methods ** //
  /// ///////////////////////

  /**
   * Set default options
   */

  the.setDefaults = function setDefaults(opts) {
    defaultOptions = opts;
  };

  /**
   * Update scroll
   */
  the.scrollUpdate = () => {
    return Plugin.scrollUpdate();
  };

  /**
   * Re-init scroll
   */
  the.scrollReInit = () => Plugin.scrollInit();

  /**
   * Scroll top
   */
  the.scrollTop = () => Plugin.scrollTop();

  /**
   * Set active menu item
   */
  the.setActiveItem = item => Plugin.setActiveItem(item);

  the.reload = () => Plugin.reload();

  the.update = opots => Plugin.update(opots);

  /**
   * Set breadcrumb for menu item
   */
  the.getBreadcrumbs = item => Plugin.getBreadcrumbs(item);

  /**
   * Set page title for menu item
   */
  the.getPageTitle = item => Plugin.getPageTitle(item);

  /**
   * Get submenu mode
   */
  the.getSubmenuMode = el => Plugin.getSubmenuMode(el);

  /**
   * Hide dropdown
   * @returns {Object}
   */
  the.hideDropdown = item => {
    Plugin.hideSubmenuDropdown(item, true);
  };

  /**
   * Hide dropdowns
   * @returns {Object}
   */
  the.hideDropdowns = () => {
    Plugin.hideSubmenuDropdowns();
  };

  /**
   * Disable menu for given time
   * @returns {Object}
   */
  the.pauseDropdownHover = time => {
    Plugin.pauseDropdownHover(time);
  };

  /**
   * Disable menu for given time
   * @returns {Object}
   */
  the.resumeDropdownHover = () => Plugin.resumeDropdownHover();

  /**
   * Register event
   */
  the.on = (name, handler) => Plugin.addEvent(name, handler);

  the.off = name => Plugin.removeEvent(name);

  the.one = (name, handler) => Plugin.addEvent(name, handler, true);

  /// ////////////////////////////
  // ** Plugin Construction ** //
  /// ////////////////////////////

  // Run plugin
  Plugin.construct.apply(the, [options]);

  // Handle plugin on window resize
  KTUtil.addResizeHandler(() => {
    if (init) {
      the.reload();
    }
  });

  // Init done
  init = true;
};

// Plugin global lazy initialization
document.addEventListener('click', e => {
  const body = KTUtil.getByTagName('body')[0];
  const query = body.querySelectorAll(
    '.menu-nav .menu-item.menu-item-submenu.menu-item-hover:not(.menu-item-tabs)[data-menu-toggle="click"]',
  );
  query.forEach(item => {
    const element = item.closest('.menu-nav').parentNode;

    if (element) {
      const the = KTUtil.data(element).get('menu');

      if (!the) {
        return;
      }

      if (!the || the.getSubmenuMode() !== 'dropdown') {
        return;
      }

      if (e.target !== element && !element.contains(e.target)) {
        the.hideDropdowns();
      }
    }
  });
});

export default KTMenu;
