import { createColumnHelper } from '@tanstack/react-table';
import { Link } from '@components/Common.styles';
import { Translate } from '@hooks/useI18n';

interface IGetMerchantsTableColumnsProps {
  canViewIban: boolean;
  translate: Translate;
}

export interface IMerchantsTableColumns {
  country: string;
  createdDate: string;
  externalUUID: string;
  iban: string;
  industryName: string;
  merchantName: string;
}

export const getMerchantsTableColumns = ({
  canViewIban,
  translate,
}: IGetMerchantsTableColumnsProps) => {
  const columnHelper = createColumnHelper<IMerchantsTableColumns>();

  return [
    columnHelper.accessor('merchantName', {
      header: translate('OPS_PORTAL.LABEL.MERCHANT_NAME'),
      cell: ({ row }) => (
        <Link to={row.original.externalUUID}>{row.original.merchantName}</Link>
      ),
    }),
    columnHelper.accessor('country', {
      header: translate('OPS_PORTAL.LABEL.COUNTRY'),
    }),
    columnHelper.accessor('industryName', {
      header: translate('OPS_PORTAL.LABEL.MERCHANT_INDUSTRY'),
    }),
    columnHelper.accessor('createdDate', {
      header: translate('OPS_PORTAL.TITLE.CREATED_DATE'),
    }),
    columnHelper.accessor('externalUUID', {
      header: translate('TITLE.UUID'),
    }),
    ...(canViewIban
      ? [
          columnHelper.accessor('iban', {
            header: translate('TITLE.BANK_ACCOUNT_NUMBER'),
          }),
        ]
      : []),
  ];
};
