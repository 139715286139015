export const syncDataToCols = ({ columns, data }) =>
  data.map(row =>
    columns
      .map(col => col.accessorKey || col.id)
      .reduce((map, key) => {
        if (key !== 'ctaColumn') {
          map.set(key, row[key]);
        }
        return map;
      }, new Map()),
  );
