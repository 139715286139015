import { useCallback, useState } from 'react';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import {
  BorderColorOutlined,
  PrintOutlined,
  UploadFileOutlined,
} from '@mui/icons-material';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { useQueryOnClick } from '@hooks/useQueryOnClick';
import { QueryKeys } from '@utils/constants';
import { downloadFile } from '@utils/downloadFile';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { DocumentLink } from './DocumentLink';
import { Instructions } from './Instructions';

export interface IUseDownloadCustomerAgreementModalProps
  extends ISharedModalProps {
  externalApplicationUuid?: string;
  queryKey: QueryKey;
}

export const useDownloadCustomerAgreementModal = (
  props: IUseDownloadCustomerAgreementModalProps,
): Modal => {
  const { externalApplicationUuid, queryKey } = props;

  const modal = useModal({
    sharedProps: props,
    modalName: 'downloadCustomerAgreement',
    permissions: ['merchant_portal.can_upload_signed_agreement'],
    buttonTranslationKey: 'BUTTON.DOWNLOAD_CUSTOMER_AGREEMENT',
  });

  const { translate } = useI18n();
  const queryClient = useQueryClient();
  const { message } = useToast(translate);
  const [forceDownload, setForceDownload] = useState(false);

  const { execute: downloadAgreement, isLoading } = useQueryOnClick(
    [QueryKeys.DOWNLOAD_CUSTOMER_AGREEMENT],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.CustomerAgreement}/${externalApplicationUuid}`,
        {},
      ),
    {
      onError: () => {
        message.error('ERROR.FILE_DOWNLOAD', { timeout: 10000 });
      },
      onSuccess: (url: string) => downloadFile(url, forceDownload),
    },
  );

  const clickHandler = useCallback(
    (isButton: boolean) => {
      setForceDownload(isButton);
      downloadAgreement(null);
      queryClient.invalidateQueries([queryKey]);
      modal.closeModal();
    },
    [downloadAgreement, modal, queryClient, queryKey],
  );

  const buttonClickHandler = useCallback(() => {
    clickHandler(true);
  }, [clickHandler]);

  const linkClickHandler = useCallback(
    e => {
      e.preventDefault();
      clickHandler(false);
    },
    [clickHandler],
  );

  const instructionSteps = [
    {
      avatar: <PrintOutlined />,
      shouldRepeatStep: true,
      translationKey: translate(
        'PAGE.ORDERS.DOWNLOAD_AGREEMENT.PRINT_TWO_COPIES',
      ),
    },
    {
      avatar: <BorderColorOutlined />,
      shouldRepeatStep: true,
      translationKey: translate(
        'PAGE.ORDERS.DOWNLOAD_AGREEMENT.SIGN_TWO_COPIES',
      ),
    },
    {
      avatar: <UploadFileOutlined />,
      shouldRepeatStep: false,
      translationKey: translate(
        'PAGE.ORDERS.DOWNLOAD_AGREEMENT.UPLOAD_AGREEMENT',
      ),
    },
  ];

  const modalContent = (
    <QueryStateRouter isLoading={isLoading}>
      <>
        <Instructions steps={instructionSteps} />
        <DocumentLink
          clickHandler={linkClickHandler}
          linkText={translate('BUTTON.OPEN_DOCUMENT_IN_A_NEW_TAB')}
        />
      </>
    </QueryStateRouter>
  );

  const modalProps = {
    clickHandler: buttonClickHandler,
    modalContent,
    submitText: translate('BUTTON.DOWNLOAD_DOCUMENT'),
    title: translate('BUTTON.DOWNLOAD_CUSTOMER_AGREEMENT'),
  };

  return getModalDetails({ modal, modalProps });
};
