import { createColumnHelper } from '@tanstack/react-table';
import { Link } from '@components/Common.styles';
import { Translate } from '@hooks/useI18n';
import { Selector } from '@pages/ConsumerDetails/components/CommunicationsCard/Selector';
import { getOriginatorDisplayNameHeyLight } from '@utils/getOriginatorDisplayNameHeyLight';
import {
  IHandleSelectAllClicked,
  ITerminableContractTableColumns,
} from './types';

export interface IGetTerminableContractsTableColumns {
  collectionAgencies: Record<string, string>;
  handleSelectAllClicked: IHandleSelectAllClicked;
  isTerminatedContract: (status: string) => boolean;
  translate: Translate;
}

export const getTerminableContractsTableColumns = ({
  collectionAgencies,
  handleSelectAllClicked,
  isTerminatedContract,
  translate,
}: IGetTerminableContractsTableColumns) => {
  const columnHelper = createColumnHelper<ITerminableContractTableColumns>();
  return [
    // Changed to an accessor in order to get the checkBox Selector showing in header
    // Using .display here does not display select all check box header
    columnHelper.accessor('selectAll', {
      header: ({ table }) => {
        const { rows } = table.getRowModel();
        const areAllNonTerminatedContractsChecked = rows.every(
          row =>
            isTerminatedContract(row.original.status) || row.getIsSelected(),
        );
        const areAllContractsTerminated = rows.every(row =>
          isTerminatedContract(row.original.status),
        );
        const disableSelectAll = areAllContractsTerminated || !rows.length;
        return (
          <Selector
            aria-label={translate(
              'OPS_PORTAL.LABEL.SELECT_ALL_CONTRACT_TERMINATION',
            )}
            checked={areAllNonTerminatedContractsChecked && !disableSelectAll}
            disabled={disableSelectAll}
            onChange={handleSelectAllClicked({
              rows,
              areAllNonTerminatedContractsChecked,
            })}
            value={translate(
              'OPS_PORTAL.LABEL.SELECT_ALL_CONTRACT_TERMINATION',
            )}
          />
        );
      },
      cell: ({ row }) => (
        <Selector
          aria-label={translate('OPS_PORTAL.LABEL.SELECT_CONTRACT_TERMINATION')}
          checked={row.getIsSelected()}
          disabled={isTerminatedContract(row.original.status)}
          onChange={row.getToggleSelectedHandler()}
          value={row.original.contractUuid}
        />
      ),
      enableSorting: false,
    }),
    columnHelper.accessor('paymentPlanReference', {
      header: translate('TITLE.PAYMENT_PLAN_ID'),
      cell: ({ row }) => (
        <Link to={`/terminable_contracts/${row.original.externalContractUuid}`}>
          {row.original.paymentPlanReference}
        </Link>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor('createdDate', {
      header: translate('OPS_PORTAL.TITLE.CREATED_DATE'),
      enableSorting: false,
    }),
    columnHelper.accessor('consumerName', {
      header: translate('TITLE.CUSTOMER_NAME'),
      enableSorting: false,
    }),
    columnHelper.accessor('merchantName', {
      header: translate('OPS_PORTAL.LABEL.MERCHANT_NAME'),
      enableSorting: false,
    }),
    columnHelper.accessor('creditType', {
      header: translate('OPS_PORTAL.LABEL.CREDIT_TYPE'),
      enableSorting: false,
    }),
    columnHelper.accessor('status', {
      header: translate('OPS_PORTAL.LABEL.ACTIVITY_STATUS'),
      enableSorting: false,
    }),
    columnHelper.accessor('amount', {
      header: translate('LABEL.AMOUNT'),
      enableSorting: false,
    }),
    columnHelper.accessor('arrearsAmount', {
      header: translate('LABEL.ARREARS_AMOUNT'),
      enableSorting: false,
    }),
    columnHelper.accessor('origination_channel', {
      header: translate('OPS_PORTAL.TITLE.ORIGINATION'),
      enableSorting: false,
    }),
    columnHelper.accessor('arrearsManagedBy', {
      cell: ({ row }) => {
        const { arrearsManagedBy } = row.original;
        return collectionAgencies[arrearsManagedBy] ?? 'unknown';
      },
      header: translate('OPS_PORTAL.TITLE.ARREARS_MANAGED_BY'),
      enableSorting: false,
    }),
    columnHelper.accessor('originator', {
      cell: ({ row }) =>
        getOriginatorDisplayNameHeyLight(row.original.originator),
      header: translate('OPS_PORTAL.TITLE.ORIGINATOR'),
      enableSorting: false,
    }),
  ];
};
