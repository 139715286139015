import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { IOpsPortalApiCallDetails } from '@schemas/opsPortal/types/apiCallDetails';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { ViewRawDataContent } from './ViewRawDataContent';

interface IViewRawDataModalProps extends ISharedModalProps {
  uuid: string;
}

export const useViewRawDataModal = (props: IViewRawDataModalProps): Modal => {
  const { uuid, variant } = props;
  const { translate } = useI18n();

  const modal = useModal({
    sharedProps: props,
    modalName: 'viewRawDataModal',
    permissions: ['ops_portal.view_checkout_api_requests'],
    buttonTranslationKey: 'OPS_PORTAL.BUTTON.VIEW_RAW_DATA',
  });

  const { data, error, isLoading } = useQuery<
    IOpsPortalApiCallDetails,
    AxiosError
  >(
    [QueryKeys.API_CALLS, uuid],
    () =>
      fetchDataFromEndpoint(Endpoints.FetchApiRequests, {
        search: uuid,
      }),
    { enabled: modal.isModalShowing },
  );

  const modalContent = (
    <QueryStateRouter error={error} isLoading={isLoading}>
      <ViewRawDataContent data={data} />
    </QueryStateRouter>
  );

  const modalProps = {
    isLoading,
    modalContent,
    title: translate('OPS_PORTAL.TITLE.RAW_DATA'),
    variant,
  };

  return getModalDetails({ modal, modalProps });
};
