import { Status } from '@appTypes';
import { Cardlet, CardletBody, CardletTitle } from '@components/Common.styles';
import { StatusPill } from '@components/StatusPill';
import { RawStatuses } from '@utils/constants';

interface IDetailsCardletProps {
  details: string[];
  rawStatus?: Status;
  status?: string;
  title: string;
}

export const DetailsCardlet = ({
  details,
  rawStatus = RawStatuses.ACTIVE,
  status,
  title,
}: IDetailsCardletProps) => {
  return (
    <Cardlet>
      <CardletTitle>
        <span className="mr-3">{title}</span>
        {status ? <StatusPill rawStatus={rawStatus} status={status} /> : null}
      </CardletTitle>
      <CardletBody>
        {details.map(detail => (
          <div key={detail}>{detail}</div>
        ))}
      </CardletBody>
    </Cardlet>
  );
};
