import { AxiosError } from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseGetMonetaQuery {
  contractUuid?: string;
  externalContractUuid?: string;
}

export const useGetMonetaQuery = ({
  contractUuid,
  externalContractUuid,
}: IUseGetMonetaQuery) => {
  const { data: monetaContractData, isLoading: isLoadingMonetaData } = useQuery<
    IOpsPortalMonetaContract,
    AxiosError
  >(
    [QueryKeys.MONETA_CONTRACT_DATA, externalContractUuid],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchMonetaContractData}/${contractUuid}`,
        {},
      ),
    {
      enabled: Boolean(contractUuid),
    },
  );

  const queryClient = useQueryClient();
  const invalidateMonetaData = () => {
    queryClient.invalidateQueries([
      QueryKeys.MONETA_CONTRACT_DATA,
      externalContractUuid,
    ]);
  };

  return {
    monetaContractData,
    invalidateMonetaData,
    isLoadingMonetaData,
  };
};
