interface IValidateConditionalRequired {
  current?: string | number;
  original?: string | number;
}

// Should fail validation only if there is an original value and not a current value
export const validateConditionalRequired = ({
  current,
  original,
}: IValidateConditionalRequired) =>
  !original || (current !== null && current !== undefined && current !== '');
