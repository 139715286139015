import * as svg from '@assets/svg';
import { LabelPosition } from './enums';
import { Toggle, ToggleLabel, ToggleWrapper } from './ToggleButton.styled';
import { IToggleButtonProps } from './types';

export const ToggleButton = ({
  isPressed,
  label,
  labelPosition = LabelPosition.Right,
  ...props
}: IToggleButtonProps) => {
  const Label = (
    <ToggleLabel labelPosition={labelPosition}>{label}</ToggleLabel>
  );
  return (
    <ToggleWrapper aria-pressed={isPressed} {...props}>
      {labelPosition === LabelPosition.Left ? Label : ''}
      <Toggle>
        <svg.ToggleOn />
        <svg.ToggleOff />
      </Toggle>
      {labelPosition === LabelPosition.Right ? Label : ''}
    </ToggleWrapper>
  );
};
