import { GetLocaleCurrencyValue } from '@hooks/useI18n';
import { IAmountObject } from '@schemas/merchantPortal/types/adjustContractPreview';
import { getFormattedAmount } from '@utils/getFormattedAmount';
import { getFormattedDate } from '@utils/getFormattedDate';

interface IGetPaymentPlanScheduleFields {
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  schedule: {
    amount: IAmountObject;
    due_date: string;
  }[];
}

export const getPaymentPlanScheduleFields = ({
  getLocaleCurrencyValue,
  schedule,
}: IGetPaymentPlanScheduleFields) =>
  schedule.map(item => ({
    key: getFormattedDate(item.due_date) ?? '',
    value:
      getFormattedAmount({
        ...item.amount,
        getLocaleCurrencyValue,
      }) ?? '',
  }));
