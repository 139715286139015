import { IDataRow } from '@components/DataDisplay/DataDisplay';
import { GetLocaleDate, Translate } from '@hooks/useI18n';
import { IOpsPortalIban } from '@schemas/opsPortal/types/iban';
import { IMerchant } from '@schemas/opsPortal/types/merchants';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { convertBooleanToYesOrNo } from '@utils/convertBooleanToYesOrNo';

interface IGetDataListProps extends Partial<IOpsPortalIban> {
  canViewIban: boolean;
  data: IMerchant;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const getDataList = ({
  canViewIban,
  data,
  getLocaleDate,
  iban,
  translate,
}: IGetDataListProps) =>
  [
    {
      key: translate('LABEL.LEGAL_NAME'),
      value: data.legal_name,
    },
    {
      key: translate('OPS_PORTAL.LABEL.SHORT_NAME'),
      value: data.short_name,
    },
    {
      key: translate('TITLE.UUID'),
      value: data.external_uuid,
    },
    {
      key: translate('OPS_PORTAL.LABEL.COUNTRY'),
      value: data.country,
    },
    {
      key: translate('OPS_PORTAL.LABEL.AVAILABLE_LANGUAGES'),
      value: data.available_languages
        ?.map(languageGroup =>
          translate(
            `OPS_PORTAL.LABEL.${languageGroup.language_code.toUpperCase()}`,
          ),
        )
        .join(', '),
    },
    {
      key: translate('OPS_PORTAL.LABEL.MERCHANT_INDUSTRY'),
      value: data.industry_name,
    },
    {
      key: translate('OPS_PORTAL.TITLE.CREATED_DATE'),
      value: data.created_date
        ? getLocaleDate({ date: data.created_date })
        : '',
    },
    {
      key: translate('LABEL.VAT_REGISTRATION_NUMBER'),
      value: data.vat_number,
    },
    {
      key: translate('OPS_PORTAL.LABEL.MERCHANT_SETTLEMENT_PAUSED'),
      value: convertBooleanToYesOrNo(
        data.merchant_settlement_paused,
        translate,
      ),
    },
    ...(canViewIban
      ? [
          {
            key: translate('TITLE.BANK_ACCOUNT_NUMBER'),
            value: iban,
          },
        ]
      : []),
  ].map(val => addPlaceholderForEmptyValues<IDataRow>(val));
