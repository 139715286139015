import { GetLocaleCurrencyValue } from '@hooks/useI18n';

interface IGetFormattedAmount {
  amount?: string;
  currency?: string;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
}

export const getFormattedAmount = ({
  amount,
  currency,
  getLocaleCurrencyValue,
}: IGetFormattedAmount) => {
  if (!amount || !getLocaleCurrencyValue) {
    return '—';
  }

  return getLocaleCurrencyValue({
    currency,
    value: amount,
  });
};
