import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useQueries } from '@tanstack/react-query';
import { RiskTestsBreakdown } from '@components/RiskTestsBreakdown';
import { ShoppingCartDetails } from '@components/ShoppingCartDetails';
import { IShoppingCartOrderDetails } from '@components/ShoppingCartDetails/types';
import { useOriginator } from '@hooks/useOriginator';
import { ContractsByUuids } from '@pages/Contracts/ContractsByUuids';
import { IOpsPortalApplicationDetails } from '@schemas/opsPortal/types/applicationDetails';
import { IOpsPortalNextPayment } from '@schemas/opsPortal/types/nextPayment';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { OpsApplicationTrackingCard } from '../Tracking/OpsApplicationTrackingCard';
import { ActionLogs, DetailsCard } from './components';

export const ApplicationDetails = () => {
  const { uuid } = useParams();
  const { isCompass } = useOriginator();

  const [
    {
      data: dataApplication,
      error: errorApplication,
      isLoading: isApplicationLoading,
    },
    {
      data: dataNextPayment,
      error: errorNextPayment,
      isLoading: isNextPaymentLoading,
    },
  ] = useQueries<
    [
      [IOpsPortalApplicationDetails, AxiosError],
      [IOpsPortalNextPayment, AxiosError],
    ]
  >({
    queries: [
      {
        queryKey: [QueryKeys.APPLICATION_DETAILS, uuid],
        queryFn: () =>
          fetchDataFromEndpoint(`${Endpoints.FetchApplications}/${uuid}`),
      },
      {
        queryKey: [QueryKeys.NEXT_PAYMENT_DATA, uuid],
        queryFn: () =>
          fetchDataFromEndpoint(`${Endpoints.FetchNextPaymentData}/${uuid}`),
      },
    ],
  });

  const orderDetails = dataApplication?.order_details;

  const isLoading = isApplicationLoading || isNextPaymentLoading;

  return (
    <>
      <DetailsCard
        data={dataApplication}
        error={errorApplication || errorNextPayment}
        isLoading={isLoading}
        nextPayment={dataNextPayment}
      />

      {/* Compass want this at the top, for Swiss leave at the bottom */}
      {isCompass ? (
        <OpsApplicationTrackingCard
          contractId={dataApplication?.external_contract_uuid}
        />
      ) : null}

      {orderDetails?.products?.length ? (
        <ShoppingCartDetails
          orderDetails={orderDetails as IShoppingCartOrderDetails}
        />
      ) : null}

      <RiskTestsBreakdown
        data={dataApplication?.risk_details}
        error={errorApplication}
        isLoading={isLoading}
      />

      <ContractsByUuids
        isLoading={isLoading}
        uuids={dataApplication?.risk_details?.matching_contract_uuids}
      />

      <ActionLogs uuid={uuid} />

      {!isCompass ? (
        <OpsApplicationTrackingCard
          contractId={dataApplication?.external_contract_uuid}
        />
      ) : null}
    </>
  );
};
