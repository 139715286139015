import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IBucket } from '@schemas/opsPortal/types/collectionsListManualBuckets';
import { IUuid } from '@schemas/opsPortal/types/contractDetails';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseSetCurrentBucketQuery {
  bucketId: string;
  contractUUID: IUuid;
}

export const useSetCurrentBucketQuery = ({
  bucketId,
  contractUUID,
}: IUseSetCurrentBucketQuery) => {
  const {
    data: setBucketData,
    error: setBucketError,
    isLoading: isSettingBucketLoading,
  } = useQuery<IBucket, AxiosError>([QueryKeys.BUCKET_SET], () =>
    fetchDataFromEndpoint(`${Endpoints.BucketGet}/${contractUUID}`, {
      query: {
        bucket_id: bucketId,
      },
    }),
  );

  return {
    setBucketData,
    setBucketError,
    isSettingBucketLoading,
  };
};
