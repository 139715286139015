import { MouseEvent } from 'react';
import styled from 'styled-components';

interface IDocumentLink {
  clickHandler: (e: MouseEvent<HTMLAnchorElement>) => void;
  linkText: string;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-block: 1rem;
`;

const Link = styled.a`
  font-size: 1.15rem;
`;

export const DocumentLink = ({ clickHandler, linkText }: IDocumentLink) => (
  <Wrapper>
    <Link href="#" onClick={clickHandler} rel="noreferrer" target="_blank">
      {linkText}
    </Link>
  </Wrapper>
);
