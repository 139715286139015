import { AccessController } from '@components/AccessController';
import { CommunicationsCard, DetailsCard } from './components';

export const ConsumerDetails = () => (
  <>
    <DetailsCard />
    <AccessController scopes={['ops_portal.can_view_communication_events']}>
      <CommunicationsCard />
    </AccessController>
  </>
);
