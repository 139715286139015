import { Formik, Form } from 'formik';
import { TextField } from '@components/TextField';
import { useI18n } from '@hooks/useI18n/useI18n';
import { getValidationSchema } from './getValidationSchema';
import { ISubmitHandlerProps } from './types';

interface ITriggerPaymentFormProps {
  currency?: string;
  formID: string;
  handleSubmit: (data: ISubmitHandlerProps) => void;
  maxAmount?: string;
}

export const TriggerPaymentForm = ({
  currency,
  formID,
  handleSubmit,
  maxAmount,
}: ITriggerPaymentFormProps) => {
  const { translate } = useI18n();
  const initialFormikValues = { amount: '' };

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema({
        maxAmount,
        translate,
      })()}
    >
      <Form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
      >
        <TextField
          id="amount"
          label={`${translate('OPS_PORTAL.LABEL.AMOUNT_TO_PAY')} (${currency})`}
          name="amount"
        />
      </Form>
    </Formik>
  );
};
