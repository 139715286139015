import { useMemo } from 'react';
import { QueryKey } from '@tanstack/react-query';
import { CollectionAgencies } from '@appTypes';
import { useAddPDRModal } from '@hooks/useModal/hooks/useAddActionPDRModal';
import { useAddCertificateOfInterestModal } from '@hooks/useModal/hooks/useAddCertificateOfInterestModal';
import { useAssignOverrideBucketModal } from '@hooks/useModal/hooks/useAssignOverrideBucketModal';
import { useChangePaymentDatesModal } from '@hooks/useModal/hooks/useChangePaymentDatesModal';
import { defaultDateOptions } from '@hooks/useModal/hooks/useChangePaymentDatesModal/constants';
import { useCloseCurrentAssignmentModal } from '@hooks/useModal/hooks/useCloseCurrentAssignmentModal';
import {
  useContractManualPaymentsModal,
  useContractPrepaymentModal,
  useContractReimbursementModal,
} from '@hooks/useModal/hooks/useContractPaymentModal';
import { useDeleteFeesModal } from '@hooks/useModal/hooks/useDeleteFeesModal';
import { useDownloadQrPdfModal } from '@hooks/useModal/hooks/useDownloadQrPdfModal';
import { useEmailUserModal } from '@hooks/useModal/hooks/useEmailUserModal';
import { usePausePaymentsModal } from '@hooks/useModal/hooks/usePausePaymentsModal';
import { usePaymentLinkModal } from '@hooks/useModal/hooks/usePaymentLinkModal';
import { useSettlementAdjustmentModal } from '@hooks/useModal/hooks/useSettlementAdjustmentModal';
import { useTriggerPaymentModal } from '@hooks/useModal/hooks/useTriggerPaymentModal';
import { useUpdateArrearsManagedByModal } from '@hooks/useModal/hooks/useUpdateArrearsManagedByModal';
import { useOriginator } from '@hooks/useOriginator';
import { useGetPaymentLinkQuery } from '@pages/ContractDetails/hooks/queries';
import { getLatestPaymentDate } from '@pages/ContractDetails/utils/getLatestPaymentDate';
import { getMonthlyPaymentDate } from '@pages/ContractDetails/utils/getMonthlyPaymentDate';
import { IOpsPortalCollectionsDetails } from '@schemas/opsPortal/types/collectionsDetails';
import { IOpsPortalCollectionsListManualBuckets } from '@schemas/opsPortal/types/collectionsListManualBuckets';
import {
  IContractDetails,
  IDate,
} from '@schemas/opsPortal/types/contractDetails';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { ContractStatuses } from '@utils/constants';

interface IUseContractModalsProps {
  bucketListData?: IOpsPortalCollectionsListManualBuckets;
  collectionAgencies?: CollectionAgencies;
  collectionsData?: IOpsPortalCollectionsDetails;
  contractData?: IContractDetails;
  contractDataQueryKey?: QueryKey;
  contractUuid?: string;
  externalContractUUID?: string;
  invalidateMonetaData?: () => void;
  isInternalOpsUser: boolean;
  isLiveContracts: boolean;
  isPaused: boolean;
  monetaContractData?: IOpsPortalMonetaContract;
  paymentsPausedTillDate?: IDate;
}

export const useContractModals = ({
  bucketListData,
  collectionAgencies,
  collectionsData,
  contractData,
  contractDataQueryKey,
  contractUuid,
  externalContractUUID,
  invalidateMonetaData,
  isInternalOpsUser,
  isLiveContracts,
  isPaused,
  monetaContractData,
  paymentsPausedTillDate,
}: IUseContractModalsProps) => {
  const { isHeidiPay } = useOriginator();
  const contractStatus = contractData?.status.toLowerCase();
  const isContractActive = contractStatus === ContractStatuses.ACTIVE;
  const isContractTerminated =
    contractStatus === ContractStatuses.TERMINATED ||
    contractStatus === ContractStatuses.TERMINATED_AND_PAID;

  const remainder = contractData?.system_remainder;
  const isContractOverpaid = remainder
    ? parseFloat(remainder?.amount) < 0
    : false;
  const hasRemainder = remainder && !isContractOverpaid;
  const isActiveWithRemainder = isContractActive && hasRemainder;

  const canTriggerPayment =
    hasRemainder && (isContractActive || isContractTerminated);

  const triggerPaymentModal = useTriggerPaymentModal({
    dependencies: [canTriggerPayment],
    remainderAmount: remainder,
    uuid: externalContractUUID,
  });

  const paymentModalProps = {
    contractUuid,
    data: contractData,
    dependencies: [contractData],
    monetaData: monetaContractData,
    uuid: externalContractUUID,
  };

  const latestPaymentDate = useMemo(
    () => getLatestPaymentDate(monetaContractData),
    [monetaContractData],
  );

  const changePaymentDateModal = useChangePaymentDatesModal({
    ...paymentModalProps,
    currency: contractData?.currency,
    dependencies: [...paymentModalProps.dependencies, isActiveWithRemainder],
    latestPaymentDate,
    paymentDate:
      getMonthlyPaymentDate(monetaContractData) ?? defaultDateOptions[0],
    paymentSchedule: monetaContractData?.balances.filter(
      balance =>
        Number(balance.amount_due) > 0 &&
        balance.payment_type === 'Instalment' &&
        balance.latest_schedule,
    ),
    uuid: externalContractUUID,
  });

  const pausePaymentsModal = usePausePaymentsModal({
    contractDataQueryKey,
    dependencies: [contractData, isLiveContracts],
    isPaused,
    paymentsPausedTillDate,
    uuid: externalContractUUID,
  });

  const emailUserModal = useEmailUserModal({
    contractData,
    dependencies: [contractData],
    isInternalOpsUser,
  });

  const updateArrearsManagedByModal = useUpdateArrearsManagedByModal({
    arrearsManagedBy: contractData?.arrears_managed_by,
    collectionAgencies,
    contractDataQueryKey,
    dependencies: [contractData],
    externalContractUUID,
  });

  const contractManualPaymentModal =
    useContractManualPaymentsModal(paymentModalProps);

  const contractReimbursementModal =
    useContractReimbursementModal(paymentModalProps);

  const contractAdjustmentsModal =
    useSettlementAdjustmentModal(paymentModalProps);

  const downloadQrPdfModal = useDownloadQrPdfModal({
    dependencies: [contractData],
    uuid: contractUuid,
  });

  const { canUsePaymentLink, paymentLinkData, paymentLinkDataLoading } =
    useGetPaymentLinkQuery({
      contractUuid,
    });

  const paymentLinkModal = usePaymentLinkModal({
    contractUuid: contractUuid ?? '',
    dependencies: [
      contractData,
      isActiveWithRemainder,
      canUsePaymentLink && !paymentLinkDataLoading,
    ],
    paymentLinkData,
    remainingBalance: contractData?.system_remainder,
  });

  const isRegulatedOrUnregulatedCredit = [
    'regulated credit',
    'unregulated credit',
  ].includes(contractData?.credit_type ?? '');

  const addCertificateOfInterestModal = useAddCertificateOfInterestModal({
    externalContractUUID,
    contractData,
    dependencies: [contractData, isRegulatedOrUnregulatedCredit, isHeidiPay],
  });

  const contractPrepaymentModal = useContractPrepaymentModal({
    ...paymentModalProps,
    dependencies: [
      ...paymentModalProps.dependencies,
      isActiveWithRemainder,
      !isRegulatedOrUnregulatedCredit,
    ],
  });

  const deleteFeesModal = useDeleteFeesModal({
    currency: contractData?.currency,
    dependencies: [contractData, monetaContractData?.late_fees?.length],
    externalContractUUID,
    invalidateMonetaData,
    lateFees: monetaContractData?.late_fees ?? [],
  });

  const assignOverrideBucketModal = useAssignOverrideBucketModal({
    currentBucketId: collectionsData?.bucket?.bucket_id,
    contractUuid,
    bucketListData,
  });

  const addPDRModal = useAddPDRModal({
    contractUuid,
    dependencies: [!contractData?.managed_by_crabb],
    externalContractUuid: externalContractUUID,
  });

  const closeCurrentAssignmentModal = useCloseCurrentAssignmentModal({
    contractUuid,
    dependencies: [!contractData?.managed_by_crabb],
    externalContractUuid: externalContractUUID,
  });

  // This is the order to display these buttons.
  return [
    triggerPaymentModal,
    changePaymentDateModal,
    pausePaymentsModal,
    emailUserModal,
    updateArrearsManagedByModal,
    contractManualPaymentModal,
    contractPrepaymentModal,
    contractReimbursementModal,
    contractAdjustmentsModal,
    downloadQrPdfModal,
    paymentLinkModal,
    addCertificateOfInterestModal,
    deleteFeesModal,
    assignOverrideBucketModal,
    closeCurrentAssignmentModal,
    addPDRModal,
  ].filter(Boolean);
};
