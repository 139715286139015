import { useEffect } from 'react';

export const logoutChannel = new BroadcastChannel('logout');

export const useCheckForLogOut = () => {
  useEffect(() => {
    logoutChannel.onmessage = () => {
      window.location.href = window.location.origin;
      logoutChannel.close();
    };
  }, []);
};
