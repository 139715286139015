import { Permissions } from '@appTypes';
import { useHasPermission } from '@hooks/useHasPermission';

interface IAccessControllerProps {
  children: JSX.Element | null;
  scopes?: Permissions;
  unauthedView?: JSX.Element | null;
}

export const AccessController = ({
  children,
  scopes,
  unauthedView = null,
}: IAccessControllerProps) => {
  const hasPermission = useHasPermission(scopes);
  return hasPermission ? children : unauthedView;
};
