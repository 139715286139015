import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import {
  IUnbalancedAllocation,
  IUnbalancedAllocations,
} from '@schemas/opsPortal/types/unbalancedAllocations';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { IUnbalancedAllocationsTableColumns } from './types';

interface IMapUnbalancedAllocationsTableData {
  data?: IUnbalancedAllocations;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const mapUnbalancedAllocationsTableData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
}: IMapUnbalancedAllocationsTableData): IUnbalancedAllocationsTableColumns[] =>
  data?.map((dataRow: IUnbalancedAllocation) => {
    const {
      allocation_balance: allocationBalance,
      difference,
      external_contract_uuid: contractUuid,
      latest_test_date: latestTestDate,
      moneta_balance: monetaBalance,
      par_group_reference: parGroup,
      payment_plan_reference: paymentPlanReference,
      uuid,
    } = dataRow;

    return addPlaceholderForEmptyValues({
      allocationBalance:
        allocationBalance?.amount &&
        getLocaleCurrencyValue({
          currency: allocationBalance.currency,
          value: allocationBalance.amount,
        }),
      contractUuid,
      difference:
        difference?.amount &&
        getLocaleCurrencyValue({
          currency: difference.currency,
          value: difference.amount,
        }),
      latestTestDate: getLocaleDate({ date: latestTestDate }),
      monetaBalance:
        monetaBalance?.amount &&
        getLocaleCurrencyValue({
          currency: monetaBalance.currency,
          value: monetaBalance.amount,
        }),
      parGroup,
      paymentPlanReference,
      uuid,
    });
  }) ?? [];
