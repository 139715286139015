import { ValueOf } from '@appTypes';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { IUseModalResponse, IModalProps } from '@hooks/useModal/types';
import { IOrderDetail as IConsumerOrderDetails } from '@schemas/consumerPortal/types/ordersDetails';
import { IOrderDetail as IMerchantOrderDetails } from '@schemas/merchantPortal/types/orderDetails';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails.d';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { PaymentTypes } from '@utils/constants';
import { PaymentForm } from './PaymentForm';
import { PaymentModalContent } from './PaymentModalContent';
import { PaymentSubmitRequest } from './types';
import { useGetSettlementAmount } from './useGetSettlementAmount';
import { getPaymentModalProps } from './utils';

interface IPaymentModalProps extends IUseModalResponse {
  data?: IContractDetails | IConsumerOrderDetails | IMerchantOrderDetails;
  handleSubmit: (values: PaymentSubmitRequest) => void;
  isPaymentLoading: boolean;
  monetaData?: IOpsPortalMonetaContract;
  paymentType: ValueOf<typeof PaymentTypes>;
  submitText: string;
  title: string;
}

export const usePaymentModal = ({
  data,
  formStatusMessage,
  handleSubmit,
  isPaymentLoading,
  monetaData,
  paymentType,
  submitText,
  title,
}: IPaymentModalProps): IModalProps => {
  const { handleSettlementChanged, settlementAmount, settlementUuid } =
    useGetSettlementAmount(paymentType, monetaData?.settlements);

  const paymentModalProps = data
    ? getPaymentModalProps({
        data,
        paymentType,
        settlementAmount,
      })
    : null;

  const formID = 'paymentForm';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isPaymentLoading}
    >
      <PaymentModalContent
        paymentPlanId={paymentModalProps?.paymentPlanId}
        paymentType={paymentType}
      >
        {paymentModalProps ? (
          <PaymentForm
            contractSettlements={monetaData?.settlements}
            formID={formID}
            handleSettlementChanged={handleSettlementChanged}
            handleSubmit={handleSubmit}
            paymentType={paymentType}
            settlementUuid={settlementUuid}
            {...paymentModalProps}
          />
        ) : null}
      </PaymentModalContent>
    </ModalStatusRouter>
  );

  return {
    formID,
    isDangerousSubmit: true,
    isFormProcessing: isPaymentLoading,
    isLoading: isPaymentLoading,
    modalContent,
    submitText,
    title,
  };
};
