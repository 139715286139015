import { useI18n } from '@hooks/useI18n/useI18n';
import { IModalProps, ISharedModalProps } from '@hooks/useModal/types';

interface IConfirmModal extends ISharedModalProps {
  cancelText?: string;
  children: React.ReactElement;
  confirmText?: string;
  onCancel?: () => void;
  onConfirm: () => void;
  title: string;
}

export const useConfirmModal = ({
  cancelText,
  children,
  confirmText,
  onCancel,
  onConfirm,
  title,
  variant,
}: IConfirmModal): IModalProps => {
  const { translate } = useI18n();

  return {
    cancelText: cancelText ?? translate('GENERAL.NO'),
    clickHandler: onConfirm,
    hideModal: onCancel,
    isDangerousSubmit: true,
    modalContent: children,
    showFormButtons: true,
    submitText: confirmText ?? translate('GENERAL.YES'),
    title,
    variant,
  };
};
