import { format } from 'date-fns';

export const formatExpiryTime = (date: Date | null): string | null => {
  if (!date) {
    return null;
  }
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
};

export const getMinDateTime = (): Date => new Date();
