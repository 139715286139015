import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IOpsPortalArrearsManagement } from '@schemas/opsPortal/types/arrearsManagement';
import { QueryKeys } from '@utils/constants';
import { ArrearsManagedByKeys, Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseGetArrearsManagementQuery {
  queryKeyContractUuid?: string;
}

export const useGetArrearsManagementQuery = ({
  queryKeyContractUuid,
}: IUseGetArrearsManagementQuery) => {
  const { data: arrearsManagementData, error: arrearsManagementError } =
    useQuery<IOpsPortalArrearsManagement, AxiosError>(
      [QueryKeys.ARREARS_MANAGEMENT, queryKeyContractUuid],
      () =>
        fetchDataFromEndpoint(
          `${Endpoints.ArrearsManagement}/${queryKeyContractUuid}`,
          {},
        ),
    );

  const isArrearsCurrentlyAssignedToHeidi = !arrearsManagementError
    ? arrearsManagementData?.agency === ArrearsManagedByKeys.HeidiPay
    : false;

  return {
    isArrearsCurrentlyAssignedToHeidi,
  };
};
