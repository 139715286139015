import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { EditorWrapper, FormGroup } from '@components/Common.styles';
import { RichTextEditor } from '@components/RichTextEditor';
import { ValidationError } from '@components/ValidationError';

interface IEditorFieldProps
  extends React.TextareaHTMLAttributes<HTMLInputElement> {
  containerClassName?: string;
  name: string;
}

export const EditorField = ({
  containerClassName,
  name,
}: IEditorFieldProps) => {
  const {
    formState: { defaultValues, errors },
    setValue,
  } = useFormContext();

  const error = errors[name]?.message;

  const setFormValue = useCallback(
    val => setValue(name, val),
    [name, setValue],
  );

  return (
    <FormGroup extraClassName={containerClassName}>
      <EditorWrapper>
        <RichTextEditor
          setFormValue={setFormValue}
          value={defaultValues?.[name]}
        />
      </EditorWrapper>
      {error ? <ValidationError error={error} /> : null}
    </FormGroup>
  );
};
