import { Dropdown } from '@components/Dropdown';
import { TextField } from '@components/TextField';
import { GetLocaleDate, Translate } from '@hooks/useI18n';
import { ISettlement } from '@schemas/opsPortal/types/monetaContract';
import { getSettlementUuids } from '../utils/getSettlementUuid';

interface IAdjustmentFormFieldsProps {
  contractSettlements?: ISettlement[];
  currency: string;
  getLocaleDate: GetLocaleDate;
  handleSettlementChanged: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  translate: Translate;
}

export const AdjustmentFormFields = ({
  contractSettlements = [],
  currency,
  getLocaleDate,
  handleSettlementChanged,
  translate,
}: IAdjustmentFormFieldsProps) => {
  const settlements = contractSettlements.filter(
    settlement => settlement.transaction_invalidation_date === null,
  );

  return (
    <>
      <Dropdown
        id="settlement_uuid"
        label={translate('OPS_PORTAL.TITLE.SETTLEMENT')}
        name="settlement_uuid"
        onChange={handleSettlementChanged}
        options={getSettlementUuids(settlements, getLocaleDate, translate)}
      />
      <TextField
        disabled={true}
        id="settlement_amount"
        label={`${translate(
          'OPS_PORTAL.TITLE.SETTLEMENT_ORIGINAL_AMOUNT',
        )} (${currency})`}
        name="settlement_amount"
        readOnly={true}
      />
      <TextField
        id="amount"
        label={`${translate(
          'OPS_PORTAL.TITLE.SETTLEMENT_NEW_AMOUNT',
        )} (${currency})`}
        name="amount"
      />
    </>
  );
};
