import { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormGroup, InputFile, Label } from '@components/Common.styles';
import { ValidationError } from '@components/ValidationError';
import { useI18n } from '@hooks/useI18n/useI18n';

interface IFileUploadProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  maximumFileSizeMb?: number;
  name: string;
}

export const FileUpload = ({ label, ...props }: IFileUploadProps) => {
  const { name } = props;
  const { translate } = useI18n();
  const {
    formState: { errors },
    register,
  } = useFormContext();

  const error = errors[name]?.message;

  return (
    <FormGroup>
      <Label htmlFor={props.id}>{label}</Label>
      <InputFile {...props} {...register(name)} id={props.id} />
      {error ? <ValidationError error={error} /> : null}
      {props.maximumFileSizeMb ? (
        <p>
          <strong>
            {translate('LABEL.MAXIMUM_FILE_SIZE', {
              file_size_mb: props.maximumFileSizeMb,
            })}
          </strong>
        </p>
      ) : null}
    </FormGroup>
  );
};
