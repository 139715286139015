import { useCallback } from 'react';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { Modal } from '@hooks/useModal/types';
import { useCloseCurrentAssignmentQuery } from '@pages/ContractDetails/hooks/queries';
import { QueryKeys } from '@utils/constants';
import { CloseCurrentAssignmentForm } from './CloseCurrentAssignmentForm';
import { ICloseCurrentAssignment } from './types';

export const useCloseCurrentAssignmentModal = (
  props: ICloseCurrentAssignment,
): Modal => {
  const { contractUuid, externalContractUuid } = props;
  const { translate } = useI18n();

  const modal = useModal({
    sharedProps: props,
    buttonTranslationKey: 'OPS_PORTAL.TITLE.CLOSE_CURRENT_ASSIGNMENT',
    modalName: 'closeCurrentAssignmentModal',
    permissions: ['ops_portal.can_close_assignment_to_external_agency'],
  });
  const { closeModal, formStatusMessage } = modal;

  const contractDetailsQueryKey = [
    QueryKeys.CONTRACT_DETAILS_DATA,
    externalContractUuid,
  ];

  const { closeCurrentAssignment, isClosingCurrentAssignment } =
    useCloseCurrentAssignmentQuery({
      closeModal,
      contractDetailsQueryKey,
      contractUuid,
    });

  const handleSubmit = useCallback(() => {
    closeCurrentAssignment();
  }, [closeCurrentAssignment]);

  const formID = 'closeCurrentAssignment';
  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isClosingCurrentAssignment}
    >
      <CloseCurrentAssignmentForm formID={formID} handleSubmit={handleSubmit} />
    </ModalStatusRouter>
  );

  const modalProps = {
    allowoverflow: true,
    formID,
    isDangerousSubmit: true,
    isLoading: isClosingCurrentAssignment,
    modalContent,
    submitText: translate('OPS_PORTAL.TITLE.CLOSE_CURRENT_ASSIGNMENT'),
    title: translate('OPS_PORTAL.TITLE.CLOSE_CURRENT_ASSIGNMENT'),
  };

  return getModalDetails({ modal, modalProps });
};
