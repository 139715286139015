import { getNavData } from '@hooks/useConfig/utils';
import { ConfigFunc } from './types';

const { NavItems, NavSectionHeadingKeys } = getNavData();

export const configOpsPagolight: ConfigFunc = () => {
  return Object.freeze({
    canChangePassword: true,
    canEditAddress: false,
    canEditName: false,
    canEditPhone: false,
    navigation: [
      {
        heading: NavSectionHeadingKeys.APPLICATIONS,
        items: [
          NavItems.APPLICATIONS,
          NavItems.API_CALLS,
          NavItems.BLOCKED_APPLICANTS,
        ],
      },
      {
        heading: NavSectionHeadingKeys.CONTRACTS,
        items: [
          NavItems.ALL_CONTRACTS,
          NavItems.OVERPAID_CONTRACTS,
          NavItems.PAUSED_CONTRACTS,
          NavItems.TERMINABLE_CONTRACTS,
          NavItems.AWAITING_DISPATCH,
          NavItems.COLLECTIONS,
          NavItems.UTILITIES,
        ],
      },
      {
        heading: NavSectionHeadingKeys.BANKING,
        items: [
          NavItems.BANK_ACCOUNT_RECONCILIATIONS,
          NavItems.SUSPENSE,
          NavItems.PENDING_ALLOCATIONS,
          NavItems.UNBALANCED_ALLOCATIONS,
        ],
      },
      {
        heading: NavSectionHeadingKeys.VIEWS,
        items: [NavItems.MERCHANTS, NavItems.CONSUMERS],
      },
      {
        heading: NavSectionHeadingKeys.ACCOUNT_MANAGEMENT,
        items: [NavItems.ACCOUNT_SETTINGS, NavItems.LOGOUT],
      },
    ],
    showCompassOrders: true,
    showFooter: false,
    showFooterPrivacyLink: false,
    showInvoiceDetails: false,
    showLanguageDropdown: false,
    showProfilePicker: true,
  });
};
