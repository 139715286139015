import { useCallback } from 'react';
import { useQueryClient, useMutation, QueryKey } from '@tanstack/react-query';
import { Permissions } from '@appTypes';
import { TranslateKey } from '@hooks/useI18n';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { ModalSize } from '@hooks/useModal/components/enums';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { AdjustContractValueModalContent } from './AdjustContractValueModalContent';

interface IUseAdjustContractValueModal extends ISharedModalProps {
  amount: string;
  currency: string;
  queryKey: QueryKey;
  uuid: string;
}

interface IUseAdjustContractValueSubModal extends IUseAdjustContractValueModal {
  endpoint: string;
  errorMessageKey: TranslateKey;
  isCancel: boolean;
  modalName: string;
  permissions: Permissions;
  successMessageKey: TranslateKey;
  title: TranslateKey;
}

const useAdjustContractValueModal = (
  props: IUseAdjustContractValueSubModal,
): Modal => {
  const {
    amount,
    currency,
    endpoint,
    errorMessageKey,
    isCancel,
    modalName,
    permissions,
    queryKey,
    successMessageKey,
    title,
    uuid,
  } = props;
  const { translate } = useI18n();

  const modal = useModal({
    sharedProps: props,
    buttonTranslationKey: title,
    modalName,
    permissions,
  });

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    requestData =>
      postDataToEndpoint({
        endpoint: `${endpoint}/${uuid}`,
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey, uuid]);
        modal.resolveForm(translate(successMessageKey));
      },
      onError: () => {
        modal.resolveForm(translate(errorMessageKey));
      },
    },
  );

  const handleSubmit = useCallback(values => mutate(values), [mutate]);

  const formID = 'adjustContractValueForm';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={modal.formStatusMessage}
      isLoading={isLoading}
    >
      <AdjustContractValueModalContent
        amount={amount}
        contractUuid={uuid}
        currency={currency}
        formID={formID}
        handleSubmit={handleSubmit}
        isCancel={isCancel}
        resolveForm={modal.resolveForm}
      />
    </ModalStatusRouter>
  );

  const modalProps = {
    formID,
    isDangerousSubmit: true,
    isFormProcessing: isLoading,
    isLoading,
    modalContent,
    title: translate(title),
    width: ModalSize.Sm,
  };

  return getModalDetails({ modal, modalProps });
};

export const useEditAdjustContractValue = (
  props: IUseAdjustContractValueModal,
) =>
  useAdjustContractValueModal({
    ...props,
    modalName: 'adjustContractValueModal',
    permissions: ['merchant_portal.can_adjust_contract_value'],
    title: 'TITLE.CHANGE_ORDER_AMOUNT',
    endpoint: Endpoints.AdjustContract,
    successMessageKey: 'PAGE.ORDERS.CONTRACT_VALUE_SUCCESSFULLY_ADJUSTED',
    errorMessageKey: 'PAGE.ORDERS.UNABLE_TO_ADJUST_CONTRACT_VALUE',
    isCancel: false,
  });

export const useCancelAdjustContractValue = (
  props: IUseAdjustContractValueModal,
) =>
  useAdjustContractValueModal({
    ...props,
    modalName: 'cancelContractModal',
    permissions: ['merchant_portal.can_cancel_contract'],
    title: 'TITLE.CANCEL_ORDER',
    endpoint: Endpoints.CancelContract,
    successMessageKey: 'PAGE.ORDERS.ORDER_SUCCESSFULLY_CANCELLED',
    errorMessageKey: 'PAGE.ORDERS.UNABLE_TO_CANCEL_ORDER',
    isCancel: true,
  });
