import { IAddressComponentsTypesType } from '@schemas/consumerPortal/types/addressData';
import { IAddressComponents, IAddressValues } from './types';

const keyMapping = {
  country: 'address_country',
  locality: 'address_town',
  postal_code: 'address_post_code',
  postal_town: 'address_town',
  route: 'address_first_line',
  second_line: 'address_second_line',
  street_number: 'address_street_number',
};

const allowedKeys: Array<IAddressComponentsTypesType> = [
  'country',
  'locality',
  'postal_code',
  'postal_town',
  'route',
  'second_line',
  'street_number',
];

export const getManualAddressFormData = (
  addressComponents: IAddressComponents,
) =>
  addressComponents.reduce((previous, current) => {
    const key = current.types.find((type: IAddressComponentsTypesType) =>
      allowedKeys.includes(type),
    );
    return key
      ? {
          ...previous,
          [keyMapping[key as keyof typeof keyMapping]]: current.long_name ?? '',
        }
      : previous;
  }, {} as IAddressValues);
