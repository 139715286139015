import { createColumnHelper } from '@tanstack/react-table';
import { ReactTableColumns } from '@appTypes';
import { Translate } from '@hooks/useI18n';
import { SortingFunctions } from '@utils/constants';
import { filterDateRange } from '@utils/filterDateRange';
import { BlockActions } from './BlockActions';
import { IBlockedApplicantsTableColumns } from './types';

export const getColumns = (
  translate: Translate,
  showButtons: boolean,
): ReactTableColumns<IBlockedApplicantsTableColumns> => {
  const columnHelper = createColumnHelper<IBlockedApplicantsTableColumns>();

  return [
    columnHelper.accessor('applicantUuid', {
      header: translate('OPS_PORTAL.LABEL.APPLICANT_UUID'),
      enableSorting: false,
    }),
    columnHelper.accessor('applicantName', {
      header: translate('OPS_PORTAL.TITLE.APPLICANT_NAME'),
    }),
    columnHelper.accessor('dateBlocked', {
      header: translate('OPS_PORTAL.TITLE.DATE_BLOCKED'),
      sortingFn: SortingFunctions.DATETIME,
    }),
    columnHelper.accessor('unblockDate', {
      header: translate('OPS_PORTAL.TITLE.DATE_TO_UNBLOCK'),
      filterFn: (row, _, filterValue) =>
        filterDateRange({ accessorKey: 'unblockDate', filterValue, row }),
      sortingFn: SortingFunctions.DATETIME,
    }),
    columnHelper.accessor('blockReason', {
      header: translate('OPS_PORTAL.TITLE.BLOCK_REASON'),
    }),
    columnHelper.accessor('blockedBy', {
      header: translate('OPS_PORTAL.TITLE.BLOCKED_BY'),
    }),
    ...(showButtons
      ? [
          columnHelper.display({
            cell: ({ row }) => (
              <BlockActions applicantUuid={row.original.applicantUuid} />
            ),
            id: 'ctaColumn',
          }),
        ]
      : []),
  ];
};
