import { Translate } from '@heidi-pay/heidi-common-fe/types';
import { GetLocaleCurrencyValue } from '@hooks/useI18n';
import {
  IAmountObject,
  ISchedule,
} from '@schemas/merchantPortal/types/adjustContractPreview';
import { getFormattedAmount } from '@utils/getFormattedAmount';

export interface IGetPaymentPlanFieldsProps {
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  paymentSimulation: {
    numberOfInstallments: number;
    payMonthly: IAmountObject;
    payNow?: IAmountObject;
    residualAmount?: IAmountObject;
    residualPercentage?: string | null;
    schedule: ISchedule;
    total: IAmountObject;
  };
  translate: Translate;
}

export const getPaymentPlanFields = ({
  getLocaleCurrencyValue,
  paymentSimulation: {
    numberOfInstallments,
    payMonthly,
    payNow,
    residualAmount,
    residualPercentage,
    schedule,
    total,
  },
  translate,
}: IGetPaymentPlanFieldsProps) => {
  const residualAmountPayment =
    Number(residualAmount?.amount) > 0 || residualPercentage
      ? schedule[schedule.length - 1]
      : null;

  const numberOfInstallmentsExcludingResidualPayment = residualAmountPayment
    ? numberOfInstallments - 1
    : numberOfInstallments;

  return [
    {
      key: translate('LABEL.NEW_TOTAL'),
      value:
        getFormattedAmount({
          ...total,
          getLocaleCurrencyValue,
        }) ?? '',
    },
    ...(Number(payNow?.amount) > 0
      ? [
          {
            key: translate('LABEL.PAY_NOW'),
            value:
              getFormattedAmount({
                ...payNow,
                getLocaleCurrencyValue,
              }) ?? '',
          },
        ]
      : []),
    {
      key: translate('LABEL.PAY_MONTHLY', {
        number_of_equal_installments:
          numberOfInstallmentsExcludingResidualPayment,
      }),
      value:
        getFormattedAmount({
          ...payMonthly,
          getLocaleCurrencyValue,
        }) ?? '',
    },
    ...(residualAmountPayment
      ? [
          {
            key: translate('LABEL.FINAL_INSTALMENT_WITH_MONTH', {
              final_installment_month: schedule.length,
            }),
            value:
              getFormattedAmount({
                ...residualAmountPayment?.amount,
                getLocaleCurrencyValue,
              }) ?? '',
          },
        ]
      : []),
  ];
};
