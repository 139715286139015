import { IFilterOptions } from '@hooks/useQueryState/utils/types';
import { INotesTableDataRow } from './types';

export const getOptions = (data: INotesTableDataRow[]) => [
  { id: '', name: '—' },
  ...(data.length
    ? data.reduce(
        (options: IFilterOptions[], note) =>
          options.map(option => option.id).includes(note.createdBy)
            ? options
            : [
                ...options,
                {
                  id: note.createdBy,
                  name: note.createdBy,
                },
              ],
        [],
      )
    : []),
];
