import { FormProvider, useForm } from 'react-hook-form';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ICloseCurrentAssignmentForm } from './types';

export const CloseCurrentAssignmentForm = ({
  formID,
  handleSubmit,
}: ICloseCurrentAssignmentForm) => {
  const { translate } = useI18n();
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <form id={formID} onSubmit={methods.handleSubmit(handleSubmit)}>
        <p>{translate('OPS_PORTAL.LABEL.ARE_YOU_SURE_CLOSE_ASSIGNMENT')}</p>
      </form>
    </FormProvider>
  );
};
