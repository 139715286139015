import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePickerRHF } from '@components/DatePicker';
import { DropdownRHF as Dropdown } from '@components/Dropdown';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ValidationErrorKeys } from '@utils/enums';
import { sortDropwdownOptionsByName } from '@utils/sortDropdownOptionsByName/sortDropdownOptionsByName';
import { IAddPDRForm, IAddPDRRequest } from './types';

export const AddPDRForm = ({
  agenciesList,
  formID,
  handleSubmit,
}: IAddPDRForm) => {
  const { translate } = useI18n();
  const defaultValues = {
    agency_uuid: '',
    due_date: '',
  };

  const validationSchema = () => {
    return Yup.object({
      agency_uuid: Yup.string().required(
        translate(ValidationErrorKeys.Required),
      ),
      due_date: Yup.string().required(translate(ValidationErrorKeys.Required)),
    });
  };

  const methods = useForm<IAddPDRRequest>({
    defaultValues,
    resolver: yupResolver(validationSchema()),
  });

  const agencyOptions = agenciesList?.map(agency => ({
    id: agency.uuid,
    name: agency.name,
  }));

  return (
    <FormProvider {...methods}>
      <form id={formID} onSubmit={methods.handleSubmit(handleSubmit)}>
        {!agenciesList.length ? (
          <p>
            {translate('OPS_PORTAL.LABEL.NO_AGENCIES_AVAILABLE_TO_ADD_PDR')}
          </p>
        ) : (
          <>
            <Dropdown
              label={translate('OPS_PORTAL.TITLE.ADD_PDR')}
              name="agency_uuid"
              options={sortDropwdownOptionsByName(agencyOptions)}
            />
            <DatePickerRHF
              label={translate('TITLE.DUE_DATE')}
              minDate={new Date()}
              name="due_date"
            />
          </>
        )}
      </form>
    </FormProvider>
  );
};
