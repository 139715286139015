import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useQueryClient, useMutation, QueryKey } from '@tanstack/react-query';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { IDate } from '@schemas/opsPortal/types/contractDetails';
import { Endpoints } from '@utils/enums';
import { getErrorMessage } from '@utils/getErrorMessage';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { PausePaymentForm } from './PausePaymentForm';

interface IPauseData {
  date: string;
  pause_payment_processing: boolean;
}

interface IPauseResponse {
  message: string;
}

interface IUsePausePaymentsModalProps extends ISharedModalProps {
  contractDataQueryKey?: QueryKey;
  isPaused: boolean;
  paymentsPausedTillDate?: IDate;
  uuid?: string;
}

export const usePausePaymentsModal = (
  props: IUsePausePaymentsModalProps,
): Modal => {
  const { contractDataQueryKey, isPaused, paymentsPausedTillDate, uuid } =
    props;
  const { translate } = useI18n();
  const queryClient = useQueryClient();

  const modal = useModal({
    sharedProps: props,
    buttonTranslationKey: isPaused
      ? 'OPS_PORTAL.LABEL.RESUME_PAYMENTS'
      : 'OPS_PORTAL.LABEL.PAUSE_PAYMENTS',
    modalName: 'pausePaymentsModal',
    permissions: [
      'ops_portal.can_toggle_pause_payment_processing',
      'ops_portal.can_pause_payment_processing_till_date',
    ],
  });

  const { formStatusMessage, resolveForm } = modal;

  const hasFullToggleAccess = useHasPermission([
    'ops_portal.can_toggle_pause_payment_processing',
  ]);

  const endpointForUnpausing =
    hasFullToggleAccess && !paymentsPausedTillDate
      ? Endpoints.ResumePayments
      : Endpoints.ResumePaymentsTillDate;

  const { isLoading: isRequestingPause, mutate: pausePayment } = useMutation<
    IPauseResponse,
    AxiosError<IError>,
    IPauseData
  >(
    requestData => {
      if (requestData.pause_payment_processing) {
        return postDataToEndpoint({
          endpoint: `${Endpoints.PausePaymentIndefinitely}/${uuid}`,
        });
      }
      return postDataToEndpoint({
        endpoint: `${Endpoints.PausePaymentsTillDate}/${uuid}`,
        requestData,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(contractDataQueryKey);
        resolveForm(translate('OPS_PORTAL.STATUS.PAUSE_PAYMENTS_SUCCESSFUL'));
      },
      onError: error => {
        resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey:
              'OPS_PORTAL.STATUS.UNABLE_TO_PAUSE_PAYMENTS',
            translate,
          }),
        );
      },
    },
  );

  const { isLoading: isRequestingUnpause, mutate: unpausePayment } =
    useMutation<IPauseResponse, AxiosError<IError>>(
      () => postDataToEndpoint({ endpoint: `${endpointForUnpausing}/${uuid}` }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(contractDataQueryKey);
          resolveForm(
            translate('OPS_PORTAL.STATUS.RESUME_PAYMENTS_SUCCESSFUL'),
          );
        },
        onError: error => {
          resolveForm(
            getErrorMessage({
              error,
              genericErrorMessageKey:
                'OPS_PORTAL.STATUS.UNABLE_TO_RESUME_PAYMENTS',
              translate,
            }),
          );
        },
      },
    );

  const handleSubmit = useCallback(data => pausePayment(data), [pausePayment]);
  const handleClick = useCallback(() => unpausePayment(), [unpausePayment]);
  const formID = 'pausePayment';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isRequestingPause || isRequestingUnpause}
    >
      {isPaused ? (
        <p>{translate('OPS_PORTAL.PAGE.CONFIRM_RESUME_PAYMENTS')}</p>
      ) : (
        <PausePaymentForm formID={formID} handleSubmit={handleSubmit} />
      )}
    </ModalStatusRouter>
  );

  const modalProps = {
    allowoverflow: true,
    formID,
    ...(isPaused ? { clickHandler: handleClick } : {}),
    isDangerousSubmit: true,
    isLoading: isRequestingPause || isRequestingUnpause,
    modalContent,
    submitText: isPaused
      ? translate('BUTTON.CONFIRM')
      : translate('BUTTON.SUBMIT'),
    title: translate(
      isPaused
        ? 'OPS_PORTAL.LABEL.RESUME_PAYMENTS'
        : 'OPS_PORTAL.LABEL.PAUSE_PAYMENTS',
    ),
  };

  return getModalDetails({ modal, modalProps });
};
