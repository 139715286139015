import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IOpsPortalMerchantsList } from '@schemas/opsPortal/types/merchantsList';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseGetMerchantsQuery {
  enabled: boolean;
  originator: string;
}

export const useGetMerchantsQuery = ({
  enabled,
  originator,
}: IUseGetMerchantsQuery) => {
  const {
    data: merchantsData,
    error: merchantsError,
    isLoading: isMerchantsLoading,
  } = useQuery<IOpsPortalMerchantsList, AxiosError>(
    [QueryKeys.FULL_MERCHANT_LIST, originator],
    () =>
      fetchDataFromEndpoint(Endpoints.FetchMerchantsAll, {
        query: { originator },
      }),
    {
      enabled,
    },
  );

  return {
    merchantsData,
    merchantsError,
    isMerchantsLoading,
  };
};
