import { useCallback } from 'react';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { Modal } from '@hooks/useModal/types';
import {
  useAddPDRQuery,
  useGetAgenciesListQuery,
} from '@pages/ContractDetails/hooks/queries';
import { QueryKeys } from '@utils/constants';
import { AddPDRForm } from './AddPDRForm';
import { IAddPDR } from './types';

export const useAddPDRModal = (props: IAddPDR): Modal => {
  const { contractUuid, externalContractUuid } = props;
  const { translate } = useI18n();

  const contractDetailsQueryKey = [
    QueryKeys.CONTRACT_DETAILS_DATA,
    externalContractUuid,
  ];

  const { agenciesListData, isLoadingAgenciesList } = useGetAgenciesListQuery();

  const modal = useModal({
    sharedProps: props,
    modalName: 'addPDRModal',
    permissions: ['ops_portal.can_create_acknowledgement_of_debt'],
    buttonTranslationKey: 'OPS_PORTAL.TITLE.ADD_PDR',
  });

  const { addPDR, isAddingPDR } = useAddPDRQuery({
    closeModal: modal.closeModal,
    contractDetailsQueryKey,
    contractUuid,
  });

  const handleSubmit = useCallback(
    async data => {
      addPDR(data);
    },
    [addPDR],
  );

  const formID = 'addPDR';
  const isLoading = isAddingPDR || isLoadingAgenciesList;
  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={modal.formStatusMessage}
      isLoading={isLoading}
    >
      <AddPDRForm
        agenciesList={agenciesListData?.agencies ?? []}
        formID={formID}
        handleSubmit={handleSubmit}
      />
    </ModalStatusRouter>
  );

  const modalProps = {
    allowoverflow: true,
    formID,
    isDangerousSubmit: true,
    isLoading,
    modalContent,
    title: translate('OPS_PORTAL.TITLE.ADD_PDR'),
  };

  return getModalDetails({ modal, modalProps });
};
