import { useMemo } from 'react';
import { Card } from '@components/Card';
import { StaticTable } from '@components/Table/StaticTable';
import { useI18n } from '@hooks/useI18n/useI18n';
import { missingValuePlaceholder } from '@utils/constants';
import { getAllocationsColumns } from './getAllocationsColumns';
import { mapAllocationsTableData } from './mapAllocationsTableData';
import { IAllocationsProps } from './types';

export const AllocationsCard = ({
  allocations,
  balance,
}: IAllocationsProps) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const columns = useMemo(() => getAllocationsColumns(translate), [translate]);

  const tableData = mapAllocationsTableData({
    data: allocations,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
  });

  const transactionsTotal =
    (balance &&
      getLocaleCurrencyValue({
        currency: balance.currency,
        value: balance.amount,
      })) ??
    missingValuePlaceholder;

  return (
    <Card
      title={translate(
        'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.ALLOCATIONS_BANK_RECEIPTS',
      )}
    >
      <StaticTable
        columns={columns}
        data={tableData}
        tfoot={[translate('LABEL.TOTAL'), transactionsTotal]}
      />
    </Card>
  );
};
