import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IOpsPortalAllApplicantRiskOverrideLists } from '@schemas/opsPortal/types/allApplicantRiskOverrideLists';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

export const useGetRiskOverridesList = () => {
  const {
    data: riskOverridesListData,
    error: riskOverridesListError,
    isLoading: riskOverridesIsLoading,
  } = useQuery<IOpsPortalAllApplicantRiskOverrideLists, AxiosError>(
    [QueryKeys.APPLICANT_RISK_OVERRIDES_LIST],
    () => fetchDataFromEndpoint(`${Endpoints.FetchApplicantRiskOverridesList}`),
  );

  return {
    riskOverridesListData,
    riskOverridesListError,
    riskOverridesIsLoading,
  };
};
