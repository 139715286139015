import { TableState, Updater } from '@tanstack/react-table';
import { IFilter } from '@hooks/useQueryState/utils/types';
import { FilterTypes } from '@utils/constants';

type Values = Record<string, string>;

interface IHandleFilteringProps {
  filters: IFilter[];
  setState: (updater: Updater<TableState>) => void;
  state: TableState;
  values: Values;
}

const getAccessor = (filter: IFilter) => filter.accessorKey;

const getFilterValues = (values: Values, filter: IFilter) => {
  const { from, id, to, type } = filter;
  if (type === FilterTypes.DATE_RANGE && from?.id && to?.id) {
    return [values[from.id], values[to.id]];
  }

  if (type === FilterTypes.PRESET_DATE_RANGE && id && from?.id && to?.id) {
    return JSON.parse(
      values[id] ?? `["${values[from.id]}","${values[to.id]}"]`,
    );
  }

  return values[id];
};

export const handleFiltering = ({
  filters,
  setState,
  state,
  values,
}: IHandleFilteringProps) => {
  // At this point we are passing all of the current filter values the user has entered/selected.
  // Each filter is mapped to the name of the filter and what the user has entered/selected.
  // E.g. values = { scheduleVersion: '3' }
  const columnFilters = filters.filter(getAccessor).map(filter => ({
    id: filter.accessorKey as string,
    value: getFilterValues(values, filter),
  }));
  setState({ ...state, columnFilters, globalFilter: values.search });
};
