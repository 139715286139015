import { ContractStatuses } from '@utils/constants';
import { IExpectedTableData } from '@utils/test/helpers';

export const expectedTableData: IExpectedTableData[] = [
  { header: 'Payment plan ID', cell: 'H-12345' },
  { header: 'Created date', cell: 'Mar 28, 2024' },
  { header: 'Customer name', cell: 'David Cross' },
  { header: 'Merchant name', cell: 'Silky Salmon' },
  { header: 'Credit type', cell: 'BNPL factoring' },
  { header: 'Activity status', cell: 'Active' },
  { header: 'Amount', cell: 'CHF\u00a0528.75' },
  { header: 'Arrears amount', cell: 'CHF\u00a031.01' },
  { header: 'Origination', cell: 'ecommerce' },
  { header: 'Arrears managed by', cell: 'HeyLight' },
  { header: 'Originator', cell: 'HeyLight Dilazione' },
];

export const terminatedContractStatusKeys: string[] = [
  ContractStatuses.TERMINATED,
  ContractStatuses.TERMINATED_AND_PAID,
  ContractStatuses.PENDING_TERMINATION,
];
