import { IOrderDetail as IOrderDetailConsumer } from '@schemas/consumerPortal/types/ordersDetails';
import { IOrderDetail as IOrderDetailMerchant } from '@schemas/merchantPortal/types/orderDetails';
import { ContractStatuses } from '@utils/constants';

export interface ICheckPropertiesExist {
  data: IOrderDetailConsumer | IOrderDetailMerchant;
  isPagolightPro: boolean;
}

type ContractStatusesWhichAllowPayment =
  | typeof ContractStatuses.ACTIVE
  | typeof ContractStatuses.AWAITING_DISPATCH;

export const checkPropertiesExist = ({
  data,
  isPagolightPro = false,
}: ICheckPropertiesExist) => {
  const {
    order_details: { amount, products },
    status,
  } = data;

  const hasCurrency = Boolean(
    typeof amount === 'string' ? null : amount.currency,
  );

  const hasProducts = Boolean(products?.length);

  const hasPayments = Boolean(
    (data as IOrderDetailConsumer).receivables?.length,
  );

  const statusesWhichAllowPayment = [
    ContractStatuses.ACTIVE,
    ...(isPagolightPro ? [ContractStatuses.AWAITING_DISPATCH] : []),
  ];

  const canMakePayment =
    statusesWhichAllowPayment.includes(
      status as ContractStatusesWhichAllowPayment,
    ) && hasCurrency;

  const canAdjustContractValue = status === ContractStatuses.AWAITING_DISPATCH;

  const canCancelOrder = status === ContractStatuses.AWAITING_DISPATCH;

  const canConfirmDispatch = status === ContractStatuses.AWAITING_DISPATCH;

  return {
    hasCurrency,
    hasPayments,
    hasProducts,
    permissions: {
      canAdjustContractValue,
      canCancelOrder,
      canConfirmDispatch,
      canMakePayment,
    },
  };
};
