import axios from 'axios';

interface IPostFormDataToEndpointProps {
  endpoint: string;
  options?: { search: string };
  requestData: FormData;
}

export const postFormDataToEndpoint = async ({
  endpoint,
  options,
  requestData,
}: IPostFormDataToEndpointProps) => {
  const url = `/api/${endpoint}/${options?.search ?? ''}`;
  const { data } = await axios.post(url, requestData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return data;
};
