import { Permissions } from '@appTypes';

export interface IPermissionScopes {
  scopes: Permissions;
}

export const getCombinedScopes = (items: IPermissionScopes[]) =>
  items
    .reduce((scopes, item) => [...scopes, ...item.scopes], [] as Permissions)
    .filter((item, idx, arr) => arr.indexOf(item) === idx)
    .sort((a, b) => a.localeCompare(b));
