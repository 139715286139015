import { OriginatorRouterLink } from '@auth/pages/components';
import { Button } from '@components/Button';
import { NotificationBanner } from '@components/NotificationBanner';
import { AlertSeverity, PageRoutes } from '@utils/enums';
import { getNotificationMessage } from './getNotificationMessage';
import { INotificationProps } from './types';

interface IGetNotificationProps extends INotificationProps {
  id: string;
}

export const getNotification = ({
  associations,
  blockDetails,
  getLocaleDate,
  id,
  isBlocked,
  isPrimaryBlock,
  translate,
}: IGetNotificationProps) => {
  const message = getNotificationMessage({
    associations,
    blockDetails,
    getLocaleDate,
    isBlocked,
    isPrimaryBlock,
    translate,
  });

  const link = (
    <Button
      as={OriginatorRouterLink}
      to={PageRoutes.BlockedApplicants}
      variant="primary"
    >
      {translate('OPS_PORTAL.BUTTON.VIEW_BLOCKED_APPLICANTS')}
    </Button>
  );

  const notificationOptions = [
    {
      id,
      message,
      severity: AlertSeverity.Warning,
      title: translate('OPS_PORTAL.STATUS.BLOCKED'),
    },
  ];
  return isBlocked ? (
    <NotificationBanner
      action={isPrimaryBlock ? undefined : link}
      notifications={notificationOptions}
    />
  ) : null;
};
