import {
  GetLocaleDate,
  IGetLocaleCurrencyValueOptions,
  Translate,
} from './types';
import { getLocaleCurrencyValue, getLocaleDate } from './utils';
import translationsEnglish from './translations/en.json';

export const getLocaleCurrencyValueMock = ({
  currency,
  value,
}: IGetLocaleCurrencyValueOptions) =>
  getLocaleCurrencyValue({ currency, languageCode: 'en', value });

export const getLocaleDateMock: GetLocaleDate = ({ date, includeTime }) =>
  getLocaleDate({ date, includeTime, languageCode: 'en' });

export const translateMock: Translate = (id, params) => {
  const translation =
    translationsEnglish[id as keyof typeof translationsEnglish] ?? id;

  if (!params) {
    return translation;
  }

  // Replace placeholders in the translation string with values from params
  return translation.replace(/\{(\w+)\}/g, (_, paramKey) =>
    params[paramKey] !== undefined ? String(params[paramKey]) : '',
  );
};
