import KTScrolltop from '@metronic/_assets/js/components/scrolltop';
import { KTUtil } from '@metronic/_assets/js/components/util';

const KTLayoutScrolltop = (function ktLayoutScrolltop() {
  // Private properties
  let element;

  // Private functions
  const init = function init() {
    return new KTScrolltop(element, {
      offset: 300,
      speed: 600,
    });
  };

  // Public methods
  return {
    init(id) {
      element = KTUtil.getById(id);

      if (!element) {
        return;
      }

      init();
    },

    getElement() {
      return element;
    },
  };
})();

export default KTLayoutScrolltop;
