import { CardCTAs } from '@appTypes';
import { ButtonMenu } from '@components/ButtonMenu';
import { CardCTA } from '@components/CardCTA';
import { useConfig } from '@hooks/useConfig';

interface ICardButtonsProps {
  buttons: CardCTAs;
}

export const CardButtons = ({ buttons }: ICardButtonsProps) => {
  const { maxCardHeaderCTAs } = useConfig();

  return (
    <>
      {buttons?.length > maxCardHeaderCTAs ? (
        <ButtonMenu buttons={buttons} />
      ) : (
        buttons
          ?.map(button => button && <CardCTA {...button} key={button.key} />)
          .filter(Boolean)
      )}
    </>
  );
};
