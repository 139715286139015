import { CSSProperties } from 'react';
import styled from 'styled-components';
import { ValueOf } from '@appTypes';
import { useConfig } from '@hooks/useConfig';
import { LogoVariants } from '@utils/constants';
import { ImageLogo } from './ImageLogo';

interface ILogoProps {
  fill?: ValueOf<typeof LogoVariants>;
  justifyContent?: CSSProperties['justifyContent'];
}

interface IOuterWrapperProps {
  justifyContent: CSSProperties['justifyContent'];
}

const OuterWrapper = styled.span<IOuterWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  width: 100%;

  @media (max-width: 991.98px) {
    h1 & {
      justify-content: center;
    }
  }
`;

const InnerWrapper = styled.span`
  display: inline-block;
  width: 150px;
  overflow: hidden;
`;

export const Logo = ({
  fill = LogoVariants.DARK,
  justifyContent = 'flex-start',
}: ILogoProps) => {
  const { logo } = useConfig();

  return (
    <OuterWrapper justifyContent={justifyContent}>
      <InnerWrapper>
        <ImageLogo altTxt={logo.altTxt} url={logo.url} variant={fill} />
      </InnerWrapper>
    </OuterWrapper>
  );
};
