import { ColumnDef } from '@tanstack/react-table';
import { Translate } from '@hooks/useI18n';
import { createCSV } from '@utils/createCSV/createCSV';
import { mapArrayOfMapsTo2dArray } from '@utils/mapArrayOfMapsTo2dArray/mapArrayOfMapsTo2dArray';
import { syncDataToCols } from '@utils/syncDataToCols/syncDataToCols';
import { IContractTableData } from './types';

interface ICreateContractsCSVParams {
  columns: ColumnDef<IContractTableData, unknown>[];
  data: IContractTableData[];
  translate: Translate;
}

export const createContractsCSV = ({
  columns,
  data,
  translate,
}: ICreateContractsCSVParams) => {
  columns.splice(1, 0, {
    accessorKey: 'contractId',
    header: translate('OPS_PORTAL.TITLE.CONTRACT_ID'),
  });

  const headers = columns
    .filter(col => typeof col.header === 'string')
    .map(col => col.header) as string[];

  const syncedData = syncDataToCols({ columns, data });
  const arrayedData = mapArrayOfMapsTo2dArray(syncedData);

  return createCSV({ arrayedData, headers });
};
