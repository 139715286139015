import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useQueryClient, useMutation, QueryKey } from '@tanstack/react-query';
import { Permissions } from '@appTypes';
import { TranslateKey } from '@hooks/useI18n';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { useOriginator } from '@hooks/useOriginator';
import { usePortalError } from '@hooks/usePortalError';
import { IEditUserGroup } from '@schemas/merchantPortal/types/editUserGroups';
import { IGroup } from '@schemas/merchantPortal/types/groups';
import { Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { putDataToEndpoint } from '@utils/putDataToEndpoint';
import { UserForm } from './UserForm';

interface IUserModalProps extends ISharedModalProps {
  groups?: IGroup[];
  queryKey: QueryKey;
  userProps?: IEditUserGroup;
}

interface IUserModalSubProps extends IUserModalProps {
  buttonTranslationKey: TranslateKey;
  errorKey: TranslateKey;
  formID: string;
  isCreate: boolean;
  modalName: string;
  permissions: Permissions;
  successKey: TranslateKey;
  title: TranslateKey;
}

export const useUserModal = (props: IUserModalSubProps): Modal => {
  const {
    buttonTranslationKey,
    errorKey,
    formID,
    groups,
    isCreate,
    modalName,
    permissions,
    queryKey,
    successKey,
    title,
    userProps,
    variant,
  } = props;
  const { translate } = useI18n();
  const { originator } = useOriginator();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast(translate);
  const queryClient = useQueryClient();

  const modal = useModal({
    sharedProps: props,
    modalName,
    permissions,
    buttonTranslationKey,
  });

  const sendData = isCreate ? postDataToEndpoint : putDataToEndpoint;

  const { isLoading, mutate } = useMutation<
    unknown,
    AxiosError<IError>,
    IEditUserGroup
  >(
    requestData => {
      const payload = { ...requestData, originator };
      return sendData({
        endpoint: Endpoints.EditUser,
        requestData: isCreate ? payload : [payload],
      });
    },
    {
      onSuccess: () => {
        // TODO: remove timeout when endpoint issues are sorted out.
        // https://heidi-pay.atlassian.net/browse/HC-8406
        window.setTimeout(() => {
          queryClient.invalidateQueries(queryKey);
        }, 500);
        message.success(successKey);
        modal.closeModal();
      },
      onError: error => {
        handleAxiosError(error, errorKey);
      },
    },
  );

  const handleSubmit = useCallback(
    (values: IEditUserGroup) => {
      mutate(values);
    },
    [mutate],
  );

  const modalContent = (
    <UserForm
      formID={formID}
      groups={groups ?? []}
      handleSubmit={handleSubmit}
      values={userProps}
    />
  );

  const modalProps = {
    formID,
    isFormProcessing: isLoading,
    isLoading,
    loadingOverlay: true,
    modalContent,
    showFormButtons: !isLoading,
    title: translate(title),
    variant,
  };

  return getModalDetails({ modal, modalProps });
};

export const useCreateUserModal = (props: IUserModalProps) =>
  useUserModal({
    ...props,
    permissions: ['merchant_portal.can_edit_user_settings'],
    buttonTranslationKey: 'PAGE.USERS.CREATE_A_NEW_USER',
    modalName: 'createUserModal',
    title: 'PAGE.USERS.CREATE_A_NEW_USER',
    formID: 'createUser',
    successKey: 'PAGE.USERS.USER_SUCCESSFULLY_ADDED',
    errorKey: 'PAGE.USERS.UNABLE_TO_ADD_USER',
    isCreate: true,
  });

export const useEditUserModal = (props: IUserModalProps) =>
  useUserModal({
    ...props,
    permissions: ['merchant_portal.can_edit_user_settings'],
    buttonTranslationKey: 'BUTTON.EDIT',
    modalName: 'editUserModal',
    title: 'PAGE.USERS.EDIT_USER',
    formID: `edit-${props.userProps?.external_uuid ?? ''}`,
    successKey: 'PAGE.USERS.USER_SUCCESSFULLY_EDITED',
    errorKey: 'PAGE.USERS.UNABLE_TO_EDIT_USER',
    isCreate: false,
  });
