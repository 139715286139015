import { Link } from '@components/Common.styles';
import { IPaymentPlanLinkProps } from './types';

export const PaymentPlanLink = ({
  contractId,
  paymentPlanId: paymentPlanRef,
}: IPaymentPlanLinkProps) => {
  return contractId ? (
    <Link to={`/track/${contractId}`}>{paymentPlanRef}</Link>
  ) : null;
};
