import { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { LinkThemed } from '@components/Common.styles';
import { useI18n } from '@hooks/useI18n/useI18n';

const TableCellStyled = styled(TableCell)`
  border-bottom: none;
  padding: 0 0 10px 0;
  font-size: 13px;
`;

interface IDataRow {
  key?: string;
  value?: string;
}

interface IDataTableProps {
  className?: string;
  rows: IDataRow[];
}

const DataTable = ({ className, rows }: IDataTableProps) => (
  <Table className={className}>
    <TableBody>
      {rows.map(({ key, value }) => (
        <TableRow key={key}>
          <TableCellStyled>{key ?? ''}</TableCellStyled>
          <TableCellStyled sx={{ textAlign: 'right' }}>
            {value ?? ''}
          </TableCellStyled>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export interface IPaymentPlanPorps {
  className?: string;
  rows: IDataRow[];
  scheduleRows: IDataRow[];
}

export const PaymentPlan = ({
  className,
  rows,
  scheduleRows,
}: IPaymentPlanPorps) => {
  const { translate } = useI18n();
  const [isScheduleExpanded, setIsScheduleExpanded] = useState(false);

  const handleScheduleLinkClick = useCallback(
    () => setIsScheduleExpanded(value => !value),
    [],
  );

  return (
    <>
      <DataTable className={className} rows={rows} />
      <LinkThemed mb="10px" onClick={handleScheduleLinkClick}>
        {translate('BUTTON.VIEW_PAYMENT_SCHEDULE')}
      </LinkThemed>
      <Collapse in={isScheduleExpanded}>
        <Box ml="2rem" mt="10px">
          <DataTable rows={scheduleRows} />
        </Box>
      </Collapse>
    </>
  );
};
