import { InputCheckbox, Label } from '@components/Common.styles';
import { TextField } from '@components/TextField';
import { Translate } from '@hooks/useI18n';

interface IToggleProps {
  checked: boolean;
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const Toggle = ({ checked, label, onChange }: IToggleProps) => (
  <Label checkbox={true} htmlFor="refundAmountToggle">
    <InputCheckbox
      checked={checked}
      id="refundAmountToggle"
      onChange={onChange}
    />{' '}
    {label}
  </Label>
);

interface IRefundFormFieldsProps {
  currency: string;
  isFullRefund: boolean;
  toggleRefundAmount: React.ChangeEventHandler<HTMLInputElement>;
  translate: Translate;
}

export const RefundFormFields = ({
  currency,
  isFullRefund,
  toggleRefundAmount,
  translate,
}: IRefundFormFieldsProps) => {
  return (
    <>
      <TextField
        id="payment_plan_reference"
        label={translate('TITLE.PAYMENT_PLAN_ID')}
        name="payment_plan_reference"
      />

      <Toggle
        checked={isFullRefund}
        label={translate('LABEL.FULL_REFUND')}
        onChange={toggleRefundAmount}
      />

      {isFullRefund ? null : (
        <TextField
          id="amount"
          label={`${translate('PAGE.ORDERS.AMOUNT_TO_REFUND')} (${currency})`}
          name="amount"
        />
      )}
    </>
  );
};
