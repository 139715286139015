export type SortType = string | number | Date;

export const sort = (a: SortType, b: SortType) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};
