import { IStatusLabel } from '@schemas/merchantPortal/types/tracking';

const successfulStatuses: IStatusLabel[] = [
  'Successful',
  'Active',
  'Awaiting Dispatch',
];

export const getIsApplicationSuccessful = (statusLabel: IStatusLabel) =>
  successfulStatuses.includes(statusLabel);
