import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useQuery } from '@tanstack/react-query';

interface IUseQueryCallbacks<TReturnData> {
  onError?: (error: AxiosError<IError>) => unknown;
  onSuccess?: (data: TReturnData) => void;
}

export const useQueryOnClick = <TReturnData, TRequestObj>(
  queryKey: string[],
  fetchQuery: (data: TRequestObj) => Promise<TReturnData>,
  callbacks: IUseQueryCallbacks<TReturnData>,
) => {
  const [fetchQueryArgs, setFetchQueryArgs] = useState<TRequestObj>(
    {} as TRequestObj,
  );
  const [isEnabled, setIsEnabled] = useState(false);
  const [callCount, setCallCount] = useState(0);

  const execute = (newData: TRequestObj) => {
    setFetchQueryArgs(newData);
    setIsEnabled(true);
  };

  const { data, error, isError, isLoading, isSuccess } = useQuery<
    TReturnData,
    AxiosError<IError>
  >([...queryKey, callCount], () => fetchQuery(fetchQueryArgs), {
    enabled: isEnabled,
    retry: 0,
    cacheTime: 0,
  });

  useEffect(() => {
    if (isEnabled && (isSuccess || isError)) {
      if (isError && error) {
        callbacks.onError?.(error);
      }

      if (isSuccess && data) {
        callbacks.onSuccess?.(data);
      }

      setIsEnabled(false);
      setFetchQueryArgs({} as TRequestObj);
      setCallCount(prev => prev + 1);
    }
  }, [
    data,
    isSuccess,
    callbacks,
    error,
    isError,
    isEnabled,
    queryKey,
    callCount,
  ]);

  return {
    execute,
    data,
    error,
    isLoading: isLoading && isEnabled,
    isError,
  };
};
